.cookies-policy-main-container {
  width: 100%;
  height: 3251px;
  background-color: #f8f8f8;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  font-family: "Catamaran";
}

.cookies-policy-actual-content-container {
  width: 1080px;
  height: 3251px;
  border-left: 1px solid rgb(144, 144, 144, 0.5);
  border-right: 1px solid rgb(144, 144, 144, 0.5);
  margin: 0px auto 0px auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* align-items: ; */
  justify-content: flex-start;
}

.cookies-policy-first-section {
  width: 1080px;
  height: auto;
  margin: 50px auto 0px auto;
  /* background-color: beige; */
}

.cookies-policy-heading1 {
  width: 100%;
  height: 40px;
  margin: 0px auto 0px auto;
}

.cookies-policy-heading1 p {
  width: 100%;
  height: 40px;
  margin: 0px auto 0px auto;
  text-align: center;
  font-size: 32px;
  letter-spacing: 0.32px;
  color: #000000;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
}

.cookies-policy-last-updated-div {
  width: 100%;
  height: 29px;
  margin: 23px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
  /* text-align: flex-end; */
}

.cookies-policy-last-updated-div p {
  width: 233px;
  height: 20px;
  text-align: right;
  /* font: normal normal medium 18px/32px Helvetica Neue; */
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
  margin: 0px 107px 0px auto;
}

.cookies-policy-description1 {
  width: 850px;
  height: auto;
  text-align: left;
  /* font: normal normal medium 16px/24px Helvetica Neue; */
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  color: #000000;
  opacity: 1;
  margin: 54px auto 0px auto;
  /* background-color: #f3f3f3;    */
}

.cookies-policy-description1 p {
  margin: 0px;
}

.cookies-policy-sub-section1 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section1-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section1-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section1-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section1-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section2 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section2-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section2-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section2-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section2-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section3 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section3-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section3-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section3-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section3-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section4 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section4-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section4-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  color: #000000;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
  font-style: italic !important;

}

.cookies-policy-sub-section4-description-column1 {
  width: 50%;
  height: 320px;
  border: 1px solid rgb(144, 144, 144);
  border-width: 1px 0.5px 1px 1px;
  margin: 0px;
  font-size: 15px;
  letter-spacing: 0.15px;
  line-height: 22.5px;
}

.cookies-policy-sub-section4-description-column2 {
  width: 50%;
  height: 320px;
  border: 1px solid rgb(144, 144, 144);
  margin: 0px;
  border-width: 1px 1px 1px 0.5px;
  font-size: 15px;
  letter-spacing: 0.15px;
  line-height: 22.5px;
}

.cookies-policy-sub-section4-description-column1 p {
  width: 90%;
  text-align: left;
  margin: 15px auto 12px auto;

}

.cookies-policy-sub-section4-description-column1 p:last-child {
  margin: 0px auto 0px auto;
}

.cookies-policy-sub-section4-description-column2 p {
  width: 90%;
  text-align: left;
  margin: 0px auto 2.5px auto;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.cookies-policy-sub-section4-description-column2 p:first-child {
  margin: 10px auto 5px auto;
}

.cookies-policy-sub-section4-description-column2 p:last-child {
  margin: 0px auto 0px auto;
}

/* .cookies-policy-sub-section4-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    font: normal normal medium 16px Helvetica;
  }
  .cookies-policy-sub-section4-description p:last-child {    
     margin: 0px;
  } */

.cookies-policy-description2 {
  width: 850px;
  height: auto;
  text-align: left;
  /* font: normal normal medium 16px/24px Helvetica Neue; */
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  color: #000000;
  opacity: 1;
  margin: 20px auto 0px auto;
  /* background-color: tomato; */
}

.cookies-policy-description2 p {
  margin: 0px;
}

.cookies-policy-sub-section5 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section5-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section5-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section5-description p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section6 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section6-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section6-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section6-description p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section6-description-table {
  width: 100%;
  height: 200px;
  margin: 12px auto 0px auto;
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: row;
  justify-content: baseline;

}

.cookies-policy-sub-section6-description-table-column1 {
  width: 50%;
  height: 200px;
  margin: 0px;
  border: 1px solid rgb(144, 144, 144);
  border-width: 1px 0.5px 1px 1px;
  font-size: 15px;
  letter-spacing: 0.15px;
  line-height: 22.5px;
}

.cookies-policy-sub-section6-description-table-column2 {
  width: 50%;
  height: 200px;
  margin: 0px;
  border: 1px solid rgb(144, 144, 144);
  border-width: 1px 1px 1px 0.5px;
  font-size: 15px;
  letter-spacing: 0.15px;
  line-height: 22.5px;
}

.cookies-policy-sub-section6-description-table-column1 p {
  width: 90%;
  text-align: left;
  margin: 15px auto 12px auto;
}

.cookies-policy-sub-section6-description-table-column1 p:last-child {
  margin: 0px auto 0px auto;
}

.cookies-policy-sub-section6-description-table-column2 p {
  width: 90%;
  text-align: left;
  margin: 0px auto 2px auto;
}

.cookies-policy-sub-section6-description-table-column2 p:first-child {
  margin: 10px auto 5px auto;
}

.cookies-policy-sub-section6-description-table-column2 p:last-child {
  margin: 0px auto 0px auto;
}

.cookies-policy-sub-section7 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section7-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section7-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section7-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section7-description ol {
  margin: 12px 0px 0px 0px;
}

.cookies-policy-sub-section7-description li {
  margin: 0px 0px 10px 0px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.cookies-policy-sub-section7-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section8 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section8-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section8-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section8-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section8-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section9 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section9-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section9-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section9-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section9-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section10 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section10-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section10-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section10-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-sub-section10-description p:last-child {
  margin: 0px;
}

.cookies-policy-sub-section11 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.cookies-policy-sub-section11-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.cookies-policy-sub-section11-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.cookies-policy-sub-section11-description p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.cookies-policy-go-to-top {
  width: 100%;
  height: auto;
  margin: 20px 0px 0px 0px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
}

@media only screen and (max-width: 440px) {
  .cookies-policy-main-container {
    max-width: 440px;
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
  }

  .cookies-policy-actual-content-container {
    width: 100%;
    height: auto;
    border: none;
    margin: 0px auto 0px auto;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 500;
  }

  .cookies-policy-first-section {
    width: 100%;
    height: auto;
    margin: 29px auto 0px auto;
    /* background-color: #e35f5f; */
    /* background-color: beige; */
  }

  .cookies-policy-heading1 {
    width: 100%;
    height: 40px;
    margin: 0px auto 0px auto;
  }

  .cookies-policy-heading1 p {
    width: 146px;
    height: 28px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .cookies-policy-last-updated-div {
    width: 95%;
    height: 15px;
    margin: 14px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .cookies-policy-last-updated-div p {
    width: 156px;
    height: 14px;
    text-align: right;
    /* font: normal normal medium 12px/32px Helvetica Neue; */
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    opacity: 1;
    letter-spacing: 0.12px;
    margin: 0px;
  }

  .cookies-policy-description1 {
    width: 90%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 12px/24px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #000000;
    opacity: 1;
    margin: 15px auto 0px auto;
    /* background-color: #e35f5f;    */
  }

  .cookies-policy-description1 p {
    margin: 0px;
  }

  .cookies-policy-sub-section1 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section1-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section1-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section2 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section2-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section2-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section2-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section3 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section3-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section3-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section3-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section4 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section4-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    color: #000000;
    /* font: normal normal medium 12px Helvetica; */
    font-style: italic !important;
    letter-spacing: 0.12px;
    line-height: 18px;

  }

  .cookies-policy-sub-section4-description-column1 {
    width: 50%;
    height: 260px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 0.5px 1px 1px;
    margin: 0px;
    font-size: 11px;
    letter-spacing: 0.11px;
    line-height: 16px;

  }

  .cookies-policy-sub-section4-description-column2 {
    width: 50%;
    height: 260px;
    border: 1px solid rgb(144, 144, 144);
    margin: 0px;
    border-width: 1px 1px 1px 0.5px;
    font-size: 10px;
    letter-spacing: 0.10px;
    line-height: 15px;
  }

  .cookies-policy-sub-section4-description-column1 p {
    width: 90%;
    text-align: left;

    margin: 15px auto 6px auto;

  }

  .cookies-policy-sub-section4-description-column1 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section4-description-column2 p {
    width: 90%;
    height: auto;
    text-align: left;
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section4-description-column2 p:first-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section4-description-column2 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-description2 {
    width: 90%;
    height: 46px;
    text-align: left;
    /* font: normal normal medium 12px/18px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #000000;
    opacity: 1;
    margin: 20px auto 0px auto;
    /* background-color: tomato; */
  }

  .cookies-policy-description2 p {
    margin: 0px;
  }

  .cookies-policy-sub-section5 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section5-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section5-description p {
    text-align: left;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section6 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section6-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section6-description p {
    text-align: left;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section6-description-table {
    width: 100%;
    height: 160px;
    margin: 12px auto 0px auto;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    font-style: italic !important;

  }

  .cookies-policy-sub-section6-description-table-column1 {
    width: 50%;
    height: 160px;
    margin: 0px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 0.5px 1px 1px;
    font-size: 11px;
    letter-spacing: 0.11px;
    line-height: 16.5px;
  }

  .cookies-policy-sub-section6-description-table-column2 {
    width: 50%;
    height: 160px;
    margin: 0px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 1px 1px 0.5px;
    font-size: 11px;
    letter-spacing: 0.11px;
    line-height: 16.5px;
  }

  .cookies-policy-sub-section6-description-table-column1 p {
    width: 90%;
    text-align: left;
    margin: 10px auto 6px auto;
  }

  .cookies-policy-sub-section6-description-table-column1 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p {
    width: 90%;
    text-align: left;
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p:first-child {
    margin: 15px auto 5px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section7 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section7-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section7-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section7-description ol {
    margin: 10px 0px 0px 0px;
  }

  .cookies-policy-sub-section7-description li {
    margin: 0px 0px 6px 0px;
    letter-spacing: 0.12px;
    line-height: 18px;
  }

  .cookies-policy-sub-section7-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section8 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section8-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section8-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section8-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section8-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section9 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section9-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section9-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section9-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section9-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section10 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section10-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section10-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section10-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-sub-section10-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section11 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section11-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section11-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section11-description p {
    text-align: left;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .cookies-policy-go-to-top {
    width: 100%;
    height: auto;
    margin: 20px 0px 20px -20px;
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .cookies-policy-main-container {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
  }

  .cookies-policy-actual-content-container {
    width: 90%;
    height: auto;
    border-left: none;
    border-right: none;
    margin: 0px auto 0px auto;
    background: none;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
    /* align-items: ; */
    justify-content: flex-start;
  }

  .cookies-policy-first-section {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
    /* background-color: beige; */
  }

  .cookies-policy-heading1 {
    width: 100%;
    height: 40px;
    margin: 0px auto 0px auto;
  }

  .cookies-policy-heading1 p {
    width: 100%;
    height: 40px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.32px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .cookies-policy-last-updated-div {
    width: 100%;
    height: 29px;
    margin: 23px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .cookies-policy-last-updated-div p {
    width: 233px;
    height: 20px;
    text-align: right;
    /* font: normal normal medium 18px/32px Helvetica Neue; */
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0px;
    margin: 0px 0px 0px auto;
  }

  .cookies-policy-description1 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
    /* background-color: #f3f3f3;    */
  }

  .cookies-policy-description1 p {
    margin: 0px;
  }

  .cookies-policy-sub-section1 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section1-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section1-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section2-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section2-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section2-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section3 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section3-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section3-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section3-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section4 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section4-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    color: #000000;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
    font-style: italic !important;

  }

  .cookies-policy-sub-section4-description-column1 {
    width: 50%;
    height: 320px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 0.5px 1px 1px;
    margin: 0px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section4-description-column2 {
    width: 50%;
    height: 320px;
    border: 1px solid rgb(144, 144, 144);
    margin: 0px;
    border-width: 1px 1px 1px 0.5px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section4-description-column1 p {
    width: 90%;
    text-align: left;
    margin: 15px auto 12px auto;

  }

  .cookies-policy-sub-section4-description-column1 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section4-description-column2 p {
    width: 90%;
    text-align: left;
    margin: 0px auto 2.5px auto;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .cookies-policy-sub-section4-description-column2 p:first-child {
    margin: 10px auto 5px auto;
  }

  .cookies-policy-sub-section4-description-column2 p:last-child {
    margin: 0px auto 0px auto;
  }

  /* .cookies-policy-sub-section4-description p {
      text-align: left;
      margin: 0px 0px 12px 0px;
      font-size: 16px;
      font: normal normal medium 16px Helvetica;
    }
    .cookies-policy-sub-section4-description p:last-child {    
       margin: 0px;
    } */

  .cookies-policy-description2 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 20px auto 0px auto;
    /* background-color: tomato; */
  }

  .cookies-policy-description2 p {
    margin: 0px;
  }

  .cookies-policy-sub-section5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section5-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section5-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section6-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section6-description-table {
    width: 100%;
    height: 200px;
    margin: 12px auto 0px auto;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: row;
    justify-content: baseline;

  }

  .cookies-policy-sub-section6-description-table-column1 {
    width: 50%;
    height: 200px;
    margin: 0px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 0.5px 1px 1px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section6-description-table-column2 {
    width: 50%;
    height: 200px;
    margin: 0px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 1px 1px 0.5px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section6-description-table-column1 p {
    width: 90%;
    text-align: left;
    margin: 15px auto 12px auto;
  }

  .cookies-policy-sub-section6-description-table-column1 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p {
    width: 90%;
    text-align: left;
    margin: 0px auto 2px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p:first-child {
    margin: 10px auto 5px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section7 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section7-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section7-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section7-description ol {
    margin: 12px 0px 0px 0px;
  }

  .cookies-policy-sub-section7-description li {
    margin: 0px 0px 10px 0px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .cookies-policy-sub-section7-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section8 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section8-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section8-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section8-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section8-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section9 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section9-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section9-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section9-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section9-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section10 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section10-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section10-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section10-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section10-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section11 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section11-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section11-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section11-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-go-to-top {
    width: 100%;
    height: auto;
    margin: 40px 0px 20px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .cookies-policy-main-container {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
  }

  .cookies-policy-actual-content-container {
    width: 90%;
    height: auto;
    border-left: none;
    border-right: none;
    margin: 0px auto 0px auto;
    background: none;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
    /* align-items: ; */
    justify-content: flex-start;
  }

  .cookies-policy-first-section {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
    /* background-color: beige; */
  }

  .cookies-policy-heading1 {
    width: 100%;
    height: 40px;
    margin: 0px auto 0px auto;
  }

  .cookies-policy-heading1 p {
    width: 100%;
    height: 40px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.32px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .cookies-policy-last-updated-div {
    width: 100%;
    height: 29px;
    margin: 23px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .cookies-policy-last-updated-div p {
    width: 233px;
    height: 20px;
    text-align: right;
    /* font: normal normal medium 18px/32px Helvetica Neue; */
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0px;
    margin: 0px 0px 0px auto;
  }

  .cookies-policy-description1 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
    /* background-color: #f3f3f3;    */
  }

  .cookies-policy-description1 p {
    margin: 0px;
  }

  .cookies-policy-sub-section1 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section1-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section1-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section2-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section2-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section2-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section3 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section3-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section3-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section3-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section4 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section4-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    color: #000000;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
    font-style: italic !important;

  }

  .cookies-policy-sub-section4-description-column1 {
    width: 50%;
    height: 320px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 0.5px 1px 1px;
    margin: 0px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section4-description-column2 {
    width: 50%;
    height: 320px;
    border: 1px solid rgb(144, 144, 144);
    margin: 0px;
    border-width: 1px 1px 1px 0.5px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section4-description-column1 p {
    width: 90%;
    text-align: left;
    margin: 15px auto 12px auto;

  }

  .cookies-policy-sub-section4-description-column1 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section4-description-column2 p {
    width: 90%;
    text-align: left;
    margin: 0px auto 2.5px auto;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .cookies-policy-sub-section4-description-column2 p:first-child {
    margin: 10px auto 5px auto;
  }

  .cookies-policy-sub-section4-description-column2 p:last-child {
    margin: 0px auto 0px auto;
  }

  /* .cookies-policy-sub-section4-description p {
      text-align: left;
      margin: 0px 0px 12px 0px;
      font-size: 16px;
      font: normal normal medium 16px Helvetica;
    }
    .cookies-policy-sub-section4-description p:last-child {    
       margin: 0px;
    } */

  .cookies-policy-description2 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 20px auto 0px auto;
    /* background-color: tomato; */
  }

  .cookies-policy-description2 p {
    margin: 0px;
  }

  .cookies-policy-sub-section5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section5-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section5-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section6-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section6-description-table {
    width: 100%;
    height: 200px;
    margin: 12px auto 0px auto;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: row;
    justify-content: baseline;

  }

  .cookies-policy-sub-section6-description-table-column1 {
    width: 50%;
    height: 200px;
    margin: 0px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 0.5px 1px 1px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section6-description-table-column2 {
    width: 50%;
    height: 200px;
    margin: 0px;
    border: 1px solid rgb(144, 144, 144);
    border-width: 1px 1px 1px 0.5px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22.5px;
  }

  .cookies-policy-sub-section6-description-table-column1 p {
    width: 90%;
    text-align: left;
    margin: 15px auto 12px auto;
  }

  .cookies-policy-sub-section6-description-table-column1 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p {
    width: 90%;
    text-align: left;
    margin: 0px auto 2px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p:first-child {
    margin: 10px auto 5px auto;
  }

  .cookies-policy-sub-section6-description-table-column2 p:last-child {
    margin: 0px auto 0px auto;
  }

  .cookies-policy-sub-section7 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section7-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section7-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section7-description ol {
    margin: 12px 0px 0px 0px;
  }

  .cookies-policy-sub-section7-description li {
    margin: 0px 0px 10px 0px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .cookies-policy-sub-section7-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section8 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section8-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section8-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section8-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section8-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section9 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section9-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section9-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section9-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section9-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section10 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section10-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section10-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section10-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-sub-section10-description p:last-child {
    margin: 0px;
  }

  .cookies-policy-sub-section11 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .cookies-policy-sub-section11-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .cookies-policy-sub-section11-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .cookies-policy-sub-section11-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .cookies-policy-go-to-top {
    width: 100%;
    height: auto;
    margin: 40px 0px 20px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}