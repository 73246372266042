*::-webkit-scrollbar {
  width: thin;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar {
  display: none;
}

.main-container1 {
  /* max-width: 100%; */
  width: 100%;
  /* max-height: 4790px; */
  height: 3980px;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  /* justify-content: center; */
}

.bbw-section-container1 {
  width: 100%;
  height: 568px !important;
  margin: 0px auto 0px auto !important;
  position: relative;
}

.bbw-section1-container1-bottom-layer {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 568px;
  background: transparent linear-gradient(63deg,
      rgb(157, 198, 216, 0.5) 0%,
      rgb(75, 121, 154, 0.75) 100%) 0% 0% no-repeat padding-box;
}

.bbw-section1-container1-upper-layer {
  height: 568px !important;
  width: 1080px;
  margin: 0px auto 0px auto !important;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  position: relative;
  display: flex;
  flex-direction: row;
}

.bbw-section1-mobile-div {
  width: 360px;
  height: 484px;
  margin: 83px 0px 0px 68px;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: transparent transparent;
}

.bbw-section1-mobile-div-img {
  width: 360px;
  height: 736px;
  margin: 0px auto 0px auto;
}

.bbw-section1-mobile-div-img img {
  width: 90%;
  height: 100%;
  /* object-fit: fill; */
  margin: 0px auto 0px auto;
}

.bbw-section1-part2 {
  width: 608px;
  height: 501px;
  margin: 59px 0px 0px 20px;
  display: flex;
  flex-direction: column;
}

.section1-title-container {
  width: 442px;
  height: 111px;
  display: flex;
  flex-direction: row;
  margin: 30px 0px 0px 0px;
  /* border: 1px solid #000; */
  position: relative;
}

.section1-title-decoration {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
}

.weather-star1 {
  width: 50px;
  height: 50px;
  margin: 72px 0px 0px 0px;
}

.section1-title-div {
  width: auto;
  height: auto;
  font-size: 36px;
  text-align: left;
  color: #427cac;
  opacity: 1;
  margin: auto;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0.45px;
}

.weather-star2 {
  width: 75px;
  height: 75px;
  margin: -30px 0px 0px 25px;
}

.section1-description-container {
  width: 100%;
  height: 237px;
  position: relative;
  margin: 15px 0px 0px 0px;
}

.section1-part2-bottom-layer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: -1;
  height: 229px;
  width: 239px;
  margin: -107px 0px 0px 396px;
}

.section1-part2-bottom-layer-img {
  height: 229px;
  width: 239px;
  background-image: url("../Assets/BestBudgetWay/star.svg");
  background-size: 239px 228px;
}

.section1-description-text {
  margin: -26px 0px 0px 20px;
  width: 430px;
  height: 172px;
  text-align: center;
  font-size: 18px;
  font-family: "Catamaran";
  line-height: 27px;
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.18px;
}

.start-your-free-trial2 {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: #427bac;
  color: #ffffff;
  border: 2px solid #ffffff;
  margin: 32px auto 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.start-your-free-trial2 p {
  height: auto;
  font-size: 18px;
  letter-spacing: 0.18px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
}

.start-your-free-trial2:hover {
  color: #FFFFFF;
  background-color: #48588D;

}

.section1-description-text p {
  margin: 0px 0px 10px 0px;
}

.section1-description-text p:last-child {
  margin: 0px;
}

.section-footer-image {
  width: 250px;
  height: 164px;
  margin: -28px 0px 0px 284px;
}

.section-footer-image img {
  width: 250px;
  height: 164px;
}

.bbw-section-container2 {
  width: 1080px;
  height: 1026px !important;
  margin: 0px auto 0px auto !important;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  display: flex;
  flex-direction: column;
}

.section2-title-div {
  width: 100%;
  height: 40px;
  margin: 60px auto 0px auto;
}

.section2-title-div p {
  text-align: center;
  font-size: 36px;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 0.36px;
  color: #333333;
  margin: 0px auto;
  width: auto;
  height: auto;
}

.section2-first-cotainer {
  width: 790px;
  height: 395px;
  margin: 40px auto 0px auto;
  /* background: #770707; */
  display: flex;
  flex-direction: row;
}

.intelligent-budgeting {
  width: 355px;
  height: 395px;
  border-radius: 13px;
  margin: 0px 40px 0px 0px;
  background: transparent linear-gradient(138deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
}

.allocate-all-categories {
  width: 326px;
  height: 233px;
  margin: 17px auto 0px auto;
}

.allocate-all-categories img {
  width: 100%;
  height: 100%;
}

.intelligent-budgeting-title {
  width: auto;
  height: auto;
  margin: 17px auto 0px auto;
  font-size: 20px;
  font-family: "Catamaran";
  font-weight: bold;
  letter-spacing: 0.2px;
}

.intelligent-budgeting-description {
  width: 330px;
  height: 36px;
  text-align: center;
  margin: 5px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.bulk-actions-transactions {
  width: 356px;
  height: 395px;
  border-radius: 13px;
  margin: 0px 0px 0px 40px;
  background: transparent linear-gradient(222deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
}

.bulk-action-image {
  width: 326px;
  height: 233px;
  margin: 17px auto 0px auto;

}

.bulk-action-image img {
  width: 326px;
  height: 233px;
  margin: 0px;
  padding: 0px;
  object-fit: fill;
}

.bulk-action-title {
  width: 260px;
  height: auto;
  margin: 17px auto 0px auto;
  font-size: 20px;
  font-family: "Catamaran";
  letter-spacing: 0.20px;
  font-weight: bold;
  /* font-stretch: condensed; */
}

.bulk-action-description {
  width: 330px;
  height: 55px;
  text-align: center;
  margin: 5px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.section2-center-image {
  width: 923px;
  z-index: -1;
  height: 158px;
  margin: -63px 0px 0px 40px;
  background-image: url("../Assets/BestBudgetWay/centerImage.svg");
  background-size: 923px 158px;
}

.section2-second-cotainer {
  width: 790px;
  height: 395px;
  margin: -15px auto 0px auto;
  /* background: #770707; */
  display: flex;
  flex-direction: row;
}

.insights {
  width: 356px;
  height: 395px;
  border-radius: 13px;
  margin: 0px 40px 0px 0px;
  background: transparent linear-gradient(42deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
}

.insights-image {
  width: 326px;
  height: 233px;
  margin: 17px auto 0px auto;
}

.insights-image img {
  width: 326px;
  height: 233px;
}

.insights-title {
  width: 300px;
  height: 39px;
  margin: 17px auto 0px auto;
  font-size: 20px;
  font-family: "Catamaran";
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;
}

.insights-description {
  width: 330px;
  height: 68px;
  text-align: center;
  margin: 5px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.summary {
  width: 356px;
  height: 395px;
  border-radius: 13px;
  margin: 0px 0px 0px 40px;
  background: transparent linear-gradient(318deg, #e067c7 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
}

.summary-image {
  width: 326px;
  height: 233px;
  margin: 17px auto 0px auto;
}

.summary-image img {
  width: 100%;
  height: 100%;
}

.summary-title {
  width: auto;
  height: 39px;
  margin: 17px auto 0px auto;
  font-size: 20px;
  font-family: "Catamaran";
  font-weight: bold;
  letter-spacing: 0.20px;
  text-align: center;
}

.summary-description {
  width: 330px;
  height: 88px;
  text-align: center;
  margin: 5px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.bbw-section-container3 {
  width: 1080px !important;
  height: 553px !important;
  margin: 0px auto 0px auto !important;
  border-right: 1px solid rgb(191, 191, 191, 0.5) !important;
  border-left: 1px solid rgb(191, 191, 191, 0.5) !important;
  display: flex;
  flex-direction: column;
}

.section3-title-div {
  width: 100%;
  height: 40px;
  margin: 60px auto 0px auto;
}

.section3-title-div p {
  text-align: center;
  width: auto;
  height: auto;
  font-size: 36px;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  line-height: auto;
  letter-spacing: 0.36px;
  color: #333333;
  margin: 0px auto;
}

.section3-content-div {
  width: 893px;
  height: 387px;
  display: flex;
  flex-direction: row;
  margin: 40px auto 0px auto;
}

.all-bills-at-your-glance {
  width: 631px;
  height: 380px;
  border-radius: 13px;
  background: transparent linear-gradient(180deg, #fabd64 0%, #afe095 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
}

.all-bills-at-your-glance-div {
  width: 344px;
  height: 380px;
  display: flex;
  flex-direction: column;
  margin: 33px 0px 0px 28px;
}

.all-bills-at-your-glance-image {
  width: 326px;
  height: 183px;
}

.all-bills-at-your-glance-image img {
  width: 100%;
  height: 100%;
}

.all-bills-at-your-glance-title {
  width: 100%;
  height: 32px;
  margin: 25px auto 0px 20px;
  font-size: 24px;
  letter-spacing: 0.24px;
  font-family: "Catamaran";
  font-weight: bold;
  text-align: left;
}

.all-bills-at-your-glance-title p {
  /* text-align: center; */
  margin: 0px;
}

.all-bills-at-your-glance-description {
  width: 100%;
  height: 55px;
  margin: 6px auto 0px 20px;
  font-size: 18px;
  line-height: 27px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.18px;
  text-align: left;
}

.all-bills-at-your-glance-description p {
  width: 344px;
  /* text-align: center; */
  margin: 0px auto 0px auto;
}

.man-holding-card-div {
  width: 165px;
  height: 146px;
  margin: 143px 0px 0px 23px;
  background-image: url("../Assets/BestBudgetWay/manHoldingCard.png");
  background-size: 165px 146px;
}

.section3-part2-list {
  width: 350px;
  height: auto;
  margin: 0px 0px 0px 40px;
}

.mark-as-recurring {
  height: auto;
  width: 350px;
  margin: 15px auto 0px auto;
}

.mark-as-recurring img {
  width: 36px;
  height: 36px;
  margin: 0px 0px 0px 30px;
}

.mark-as-recurring-title {
  width: auto;
  height: auto;
  font-size: 20px;
  font-family: "Catamaran";
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.2px;
  text-align: left;
  margin: 6px 0px 0px 0px;
}

.mark-as-recurring-description {
  width: 350px;
  height: auto;
  margin: 0px 0px 0px 10px;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0.14px;
}

.set-frequency {
  height: auto;
  width: 350px;
  margin: 25px auto 0px auto;
}

.set-frequency img {
  width: 32px;
  height: 36px;
  margin: 0px 0px 0px 30px;
}

.set-frequency-title {
  width: auto;
  height: auto;
  font-size: 20px;
  font-family: "Catamaran";
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.20px;
  text-align: left;
  margin: 6px 0px 0px 0px;
}

.set-frequency-description {
  width: 350px;
  height: auto;
  margin: 0px 0px 0px 10px;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.approve-delete {
  height: auto;
  width: 350px;
  margin: 25px auto 0px auto;
}

.approve-delete img {
  width: 32px;
  height: 32px;
  margin: 0px 0px 0px 30px;
}

.approve-delete-title {
  width: auto;
  height: auto;
  font-size: 20px;
  font-family: "Catamaran";
  font-weight: bold;
  color: #333333;
  letter-spacing: 0.2px;
  text-align: left;
  margin: 6px 0px 0px 0px;
}

.approve-delete-description {
  width: 350px;
  height: auto;
  margin: 0px 0px 0px 10px;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.bbw-section-container4 {
  width: 1367px;
  height: 1543px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin: 0px auto 0px auto;
}

.bbw-section4-carousal-container {
  display: none;
}

.section-container4-bottom-layer {
  display: block;
  position: absolute;
  z-index: -1;
  width: 1080px;
  height: 1543px;
  margin: 0px auto 0px auto;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  /* background-color: #FABD64; */
}

.section-container4-upper-layer {
  width: 1536px;
  height: 1100px;
  margin: 68px 0px 0px 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  /* background-color: #3498db; */
}

/* .section-container4-upper-layer > div {
  background: transparent
    linear-gradient(124deg, #a1c3c3 0%, #c9adc3 48%, #84aaaf 100%) 0% 0%
    no-repeat padding-box; 
  box-shadow: inset 0px 6px 12px #00000029;
} */

.section-container4-upper-layer-container1 {
  width: 444px;
  height: 722px;
  background: transparent linear-gradient(302deg, #c9adc3 0%, #a1c3c3 100%);
  /* clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100% ); */
  margin: 0;
  padding: 0;
  border-right: 1px solid #ffffff;
}

.section-container4-upper-layer-container1-image {
  width: 338px;
  height: 613px;
  padding: 0px 0px 0px 0px;
  margin: 50.25px auto 0px auto;
}

.section-container4-upper-layer-container1-image img {
  width: 100%;
  height: 100%;
}

.section-container4-upper-layer-container2 {
  width: 250px;
  height: 948px;
  background: transparent linear-gradient(167deg, #b0bbc3 0%, #c9adc3 100%);
  clip-path: polygon(0 0, 40% 24%, 40% 100%, 0 76%);
  margin: 0px;
  padding: 0;
}

.section-container4-upper-layer-container3 {
  width: 444px;
  height: 722px;
  background: transparent linear-gradient(149deg, #b8b7c6 0%, #c8adc3 49%, #b8b7c6 100%);
  margin: 227px 0px 0px -150px;
  padding: 0;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.section-container4-upper-layer-container3-image {
  width: 338px;
  height: 613px;
  padding: 0px 0px 0px 0px;
  margin: 50.25px auto 0px auto;
}

.section-container4-upper-layer-container3-image img {
  width: 100%;
  height: 100%;
}

.section-container4-upper-layer-container4 {
  width: 252px;
  height: 948px;
  /* background-color: #cc3737; */
  background: transparent linear-gradient(351deg, #a3afb8 0%, #c9adc3 100%);
  clip-path: polygon(0 0, 40% 24%, 40% 100%, 0 76%);
  margin: 226px 0px 0px 0px;
  padding: 0;
}

.section-container4-upper-layer-container5 {
  width: 444px;
  height: 722px;

  background: transparent linear-gradient(131deg, #c1b2c3 0%, #92abb3 100%);
  margin: 452px 0px 0px -151.5px;
  padding: 0;
  border-left: 1px solid #ffffff;
  /* border-right: 1px solid #FFFFFF; */
}

.section-container4-upper-layer-container5-image {
  width: 338px;
  height: 613px;
  padding: 0px 0px 0px 0px;
  margin: 50.25px auto 0px auto;
}

.section-container4-upper-layer-container5-image img {
  width: 100%;
  height: 100%;
}

.section4-title-div {
  width: 492px;
  height: 85px;
  margin: 64px auto 0px auto;
  display: flex;
  align-items: center;
}

.bbw-section4-title-div {
  display: none;
}

.section4-title-div p {
  width: auto;
  height: auto;
  text-align: center;
  font-size: 36px;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  line-height: 42px;
  /* font-size: 45px; */
  color: #333333;
  margin: 24px auto 0px auto;
  letter-spacing: 0.36px;
}

.section4-first-image-container {
  width: 100%;
  height: 316px;
  display: flex;
  justify-content: flex-end;
  /* background-color: #84aaaf; */
  margin: 50px 0px 0px 250px;
}

.couple-sitting-on-bench img {
  width: 594px;
  height: 358px;
}

.section4-text-container {
  width: 100%;
  height: 395px;
  /* background-color: #333333; */
  margin: 280px auto 0px auto;
  display: flex;
  flex-direction: row;
}

.simple-budgeting {
  width: 400px;
  height: auto;
  /* background-color: #FABD64; */
  margin: 0px 0px 0px -140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
}

.simple-budgeting-title {
  width: 100%;
  height: 28px;
  font-size: 24px;
  font-family: "Catamaran";
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.24px;
  text-align: left;
  margin: 20px auto 0px auto;
}

.simple-budgeting-description {
  width: 300px;
  height: auto;
  margin: 15px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.1p6x;
  text-align: left;
  line-height: 24px;
  /* padding: auto; */
}

.knowledge-at-your-fingertips {
  width: 429px;
  height: 95px;
  /* background-color: #FABD64; */
  margin: 230px 0px 0px 165px;
}

.knowledge-at-your-fingertips-title {
  width: 429px;
  height: 28px;
  font-size: 24px;
  font-family: "Catamaran";
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.24px;
  text-align: center;
  margin: 20px auto 0px auto;
}

.knowledge-at-your-fingertips-description {
  width: 400px;
  height: auto;
  margin: 15px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  text-align: center;
  line-height: 24px;
}

.everything-at-a-glance {
  width: 325px;
  height: auto;
  /* background-color: #FABD64; */
  margin: 488px 0px 0px 165px;
}

.everything-at-a-glance-title {
  width: 325px;
  height: 28px;
  font-size: 24px;
  font-family: "Catamaran";
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.24px;
  text-align: right;
  margin: 0px auto 0px auto;
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

.everything-at-a-glance-description {
  width: 325px;
  height: auto;
  margin: 10px auto 0px auto;
  font-size: 16px;
  font-family: "Catamaran";
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.16px;
  text-align: right;
  line-height: 24px;
}

.bbw-section4-second-image-container {
  display: none;
}

.section4-second-image-container {
  width: 100%;
  height: 333px;
  display: flex;
  justify-content: flex-start;
  /* background-color: #84aaaf; */
  margin: 10px 0px 0px -140px;
}

.girl-watching-sunset img {
  width: 400px;
  height: 333px;
}

.bbw-section-container5 {
  width: 1080px;
  height: auto;
  /* background: transparent linear-gradient(63deg, rgb(157, 198, 216,0.5) 0%, rgb(75, 121, 154,0.75) 100%) 0% 0% no-repeat padding-box; */
  /* opacity: 0.5;   */
  margin: 20px auto 0px auto !important;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  /* padding-bottom: 150px; */
}

.ready-to-get-started-div {
  width: 435px;
  height: 200px;
  background: transparent linear-gradient(180deg, #c0e2e1 0%, #d9abd0 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  /* border: 1px solid #000000; */

  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

}

.ready-to-get-started-title {
  width: auto;
  height: 52px;
  color: #000000;
  font-size: 32px;
  font-family: "Catamaran";
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.32px;
  margin: 33px auto 0px auto;
}

.start-your-free-trial {
  width: 250px;
  height: 60px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #18485a;
  margin: 25px auto 0px auto;
  border: 0.6000000238418579px solid #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.start-your-free-trial p {
  font-size: 20px;
  letter-spacing: 0.2px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  /* font-size: 24px; */
  /* font-stretch: condensed; */
  padding: 0px 0px 0px 0px;
  cursor: pointer;
}

.start-your-free-trial:hover {
  background-color: #2c4e6c;
  color: #ffffff;
}

.go-to-page-top-container {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: flex-end;
  margin: 40px 0px 50px 0px;
}

@media only screen and (max-width: 440px) {
  .main-container1 {
    max-width: 440px;
    width: 100%;
    height: auto;
  }

  .bbw-section-container1 {
    width: 100%;
    height: 867px !important;
    margin: 0px auto 0px auto !important;
  }

  .bbw-section-container1 {
    width: 100%;
    height: 869px !important;
    margin: 0px auto 0px auto !important;
  }

  .bbw-section1-container1-bottom-layer {
    display: none;
  }

  .bbw-section1-container1-upper-layer {
    /* position: relative; */
    width: 100%;
    height: 869px !important;
    background: transparent linear-gradient(26deg,
        rgb(158, 199, 216, 0.5) 0%,
        rgb(75, 121, 154, 0.5) 100%) 0% 0% no-repeat padding-box;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .bbw-section1-part2 {
    width: 100%;
    height: 869px;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    overflow-x: hidden;
  }

  .section1-title-container {
    width: 95%;
    height: 91px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    margin: 0px auto 0px auto;
  }

  .section1-title-decoration {
    width: 85%;
    height: auto;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: -1;
  }

  .weather-star1 {
    width: 35px;
    height: 35px;
    margin: 79px 0px 0px 0px;
  }

  .section1-title-div {
    width: 100%;
    height: 27px;
    font-size: 24px !important;
    text-align: center;
    color: #427cac;
    opacity: 1;
    margin: 50px auto 0px auto;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0.24px;
  }

  .weather-star2 {
    width: 45px;
    height: 45px;
    margin: 10px 0px 0px 0px;
  }

  .section1-description-container {
    width: 100%;
    height: 108px;
    position: relative;
    margin: 18px 0px 0px 0px;
  }

  .section1-part2-bottom-layer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: -1;
    height: 300px;
    width: 100%;
    align-self: flex-start;
    margin: -20px 0px 0px 20px;
  }

  .section1-part2-bottom-layer-img {
    height: 300px;
    width: 303px;
    background-image: url("../Assets/BestBudgetWay/star.svg");
    background-size: 303px 300px;
    transform: rotate(14deg);
    margin: 0px;
  }

  .section1-description-text {
    margin: -15px auto 0px auto;
    width: 65%;
    height: auto;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.12px;
    line-height: 18px;
  }

  .section1-description-text p {
    margin: 0px 0px 10px 0px;
  }

  .section1-description-text p:last-child {
    margin: 0px;
  }

  .start-your-free-trial2 {
    width: 125px;
    height: 35px;
    border-radius: 6px;
    background-color: #427bac;
    color: #ffffff;
    margin: 20px auto 0px auto;
    border: 1.2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .start-your-free-trial2 p {
    font-size: 12px;
    letter-spacing: 0.12px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
  }

  .section-footer-image {
    width: 100%;
    height: 145px;
    margin: 505px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .section-footer-image img {
    width: 181px !important;
    height: 125px !important;
    margin: 0px;
  }

  .bbw-section1-mobile-div {
    width: 220px;
    height: 437px;
    margin: -590px auto 0px auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
  }

  .bbw-section1-mobile-div-img {
    width: 210px;
    height: 390px;
    display: flex;
    justify-content: center;
  }

  .bbw-section1-mobile-div-img img {
    width: 90%;
    height: 390px;
    object-fit: fill;
    margin: 0px auto 0px auto;
  }

  .bbw-section-container2 {
    width: 100%;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    border-right: none;
    border-left: none;
  }

  .section2-title-div {
    width: 100%;
    height: 27px;
    margin: 66px auto 0px auto;
  }

  .section2-title-div p {
    text-align: center;
    font-size: 24px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    color: #333333;
    margin: 0px;
    letter-spacing: 0.24px;
  }

  .section2-first-cotainer,
  .section2-second-cotainer {
    display: none;
  }

  .bbw-section2-carousal-container {
    width: 97%;
    height: 427px !important;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bbw-section2-carousal {
    width: 80%;
    height: 340px !important;
    border-radius: 16px;
    background: transparent linear-gradient(137deg, #e269c9 0%, #7fc6c6 100%);
    margin: 0px auto 1 0px auto;
  }

  .section2-slide {
    height: 340px;
    display: none;
    flex-direction: column;
  }

  .active {
    width: 100%;
    height: 340px;
    display: flex;
    margin: 0px auto 0px auto;
    align-items: center;
  }

  .slide-btn {
    margin: 0px auto;
  }

  .allocate-all-categories-image {
    width: 88%;
    height: auto;
    margin: 25px auto 0px auto;
  }

  .allocate-all-categories-image img {
    width: 100%;
    height: auto;
  }

  .intelligent-budgeting-title {
    width: 100%;
    height: 27px;
    margin: 17px auto 0px auto;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }

  .intelligent-budgeting-description {
    width: 90%;
    height: 55px;
    text-align: center;
    margin: 10px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0px;
    line-height: 15px;
  }

  .bulk-action-image {
    width: 88%;
    height: auto;
    margin: 25px auto 0px auto;
  }

  .bulk-action-image img {
    width: 100%;
    height: auto;
  }

  .bulk-action-title {
    width: 100%;
    height: 27px;
    margin: 17px auto 0px auto;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .bulk-action-description {
    width: 90%;
    height: 55px;
    text-align: center;
    margin: 10px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    line-height: 15px;
  }

  .insights-image {
    width: 88%;
    height: auto;
    margin: 25px auto 0px auto;
  }

  .insights-image img {
    width: 100%;
    height: auto;
  }

  .insights-title {
    width: 100%;
    height: 27px;
    margin: 17px auto 0px auto;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .insights-description {
    width: 90%;
    height: 55px;
    text-align: center;
    margin: 10px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    line-height: 15px;
  }

  .summary-image {
    width: 88%;
    height: auto;
    margin: 25px auto 0px auto;
  }

  .summary-image img {
    width: 100%;
    height: auto;
  }

  .summary-title {
    width: 100%;
    height: 27px;
    margin: 17px auto 0px auto;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }

  .summary-description {
    width: 90%;
    height: 55px;
    text-align: center;
    margin: 10px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    line-height: 15px;
  }

  .section2-center-image {
    width: 93%;
    z-index: -1;
    height: 125px;
    margin: -80px auto 0px auto;
    background-image: url("../Assets/BestBudgetWay/centerImage.svg");
    background-size: 100% 125px;
  }

  .section2-radio-controls {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px auto 0px auto;
  }

  .section2-radio-container {
    margin: 0px 10px 0px 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(125, 125, 125, 0.726);
    cursor: pointer;
  }

  .section2-radio-container.checked {
    background-color: #000000;
    border-color: #000000;
  }

  .bbw-section-container3 {
    width: 100% !important;
    height: 100% !important;
    margin: 0px auto 0px auto !important;
    border-right: none !important;
    border-left: none !important;
    display: flex;
    flex-direction: column;
  }

  .section3-title-div {
    width: 80%;
    height: auto;
    margin: 0px auto 0px auto;
    display: flex;
    justify-content: center;
  }

  .section3-title-div p {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 24px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    color: #333333;
    letter-spacing: 0.24px;
    line-height: 36px;
    margin: 0px auto 0px auto;
  }

  .section3-content-div {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 31px auto 0px auto;
  }

  .all-bills-at-your-glance {
    width: 100%;
    height: 337px;
    border-radius: 16px;
    margin: 0px auto 0px auto;
    background: transparent linear-gradient(180deg, #fabd64 0%, #afe095 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
  }

  .all-bills-at-your-glance-div {
    width: 273px;
    height: 337px;
    display: flex;
    flex-direction: column;
    margin: 29px 0px 0px 21px;
  }

  .all-bills-at-your-glance-image {
    width: 100%;
    height: auto;
  }

  .all-bills-at-your-glance-image img {
    width: 100%;
    height: 100%;
  }

  .all-bills-at-your-glance-title {
    width: 100%;
    height: 21px;
    margin: 19px auto 0px auto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.16px;
  }

  .all-bills-at-your-glance-title p {
    text-align: left;
    margin: 0px;
  }

  .all-bills-at-your-glance-description {
    width: 100%;
    height: auto;
    margin: 8px 0px 0px 3px;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    line-height: 15px;
  }

  .all-bills-at-your-glance-description p {
    width: 80%;
    text-align: left;
    margin: 0px;
  }

  .man-holding-card-div {
    width: 30%;
    height: 118px;
    z-index: 1;
    margin: 182px 0px 0px -50px;
    background-image: url("../Assets/BestBudgetWay/manHoldingCard.png");
    background-size: 100% 100%;
  }

  .section3-part2-list {
    width: 95%;
    height: auto;
    margin: 22px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .mark-as-recurring {
    height: auto;
    width: 42%;
    margin: 0px 5px 0px 0px;
  }

  .mark-as-recurring img {
    width: 20px;
    height: 20px;
    margin: 5px auto 0px 5px;
  }

  .mark-as-recurring-title {
    width: 100px;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.16px;
    text-align: left;
    margin: 8px 0px 0px 0px;
    line-height: 20px;
  }

  .mark-as-recurring-description {
    width: 100%;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    text-align: left;
    line-height: 15px;
  }

  .set-frequency {
    height: auto;
    width: 42%;
    margin: 0px 5px 0px 5px;
  }

  .set-frequency img {
    width: 20px;
    height: 20px;
    margin: 5px auto 0px 5px;
  }

  .set-frequency-title {
    width: 80px;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.16px;
    text-align: left;
    margin: 8px 0px 0px 0px;
    line-height: 20px;
  }

  .set-frequency-description {
    width: 100%;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    text-align: left;
    line-height: 15px;
  }

  .approve-delete {
    height: auto;
    width: 42%;
    margin: 0px 0px 0px 5px;
  }

  .approve-delete img {
    width: 20px;
    height: 20px;
    margin: 5px auto 0px 5px;
  }

  .approve-delete-title {
    width: 100px;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.16px;
    text-align: left;
    line-height: 20px;
    margin: 8px 0px 0px 0px;
  }

  .approve-delete-description {
    width: 100%;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    text-align: left;
    line-height: 15px;
  }

  .bbw-section-container4 {
    width: 100% !important;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
  }

  .section-container4-bottom-layer {
    display: none;
  }

  .section-container4-upper-layer {
    display: none;
  }

  /* .section4-title-div,.section4-first-image-container,.section4-second-image-container {
 display: none;
} */

  .bbw-section4-title-div {
    width: 100%;
    height: auto;
    margin: 65px auto 0px auto !important;
    display: flex;
  }

  .bbw-section4-title-div p {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    color: #333333;
    margin: 50px auto 0px auto;
    letter-spacing: 0px;
  }

  .bbw-section4-carousal-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100% !important;
    height: 780px;
    margin: 28px auto 0px auto;
  }

  .section4-carousal-part1 {
    width: 100% !important;
    height: auto;
    background: transparent linear-gradient(145deg, #a1c3c3 0%, #c9adc3 48%, #84aaaf 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bbw-section4-carousal {
    width: 68%;
    height: 87%;
    margin: 0px auto 0px auto;
  }

  .section4-slide {
    display: none;
    width: 100%;
    height: 100%;
    /* border: 1px solid #000000; */
  }

  .active-slide {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .section-container4-upper-layer-container1-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container1-image img {
    width: 100%;
    height: 100%;
  }

  .section-container4-upper-layer-container3-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container3-image img {
    width: 100%;
    height: 100%;
  }

  .section-container4-upper-layer-container5-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container5-image img {
    width: 100%;
    height: 100%;
  }

  .section4-radio-controls {
    width: 100%;
    height: 20px;
    display: flex;
    /* border: 1px solid #000000; */
    align-items: center;
    justify-content: center;
    margin: 8px auto 0px auto;
  }

  .section4-radio-container {
    margin: 0px 10px 0px 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(125, 125, 125, 0.726);
    cursor: pointer;
  }

  .section4-radio-container.checked {
    background-color: #000000;
    border-color: #000000;
  }

  .section4-slide-btn {
    margin: 0px auto;
  }

  .section4-carousal-part2 {
    width: 100% !important;
    height: 80px;
    margin: 10px auto 0px auto;
  }

  .section4-description {
    display: none;
  }

  .active-description {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    margin: 0px auto 0px auto;
  }

  .section4-carousal-title {
    width: 90%;
    height: 17px;
    margin: 0px auto 0px auto;
  }

  .section4-carousal-description {
    width: 90%;
    height: 45px;
    margin: 10px auto 0px auto;
  }

  .simple-budgeting-title {
    width: auto;
    height: 17px;
    font-size: 16px;
    /* font-family: "Helvetica Neue Bold", sans-serif; */
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.16px;
    text-align: center;
    margin: 5px auto 0px auto;
  }

  .simple-budgeting-description {
    width: 250px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 12px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.12px;
    text-align: center;
    line-height: 15px;
    /* padding: auto; */
  }

  .knowledge-at-your-fingertips-title {
    width: 224px;
    height: auto;
    font-size: 16px;
    /* font-family: "Helvetica Neue Bold", sans-serif; */
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.16px;
    text-align: center;
    margin: 5px auto 0px auto;
  }

  .knowledge-at-your-fingertips-description {
    width: 250px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 12px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
  }

  .everything-at-a-glance-title {
    width: 175px;
    height: auto;
    font-size: 16px;
    /* font-family: "Helvetica Neue Bold", sans-serif; */
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.16px;
    text-align: center;
    margin: 5px auto 0px auto;
  }

  .everything-at-a-glance-description {
    width: 250px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 13px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0px;
    text-align: center;
    line-height: 15px;
  }

  .bbw-section4-second-image-container {
    width: 100%;
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #84aaaf; */
    margin: 5px auto 0px auto;
  }

  .bbw-girl-watching-sunset img {
    width: 265px;
    height: 195px;
    margin: 0px auto 0px auto;
  }

  .bbw-section-container5 {
    width: 100% !important;
    height: auto !important;
    margin: 80px auto 0px auto !important;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
  }

  .ready-to-get-started-div {
    width: 243px;
    height: 110px;
    background: transparent linear-gradient(180deg, #c0e2e1 0%, #d9abd0 100%);
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ready-to-get-started-title {
    width: auto;
    height: 22px;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 20px auto 0px auto;
  }

  .start-your-free-trial {
    width: 149px;
    height: 37px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #18485a;
    margin: 15px auto 0px auto;
    border: 0.6000000238418579px solid #bfbfbf;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .start-your-free-trial p {
    font-size: 12px;
    letter-spacing: 0.12px;
    text-align: center;
    /* font-family: "Helvetica Neue Condensed Bold", sans-serif; */
    font-weight: 500;
    /* font-stretch: condensed; */
    padding: 0px 0px 0px 0px;
    cursor: pointer;
  }

  .start-your-free-trial:hover {
    background-color: #2c4e6c;
    color: #ffffff;
  }

  .go-to-page-top-container {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    margin: 20px 25px 0px 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .main-container1 {
    /* max-width: 100%; */
    width: 100%;
    /* max-height: 4790px; */
    height: auto;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;

    /* justify-content: center; */
  }

  .bbw-section-container1 {
    width: 100%;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    position: relative;
  }

  .bbw-section1-container1-bottom-layer {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 568px;
    background: transparent linear-gradient(63deg,
        rgb(157, 198, 216, 0.5) 0%,
        rgb(75, 121, 154, 0.75) 100%) 0% 0% no-repeat padding-box;
  }

  .bbw-section1-container1-upper-layer {
    height: 568px !important;
    width: 100%;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    position: relative;
    display: flex;
    flex-direction: row;

  }

  .bbw-section1-mobile-div {
    width: 33%;
    height: 430px;
    margin: 83px auto 0px auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: transparent transparent;
    /* border: 1px solid #FFF; */
  }

  .bbw-section1-mobile-div-img {
    width: 90%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .bbw-section1-mobile-div-img img {
    width: 100%;
    height: auto;
    /* object-fit: fill; */
    margin: 0px auto 0px auto;
  }

  .bbw-section1-part2 {
    width: 56%;
    height: auto;
    margin: 59px auto 0px auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #FFF; */
  }

  .section1-title-container {
    width: 98%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 30px auto 0px auto;
    /* border: 1px solid #ff0000; */
    position: relative;
  }

  .section1-title-decoration {
    width: 100%;
    height: auto;
    display: flex;
    margin: -20px auto 0px auto;
    justify-content: space-between;
    position: absolute;
    z-index: -1;
    /* border: 1px solid #FFF; */
  }

  .weather-star1 {
    width: 40px;
    height: 40px;
    margin: 50px 0px 0px 0px;
    /* border: 1px solid #FFF; */
  }

  .section1-title-div {
    width: auto;
    height: auto;
    font-size: 36px;
    text-align: left;
    color: #427cac;
    opacity: 1;
    margin: auto;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0.36px;
  }

  .weather-star2 {
    width: 55px;
    height: 55px;
    margin: -45px 0px 0px 55px;
    /* border: 1px solid #ff0000; */
  }

  .section1-description-container {
    width: 100%;
    height: auto;
    position: relative;
    margin: 10px auto 0px auto;
    display: flex;
    /* border: 1px solid #FFF; */
  }

  .section1-part2-bottom-layer {
    display: flex;
    position: absolute;
    z-index: -1;
    /* border: 1px solid #fff; */
    height: 100%;
    width: 100%;
    left: auto;
    margin: 0px auto 0px auto !important;
  }

  .section1-part2-bottom-layer-img {
    height: 100%;
    width: 100%;
    margin: -40px 0px 0px 190px;
    /* border: 1px solid #fff; */
    background-image: url("../Assets/BestBudgetWay/star.svg");
    background-size: 100% 100%;
  }

  .section1-description-text {
    margin: 0px auto 0px auto;
    width: 80%;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    line-height: 24px;
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.16px;
  }

  .start-your-free-trial2 {
    width: 200px;
    height: 50px;
    border-radius: 8px;
    background-color: #427bac;
    color: #ffffff;
    border: 2px solid #ffffff;
    margin: 32px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .start-your-free-trial2 p {
    height: auto;
    font-size: 16px;
    letter-spacing: 0.16px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
  }

  .start-your-free-trial2:hover {
    color: #FFFFFF;
    background-color: #48588D;

  }

  .section1-description-text p {
    margin: 0px 0px 10px 0px;
  }

  .section1-description-text p:last-child {
    margin: 0px;
  }

  .section-footer-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: end;
    margin: 20px 0px 0px auto;
  }

  .section-footer-image img {
    width: 250px;
    height: 164px;
  }

  .bbw-section-container2 {
    width: 100%;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    display: flex;
    flex-direction: column;
  }

  .section2-title-div {
    width: 100%;
    height: auto;
    margin: 60px auto 0px auto;
  }

  .section2-title-div p {
    text-align: center;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0.36px;
    color: #333333;
    margin: 0px auto;
    width: auto;
    height: auto;
  }

  .section2-first-cotainer {
    width: 73%;
    height: auto;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .intelligent-budgeting {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px auto 0px 0px;
    background: transparent linear-gradient(138deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
  }

  .allocate-all-categories {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
  }

  .allocate-all-categories img {
    width: 100%;
    height: 100%;
  }

  .intelligent-budgeting-title {
    width: auto;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 19px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.18px;
  }

  .intelligent-budgeting-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .bulk-actions-transactions {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px 0px 0px auto;
    background: transparent linear-gradient(222deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
  }

  .bulk-action-image {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .bulk-action-image img {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    object-fit: fill;
  }

  .bulk-action-title {
    width: 100%;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 18px;
    font-family: "Catamaran";
    letter-spacing: 0.18px;
    font-weight: bold;
    text-align: center;
    /* font-stretch: condensed; */
  }

  .bulk-action-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .section2-center-image {
    width: 85%;
    z-index: -1;
    height: 158px;
    margin: -63px 0px 0px 40px;
    background-image: url("../Assets/BestBudgetWay/centerImage.svg");
    background-size: 100% 158px;
  }

  .section2-second-cotainer {
    width: 73%;
    height: auto;
    /* border: 1px solid #000; */
    margin: -15px auto 0px auto;
    /* background: #770707; */
    display: flex;
    flex-direction: row;
  }

  .insights {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px auto 0px 0px;
    background: transparent linear-gradient(42deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
  }

  .insights-image {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
  }

  .insights-image img {
    width: 100%;
    height: 100%;
  }

  .insights-title {
    width: auto;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.18px;
    text-align: center;
  }

  .insights-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .summary {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px 0px 0px auto;
    background: transparent linear-gradient(318deg, #e067c7 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
  }

  .summary-image {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
  }

  .summary-image img {
    width: 100%;
    height: 100%;
  }

  .summary-title {
    width: auto;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.18px;
    text-align: center;
  }

  .summary-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .bbw-section-container3 {
    width: 100% !important;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: column;
  }

  .section3-title-div {
    width: 100%;
    height: auto;
    margin: 60px auto 0px auto;
  }

  .section3-title-div p {
    text-align: center;
    width: auto;
    height: auto;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    line-height: auto;
    letter-spacing: 0.36px;
    color: #333333;
    margin: 0px auto;
  }

  .section3-content-div {
    width: 83%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 40px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .all-bills-at-your-glance {
    width: 100%;
    height: 380px;
    border-radius: 13px;
    background: transparent linear-gradient(180deg, #fabd64 0%, #afe095 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
  }

  .all-bills-at-your-glance-div {
    width: 344px;
    height: 380px;
    display: flex;
    flex-direction: column;
    margin: 33px 0px 0px 28px;
  }

  .all-bills-at-your-glance-image {
    width: 326px;
    height: 183px;
  }

  .all-bills-at-your-glance-image img {
    width: 100%;
    height: 100%;
  }

  .all-bills-at-your-glance-title {
    width: 100%;
    height: 32px;
    margin: 25px auto 0px 20px;
    font-size: 24px;
    letter-spacing: 0.24px;
    font-family: "Catamaran";
    font-weight: bold;
    text-align: left;
  }

  .all-bills-at-your-glance-title p {
    /* text-align: center; */
    margin: 0px;
  }

  .all-bills-at-your-glance-description {
    width: 100%;
    height: 55px;
    margin: 6px auto 0px 20px;
    font-size: 18px;
    line-height: 27px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
  }

  .all-bills-at-your-glance-description p {
    width: 344px;
    /* text-align: center; */
    margin: 0px auto 0px auto;
  }

  .man-holding-card-div {
    width: 165px;
    height: 146px;
    margin: 143px 0px 0px 23px;
    background-image: url("../Assets/BestBudgetWay/manHoldingCard.png");
    background-size: 165px 146px;
  }

  .section3-part2-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .mark-as-recurring {
    height: auto;
    width: 30%;
    margin: 25px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .mark-as-recurring img {
    width: 36px;
    height: 36px;
    margin: 0px auto 0px auto;
  }

  .mark-as-recurring-title {
    width: auto;
    height: auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
    margin: 6px 0px 0px 0px;
  }

  .mark-as-recurring-description {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    line-height: 21px;
    color: #333333;
    letter-spacing: 0.14px;
  }

  .set-frequency {
    height: auto;
    width: 30%;
    /* border: 1px solid #000; */
    margin: 25px auto 0px auto;
  }

  .set-frequency img {
    width: 32px;
    height: 36px;
    margin: 0px auto 0px auto;
  }

  .set-frequency-title {
    width: auto;
    height: auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
    margin: 6px 0px 0px 0px;
  }

  .set-frequency-description {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .approve-delete {
    height: auto;
    /* border: 1px solid #000; */
    width: 30%;
    margin: 25px auto 0px auto;

  }

  .approve-delete img {
    width: 32px;
    height: 32px;
    margin: 0px auto 0px auto;
  }

  .approve-delete-title {
    width: auto;
    height: auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
    margin: 6px 0px 0px 0px;
  }

  .approve-delete-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .bbw-section-container4 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    margin: 0px auto 0px auto;
  }

  .bbw-section4-carousal-container {
    display: none;
  }

  .section-container4-bottom-layer {
    display: none;
    position: absolute;
    z-index: -1;
    width: 1080px;
    height: 1543px;
    margin: 0px auto 0px auto;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    /* background-color: #FABD64; */
  }

  .section-container4-upper-layer {
    display: none;
  }

  .bbw-section4-title-div {
    width: 100%;
    height: auto;
    margin: 65px auto 0px auto !important;
    display: flex;
  }

  .bbw-section4-title-div p {
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    color: #333333;
    margin: 0px auto 0px auto;
    letter-spacing: 00.36px;
  }

  .bbw-section4-carousal-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: auto;
    margin: 28px auto 0px auto;
  }

  .section4-carousal-part1 {
    width: 100% !important;
    height: auto;
    background: transparent linear-gradient(145deg, #a1c3c3 0%, #c9adc3 48%, #84aaaf 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bbw-section4-carousal {
    width: 30%;
    height: 85%;
    margin: 20px auto 20px auto;
  }

  .section4-slide {
    display: none;
    width: 100%;
    height: 100%;
    /* border: 1px solid #000000; */
  }

  .active-slide {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .section-container4-upper-layer-container1-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container1-image img {
    width: 100%;
    height: 100%;
  }

  .section-container4-upper-layer-container3-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container3-image img {
    width: 100%;
    height: 100%;
  }

  .section-container4-upper-layer-container5-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container5-image img {
    width: 100%;
    height: 100%;
  }

  .section4-radio-controls {
    width: 100%;
    height: 30px;
    display: flex;
    /* border: 1px solid #000000; */
    align-items: center;
    justify-content: center;
    margin: 8px auto 0px auto;
  }

  .section4-radio-container {
    margin: 0px 10px 0px 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(125, 125, 125, 0.726);
    cursor: pointer;
  }

  .section4-radio-container.checked {
    background-color: #000000;
    border-color: #000000;
  }

  .section4-slide-btn {
    margin: 0px auto;
    width: 20px;
    height: 40px;

  }

  .section4-slide-btn img {
    width: 100%;
    height: 100%;
  }

  .section4-carousal-part2 {
    width: 100% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .section4-description {
    display: none;
  }

  .active-description {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    margin: 0px auto 0px auto;
  }

  .section4-carousal-title {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border: 1px solid #000; */
    margin: 0px auto 0px auto;
  }

  .section4-carousal-description {
    width: 90%;
    height: auto;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .simple-budgeting-title {
    width: auto;
    height: auto;
    font-size: 24px;
    /* font-family: "Helvetica Neue Bold", sans-serif; */
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.24px;
    text-align: center;
    margin: 5px auto 0px auto;
  }

  .simple-budgeting-description {
    width: 550px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 18px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: center;
    line-height: 27px;
  }

  .knowledge-at-your-fingertips-title {
    width: auto !important;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.24px;
    text-align: center;
    margin: 5px auto 0px auto !important;
  }

  .knowledge-at-your-fingertips-description {
    width: 550px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 18px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    line-height: 27px;
    text-align: center;
  }

  .everything-at-a-glance-title {
    width: auto;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.24px;
    text-align: center !important;
    margin: 5px auto 0px auto !important;
  }

  .everything-at-a-glance-description {
    width: 550px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 18px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: center;
    line-height: 27px;
  }

  .bbw-section4-second-image-container {
    width: 100%;
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #84aaaf; */
    margin: 45px auto 0px auto;
  }

  .bbw-girl-watching-sunset img {
    width: 265px;
    height: 195px;
    margin: 0px auto 0px auto;
  }

  .bbw-section-container5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
  }

  .ready-to-get-started-div {
    width: 360px;
    height: 200px;
    background: transparent linear-gradient(180deg, #c0e2e1 0%, #d9abd0 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ready-to-get-started-title {
    width: auto;
    height: auto;
    color: #000000;
    font-size: 26px;
    font-family: "Catamaran";
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.26px;
    margin: 33px auto 0px auto;
  }

  .start-your-free-trial {
    width: 200px;
    height: 60px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #18485a;
    margin: 25px auto 0px auto;
    border: 0.6000000238418579px solid #bfbfbf;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .start-your-free-trial p {
    font-size: 18px;
    letter-spacing: 0.18px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
  }

  .start-your-free-trial:hover {
    background-color: #2c4e6c;
    color: #ffffff;
  }

  .go-to-page-top-container {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: flex-end;
    margin: 40px 50px 50px 0px;
  }


}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-container1 {
    /* max-width: 100%; */
    width: 100%;
    /* max-height: 4790px; */
    height: auto;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;

    /* justify-content: center; */
  }

  .bbw-section-container1 {
    width: 100%;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    position: relative;
  }

  .bbw-section1-container1-bottom-layer {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 568px;
    background: transparent linear-gradient(63deg,
        rgb(157, 198, 216, 0.5) 0%,
        rgb(75, 121, 154, 0.75) 100%) 0% 0% no-repeat padding-box;
  }

  .bbw-section1-container1-upper-layer {
    height: 568px !important;
    width: 100%;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    position: relative;
    display: flex;
    flex-direction: row;

  }

  .bbw-section1-mobile-div {
    width: 33%;
    height: 430px;
    margin: 83px auto 0px auto;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-color: transparent transparent;
    /* border: 1px solid #FFF; */
  }

  .bbw-section1-mobile-div-img {
    width: 90%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .bbw-section1-mobile-div-img img {
    width: 100%;
    height: auto;
    /* object-fit: fill; */
    margin: 0px auto 0px auto;
  }

  .bbw-section1-part2 {
    width: 56%;
    height: auto;
    margin: 59px auto 0px auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #FFF; */
  }

  .section1-title-container {
    width: 98%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 30px auto 0px auto;
    /* border: 1px solid #ff0000; */
    position: relative;
  }

  .section1-title-decoration {
    width: 100%;
    height: auto;
    display: flex;
    margin: -20px auto 0px auto;
    justify-content: space-between;
    position: absolute;
    z-index: -1;
    /* border: 1px solid #FFF; */
  }

  .weather-star1 {
    width: 40px;
    height: 40px;
    margin: 50px 0px 0px 0px;
    /* border: 1px solid #FFF; */
  }

  .section1-title-div {
    width: auto;
    height: auto;
    font-size: 36px;
    text-align: left;
    color: #427cac;
    opacity: 1;
    margin: auto;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0.36px;
  }

  .weather-star2 {
    width: 55px;
    height: 55px;
    margin: -45px 0px 0px 55px;
    /* border: 1px solid #ff0000; */
  }

  .section1-description-container {
    width: 100%;
    height: auto;
    position: relative;
    margin: 10px auto 0px auto;
    display: flex;
    /* border: 1px solid #FFF; */
  }

  .section1-part2-bottom-layer {
    display: flex;
    position: absolute;
    z-index: -1;
    /* border: 1px solid #fff; */
    height: 100%;
    width: 100%;
    left: auto;
    margin: 0px auto 0px auto !important;
  }

  .section1-part2-bottom-layer-img {
    height: 100%;
    width: 100%;
    margin: -40px 0px 0px 190px;
    /* border: 1px solid #fff; */
    background-image: url("../Assets/BestBudgetWay/star.svg");
    background-size: 100% 100%;
  }

  .section1-description-text {
    margin: 0px auto 0px auto;
    width: 80%;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    line-height: 24px;
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.16px;
  }

  .start-your-free-trial2 {
    width: 200px;
    height: 50px;
    border-radius: 8px;
    background-color: #427bac;
    color: #ffffff;
    border: 2px solid #ffffff;
    margin: 32px auto 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .start-your-free-trial2 p {
    height: auto;
    font-size: 16px;
    letter-spacing: 0.16px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
  }

  .start-your-free-trial2:hover {
    color: #FFFFFF;
    background-color: #48588D;

  }

  .section1-description-text p {
    margin: 0px 0px 10px 0px;
  }

  .section1-description-text p:last-child {
    margin: 0px;
  }

  .section-footer-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: end;
    margin: 20px 0px 0px auto;
  }

  .section-footer-image img {
    width: 250px;
    height: 164px;
  }

  .bbw-section-container2 {
    width: 100%;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    display: flex;
    flex-direction: column;
  }

  .section2-title-div {
    width: 100%;
    height: auto;
    margin: 60px auto 0px auto;
  }

  .section2-title-div p {
    text-align: center;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0.36px;
    color: #333333;
    margin: 0px auto;
    width: auto;
    height: auto;
  }

  .section2-first-cotainer {
    width: 73%;
    height: auto;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .intelligent-budgeting {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px auto 0px 0px;
    background: transparent linear-gradient(138deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
  }

  .allocate-all-categories {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
  }

  .allocate-all-categories img {
    width: 100%;
    height: 100%;
  }

  .intelligent-budgeting-title {
    width: auto;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 19px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.18px;
  }

  .intelligent-budgeting-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .bulk-actions-transactions {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px 0px 0px auto;
    background: transparent linear-gradient(222deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
  }

  .bulk-action-image {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .bulk-action-image img {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    object-fit: fill;
  }

  .bulk-action-title {
    width: 100%;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 18px;
    font-family: "Catamaran";
    letter-spacing: 0.18px;
    font-weight: bold;
    text-align: center;
    /* font-stretch: condensed; */
  }

  .bulk-action-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .section2-center-image {
    width: 85%;
    z-index: -1;
    height: 158px;
    margin: -63px 0px 0px 40px;
    background-image: url("../Assets/BestBudgetWay/centerImage.svg");
    background-size: 100% 158px;
  }

  .section2-second-cotainer {
    width: 73%;
    height: auto;
    /* border: 1px solid #000; */
    margin: -15px auto 0px auto;
    /* background: #770707; */
    display: flex;
    flex-direction: row;
  }

  .insights {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px auto 0px 0px;
    background: transparent linear-gradient(42deg, #e269c9 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
  }

  .insights-image {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
  }

  .insights-image img {
    width: 100%;
    height: 100%;
  }

  .insights-title {
    width: auto;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.18px;
    text-align: center;
  }

  .insights-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .summary {
    width: 47%;
    height: auto;
    border-radius: 13px;
    margin: 0px 0px 0px auto;
    background: transparent linear-gradient(318deg, #e067c7 0%, #7fc6c6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
  }

  .summary-image {
    width: 90%;
    height: auto;
    margin: 17px auto 0px auto;
  }

  .summary-image img {
    width: 100%;
    height: 100%;
  }

  .summary-title {
    width: auto;
    height: auto;
    margin: 17px auto 0px auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.18px;
    text-align: center;
  }

  .summary-description {
    width: 90%;
    height: auto;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .bbw-section-container3 {
    width: 100% !important;
    height: auto !important;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: column;
  }

  .section3-title-div {
    width: 100%;
    height: auto;
    margin: 60px auto 0px auto;
  }

  .section3-title-div p {
    text-align: center;
    width: auto;
    height: auto;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    line-height: auto;
    letter-spacing: 0.36px;
    color: #333333;
    margin: 0px auto;
  }

  .section3-content-div {
    width: 83%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 40px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .all-bills-at-your-glance {
    width: 100%;
    height: 380px;
    border-radius: 13px;
    background: transparent linear-gradient(180deg, #fabd64 0%, #afe095 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
  }

  .all-bills-at-your-glance-div {
    width: 344px;
    height: 380px;
    display: flex;
    flex-direction: column;
    margin: 33px 0px 0px 28px;
  }

  .all-bills-at-your-glance-image {
    width: 326px;
    height: 183px;
  }

  .all-bills-at-your-glance-image img {
    width: 100%;
    height: 100%;
  }

  .all-bills-at-your-glance-title {
    width: 100%;
    height: 32px;
    margin: 25px auto 0px 20px;
    font-size: 24px;
    letter-spacing: 0.24px;
    font-family: "Catamaran";
    font-weight: bold;
    text-align: left;
  }

  .all-bills-at-your-glance-title p {
    /* text-align: center; */
    margin: 0px;
  }

  .all-bills-at-your-glance-description {
    width: 100%;
    height: 55px;
    margin: 6px auto 0px 20px;
    font-size: 18px;
    line-height: 27px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
  }

  .all-bills-at-your-glance-description p {
    width: 344px;
    /* text-align: center; */
    margin: 0px auto 0px auto;
  }

  .man-holding-card-div {
    width: 165px;
    height: 146px;
    margin: 143px 0px 0px 23px;
    background-image: url("../Assets/BestBudgetWay/manHoldingCard.png");
    background-size: 165px 146px;
  }

  .section3-part2-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .mark-as-recurring {
    height: auto;
    width: 30%;
    margin: 25px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .mark-as-recurring img {
    width: 36px;
    height: 36px;
    margin: 0px auto 0px auto;
  }

  .mark-as-recurring-title {
    width: auto;
    height: auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
    margin: 6px 0px 0px 0px;
  }

  .mark-as-recurring-description {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    line-height: 21px;
    color: #333333;
    letter-spacing: 0.14px;
  }

  .set-frequency {
    height: auto;
    width: 30%;
    /* border: 1px solid #000; */
    margin: 25px auto 0px auto;
  }

  .set-frequency img {
    width: 32px;
    height: 36px;
    margin: 0px auto 0px auto;
  }

  .set-frequency-title {
    width: auto;
    height: auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
    margin: 6px 0px 0px 0px;
  }

  .set-frequency-description {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .approve-delete {
    height: auto;
    /* border: 1px solid #000; */
    width: 30%;
    margin: 25px auto 0px auto;

  }

  .approve-delete img {
    width: 32px;
    height: 32px;
    margin: 0px auto 0px auto;
  }

  .approve-delete-title {
    width: auto;
    height: auto;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: left;
    margin: 6px 0px 0px 0px;
  }

  .approve-delete-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: "Catamaran";
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.14px;
    line-height: 21px;
  }

  .bbw-section-container4 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;
    margin: 0px auto 0px auto;
  }

  .bbw-section4-carousal-container {
    display: none;
  }

  .section-container4-bottom-layer {
    display: none;
    position: absolute;
    z-index: -1;
    width: 1080px;
    height: 1543px;
    margin: 0px auto 0px auto;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    /* background-color: #FABD64; */
  }

  .section-container4-upper-layer {
    display: none;
  }

  .bbw-section4-title-div {
    width: 100%;
    height: auto;
    margin: 65px auto 0px auto !important;
    display: flex;
  }

  .bbw-section4-title-div p {
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    color: #333333;
    margin: 0px auto 0px auto;
    letter-spacing: 00.36px;
  }

  .bbw-section4-carousal-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: auto;
    margin: 28px auto 0px auto;
  }

  .section4-carousal-part1 {
    width: 100% !important;
    height: auto;
    background: transparent linear-gradient(145deg, #a1c3c3 0%, #c9adc3 48%, #84aaaf 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bbw-section4-carousal {
    width: 30%;
    height: 85%;
    margin: 20px auto 20px auto;
  }

  .section4-slide {
    display: none;
    width: 100%;
    height: 100%;
    /* border: 1px solid #000000; */
  }

  .active-slide {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .section-container4-upper-layer-container1-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container1-image img {
    width: 100%;
    height: 100%;
  }

  .section-container4-upper-layer-container3-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container3-image img {
    width: 100%;
    height: 100%;
  }

  .section-container4-upper-layer-container5-image {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px auto 0px auto;
  }

  .section-container4-upper-layer-container5-image img {
    width: 100%;
    height: 100%;
  }

  .section4-radio-controls {
    width: 100%;
    height: 30px;
    display: flex;
    /* border: 1px solid #000000; */
    align-items: center;
    justify-content: center;
    margin: 8px auto 0px auto;
  }

  .section4-radio-container {
    margin: 0px 10px 0px 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(125, 125, 125, 0.726);
    cursor: pointer;
  }

  .section4-radio-container.checked {
    background-color: #000000;
    border-color: #000000;
  }

  .section4-slide-btn {
    margin: 0px auto;
    width: 20px;
    height: 40px;

  }

  .section4-slide-btn img {
    width: 100%;
    height: 100%;
  }

  .section4-carousal-part2 {
    width: 100% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .section4-description {
    display: none;
  }

  .active-description {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    margin: 0px auto 0px auto;
  }

  .section4-carousal-title {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border: 1px solid #000; */
    margin: 0px auto 0px auto;
  }

  .section4-carousal-description {
    width: 90%;
    height: auto;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .simple-budgeting-title {
    width: auto;
    height: auto;
    font-size: 24px;
    /* font-family: "Helvetica Neue Bold", sans-serif; */
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.24px;
    text-align: center;
    margin: 5px auto 0px auto;
  }

  .simple-budgeting-description {
    width: 550px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 18px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: center;
    line-height: 27px;
  }

  .knowledge-at-your-fingertips-title {
    width: auto !important;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.24px;
    text-align: center;
    margin: 5px auto 0px auto !important;
  }

  .knowledge-at-your-fingertips-description {
    width: 550px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 18px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    line-height: 27px;
    text-align: center;
  }

  .everything-at-a-glance-title {
    width: auto;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.24px;
    text-align: center !important;
    margin: 5px auto 0px auto !important;
  }

  .everything-at-a-glance-description {
    width: 550px;
    height: auto;
    margin: 5px auto 0px auto;
    font-size: 18px;
    /* font-family: "Helvetica Neue Medium", sans-serif; */
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.18px;
    text-align: center;
    line-height: 27px;
  }

  .bbw-section4-second-image-container {
    width: 100%;
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #84aaaf; */
    margin: 45px auto 0px auto;
  }

  .bbw-girl-watching-sunset img {
    width: 265px;
    height: 195px;
    margin: 0px auto 0px auto;
  }

  .bbw-section-container5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
  }

  .ready-to-get-started-div {
    width: 360px;
    height: 200px;
    background: transparent linear-gradient(180deg, #c0e2e1 0%, #d9abd0 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ready-to-get-started-title {
    width: auto;
    height: auto;
    color: #000000;
    font-size: 26px;
    font-family: "Catamaran";
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.26px;
    margin: 33px auto 0px auto;
  }

  .start-your-free-trial {
    width: 200px;
    height: 60px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #18485a;
    margin: 25px auto 0px auto;
    border: 0.6000000238418579px solid #bfbfbf;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .start-your-free-trial p {
    font-size: 18px;
    letter-spacing: 0.18px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
  }

  .start-your-free-trial:hover {
    background-color: #2c4e6c;
    color: #ffffff;
  }

  .go-to-page-top-container {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: flex-end;
    margin: 40px 50px 50px 0px;
  }


}