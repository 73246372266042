.learn-main-container {
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.learn-section-container {
    width: 1080px;
    height: auto;
    /* border: 1px solid #000; */
    margin: 0px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    background-color: #FFFFFF;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
}

.learn-section-toggle-button-container {
    width: 100%;
    height: 70px;
    /* border: 2px solid #000; */
    margin: 30px auto 0px auto;
    display: flex;
    justify-content: center;
}

.learn-section-toggle-button-container-guides,
.learn-section-toggle-button-container-videos {
    width: 50%;
    height: 68px;
    margin: 0px;
    border: 1px solid #707070;
    background: rgb(213, 218, 221, 0.5);
    display: flex;
    align-items: end;
    justify-content: center;

}

.learn-section-toggle-button-container-guides p,
.learn-section-toggle-button-container-videos p {
    width: 100%;
    height: 40px;
    margin: 0px auto;
    padding-bottom: 15px;
    cursor: pointer;
    transition: background 0.3s;
    text-align: center;
    font-size: 24px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #000000;
}

.learn-section-toggle-button-container-guides .active,
.learn-section-toggle-button-container-videos .active {
    color: #38ADC7;
    padding-bottom: 8px;
    text-align: center;
    border-bottom: 8px solid #38ADC7;
    /* box-sizing: border-box; */
}

.learn-video-page-container1 {
    width: 1080px;
    height: auto;
    /* border: 1px solid #000; */
    margin: 0px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    background-color: #FFFFFF;
    opacity: 1;
    display: flex;
    flex-direction: column;
}

.learn-video-page-container1 h2 {
    width: auto;
    height: auto;
    margin: 0px auto 0px auto;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.32px;
    font-family: "Catamaran";
}

.learn-video-page-video-container-grid {
    width: 990px !important;
    height: auto !important;
    margin: 50px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 30px;
}

.learn-video-page-video-grid {
    width: 300px;
    height: 440px;
    cursor: pointer;
}

.learn-video-page-video-grid-row1 {
    width: 259px;
    height: 46px;
    display: flex;
    flex-direction: row;
    margin: 10px auto 0px auto;
}

.learn-video-page-video-grid-row1 p {
    width: auto;
    height: auto;
    font-size: 28px;
    line-height: 42px;
    font-weight: bold;
    letter-spacing: 0.28px;
    margin: 0px;
}

.learn-video-page-video-grid-border {
    width: 240px;
    height: 3px;
    margin: auto 0px auto 5px;
}

.learn-video-page-video-container-img-div {
    width: 259px;
    height: 160px;
    margin: 10px auto 0px auto;
    box-shadow: 0px 0px 1px 0.5px rgba(0, 0, 0, 0.35);
}

.learn-video-page-video-container-img-div img {
    width: 226px;
    height: 130px;
    margin: 15px;
}

.learn-video-page-video-container-title {
    width: 256px;
    height: auto;
    margin: 15px auto 0px auto;
}

.learn-video-page-video-container-title p {
    width: 256px;
    height: auto;
    font-size: 20px;
    line-height: 25px;
    font-family: Catamaran;
    /* letter-spacing: 0.28px; */
    color: #000000;
    font-weight: bold;
    margin: 0px;
}

.learn-video-page-video-container-description {
    width: 256px;
    height: auto;
    margin: 10px auto 0px auto;
}

.learn-video-page-video-container-description p {
    width: 256px;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    font-family: Catamaran;
    /* letter-spacing: 0.28px; */
    color: #000000;
    font-weight: 400;
    margin: 0px;
}

.learn-section-container1-back-navigation-div {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 30px 0px 0px 0px;
    cursor: pointer;
}

.learn-section-container1-back-navigation-div img {
    width: 18px;
    height: 12px;
    margin: 0px 10px 0px 0px;
}

.learn-section-container1-back-navigation-div p {
    font-family: Catamaran;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.24px;
    margin: 0px 149px 0px 0px;
}

.learn-section-container1-back-navigation-div-guides {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 30px 0px 0px 0px;
    cursor: pointer;
}

.learn-section-container1-back-navigation-div-guides img {
    width: 18px;
    height: 12px;
    margin: 0px 10px 0px 0px;
}

.learn-section-container1-back-navigation-div-guides p {
    font-family: Catamaran;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.24px;
    margin: 0px 40px 0px 0px;
}

.learn-section-container1-video {
    width: 796px;
    height: 448px;
    border-radius: 0px;
    border: 1px solid #000;
    margin: 30px auto 0px auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

}

.learn-section-container1-video iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: none;
}

.learn-section-container1-description {
    width: 796px;
    height: auto;
    /* border: 1px solid #000; */
    margin: 30px auto;
}

.learn-section-container1-description h3 {
    width: 100%;
    height: auto;
    text-align: left;
    margin: 0px;
    font-size: 24px;
    font-family: Catamaran;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.24px;
    color: #000;
}

.learn-section-container1-description p {
    width: 95%;
    height: auto;
    text-align: left;
    margin: 20px 0px 15px 10px;
    font-size: 20px;
    font-family: Catamaran;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #3a3a3a;
}



.learn-section-container1 {
    width: 1080px;
    height: auto;
    margin: 0px auto 0px auto !important;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    background-color: #FFFFFF;
    opacity: 1;
}

.learn-section-container1-title {
    width: 100%;
    height: 41px;
    margin: 50px auto 30px auto !important;
}

.learn-section-container1-title p {
    width: 515px;
    height: auto;
    text-align: center;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    margin: 0px auto 0px auto;
    color: #333333;
    letter-spacing: 0.36px;
}

.ln-section-container1-content-div {
    width: 995px;
    height: 387px;
    margin: 50px auto 0px auto;
    background: #e5eaea 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #e8e8e8;
    border: 1px solid rgb(191, 191, 191, 0.5);
    border-radius: 16px;
    opacity: 1;
    display: flex;
}

.ln-section-container1-description {
    width: 338px;
    height: 246px;
    margin: 67px 0px 0px 80px;
}

.ln-section-container1-description-title {
    width: 314px;
    height: 39px;
    margin: 0px auto 0px auto;
}

.ln-section-container1-description-title p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 24px;
    font-family: "Catamaran";
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #000000;
    opacity: 1;
}

.ln-section-container1-description-text {
    width: 300px;
    height: auto;
    margin: 6px auto 0px auto;
}

.ln-section-container1-description-text p {
    width: 100%;
    height: auto;
    margin: 0px 0px 10px 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
    line-height: 27px;
}

.ln-section-container1-description-text p:last-child {
    margin: 0px;
}

.ln-section-container1-img-div {
    width: 331px;
    height: 286px;
    margin: 45px 119px 56px 145px;
}

.ln-section-container1-img {
    width: 305px;
    height: 164px;
    margin: 0px auto 0px auto;
}

.ln-section-container1-img img {
    width: 100%;
    height: 100%;
}

.ln-section-container1-learn-more-button {
    width: 331px;
    height: 59px;
    margin: 63px 0px 0px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.6000000238418579px solid rgb(102, 102, 102, 0.5);
    border-radius: 6px;
    opacity: 1;
    display: inline-flex;
    cursor: pointer;
}

.ln-section-container1-learn-more-button:hover {
    width: 331px;
    height: 59px;
    margin: 63px auto 0px auto;
    background: #66A6C1 0% 0% no-repeat padding-box;
    border: 0.6000000238418579px solid #66A6C1;
    border-radius: 6px;
    opacity: 1;
    display: inline-flex;
    cursor: pointer;
    color: #FFFFFF;

    .ln-section-container1-learn-more-button-img {
        width: 11px;
        height: 18px;
        margin: 21px 13px 0px 0px;
        background-image: url("../Assets/Learn/dropRightLight.svg");
        background-size: 100% 100%;
    }
}

.ln-section-container1-learn-more-button p {
    width: 123px;
    height: 27px;
    text-align: center;
    margin: auto 89px auto 94px;
    color: #333333;
    font-size: 20px;
    letter-spacing: 0.2px;
    font-family: "Catamaran";
    font-weight: 500;
}

.ln-section-container1-learn-more-button-img {
    width: 11px;
    height: 18px;
    margin: 21px 13px 0px 0px;
    background-image: url("../Assets/Learn/dropRight.svg");
    background-size: 100% 100%;
}

.learn-section-container2 {
    height: 1280px;
    width: 1080px;
    margin: 0px auto 0px auto;
    background-color: #FFFFFF;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
}

.ln-section-container-grid {
    width: 995px;
    margin: 50px auto 0px auto;
    height: 1152px;
    /* background-color: #e8e8e8; */
    display: grid;
    grid-template-rows: 2fr 2fr 2fr;
    grid-template-columns: 2fr 2fr 2fr;
}

.ln-section-container2-grid1,
.ln-section-container2-grid2,
.ln-section-container2-grid3,
.ln-section-container2-grid4,
.ln-section-container2-grid5,
.ln-section-container2-grid6,
.ln-section-container2-grid7,
.ln-section-container2-grid8,
.ln-section-container2-grid9 {
    width: 300px;
    height: 350px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid rgb(191, 191, 191, 0.5);
    border-radius: 12px;
    opacity: 1;
    margin: 0px 45px 49px 0px;
    /* cursor: pointer; */
}

.ln-section-container2-grid1:hover,
.ln-section-container2-grid2:hover,
.ln-section-container2-grid3:hover,
.ln-section-container2-grid4:hover,
.ln-section-container2-grid5:hover,
.ln-section-container2-grid6:hover,
.ln-section-container2-grid7:hover,
.ln-section-container2-grid8:hover,
.ln-section-container2-grid9:hover {
    background-color: #F7F7F7;
}

.ln-section-container2-grid1 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid1-img {
    width: 35px;
    height: 30px;
    margin: 36px 235px 0px 30px;
}

.ln-section-container2-grid1-img img {
    width: 35px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid1-title {
    width: 100%;
    height: 39px;
    margin: 26px 0px 0px 0px;
    display: flex;
}

.ln-section-container2-grid1-title p {
    width: 126px;
    height: 39px;
    margin: 0px 154px 0px 30px;
    font-size: 24px;
    font-family: "Catamaran";
    letter-spacing: 0.24px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid1-description {
    width: 239px;
    height: auto;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue;s */
}

.ln-section-container2-grid1-description p {
    width: 239px;
    height: auto;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    line-height: 27px;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
}

.ln-section-container2-grid1-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 11px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid1-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid1-description-learn-more p:hover {
    text-decoration: underline;
}

.ln-section-container2-grid2 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid2-img {
    width: 34px;
    height: 30px;
    margin: 35px 236px 0px 30px;
}

.ln-section-container2-grid2-img img {
    width: 34px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid2-title {
    width: 100%;
    height: 39px;
    margin: 26px 0px 0px 0px;
    display: flex;
}

.ln-section-container2-grid2-title p {
    width: 136px;
    height: 39px;
    margin: 0px 134px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid2-description {
    width: 239px;
    height: 96px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid2-description p {
    width: 239px;
    height: auto;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    line-height: 27px;
    opacity: 1;
}

.ln-section-container2-grid2-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 48px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid2-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    /* font: normal normal medium 16px/18px Helvetica Neue; */
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid2-description-learn-more p:hover {
    text-decoration: underline;
}


.ln-section-container2-grid3 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid3-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid3-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid3-title {
    width: 100%;
    height: 39px;
    /* background-color: beige; */
    margin: 26px 0px 0px 0px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.ln-section-container2-grid3-title p {
    width: 160px;
    height: 39px;
    /* background-color: beige;   */
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    /* font: normal normal medium 28px/20px Helvetica Neue; */
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid3-description {
    width: 239px;
    height: auto;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid3-description p {
    width: 239px;
    height: auto;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
}

.ln-section-container2-grid3-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 6px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid3-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    /* font: normal normal medium 16px/18px Helvetica Neue; */
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid3-description-learn-more p:hover {
    text-decoration: underline;
}


.ln-section-container2-grid4 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid4-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid4-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid4-title {
    width: 100%;
    height: 39px;
    margin: 26px 0px 0px 0px;
    display: flex;
}

.ln-section-container2-grid4-title p {
    width: 160px;
    height: 39px;
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid4-description {
    width: 239px;
    height: 120px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid4-description p {
    width: 239px;
    height: 120px;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
    line-height: 27px;
}

.ln-section-container2-grid4-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 20px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid4-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    font-weight: 500;
    font-family: "Catamaran";
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid4-description-learn-more p:hover {
    text-decoration: underline;
}


.ln-section-container2-grid5 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid5-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid5-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid5-title {
    width: 100%;
    height: 39px;
    /* background-color: beige; */
    margin: 26px 0px 0px 0px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.ln-section-container2-grid5-title p {
    width: 160px;
    height: 39px;
    /* background-color: beige;   */
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    /* font: normal normal medium 28px/20px Helvetica Neue; */
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid5-description {
    width: 239px;
    height: 120px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid5-description p {
    width: 239px;
    height: 120px;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #333333;
    opacity: 1;
}

.ln-section-container2-grid5-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 17px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid5-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid5-description-learn-more p:hover {
    text-decoration: underline;
}

.ln-section-container2-grid6 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid6-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid6-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid6-title {
    width: 100%;
    height: 39px;
    /* background-color: beige; */
    margin: 26px 0px 0px 0px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.ln-section-container2-grid6-title p {
    width: 160px;
    height: 39px;
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid6-description {
    width: 239px;
    height: 120px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid6-description p {
    width: 239px;
    height: 120px;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    line-height: 27px;
    opacity: 1;
}

.ln-section-container2-grid6-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 17px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid6-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid6-description-learn-more p:hover {
    text-decoration: underline;
}

.ln-section-container2-grid7 {
    display: flex;
    flex-direction: column;
}

.ln-section-container2-grid7-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid7-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid7-title {
    width: 100%;
    height: 39px;
    /* background-color: beige; */
    margin: 26px 0px 0px 0px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.ln-section-container2-grid7-title p {
    width: 160px;
    height: 39px;
    /* background-color: beige;   */
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid7-description {
    width: 239px;
    height: 120px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid7-description p {
    width: 239px;
    height: 120px;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
    line-height: 27px;
}

.ln-section-container2-grid7-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 17px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid7-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid7-description-learn-more p:hover {
    text-decoration: underline;
}


.ln-section-container2-grid8 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid8-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid8-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid8-title {
    width: 100%;
    height: 39px;
    /* background-color: beige; */
    margin: 26px 0px 0px 0px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.ln-section-container2-grid8-title p {
    width: 160px;
    height: 39px;
    /* background-color: beige;   */
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid8-description {
    width: 239px;
    height: 120px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid8-description p {
    width: 239px;
    height: 120px;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #333333;
    opacity: 1;
}

.ln-section-container2-grid8-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 17px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid8-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid8-description-learn-more p:hover {
    text-decoration: underline;
}

.ln-section-container2-grid9 {
    display: flex;
    flex-direction: column;

}

.ln-section-container2-grid9-img {
    width: 30px;
    height: 30px;
    margin: 36px 240px 0px 30px;
}

.ln-section-container2-grid9-img img {
    width: 30px;
    height: 30px;
    margin: 0px;
}

.ln-section-container2-grid9-title {
    width: 100%;
    height: 39px;
    /* background-color: beige; */
    margin: 26px 0px 0px 0px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

.ln-section-container2-grid9-title p {
    width: 160px;
    height: 39px;
    /* background-color: beige;   */
    margin: 0px 110px 0px 30px;
    font-size: 28px;
    font-family: "Catamaran";
    letter-spacing: 0.28px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.ln-section-container2-grid9-description {
    width: 239px;
    height: 120px;
    margin: 15px 0px 0px 30px;
    /* background-color: cadetblue; */
}

.ln-section-container2-grid9-description p {
    width: 239px;
    height: 120px;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
    line-height: 27px;
}

.ln-section-container2-grid9-description-learn-more {
    width: 92px;
    height: 17px;
    margin: 17px 0px 0px 30px;
    cursor: pointer;
}

.ln-section-container2-grid9-description-learn-more p {
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #0578FF;
    opacity: 1;
}

.ln-section-container2-grid9-description-learn-more p:hover {
    text-decoration: underline;
}

.learn-go-to-top {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    margin: 35px 45px 35px 0px !important;
}

@media only screen and (max-width: 440px) {
    .learn-main-container {
        max-width: 440px;
        width: 100%;
        height: auto;
        padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        background-color: #F8F8F8;
    }

    .learn-section-container {
        width: 100%;
        height: auto;
        border: none;
        margin: 0px auto 0px auto !important;
        background-color: #FFFFFF;
        opacity: 1;
        display: flex;
        justify-content: center;
    }

    .learn-section-toggle-button-container {
        width: 100%;
        height: 60px;
        margin: 50px auto 0px auto;
        background: #F7F7F7;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
    }

    .learn-section-toggle-button-container-guides,
    .learn-section-toggle-button-container-videos {
        width: 50%;
        height: 56px;
        margin: 0px auto 0px auto;
        border: 1px solid #707070;
        background: rgb(213, 218, 221, 0.5);
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .learn-section-toggle-button-container-guides p,
    .learn-section-toggle-button-container-videos p {
        width: 100% !important;
        height: 40px;
        margin: 10px auto 0px auto;
        padding-bottom: 0px;
        cursor: pointer;
        text-align: center;
        transition: background 0.3s;
        font-size: 16px;
        font-family: "Catamaran";
        font-weight: bold;
        letter-spacing: 0.16px;
        color: #000000;
        display: flex;
        /* align-items: center; */
        justify-content: center;
    }

    .learn-section-toggle-button-container-guides .active,
    .learn-section-toggle-button-container-videos .active {
        padding-bottom: 0px !important;
    }

    .learn-video-page-container1 {
        width: 100%;
        height: auto;
        margin: 20px auto 0px auto !important;
        border-right: none !important;
        border-left: none !important;
        background-color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
    }

    .learn-video-page-container1 h2 {
        width: auto;
        height: auto;
        margin: 0px auto 0px auto;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.2px;
        font-family: "Catamaran";
    }

    .learn-video-page-video-container-grid {
        width: 300px !important;
        height: auto !important;
        margin: 20px auto 20px auto;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr;
        row-gap: 20px;
        column-gap: auto;
    }

    .learn-video-page-video-grid {
        width: 300px;
        height: 400px;
        cursor: pointer;
        /* border: 1px solid #000; */
    }

    .learn-video-page-video-grid-row1 p {
        width: auto;
        height: auto;
        font-size: 20px;
        line-height: 42px;
        font-weight: bold;
        letter-spacing: 0.2px;
        margin: 0px;
    }

    .learn-video-page-video-container-title p {
        width: 256px;
        height: auto;
        font-size: 18px;
        line-height: 24px;
        font-family: Catamaran;
        /* letter-spacing: 0.28px; */
        color: #000000;
        font-weight: bold;
        margin: 0px;
    }

    .learn-video-page-video-container-description p {
        width: 256px;
        height: auto;
        font-size: 14px;
        line-height: 20px;
        font-family: Catamaran;
        /* letter-spacing: 0.28px; */
        color: #000000;
        font-weight: 400;
        margin: 0px;
    }

    .learn-section-container1-back-navigation-div {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 30px 0px 0px 0px;
        cursor: pointer;
    }

    .learn-section-container1-back-navigation-div img {
        width: 9px;
        height: 6px;
        margin: 0px 10px 0px 0px;
    }

    .learn-section-container1-back-navigation-div p {
        font-family: Catamaran;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.16px;
        margin: 0px 20px 0px 0px;
    }

    .learn-section-container1-back-navigation-div-guides {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center !important;
        justify-content: right;
        margin: 30px 0px 0px 0px;
        cursor: pointer;
    }

    .learn-section-container1-back-navigation-div-guides img {
        width: 9px;
        height: 6px;
        margin: 6px 5px 9px 0px;
    }

    .learn-section-container1-back-navigation-div-guides p {
        font-family: Catamaran;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.16px;
        margin: 0px 20px 0px 0px;
    }

    .learn-section-container1-video {
        width: 83%;
        height: 200px;
        border-radius: none;
        border: 1px solid #000;
        margin: 30px auto 0px auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .learn-section-container1-video iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: none;
    }

    .learn-section-container1-description {
        width: 83%;
        height: auto;
        /* border: 1px solid #000; */
        margin: 30px auto;
    }

    .learn-section-container1-description h3 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 0px;
        font-size: 12px;
        font-family: Catamaran;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.12px;
        color: #000;
    }

    .learn-section-container1-description p {
        width: 95%;
        height: auto;
        text-align: left;
        margin: 10px 0px 5px 10px;
        font-size: 12px;
        font-family: Catamaran;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.12px;
        color: #3a3a3a;
    }

    .learn-section-container1 {
        width: 97%;
        height: auto;
        margin: 50px auto 0px auto !important;
        border: none;
        background-color: #F8F8F8
    }

    .learn-section-container1-title {
        width: 100%;
        height: 27px;
        margin: 50px auto 0px auto !important;
        /* background-color: aliceblue; */
    }

    .learn-section-container1-title p {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 24px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-stretch: condensed;
        margin: 0px auto 0px auto;
        color: #333333;
        letter-spacing: 0.24px;
    }

    .ln-section-container1-content-div {
        width: 98%;
        height: auto;
        margin: 22px auto 0px auto;
        background: #e5eaea 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 24px #e8e8e8;
        border: 1px solid rgb(191, 191, 191, 0.5);
        border-radius: 16px;
        opacity: 1;
        display: flex;
        flex-direction: column;
    }

    .ln-section-container1-description {
        width: 87%;
        height: auto;
        margin: 30px auto 0px auto;
    }

    .ln-section-container1-description-title {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .ln-section-container1-description-title p {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.2px;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container1-description-text {
        width: 90%;
        height: auto;
        margin: 20px auto 0px auto;
        /* background-color: #712121; */
    }

    .ln-section-container1-description-text p {
        width: 100%;
        height: auto;
        margin: 0px auto 15px auto;
        text-align: center;
        /* font: normal normal medium 18px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container1-description-text p:last-child {
        margin: 0px;
    }

    .ln-section-container1-img-div {
        width: 223px;
        height: auto;
        margin: 20px auto 20px auto;
        /* background-color: #712121; */
    }

    .ln-section-container1-img {
        width: 223px;
        height: 120px;
        margin: 0px auto 0px auto;
    }

    .ln-section-container1-img img {
        width: 100%;
        height: 100%;
    }

    .ln-section-container1-learn-more-button {
        width: 235px;
        height: 41px;
        margin: 29px auto 0px auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.6000000238418579px solid rgb(102, 102, 102, 0.5);
        border-radius: 6px;
        opacity: 1;
        display: inline-flex;
        cursor: pointer;
    }

    .ln-section-container1-learn-more-button:hover {
        width: 235px;
        height: 41px;
        margin: 29px auto 0px auto;
        background: #66A6C1 0% 0% no-repeat padding-box;
        border: 0.6000000238418579px solid #66A6C1;
        border-radius: 6px;
        opacity: 1;
        display: inline-flex;
        cursor: pointer;
        color: #FFFFFF;


    }

    .ln-section-container1-learn-more-button-img {
        width: 8px;
        height: 13px;
        margin: 14px 16px 0px 0px;
        background-image: url("../Assets/Learn/dropRightLight.svg");
        background-size: 100% 100%;
    }

    .ln-section-container1-learn-more-button p {
        width: auto;
        height: auto;
        text-align: center;
        margin: auto 47px auto 72px;
        font-weight: 400;
        font-size: 18px;
    }

    .ln-section-container1-learn-more-button-img {
        width: 8px;
        height: 13px;
        margin: 14px 16px 0px 0px;
        background-image: url("../Assets/Learn/dropRight.svg");
        background-size: 100% 100%;
    }

    .learn-section-container2 {
        height: auto;
        width: 98%;
        margin: 35px auto 0px auto;
        background-color: #F8F8F8;
        border: none;
    }

    .ln-section-container-grid {
        width: 88%;
        margin: 0px auto 0px auto;
        height: auto;
        display: grid;
        grid-template-rows: 2fr 2fr;
        grid-template-columns: 2fr 2fr;
        column-gap: 10px;
    }

    .ln-section-container2-grid1,
    .ln-section-container2-grid2,
    .ln-section-container2-grid3,
    .ln-section-container2-grid4,
    .ln-section-container2-grid5,
    .ln-section-container2-grid6,
    .ln-section-container2-grid7,
    .ln-section-container2-grid8,
    .ln-section-container2-grid9 {
        width: 100%;
        height: 200px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid rgb(191, 191, 191, 0.5);
        border-radius: 8px;
        opacity: 1;
        margin: 0px auto 22px auto;
        cursor: pointer;
    }

    .ln-section-container2-grid1:hover,
    .ln-section-container2-grid2:hover,
    .ln-section-container2-grid3:hover,
    .ln-section-container2-grid4:hover,
    .ln-section-container2-grid5:hover,
    .ln-section-container2-grid6:hover,
    .ln-section-container2-grid7:hover,
    .ln-section-container2-grid8:hover,
    .ln-section-container2-grid9:hover {
        background-color: #F7F7F7;
    }

    .ln-section-container2-grid1 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid1-img {
        width: 24px;
        height: 21px;
        margin: 16px 0px 0px 11px;
    }

    .ln-section-container2-grid1-img img {
        width: 24px;
        height: 21px;
        margin: 0px;
    }

    .ln-section-container2-grid1-title {
        width: 100%;
        height: 17px;
        /* background-color: beige; */
        margin: 11px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid1-title p {
        width: 66px;
        height: 17px;
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid1-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
    }

    .ln-section-container2-grid1-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid1-description-learn-more {
        width: 71px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
        /* position: fixed; */
    }

    .ln-section-container2-grid1-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/14px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid1-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid2 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid2-img {
        width: 24px;
        height: 21px;
        margin: 16px 0px 0px 11px;
    }

    .ln-section-container2-grid2-img img {
        width: 24px;
        height: 21px;
        margin: 0px;
    }

    .ln-section-container2-grid2-title {
        width: 100%;
        height: 17px;
        margin: 10px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid2-title p {
        width: 78px;
        height: 17px;
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid2-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
    }

    .ln-section-container2-grid2-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid2-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 20px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid2-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid2-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid3 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid3-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid3-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid3-title {
        width: 100%;
        height: 17px;
        margin: 10px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid3-title p {
        width: 91px;
        height: 17px;
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid3-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
    }

    .ln-section-container2-grid3-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid3-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid3-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid3-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid4 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid4-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid4-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid4-title {
        width: 100%;
        height: 17px;
        /* background-color: beige; */
        margin: 10px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid4-title p {
        width: 91px;
        height: 17px;
        /* background-color: beige;   */
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid4-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid4-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid4-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid4-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid4-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid5 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid5-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid5-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid5-title {
        width: 100%;
        height: 17px;
        /* background-color: beige; */
        margin: 10px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid5-title p {
        width: 91px;
        height: 17px;
        /* background-color: beige;   */
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid5-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid5-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid5-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid5-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid5-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid6 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid6-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid6-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid6-title {
        width: 100%;
        height: 17px;
        /* background-color: beige; */
        margin: 10px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid6-title p {
        width: 91px;
        height: 17px;
        /* background-color: beige;   */
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid6-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid6-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid6-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid6-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid6-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid7 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid7-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid7-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid7-title {
        width: 100%;
        height: 17px;
        margin: 10px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid7-title p {
        width: 91px;
        height: 17px;
        /* background-color: beige;   */
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid7-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid7-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        color: #333333;
        line-height: 16px;
        opacity: 1;
    }

    .ln-section-container2-grid7-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid7-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid7-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid8 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid8-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid8-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid8-title {
        width: 100%;
        height: 17px;
        margin: 10px 0px 0px 0px;
        display: flex;

    }

    .ln-section-container2-grid8-title p {
        width: 91px;
        height: 17px;
        /* background-color: beige;   */
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid8-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid8-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        color: #333333;
        opacity: 1;
        line-height: 16px;
    }

    .ln-section-container2-grid8-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid8-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid8-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid9 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid9-img {
        width: 24px;
        height: 24px;
        margin: 15px 0px 0px 11px;
    }

    .ln-section-container2-grid9-img img {
        width: 24px;
        height: 24px;
        margin: 0px;
    }

    .ln-section-container2-grid9-title {
        width: 100%;
        height: 17px;
        /* background-color: beige; */
        margin: 10px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid9-title p {
        width: 91px;
        height: 17px;
        /* background-color: beige;   */
        margin: 0px 0px 0px 11px;
        font-size: 16px;
        /* font: normal normal medium 16px/20px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid9-description {
        width: 90%;
        height: auto;
        margin: 5px 0px 0px 11px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid9-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid9-description-learn-more {
        width: 72px;
        height: 14px;
        margin: 0px 0px 5px 11px;
        cursor: pointer;
    }

    .ln-section-container2-grid9-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 12px;
        /* font: normal normal medium 12px/16px Helvetica Neue; */
        letter-spacing: 0.12px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid9-description-learn-more p:hover {
        text-decoration: underline;
    }

    .learn-go-to-top {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        margin: 15px 45px 15px 0px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .learn-main-container {
        width: 100% !important;
        height: auto;
        padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .learn-section-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }

    .learn-section-toggle-button-container {
        width: 100%;
        height: 70px;
        /* border: 2px solid #000; */
        margin: 30px auto 0px auto;
        display: flex;
        justify-content: center;
    }

    .learn-section-toggle-button-container-guides,
    .learn-section-toggle-button-container-videos {
        width: 50%;
        height: 68px;
        margin: 0px;
        border: 1px solid #707070;
        background: rgb(213, 218, 221, 0.5);
        display: flex;
        align-items: end;
        justify-content: center;

    }

    .learn-section-toggle-button-container-guides p,
    .learn-section-toggle-button-container-videos p {
        width: 100%;
        height: 40px;
        margin: 0px auto;
        padding-bottom: 15px;
        cursor: pointer;
        transition: background 0.3s;
        text-align: center;
        font-size: 24px;
        font-family: "Catamaran";
        font-weight: bold;
        letter-spacing: 0.24px;
        color: #000000;
    }

    .learn-section-toggle-button-container-guides .active,
    .learn-section-toggle-button-container-videos .active {
        color: #38ADC7;
        padding-bottom: 8px;
        text-align: center;
        border-bottom: 8px solid #38ADC7;
        /* box-sizing: border-box; */
    }

    .learn-video-page-container1 {
        width: 100%;
        height: auto;
        /* border: 1px solid #000; */
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-direction: column;
    }

    .learn-video-page-container1 h2 {
        width: auto;
        height: auto;
        margin: 0px auto 0px auto;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.32px;
        font-family: "Catamaran";
    }

    .learn-video-page-video-container-grid {
        width: 91% !important;
        height: auto !important;
        margin: 50px auto 0px auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        row-gap: 30px;
    }

    .learn-video-page-video-grid {
        width: 300px;
        height: 440px;
        cursor: pointer;
    }

    .learn-video-page-video-grid-row1 {
        width: 259px;
        height: 46px;
        display: flex;
        flex-direction: row;
        margin: 10px auto 0px auto;
    }

    .learn-video-page-video-grid-row1 p {
        width: auto;
        height: auto;
        font-size: 28px;
        line-height: 42px;
        font-weight: bold;
        letter-spacing: 0.28px;
        margin: 0px;
    }

    .learn-video-page-video-grid-border {
        width: 240px;
        height: 3px;
        margin: auto 0px auto 5px;
    }

    .learn-video-page-video-container-img-div {
        width: 259px;
        height: 160px;
        margin: 10px auto 0px auto;
        box-shadow: 0px 0px 1px 0.5px rgba(0, 0, 0, 0.35);
    }

    .learn-video-page-video-container-img-div img {
        width: 226px;
        height: 130px;
        margin: 15px;
    }

    .learn-video-page-video-container-title {
        width: 256px;
        height: auto;
        margin: 15px auto 0px auto;
    }

    .learn-video-page-video-container-title p {
        width: 256px;
        height: auto;
        font-size: 20px;
        line-height: 25px;
        font-family: Catamaran;
        /* letter-spacing: 0.28px; */
        color: #000000;
        font-weight: bold;
        margin: 0px;
    }

    .learn-video-page-video-container-description {
        width: 256px;
        height: auto;
        margin: 10px auto 0px auto;
    }

    .learn-video-page-video-container-description p {
        width: 256px;
        height: auto;
        font-size: 16px;
        line-height: 20px;
        font-family: Catamaran;
        /* letter-spacing: 0.28px; */
        color: #000000;
        font-weight: 400;
        margin: 0px;
    }

    .learn-section-container1-back-navigation-div {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 30px 0px 0px 0px;
        cursor: pointer;
    }

    .learn-section-container1-back-navigation-div img {
        width: 18px;
        height: 12px;
        margin: 0px 10px 0px 0px;
    }

    .learn-section-container1-back-navigation-div p {
        font-family: Catamaran;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.24px;
        margin: 0px 149px 0px 0px;
    }

    .learn-section-container1-back-navigation-div-guides {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 30px 0px 0px 0px;
        cursor: pointer;
    }

    .learn-section-container1-back-navigation-div-guides img {
        width: 18px;
        height: 12px;
        margin: 0px 10px 0px 0px;
    }

    .learn-section-container1-back-navigation-div-guides p {
        font-family: Catamaran;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.24px;
        margin: 0px 40px 0px 0px;
    }

    .learn-section-container1-video {
        width: 74%;
        height: 332px;
        border-radius: 0px;
        border: 1px solid #000;
        margin: 30px auto 0px auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .learn-section-container1-video iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: none;
    }

    .learn-section-container1-description {
        width: 74%;
        height: auto;
        margin: 30px auto;
    }

    .learn-section-container1-description h3 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 0px;
        font-size: 24px;
        font-family: Catamaran;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.24px;
        color: #000;
    }

    .learn-section-container1-description p {
        width: 95%;
        height: auto;
        text-align: left;
        margin: 20px 0px 15px 10px;
        font-size: 20px;
        font-family: Catamaran;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.2px;
        color: #3a3a3a;
    }



    .learn-section-container1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #FFFFFF;
        opacity: 1;
    }

    .learn-section-container1-title {
        width: 100%;
        height: auto;
        margin: 50px auto 30px auto !important;
    }

    .learn-section-container1-title p {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 36px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-stretch: condensed;
        margin: 0px auto 0px auto;
        color: #333333;
        letter-spacing: 0.36px;
    }

    .ln-section-container1-content-div {
        width: 92%;
        height: auto;
        margin: 50px auto 0px auto;
        background: #e5eaea 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 24px #e8e8e8;
        border: 1px solid rgb(191, 191, 191, 0.5);
        border-radius: 16px;
        opacity: 1;
        display: flex;

    }

    .ln-section-container1-description {
        width: 45%;
        height: auto;
        margin: 45px auto 0px auto;
    }

    .ln-section-container1-description-title {
        width: 99%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .ln-section-container1-description-title p {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 22px;
        font-family: "Catamaran";
        font-weight: bold;
        letter-spacing: 0.22px;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container1-description-text {
        width: 100%;
        height: auto;
        margin: 13px auto 0px auto;
    }

    .ln-section-container1-description-text p {
        width: 100%;
        height: auto;
        margin: 0px 0px 10px 0px;
        text-align: center;
        font-family: "Catamaran";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #333333;
        opacity: 1;
        line-height: 24px;
    }

    .ln-section-container1-description-text p:last-child {
        margin: 0px;
    }

    .ln-section-container1-img-div {
        width: 40%;
        height: auto;
        margin: 45px auto 0px auto;
    }

    .ln-section-container1-img {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .ln-section-container1-img img {
        width: 100%;
        height: 100%;
    }

    .ln-section-container1-learn-more-button {
        width: 90%;
        height: 59px;
        margin: 23px auto 20px auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.6000000238418579px solid rgb(102, 102, 102, 0.5);
        border-radius: 6px;
        opacity: 1;
        display: inline-flex;
        cursor: pointer;
    }

    .ln-section-container1-learn-more-button:hover {
        width: 90%;
        height: 59px;
        margin: 23px auto 20px auto;
        background: #66A6C1;
        border: 0.6000000238418579px solid #66A6C1;
        border-radius: 6px;
        opacity: 1;
        display: inline-flex;
        cursor: pointer;
        color: #FFFFFF;

        .ln-section-container1-learn-more-button-img {
            width: 11px;
            height: 18px;
            margin: 21px 13px 0px 0px;
            background-image: url("../Assets/Learn/dropRightLight.svg");
            background-size: 100% 100%;
        }
    }

    .ln-section-container1-learn-more-button p {
        width: 123px;
        height: 27px;
        text-align: center;
        margin: auto;
        color: #333333;
        font-size: 18px;
        letter-spacing: 0.18px;
        font-family: "Catamaran";
        font-weight: 500;
    }

    .ln-section-container1-learn-more-button-img {
        width: 11px;
        height: 18px;
        margin: 21px 13px 0px 0px;
        background-image: url("../Assets/Learn/dropRight.svg");
        background-size: 100% 100%;
    }

    .learn-section-container2 {
        height: auto;
        width: 100%;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
        border-right: none;
        border-left: none;
    }

    .ln-section-container-grid {
        width: 92%;
        margin: 50px auto 0px auto;
        height: auto;
        display: grid;
        grid-template-rows: 2fr 2fr 2fr;
        grid-template-columns: 2fr 2fr;
        row-gap: 40px;
    }

    .ln-section-container2-grid1,
    .ln-section-container2-grid2,
    .ln-section-container2-grid3,
    .ln-section-container2-grid4,
    .ln-section-container2-grid5,
    .ln-section-container2-grid6,
    .ln-section-container2-grid7,
    .ln-section-container2-grid8,
    .ln-section-container2-grid9 {
        width: 300px;
        height: 350px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid rgb(191, 191, 191, 0.5);
        border-radius: 12px;
        opacity: 1;
        margin: 0px auto 0px auto;
        /* cursor: pointer; */
    }

    .ln-section-container2-grid1:hover,
    .ln-section-container2-grid2:hover,
    .ln-section-container2-grid3:hover,
    .ln-section-container2-grid4:hover,
    .ln-section-container2-grid5:hover,
    .ln-section-container2-grid6:hover,
    .ln-section-container2-grid7:hover,
    .ln-section-container2-grid8:hover,
    .ln-section-container2-grid9:hover {
        background-color: #F7F7F7;
    }

    .ln-section-container2-grid1 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid1-img {
        width: 35px;
        height: 30px;
        margin: 36px 235px 0px 30px;
    }

    .ln-section-container2-grid1-img img {
        width: 35px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid1-title {
        width: 100%;
        height: 39px;
        margin: 26px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid1-title p {
        width: 126px;
        height: 39px;
        margin: 0px 154px 0px 30px;
        font-size: 24px;
        font-family: "Catamaran";
        letter-spacing: 0.24px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid1-description {
        width: 239px;
        height: auto;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue;s */
    }

    .ln-section-container2-grid1-description p {
        width: 239px;
        height: auto;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        line-height: 27px;
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid1-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 11px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid1-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid1-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid2 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid2-img {
        width: 34px;
        height: 30px;
        margin: 35px 236px 0px 30px;
    }

    .ln-section-container2-grid2-img img {
        width: 34px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid2-title {
        width: 100%;
        height: 39px;
        margin: 26px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid2-title p {
        width: 136px;
        height: 39px;
        margin: 0px 134px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid2-description {
        width: 239px;
        height: 96px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid2-description p {
        width: 239px;
        height: auto;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        line-height: 27px;
        opacity: 1;
    }

    .ln-section-container2-grid2-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 48px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid2-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        /* font: normal normal medium 16px/18px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid2-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid3 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid3-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid3-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid3-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid3-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        /* font: normal normal medium 28px/20px Helvetica Neue; */
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid3-description {
        width: 239px;
        height: auto;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid3-description p {
        width: 239px;
        height: auto;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid3-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 6px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid3-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        /* font: normal normal medium 16px/18px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid3-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid4 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid4-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid4-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid4-title {
        width: 100%;
        height: 39px;
        margin: 26px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid4-title p {
        width: 160px;
        height: 39px;
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid4-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid4-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        line-height: 27px;
    }

    .ln-section-container2-grid4-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 20px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid4-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-weight: 500;
        font-family: "Catamaran";
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid4-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid5 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid5-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid5-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid5-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid5-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        /* font: normal normal medium 28px/20px Helvetica Neue; */
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid5-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid5-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        line-height: 27px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid5-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid5-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid5-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid6 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid6-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid6-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid6-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid6-title p {
        width: 160px;
        height: 39px;
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid6-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid6-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        line-height: 27px;
        opacity: 1;
    }

    .ln-section-container2-grid6-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid6-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid6-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid7 {
        display: flex;
        flex-direction: column;
    }

    .ln-section-container2-grid7-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid7-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid7-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid7-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid7-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid7-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        line-height: 27px;
    }

    .ln-section-container2-grid7-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid7-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid7-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid8 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid8-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid8-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid8-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid8-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid8-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid8-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        line-height: 27px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid8-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid8-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid8-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid9 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid9-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid9-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid9-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid9-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid9-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid9-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        line-height: 27px;
    }

    .ln-section-container2-grid9-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid9-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid9-description-learn-more p:hover {
        text-decoration: underline;
    }

    .learn-go-to-top {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        margin: 35px 45px 35px 0px !important;
    }



}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .learn-main-container {
        width: 100% !important;
        height: auto;
        padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .learn-section-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }

    .learn-section-toggle-button-container {
        width: 100%;
        height: 70px;
        /* border: 2px solid #000; */
        margin: 30px auto 0px auto;
        display: flex;
        justify-content: center;
    }

    .learn-section-toggle-button-container-guides,
    .learn-section-toggle-button-container-videos {
        width: 50%;
        height: 68px;
        margin: 0px;
        border: 1px solid #707070;
        background: rgb(213, 218, 221, 0.5);
        display: flex;
        align-items: end;
        justify-content: center;

    }

    .learn-section-toggle-button-container-guides p,
    .learn-section-toggle-button-container-videos p {
        width: 100%;
        height: 40px;
        margin: 0px auto;
        padding-bottom: 15px;
        cursor: pointer;
        transition: background 0.3s;
        text-align: center;
        font-size: 24px;
        font-family: "Catamaran";
        font-weight: bold;
        letter-spacing: 0.24px;
        color: #000000;
    }

    .learn-section-toggle-button-container-guides .active,
    .learn-section-toggle-button-container-videos .active {
        color: #38ADC7;
        padding-bottom: 8px;
        text-align: center;
        border-bottom: 8px solid #38ADC7;
        /* box-sizing: border-box; */
    }

    .learn-video-page-container1 {
        width: 100%;
        height: auto;
        /* border: 1px solid #000; */
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-direction: column;
    }

    .learn-video-page-container1 h2 {
        width: auto;
        height: auto;
        margin: 0px auto 0px auto;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.32px;
        font-family: "Catamaran";
    }

    .learn-video-page-video-container-grid {
        width: 91% !important;
        height: auto !important;
        margin: 50px auto 0px auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        row-gap: 30px;
    }

    .learn-video-page-video-grid {
        width: 300px;
        height: 440px;
        cursor: pointer;
    }

    .learn-video-page-video-grid-row1 {
        width: 259px;
        height: 46px;
        display: flex;
        flex-direction: row;
        margin: 10px auto 0px auto;
    }

    .learn-video-page-video-grid-row1 p {
        width: auto;
        height: auto;
        font-size: 28px;
        line-height: 42px;
        font-weight: bold;
        letter-spacing: 0.28px;
        margin: 0px;
    }

    .learn-video-page-video-grid-border {
        width: 240px;
        height: 3px;
        margin: auto 0px auto 5px;
    }

    .learn-video-page-video-container-img-div {
        width: 259px;
        height: 160px;
        margin: 10px auto 0px auto;
        box-shadow: 0px 0px 1px 0.5px rgba(0, 0, 0, 0.35);
    }

    .learn-video-page-video-container-img-div img {
        width: 226px;
        height: 130px;
        margin: 15px;
    }

    .learn-video-page-video-container-title {
        width: 256px;
        height: auto;
        margin: 15px auto 0px auto;
    }

    .learn-video-page-video-container-title p {
        width: 256px;
        height: auto;
        font-size: 20px;
        line-height: 25px;
        font-family: Catamaran;
        /* letter-spacing: 0.28px; */
        color: #000000;
        font-weight: bold;
        margin: 0px;
    }

    .learn-video-page-video-container-description {
        width: 256px;
        height: auto;
        margin: 10px auto 0px auto;
    }

    .learn-video-page-video-container-description p {
        width: 256px;
        height: auto;
        font-size: 16px;
        line-height: 20px;
        font-family: Catamaran;
        /* letter-spacing: 0.28px; */
        color: #000000;
        font-weight: 400;
        margin: 0px;
    }

    .learn-section-container1-back-navigation-div {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 30px 0px 0px 0px;
        cursor: pointer;
    }

    .learn-section-container1-back-navigation-div img {
        width: 18px;
        height: 12px;
        margin: 0px 10px 0px 0px;
    }

    .learn-section-container1-back-navigation-div p {
        font-family: Catamaran;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.24px;
        margin: 0px 149px 0px 0px;
    }

    .learn-section-container1-back-navigation-div-guides {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin: 30px 0px 0px 0px;
        cursor: pointer;
    }

    .learn-section-container1-back-navigation-div-guides img {
        width: 18px;
        height: 12px;
        margin: 0px 10px 0px 0px;
    }

    .learn-section-container1-back-navigation-div-guides p {
        font-family: Catamaran;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.24px;
        margin: 0px 40px 0px 0px;
    }

    .learn-section-container1-video {
        width: 74%;
        height: 332px;
        border-radius: 0px;
        border: 1px solid #000;
        margin: 30px auto 0px auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .learn-section-container1-video iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: none;
    }

    .learn-section-container1-description {
        width: 74%;
        height: auto;
        margin: 30px auto;
    }

    .learn-section-container1-description h3 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 0px;
        font-size: 24px;
        font-family: Catamaran;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.24px;
        color: #000;
    }

    .learn-section-container1-description p {
        width: 95%;
        height: auto;
        text-align: left;
        margin: 20px 0px 15px 10px;
        font-size: 20px;
        font-family: Catamaran;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.2px;
        color: #3a3a3a;
    }



    .learn-section-container1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #FFFFFF;
        opacity: 1;
    }

    .learn-section-container1-title {
        width: 100%;
        height: auto;
        margin: 50px auto 30px auto !important;
    }

    .learn-section-container1-title p {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 36px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-stretch: condensed;
        margin: 0px auto 0px auto;
        color: #333333;
        letter-spacing: 0.36px;
    }

    .ln-section-container1-content-div {
        width: 92%;
        height: auto;
        margin: 50px auto 0px auto;
        background: #e5eaea 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 24px #e8e8e8;
        border: 1px solid rgb(191, 191, 191, 0.5);
        border-radius: 16px;
        opacity: 1;
        display: flex;

    }

    .ln-section-container1-description {
        width: 45%;
        height: auto;
        margin: 45px auto 0px auto;
    }

    .ln-section-container1-description-title {
        width: 99%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .ln-section-container1-description-title p {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 22px;
        font-family: "Catamaran";
        font-weight: bold;
        letter-spacing: 0.22px;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container1-description-text {
        width: 100%;
        height: auto;
        margin: 13px auto 0px auto;
    }

    .ln-section-container1-description-text p {
        width: 100%;
        height: auto;
        margin: 0px 0px 10px 0px;
        text-align: center;
        font-family: "Catamaran";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #333333;
        opacity: 1;
        line-height: 24px;
    }

    .ln-section-container1-description-text p:last-child {
        margin: 0px;
    }

    .ln-section-container1-img-div {
        width: 40%;
        height: auto;
        margin: 45px auto 0px auto;
    }

    .ln-section-container1-img {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .ln-section-container1-img img {
        width: 100%;
        height: 100%;
    }

    .ln-section-container1-learn-more-button {
        width: 90%;
        height: 59px;
        margin: 23px auto 20px auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 0.6000000238418579px solid rgb(102, 102, 102, 0.5);
        border-radius: 6px;
        opacity: 1;
        display: inline-flex;
        cursor: pointer;
    }

    .ln-section-container1-learn-more-button:hover {
        width: 90%;
        height: 59px;
        margin: 23px auto 20px auto;
        background: #66A6C1;
        border: 0.6000000238418579px solid #66A6C1;
        border-radius: 6px;
        opacity: 1;
        display: inline-flex;
        cursor: pointer;
        color: #FFFFFF;

        .ln-section-container1-learn-more-button-img {
            width: 11px;
            height: 18px;
            margin: 21px 13px 0px 0px;
            background-image: url("../Assets/Learn/dropRightLight.svg");
            background-size: 100% 100%;
        }
    }

    .ln-section-container1-learn-more-button p {
        width: 123px;
        height: 27px;
        text-align: center;
        margin: auto;
        color: #333333;
        font-size: 18px;
        letter-spacing: 0.18px;
        font-family: "Catamaran";
        font-weight: 500;
    }

    .ln-section-container1-learn-more-button-img {
        width: 11px;
        height: 18px;
        margin: 21px 13px 0px 0px;
        background-image: url("../Assets/Learn/dropRight.svg");
        background-size: 100% 100%;
    }

    .learn-section-container2 {
        height: auto;
        width: 100%;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
        border-right: none;
        border-left: none;
    }

    .ln-section-container-grid {
        width: 92%;
        margin: 50px auto 0px auto;
        height: auto;
        display: grid;
        grid-template-rows: 2fr 2fr 2fr;
        grid-template-columns: 2fr 2fr;
        row-gap: 40px;
    }

    .ln-section-container2-grid1,
    .ln-section-container2-grid2,
    .ln-section-container2-grid3,
    .ln-section-container2-grid4,
    .ln-section-container2-grid5,
    .ln-section-container2-grid6,
    .ln-section-container2-grid7,
    .ln-section-container2-grid8,
    .ln-section-container2-grid9 {
        width: 300px;
        height: 350px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid rgb(191, 191, 191, 0.5);
        border-radius: 12px;
        opacity: 1;
        margin: 0px auto 0px auto;
        /* cursor: pointer; */
    }

    .ln-section-container2-grid1:hover,
    .ln-section-container2-grid2:hover,
    .ln-section-container2-grid3:hover,
    .ln-section-container2-grid4:hover,
    .ln-section-container2-grid5:hover,
    .ln-section-container2-grid6:hover,
    .ln-section-container2-grid7:hover,
    .ln-section-container2-grid8:hover,
    .ln-section-container2-grid9:hover {
        background-color: #F7F7F7;
    }

    .ln-section-container2-grid1 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid1-img {
        width: 35px;
        height: 30px;
        margin: 36px 235px 0px 30px;
    }

    .ln-section-container2-grid1-img img {
        width: 35px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid1-title {
        width: 100%;
        height: 39px;
        margin: 26px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid1-title p {
        width: 126px;
        height: 39px;
        margin: 0px 154px 0px 30px;
        font-size: 24px;
        font-family: "Catamaran";
        letter-spacing: 0.24px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid1-description {
        width: 239px;
        height: auto;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue;s */
    }

    .ln-section-container2-grid1-description p {
        width: 239px;
        height: auto;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        line-height: 27px;
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid1-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 11px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid1-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid1-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid2 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid2-img {
        width: 34px;
        height: 30px;
        margin: 35px 236px 0px 30px;
    }

    .ln-section-container2-grid2-img img {
        width: 34px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid2-title {
        width: 100%;
        height: 39px;
        margin: 26px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid2-title p {
        width: 136px;
        height: 39px;
        margin: 0px 134px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid2-description {
        width: 239px;
        height: 96px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid2-description p {
        width: 239px;
        height: auto;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        line-height: 27px;
        opacity: 1;
    }

    .ln-section-container2-grid2-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 48px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid2-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        /* font: normal normal medium 16px/18px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid2-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid3 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid3-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid3-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid3-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid3-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        /* font: normal normal medium 28px/20px Helvetica Neue; */
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid3-description {
        width: 239px;
        height: auto;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid3-description p {
        width: 239px;
        height: auto;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid3-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 6px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid3-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        /* font: normal normal medium 16px/18px Helvetica Neue; */
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid3-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid4 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid4-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid4-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid4-title {
        width: 100%;
        height: 39px;
        margin: 26px 0px 0px 0px;
        display: flex;
    }

    .ln-section-container2-grid4-title p {
        width: 160px;
        height: 39px;
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid4-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid4-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        line-height: 27px;
    }

    .ln-section-container2-grid4-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 20px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid4-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-weight: 500;
        font-family: "Catamaran";
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid4-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid5 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid5-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid5-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid5-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid5-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        /* font: normal normal medium 28px/20px Helvetica Neue; */
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid5-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid5-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        line-height: 27px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid5-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid5-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid5-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid6 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid6-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid6-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid6-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid6-title p {
        width: 160px;
        height: 39px;
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid6-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid6-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        line-height: 27px;
        opacity: 1;
    }

    .ln-section-container2-grid6-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid6-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid6-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid7 {
        display: flex;
        flex-direction: column;
    }

    .ln-section-container2-grid7-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid7-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid7-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid7-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid7-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid7-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        line-height: 27px;
    }

    .ln-section-container2-grid7-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid7-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid7-description-learn-more p:hover {
        text-decoration: underline;
    }


    .ln-section-container2-grid8 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid8-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid8-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid8-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid8-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid8-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid8-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        line-height: 27px;
        color: #333333;
        opacity: 1;
    }

    .ln-section-container2-grid8-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid8-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid8-description-learn-more p:hover {
        text-decoration: underline;
    }

    .ln-section-container2-grid9 {
        display: flex;
        flex-direction: column;

    }

    .ln-section-container2-grid9-img {
        width: 30px;
        height: 30px;
        margin: 36px 240px 0px 30px;
    }

    .ln-section-container2-grid9-img img {
        width: 30px;
        height: 30px;
        margin: 0px;
    }

    .ln-section-container2-grid9-title {
        width: 100%;
        height: 39px;
        /* background-color: beige; */
        margin: 26px 0px 0px 0px;
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
    }

    .ln-section-container2-grid9-title p {
        width: 160px;
        height: 39px;
        /* background-color: beige;   */
        margin: 0px 110px 0px 30px;
        font-size: 28px;
        font-family: "Catamaran";
        letter-spacing: 0.28px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .ln-section-container2-grid9-description {
        width: 239px;
        height: 120px;
        margin: 15px 0px 0px 30px;
        /* background-color: cadetblue; */
    }

    .ln-section-container2-grid9-description p {
        width: 239px;
        height: 120px;
        margin: 0px;
        text-align: left;
        font-family: "Catamaran";
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        line-height: 27px;
    }

    .ln-section-container2-grid9-description-learn-more {
        width: 92px;
        height: 17px;
        margin: 17px 0px 0px 30px;
        cursor: pointer;
    }

    .ln-section-container2-grid9-description-learn-more p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        font-weight: 500;
        color: #0578FF;
        opacity: 1;
    }

    .ln-section-container2-grid9-description-learn-more p:hover {
        text-decoration: underline;
    }

    .learn-go-to-top {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        margin: 35px 45px 35px 0px !important;
    }



}