.pricing-main-container {
  width: 100%;
  height: auto;
  padding: 0px !important;
  margin: 0px !important;
  display: flex;
  flex-direction: column;
}

.pricing-section-container1 {
  width: 100%;
  height: 706px;
  position: relative;
  margin: 0px 0px 0px 0px !important;
}

.pricing-section-container1-bottom-layer {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 706px;
  background: linear-gradient(180deg, #fbfbfc 0%, #edf2f5 100%);
}

.pricing-section-container1-upper-layer {
  position: relative;
  width: 1080px;
  height: 706px !important;
  z-index: 0;
  margin: 0px auto 0px auto !important;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pricing-section-container1-title {
  width: 100%;
  height: 51px;
  margin: 45px auto 0px auto;
}

.pricing-section-container1-title p {
  width: 375px;
  height: 51px;
  font-size: 36px;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-stretch: condensed;
  color: #333333;
  margin: 0px auto 0px auto;
  text-align: center;
  letter-spacing: 0.36px;
}

.pricing-section-container1-content {
  width: 1004px;
  height: 543px;
  margin: 67px auto 0px auto;
  display: flex;
  flex-direction: row;
}

.test-drive-first-div {
  width: 480px;
  height: 543px;
  background: #efebd0;
  border-radius: 24px;
  margin: 0px 22px 0px 0px;
}

.subscription-plans-div {
  width: 480px;
  height: 543px;
  background: #efebd0;
  border-radius: 24px;
  background: transparent linear-gradient(180deg, #c2e3b6 0%, #efc7e5 100%);

  margin: 0px 0px 0px 22px;
}

.test-drive-first-title {
  width: 199px;
  height: 39px;
  margin: 30px 0px 0px 30px;
}

.test-drive-first-title p {
  font-family: "Catamaran";
  font-size: 24px;
  letter-spacing: 0.24px;
  font-weight: bold;
  text-align: start;
  color: #000000;
}

.test-drive-first-description {
  width: 386px;
  height: auto;
  margin: 11px auto 0px auto;
}

.test-drive-first-description p {
  text-align: left;
  width: auto;
  font-family: "Catamaran";
  letter-spacing: 0.18px;
  color: #000000;
  margin: 0px auto;
  height: auto;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.pricing-start-free-trial-div {
  width: 200px;
  height: 50px;
  margin: 23px auto 0px auto;
  background-color: #427bac;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pricing-start-free-trial-div:hover {
  color: #FFFFFF;
  background-color: #48588D;
}

.pricing-start-free-trial-div p {
  width: 100%;
  height: auto;
  margin: auto;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  opacity: 1;
}

.pricing-test-drive-first-note {
  width: 420px;
  height: 27px;
  margin: 24px auto 0px auto;
}

.pricing-test-drive-first-note p {
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #0425af;
  opacity: 1;
  width: auto;
  height: auto;

}

.pricing-test-drive-mobile-screen-div {
  width: 380px;
  height: 254px;
  margin: 56px auto 0px auto !important;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  scrollbar-color: transparent transparent;
}

.pricing-test-drive-dashboard-image {
  width: 362px;
  height: 677px;
  margin: 0px auto 0px auto;
  background-image: url("../Assets/Pricing/dashboardImage.webp");
  background-size: 100% 100%;
  object-fit: fill;

}

.subscription-plans-title {
  width: 240px;
  height: 39px;
  margin: 30px 0px 0px 30px;
}

.subscription-plans-title p {
  text-align: left;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.24px;
  color: #000000;
  opacity: 1;
}

.saving-div {
  width: 200px;
  height: 40px;
  margin: 12px 20px 0px auto;
  /* background: #333333; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.right-hand {
  width: 36px;
  height: 38px;
}

.saving {
  width: auto;
  height: 32px;
  background: #ffffff;
  color: #51a215;
  border: 1px solid #3bb54e;
  border-radius: 12px 12px 0px 0px;
  text-align: center;
  margin: 7px 0px 0px 0px;
  font-family: "Catamaran";
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  /* padding-top: 1px; */
}

.left-hand {
  width: 36px;
  height: 38px;
}

.yearly-subscription-div {
  width: 350px;
  height: 100px;
  margin: 0px auto 0px auto;
  background: #b9f798;
  border: 1.2px solid rgb(51, 51, 51, 1);
  border-radius: 8px;
  opacity: 1;
  display: flex;
  /* justify-content: start; */
  flex-direction: row;
}

.yearly-subscription-price {
  width: 70%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 15px 0px 0px 15px;
}

.yearly-subscription-price-div1 {
  width: 75px;
  height: 30px;
  text-align: left;
  font-family: "Catamaran";
  /* font-weight: bold; */
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.24px;
  color: #000000;
}

.yearly-subscription-price-div1 p {
  margin: 0px;
}

.yearly-subscription-price-div2 {
  width: 80px;
  height: 35px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 12px 0px 0px 6px;
}

.yearly-subscription-price-div2 p {
  line-height: 15px;
  margin: 0px auto;
  width: 100%;

}

.yearly-subscription-price-div2 p:last-child {
  margin: 0px;
}

.pay-yearly {
  width: 30%;
  height: auto;
  margin: 35px auto 0px auto;
}

.pay-yearly p {
  text-align: left;
  font-family: "Catamaran";
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px;
  width: auto;
}

.monthly-subscription-div {
  width: 350px;
  height: 85px;
  margin: 32px auto 0px auto;
  background: #d3eda3;
  border: 1.2px solid rgb(51, 51, 51, 0.75);
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.monthly-subscription-price {
  width: 70%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 15px 0px 0px 15px;
}

.monthly-subscription-price-div1 {
  width: 65px;
  height: 27px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.24px;
  color: #000000;
}

.monthly-subscription-price-div1 p {
  margin: 0px;
}

.monthly-subscription-price-div2 {
  width: auto;
  height: 14px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 18px 0px 0px 15px;
}

.monthly-subscription-price-div2 p {
  margin: 0px 0px 2px 0px;
  width: auto;
}

.monthly-subscription-price-div2 p:last-child {
  margin: 0px;
}

.pay-monthly {
  width: 30%;
  height: 17px;
  margin: 35px auto 0px auto;
}

.pay-monthly p {
  text-align: left;
  font-family: "Catamaran";
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0px;
  width: auto;
}

.subscription-plans-description {
  width: 330px;
  height: 84px;
  margin: 26px auto 0px auto;
}

.subscription-plans-description p {
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #000000;
  opacity: 1;
  margin: 0px auto;
  width: auto;
}

.subscription-plans-footer {
  width: 400px;
  height: 27px;
  margin: 50px auto 0px auto;
}

.subscription-plans-footer p {
  width: 100%;
  text-align: center;
  font-family: "Catamaran";
  /* font-weight: 400; */
  font-size: 16px;
  letter-spacing: 0px;
  color: #0916a7;
  opacity: 1;
  margin: 0px;
  font-weight: 500;
}

.pricing-section-container2 {
  width: 1080px;
  height: 620px;
  margin: 0px auto 0px auto;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  background: #ffffff;
}

.pricing-section-container2-title {
  width: 100%;
  height: 43px;
  margin: 95px auto 0px auto;
}

.pricing-section-container2-title p {
  width: 600px;
  height: 43px;
  margin: 0px auto 0px auto;
  /* ba */
  text-align: center;
  /* font: normal condensed bold 36px/25px Helvetica Neue, sans-serif; */
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-stretch: condensed;
  font-size: 36px;
  letter-spacing: 0.36px;
  color: #333333;
  opacity: 1;
}

.pricing-section2-content-div {
  width: 100%;
  height: 480px;
  position: relative;
}

.pricing-section2-img-div {
  width: 190px;
  height: 152px;
  margin: 1px 20px 0px 860px;
  z-index: -1;
  /* position: absolute; */
  /* background-color: #fbfbfc; */
}

.pricing-section2-content-box {
  position: relative;
  width: 869px;
  height: 327px;
  background: #f4f4f4;
  box-shadow: 0px 0px 12px #00000029;
  border-radius: 16px;
  opacity: 1;
  /* z-index:; */
  margin: -10px auto 0px auto;
}

.shield-img-div {
  width: 100%;
  height: 50px;
  /* background: #51a215; */
  display: flex;
  justify-content: flex-end;
}

.shield-img-div img {
  width: 47px;
  height: 47px;
  margin: 25px 25px 0px 0px;
}

.pricing-section2-content-box-part1 {
  width: 742px;
  height: 91px;
  /* background-color: #8d3636; */
  margin: 30px auto 0px auto;
  display: flex;
  flex-direction: row;
}

.key-div {
  width: 311px;
  height: 87px;
  /* background-color: #ffffff; */
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.key-div-img img {
  width: 36px;
  height: 36px;
  margin: 0px auto 0px auto;
}

.key-div-description {
  width: 254px;
  height: 57px;
  margin: 0px 0px 0px 21px;
}

.key-div-description p {
  width: 100%;
  height: 57px;
  margin: -5px 0px 0px 0px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.18px;
  color: #333333;
  opacity: 1;
}

.logo-div {
  width: 396px;
  height: 87px;
  /* background-color: #ffffff; */
  margin: 0px 0px 0px 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.logo-div-img img {
  width: 50px;
  height: 50px;
  margin: 0px;
}

.logo-div-description {
  width: 325px;
  height: 57px;
  margin: 0px 0px 0px 21px;
}

.logo-div-description p {
  width: 100%;
  height: 57px;
  margin: -5px 0px 0px 0px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #333333;
  opacity: 1;
}

.pricing-section2-content-box-part2 {
  width: 742px;
  height: 91px;
  /* background-color: #8d3636; */
  margin: 29px auto 0px auto;
  display: flex;
  flex-direction: row;
}

.no-ad-div {
  width: 311px;
  height: 87px;
  /* background-color: #ffffff; */
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.no-ad-div-img {
  width: 35px;
  height: 35px;
  margin: 0px;
}

.no-ad-div-img img {
  width: 35px;
  height: 35px;
}

.no-ad-div-description {
  width: 254px;
  height: 57px;
  margin: 0px 0px 0px 25px;
}

.no-ad-div-description p {
  width: 100%;
  height: 57px;
  margin: 0px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #333333;
  opacity: 1;
}

.laptop-code-div {
  width: 396px;
  height: 87px;
  /* background-color: #ffffff; */
  margin: 0px 0px 0px 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.laptop-code-div-img img {
  width: 36px !important;
  height: 29px !important;
  margin: 0px;
}

.laptop-code-div-description {
  width: 325px;
  height: 57px;
  margin: 0px 0px 0px 27px;
}

.laptop-code-div-description p {
  width: 100%;
  height: 57px;
  margin: -5px 0px 0px 0px;
  text-align: left;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #333333;
  opacity: 1;
}

.pricing-section-container3 {
  width: 1080px;
  height: auto;
  margin: 0px auto 0px auto;
  padding-bottom: 50px;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
}

.pricing-section-container3-title {
  width: 100%;
  height: 43px;
  margin: 72px auto 0px auto;
}

.pricing-section-container3-title p {
  margin: 0px auto 0px auto;
  width: 72px;
  height: 43px;
  text-align: center;
  /* font: normal condensed bold 36px/25px Helvetica Neue; */
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-size: 36px;
  letter-spacing: 0.36px;
  color: #333333;
  opacity: 1;
}

.pricing-section-container3-content {
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: #b9f798; */
}

.pricing-section3-bottom-layer {
  width: 309px;
  height: 224px;
  margin: -100px 0px 0px 50px;
  position: absolute;
  z-index: -1;
}

.pricing-section3-top-layer {
  width: 720px;
  height: auto;
  background-color: transparent;
  margin: 51px auto 0px auto;
}

.faq-div {
  width: 720px;
  height: 45px;
  margin: 0px auto 10px auto;
  background: #eae9df;
  border: 0.30000001192092896px solid rgb(191, 191, 191, 0.5);
  border-radius: 4px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active-faq-div {
  width: 720px;
  height: auto;
  margin: 0px auto 10px auto;
  background: #eae9df;
  border: 0.30000001192092896px solid rgba(191, 191, 191, 0.5);
  border-radius: 4px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.active-faq-div>.question-div {
  margin: 0px auto 0px auto;
}

.question-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: 0px auto 0px auto;
}

.question-div p {
  width: 685px;
  height: 24px;
  text-align: center;
  font-family: "Catamaran";
  line-height: 27px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px;
  /* background-color: #0425af; */
}

.question-div img {
  width: 18px;
  height: 9px;
  cursor: pointer;
  margin-top: 5px;
}

.active-faq-div>.answer-div {
  width: 593px;
  height: auto;
  margin: 10px auto 0px auto;
  /* background-color: #0425af; */
}

.active-faq-div>.answer-div p {
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  color: #042F69;
  opacity: 1;
  margin: 0px;
  padding-bottom: 10px;
}

.pricing-section-container4 {
  width: 100%;
  height: 1282px;
  background: transparent linear-gradient(0deg, #14213e 0%, #64627e 100%);
  display: flex;
  justify-content: flex-start;
  /* margin: 50px auto 0px auto; */
}

.pricing-section-container4-content {
  width: 1080px;
  height: 1282px;
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
}

.pricing-section-container4-content-title {
  width: 100%;
  height: 50px;
  margin: 48px auto 0px auto;
}

.pricing-section-container4-content-title p {
  width: 564px;
  height: 41px;
  margin: 0px auto 0px auto;
  text-align: center;
  /* font: normal condensed bold 36px/25px Helvetica Neue; */
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-size: 36px;
  font-stretch: condensed;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.pricing-section-container4-content-description {
  width: 650px;
  height: 63px;
  margin: 12px auto 0px auto;
}

.pricing-section-container4-content-description p {
  text-align: center;
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 30px;
  color: #ffffff;
  opacity: 1;
  margin: 0px auto 0px auto;
}

.pricing-section-container4-card-tiles-div {
  width: 1005px;
  height: 275px;
  margin: 80px auto 0px auto;
  display: flex;
  flex-direction: row;
  /* background-color: #0916a7; */
}

.pricing-section-container4-carousal {
  display: none;
}

.card-tile-1 {
  width: 225px;
  height: 275px;
  background: #edebd9;
  border-radius: 12px;
  opacity: 1;
  margin: 0px auto 0px 0px;
}

.card-tile-1-img {
  width: 175px;
  height: 127px;
  margin: 42px auto 0px auto;
}

.card-tile-1-title {
  width: 175px;
  height: 57px;
  margin: 35px auto 0px auto;
}

.card-tile-1-title p {
  width: 100%;
  height: 76px;
  margin: 0px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #6c63ff;
  opacity: 1;
}

.card-tile-2 {
  width: 225px;
  height: 275px;
  background: #cbdef4;
  border-radius: 12px;
  opacity: 1;
  margin: 0px auto 0px auto;
}

.card-tile-2-img {
  width: 150px;
  height: 122px;
  margin: 42px auto 0px auto;
}

.card-tile-2-title {
  width: 175px;
  height: 30px;
  margin: 42px auto 0px auto;
}

.card-tile-2-title p {
  width: 100%;
  height: 30px;
  margin: 0px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #6c63ff;
  opacity: 1;
}

.card-tile-3 {
  width: 225px;
  height: 275px;
  background: #e1f0df;
  border-radius: 12px;
  opacity: 1;
  margin: 0px auto 0px auto;
}

.card-tile-3-img {
  width: 212px;
  height: 96px;
  margin: 60px auto 0px auto;
}

.card-tile-3-title {
  width: 175px;
  height: 30px;
  margin: 49px auto 0px auto;
}

.card-tile-3-title p {
  width: 100%;
  height: 30px;
  margin: 0px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #6c63ff;
  opacity: 1;
}

.card-tile-4 {
  width: 225px;
  height: 275px;
  background: #eddbe3;
  border-radius: 12px;
  opacity: 1;
  margin: 0px 0px 0px auto;
}

.card-tile-4-img {
  width: 151px;
  height: 123px;
  margin: 41px auto 0px auto;
}

.card-tile-4-title {
  width: 175px;
  height: 30px;
  margin: 41px auto 0px auto;
}

.card-tile-4-title p {
  width: 100%;
  height: 30px;
  margin: 0px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #6c63ff;
  opacity: 1;
}

.pricing-section2-title {
  width: 100%;
  height: 50px;
  margin: 103px 0px 0px 0px;
}

.pricing-section2-title p {
  width: 540px;
  height: 50px;
  margin: 0px auto 0px auto;
  text-align: center;
  /* font: normal condensed bold 36px/35px Helvetica Neue; */
  /* font-stretch: condensed; */
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-size: 36px;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.pricing-section2-description {
  width: 700px;
  height: 142px;
  margin: 13px auto 0px auto;
}

.pricing-section2-description p {
  margin: 0px 0px 10px 0px;
  width: 700px;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 30px;
  color: #ffffff;
  opacity: 1;
}

.pricing-section2-description p:last-child {
  margin: 0px;
}

.pricing-go-to-top {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  margin: 25px 45px 0px 0px;
}

.footer-image-div {
  width: 1080px;
  height: 282px;
  margin: 105px 0px 0px 0px;
}

.footer-image-div img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 440px) {
  .pricing-main-container {
    max-width: 440px;
    width: 100%;
    height: auto !important;
    padding: 0px 0px 0px 0px !important;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .pricing-section-container1 {
    width: 100% !important;
    height: 900px;
    margin: 0px auto 0px auto !important;
    border: none;
  }

  .pricing-section-container1-bottom-layer {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #fbfbfc 0%, #edf2f5 100%);
  }

  .pricing-section-container1-upper-layer {
    position: relative;
    width: 100%;
    height: auto !important;
    /* z-index: 1; */
    margin: 0px auto 0px auto !important;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .pricing-section-container1-title {
    width: 100%;
    height: 27px;
    margin: 30px auto 0px auto;
  }

  .pricing-section-container1-title p {
    width: 100% !important;
    height: 27px;
    font-size: 24px;
    letter-spacing: 0.24px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    color: #333333;
    margin: 0px auto 0px auto;
    text-align: center;
  }

  .pricing-section-container1-content {
    width: 93%;
    height: 800px;
    margin: 21px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .test-drive-first-div {
    width: 100%;
    height: 390px;
    background: #efebd0;
    border-radius: 18px;
    margin: 0px auto 0px auto;
  }

  .test-drive-first-title {
    width: 100%;
    height: 27px;
    margin: 20px auto 0px auto;
  }

  .test-drive-first-title p {
    font-size: 20px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #000000;
    margin: 0px;
  }

  .test-drive-first-description {
    width: 90%;
    height: auto;
    margin: 15px auto 0px auto;

  }

  .test-drive-first-description p {
    text-align: left;
    line-height: 18px;
    letter-spacing: 0px;
    color: #000000;
    margin: 0px;
    font-size: 12px;
  }

  .pricing-start-free-trial-div {
    width: 125px;
    height: 35px;
    margin: 20px auto 0px auto;
    background-color: #427bac;
    color: #ffffff;
    border: 1.2px solid #ffffff;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pricing-start-free-trial-div:hover {
    color: #FFFFFF;
    background-color: #48588D;
  }

  .pricing-start-free-trial-div p {
    width: 100%;
    height: auto;
    margin: 12px auto 11px auto;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.12px;
    opacity: 1;
  }

  .pricing-test-drive-first-note {
    width: 289px;
    height: 33px;
    margin: 12.5px auto 0px auto;
  }

  .pricing-test-drive-first-note p {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: #0425af;
    opacity: 1;
    margin: 0px;
  }

  .pricing-test-drive-mobile-screen-div {
    width: 64%;
    height: auto;
    margin: 65px auto 0px auto !important;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
  }

  .pricing-test-drive-dashboard-image {
    width: 100%;
    height: 155px;
    background-image: url("../Assets/Pricing/dashboardImage2.webp");
    background-size: 100% 100%;
  }

  .subscription-plans-div {
    width: 100%;
    height: 390px;
    background: #efebd0;
    border-radius: 18px;
    background: transparent linear-gradient(180deg, #c2e3b6 0%, #efc7e5 100%);
    margin: 45px auto 0px auto;
  }

  .subscription-plans-title {
    width: 100%;
    height: 33px;
    margin: 20px auto 0px auto;
  }

  .subscription-plans-title p {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 1;
    margin: 0px;
  }

  .subscription-plans-first-div {
    width: 245px;
    height: auto;
    margin: 0px auto;
  }

  .saving-div {
    width: 100%;
    height: 31px;
    margin: 10px 45px 0px auto !important;
    /* border: 1px solid #000000; */
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .right-hand {
    width: 25px;
    height: auto;
  }

  .saving {
    width: 90px;
    height: 27px;
    background: #ffffff;
    color: #51a215;
    border: 1px solid #3bb54e;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 5px 0px;
    /* font: normal normal medium 16px/18px Helvetica Neue; */
    font-size: 12px;
    /* padding-top: 1.5px; */
  }

  .left-hand {
    width: 25px;
    height: auto;
  }

  .yearly-subscription-div {
    width: 245px;
    height: 79px;
    margin: 0px auto 0px auto;
    background: #b9f798;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .yearly-subscription-price {
    width: 70%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 14px 0px 0px 7px;
  }

  .yearly-subscription-price-div1 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 24px/28px Helvetica Neue; */
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }

  .yearly-subscription-price-div1 p {
    margin: 0px;
  }

  .yearly-subscription-price-div2 {
    width: 100%;
    height: 25px;
    /* background-col or: #14213E; */
    text-align: left;
    /* font: normal normal medium 10px/11px Helvetica Neue; */
    font-size: 10px;
    letter-spacing: 0.1px;
    color: #000000;
    opacity: 1;
    margin: 8px 0px 0px 5px;
    line-height: 10px;
  }

  .yearly-subscription-price-div2 p {
    margin: 0px;
    width: auto;
  }

  .yearly-subscription-price-div2 p:last-child {
    margin: 0px;
    width: auto;
  }

  .pay-yearly {
    width: 80px;
    height: 16px;
    margin: 4px 4px 0px 145px;
  }

  .pay-yearly p {
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-weight: bold;
    letter-spacing: 0.12px;
    color: #000000;
    opacity: 1;
    font-size: 12px;
    margin: 0px;
  }

  .monthly-subscription-div {
    width: 245px;
    height: 79px;
    margin: 15px auto 0px auto;
    background: #d3eda3;
    /* border: 1px solid rgb(51, 51, 51, 0.75); */
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .monthly-subscription-price {
    width: 60%;
    height: 27px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 0px 7px;
  }

  .monthly-subscription-price-div1 {
    width: 59px;
    height: auto;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
  }

  .monthly-subscription-price-div1 p {
    margin: 0px;
  }

  .monthly-subscription-price-div2 {
    width: 72px;
    height: 14px;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #000000;
    opacity: 1;
    margin: 17px 0px 0px 5px;
  }

  .monthly-subscription-price-div2 p {
    margin: 0px;
  }

  .monthly-subscription-price-div2 p:last-child {
    margin: 0px;
  }

  .pay-monthly {
    width: 86px;
    height: 16px;
    margin: 10px 0px 13px 145px;
  }

  .pay-monthly p {
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: #000000;
    opacity: 1;
    margin: 0px;
  }

  .subscription-plans-description {
    width: 79%;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .subscription-plans-description p {
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #000000;
    opacity: 1;
    margin: 0px auto;
  }

  .subscription-plans-footer {
    width: 90%;
    height: auto;
    margin: 10px auto 0px auto;
    display: flex;
  }

  .subscription-plans-footer p {
    width: 100%;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.13px;
    color: #0916a7;
    opacity: 1;
    margin: 0px auto;
    font-weight: 500;
  }

  .pricing-section-container2 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    border: none;
    background: #ffffff;
  }

  .pricing-section-container2-title {
    width: 74%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .pricing-section-container2-title p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    font-size: 24px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section2-content-div {
    width: 100%;
    height: auto;
    position: relative;
    margin: 40px auto 0px auto;
  }

  .pricing-section2-img-div {
    width: 152px;
    height: 121px;
    margin: 0px 20px 0px auto;
    z-index: -1;
  }

  .pricing-section2-content-box {
    position: relative;
    width: 79%;
    height: auto;
    background: #f4f4f4;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 16px;
    opacity: 1;
    margin: 8px auto 0px auto;
  }

  .shield-img-div {
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: flex-end;
  }

  .shield-img-div img {
    width: 33px;
    height: 33px;
    margin: 14px 14px 0px 0px;
  }

  .pricing-section2-content-box-part1 {
    width: 77%;
    height: auto;
    margin: 8px auto 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .key-div {
    width: 100%;
    height: auto;
    margin: 8px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .key-div-img img {
    width: 26px !important;
    height: 26px !important;
    margin: 0px auto 0px auto;
  }

  .key-div-description {
    width: 100%;
    height: auto;
    margin: 0px;
  }

  .key-div-description p {
    width: 100%;
    height: auto !important;
    margin: 0px auto;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: #333333;
    opacity: 1;
  }

  .logo-div {
    width: 100%;
    height: auto;
    margin: 15px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo-div-img img {
    width: 36px;
    height: 36px;
    margin: 0px auto 0px auto;
  }

  .logo-div-description {
    width: 100%;
    height: auto;
    margin: 0px;
  }

  .logo-div-description p {
    width: 100%;
    height: auto;
    margin: 0px;
    text-align: center;
    /* font: normal normal medium 14px/18px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section2-content-box-part2 {
    width: 77%;
    height: auto;
    margin: 15px auto 0px auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  .no-ad-div {
    width: 100%;
    height: 46px;
    /* background-color: #ffffff; */
    margin: 15px auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .no-ad-div-img {
    width: 25px;
    height: 25px;
    margin: 0px auto 0px auto;
  }

  .no-ad-div-img img {
    width: 25px;
    height: 25px;
  }

  .no-ad-div-description {
    width: 100%;
    height: 16px;
    margin: 0px auto 0px auto;
  }

  .no-ad-div-description p {
    width: 100%;
    height: 16px;
    margin: 0px auto;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: #333333;
    opacity: 1;
  }

  .laptop-code-div {
    width: 100%;
    height: auto;
    margin: 15px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .laptop-code-div-img {
    width: 31px !important;
    height: 24px !important;
    margin: 0px auto 0px auto;
  }

  .laptop-code-div-img {
    width: 31px !important;
    height: 24px !important;
  }

  .laptop-code-div-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .laptop-code-div-description p {
    width: 100%;
    height: auto;
    margin: 0px auto;
    text-align: center;
    /* font: normal normal medium 14px/18px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section-container3 {
    width: 100%;
    height: auto;
    margin: 60px auto 0px auto;
    border: none;
  }

  .pricing-section-container3-title {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .pricing-section-container3-title p {
    margin: 0px auto 0px auto;
    width: 60px;
    height: auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section-container3-content {
    width: 100% !important;
    height: auto;
    margin: 30px auto 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }

  .pricing-section3-bottom-layer {
    width: 223px;
    height: 162px;
    margin: 0px auto 0px auto;
    position: relative;
    display: flex;
  }

  .pricing-section3-bottom-layer img {
    width: 223px;
    height: 162px;
    margin: 0px auto 0px auto;
  }

  .pricing-section3-top-layer {
    width: 100% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .faq-div {
    width: 91%;
    height: 45px;
    margin: 0px auto 10px auto;
    background: #eae9df;
    border: 0.30000001192092896px solid rgb(191, 191, 191, 0.5);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .active-faq-div {
    width: 91%;
    height: auto !important;
    margin: 0px auto 5px auto;
    background: #eae9df;
    border: 0.30000001192092896px solid rgb(191, 191, 191, 0.5);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }

  .active-faq-div>.question-div {
    margin: 10px auto 0px auto;
  }

  .question-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0px auto 0px auto;
    height: 45px;
  }

  .question-div p {
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 14px;
    font-weight: 400 !important;
    letter-spacing: 0.14px;
    line-height: 21px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 0px 10px;
  }

  .question-div img {
    width: 18px;
    height: 9px;
    cursor: pointer;
    margin: -10px 10px 0px 10px;
  }

  .active-faq-div>.answer-div {
    width: 97%;
    height: auto;
    margin: 5px 0px 0px 0px;
  }

  .active-faq-div>.answer-div p {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #163C93;
    font-weight: 500;
    line-height: 21px;
    opacity: 1;
    margin: 0px 0px 0px 5px;
    padding-bottom: 10px;
  }

  .pricing-section-container4 {
    width: 100%;
    height: auto;
    background: transparent linear-gradient(0deg, #14213e 0%, #64627e 100%);
    display: flex;
    margin: 0px auto 0px auto;
    justify-content: flex-start;
  }

  .pricing-section-container4-content {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    border: none;
  }

  .pricing-section-container4-content-title {
    width: 100%;
    height: auto;
    margin: 31px 0px 0px 0px;
  }

  .pricing-section-container4-content-title p {
    width: 80%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 24px;
    font-stretch: condensed;
    letter-spacing: 0.24px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section-container4-content-description {
    width: 90%;
    height: auto;
    margin: 14px auto 0px auto;
  }

  .pricing-section-container4-content-description p {
    width: 90%;
    height: auto;
    text-align: center;
    letter-spacing: 0.12px;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    opacity: 1;
    margin: 0px auto 0px auto;
  }

  .pricing-section-container4-card-tiles-div {
    display: none;
  }

  .pricing-section-container4-carousal {
    width: 90%;
    height: 284px;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .pricing-section4-carousal {
    width: 200px;
    height: 240px;
    margin: -5px auto 0px auto;
  }

  .slide-btn {
    margin: 0px auto 0px auto;
  }

  .card-tile-1 {
    width: 194px;
    height: 212px;
    display: none;
    background: #edebd9 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;

    margin: 0px auto 0px auto;
  }

  .card-tile-1-img {
    width: 123px;
    height: 90px;
    margin: 35px auto 0px auto;
  }

  .card-tile-1-img img {
    width: 123px;
    height: 90px;
  }

  .card-tile-1-title {
    width: 168px;
    height: 45px;
    margin: 41px auto 0px auto;
  }

  .card-tile-1-title p {
    width: 100%;
    height: 19px;
    margin: 0px;
    text-align: center;
    /* font: normal normal medium 18px/25px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-2 {
    width: 194px;
    height: 212px;
    display: none;
    background: #cbdef4 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    margin: 0px auto 0px auto;
  }

  .card-tile-2-img {
    width: 123px;
    height: 99px;
    margin: 35px auto 0px auto;
  }

  .card-tile-2-img img {
    width: 123px;
    height: 99px;
  }

  .card-tile-2-title {
    width: 168px;
    height: 19px;
    margin: 32px auto 0px auto;
  }

  .card-tile-2-title p {
    width: 100%;
    height: 20px;
    margin: 0px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-3 {
    width: 194px;
    height: 212px;
    display: none;
    background: #e1f0df 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    margin: 0px auto 0px auto;
  }

  .card-tile-3-img {
    width: 177px;
    height: 72px;
    margin: 43px auto 0px auto;
  }

  .card-tile-3-img img {
    width: 177px;
    height: 72px;
  }

  .card-tile-3-title {
    width: 168px;
    height: 20px;
    margin: 53px auto 0px auto;
  }

  .card-tile-3-title p {
    width: 100%;
    height: 20px;
    margin: 0px;
    text-align: center;
    /* font: normal normal medium 18px/25px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-4 {
    width: 194px;
    height: 212px;
    display: none;
    background: #eddbe3;
    border-radius: 12px;
    opacity: 1;
    margin: 0px auto 0px auto;
  }

  .card-tile-4-img {
    width: 123px;
    height: 86px;
    margin: 38px auto 0px auto;
  }

  .card-tile-4-img img {
    width: 123px;
    height: 86px;
  }

  .card-tile-4-title {
    width: 168px;
    height: 20px;
    margin: 35px auto 0px auto;
  }

  .card-tile-4-title p {
    width: 100%;
    height: 20px;
    margin: 0px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: #6c63ff;
    opacity: 1;
  }

  .activeTile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section4-radio-controls {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px auto 0px auto;
  }

  .section4-radio-container {
    margin: 0px 13px 0px 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(125, 125, 125, 0.726);
    cursor: pointer;
  }

  .section4-radio-container.checked {
    background-color: #ffffff;
    border-color: #ffffff;
  }

  .pricing-section2-title {
    width: 100%;
    height: 31px;
    margin: 7px 0px 0px 0px;
  }

  .pricing-section2-title p {
    width: 318px;
    height: 31px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section2-description {
    width: 90%;
    height: 155px;
    margin: 15px auto 0px auto;
  }

  .pricing-section2-description p {
    margin: 0px auto 30px auto;
    width: 90%;
    height: auto;
    text-align: center;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.12px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section2-description p:last-child {
    margin: 0px auto 0px auto;
  }

  .pricing-go-to-top {
    width: 95%;
    height: 25px;
    display: flex-end;
    margin: 35px 10px 0px 0px;
  }

  .footer-image-div {
    width: 100%;
    height: 195px;
    margin: 25px 0px 0px 0px;
    background-image: url("../Assets/Pricing/footerImage2.webp");
    background-size: 100% 100%;
  }

  .footer-image-div img {
    width: 100%;
    height: 100%;
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .pricing-main-container {
    width: 100%;
    height: auto;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .pricing-section-container1 {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0px 0px 0px 0px !important;
  }

  .pricing-section-container1-bottom-layer {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 706px;
    background: linear-gradient(180deg, #fbfbfc 0%, #edf2f5 100%);
  }

  .pricing-section-container1-upper-layer {
    position: relative;
    width: 100%;
    height: auto !important;
    z-index: 0;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pricing-section-container1-title {
    width: 100%;
    height: 51px;
    margin: 45px auto 0px auto;
  }

  .pricing-section-container1-title p {
    width: auto;
    height: auto;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    color: #333333;
    margin: 0px auto 0px auto;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .pricing-section-container1-content {
    width: 93%;
    height: auto;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .test-drive-first-div {
    width: 47.8%;
    height: auto;
    background: #efebd0;
    border-radius: 20px;
    margin: 0px auto 0px 0px;
  }

  .subscription-plans-div {
    width: 47.8%;
    height: auto;
    background: #efebd0;
    border-radius: 20px;
    background: transparent linear-gradient(180deg, #c2e3b6 0%, #efc7e5 100%);
    margin: 0px 0px 0px auto;
  }

  .test-drive-first-title {
    width: 50%;
    height: auto;
    margin: 30px 0px 0px 30px;
  }

  .test-drive-first-title p {
    font-family: "Catamaran";
    font-size: 22px;
    margin: 0px;
    letter-spacing: 0.22px;
    line-height: 33px;
    font-weight: bold;
    text-align: left;
    color: #000000;
  }

  .test-drive-first-description {
    width: 80%;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .test-drive-first-description p {
    text-align: left;
    width: auto;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    color: #000000;
    margin: 0px auto;
    height: auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .pricing-start-free-trial-div {
    width: 150px;
    height: 40px;
    margin: 23px auto 0px auto;
    background-color: #427bac;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pricing-start-free-trial-div:hover {
    color: #FFFFFF;
    background-color: #48588D;
  }

  .pricing-start-free-trial-div p {
    width: 100%;
    height: auto;
    margin: auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    opacity: 1;
  }

  .pricing-test-drive-first-note {
    width: 420px;
    height: 27px;
    margin: 24px auto 0px auto;
  }

  .pricing-test-drive-first-note p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #0425af;
    opacity: 1;
    width: auto;
    height: auto;
  }

  .pricing-test-drive-mobile-screen-div {
    width: 80%;
    height: 203px;
    margin: 56px auto 0px auto !important;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    scrollbar-color: transparent transparent;
  }

  .pricing-test-drive-dashboard-image {
    width: 100%;
    height: 677px;
    margin: 0px auto 0px auto;
    background-image: url("../Assets/Pricing/dashboardImage.webp");
    background-size: 100% 100%;
    object-fit: fill;

  }

  .subscription-plans-title {
    width: 60%;
    height: auto;
    margin: 30px 0px 0px 30px;
    /* border: 1px solid #000; */
  }

  .subscription-plans-title p {
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.22px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    line-height: 33px;
  }

  .saving-div {
    width: 200px;
    height: 40px;
    margin: 12px 20px 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .right-hand {
    width: 30px;
    height: 32px;
  }

  .saving {
    width: 100%;
    height: 32px;
    background: #ffffff;
    color: #51a215;
    border: 1px solid #3bb54e;
    border-radius: 12px 12px 0px 0px;
    text-align: center;
    margin: 7px 5px 0px 5px;
    font-family: "Catamaran";
    font-weight: bold;
    font-size: 16px;
    opacity: 1;
  }

  .left-hand {
    width: 30px;
    height: 32px;
  }

  .yearly-subscription-div {
    width: 92%;
    height: 90px;
    margin: 0px auto 0px auto;
    background: #b9f798;
    border: 1.2px solid rgb(51, 51, 51, 1);
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
  }

  .yearly-subscription-price {
    width: 70%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 0px 15px;
  }

  .yearly-subscription-price-div1 {
    width: 75px;
    height: 30px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
  }

  .yearly-subscription-price-div1 p {
    margin: 0px;
  }

  .yearly-subscription-price-div2 {
    width: 80px;
    height: 35px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 12px 0px 0px 6px;
  }

  .yearly-subscription-price-div2 p {
    line-height: 15px;
    margin: 0px auto;
    width: 100%;
  }

  .yearly-subscription-price-div2 p:last-child {
    margin: 0px;
  }

  .pay-yearly {
    width: 30%;
    height: auto;
    margin: 35px auto 0px auto;
  }

  .pay-yearly p {
    text-align: left;
    font-family: "Catamaran";
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    width: auto;
  }

  .monthly-subscription-div {
    width: 92%;
    height: 85px;
    margin: 32px auto 0px auto;
    background: #d3eda3;
    border: 1.2px solid rgb(51, 51, 51, 0.75);
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .monthly-subscription-price {
    width: 70%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 0px 15px;
  }

  .monthly-subscription-price-div1 {
    width: 65px;
    height: 27px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
  }

  .monthly-subscription-price-div1 p {
    margin: 0px;
  }

  .monthly-subscription-price-div2 {
    width: auto;
    height: 14px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 18px 0px 0px 15px;
  }

  .monthly-subscription-price-div2 p {
    margin: 0px 0px 2px 0px;
    width: auto;
  }

  .monthly-subscription-price-div2 p:last-child {
    margin: 0px;
  }

  .pay-monthly {
    width: 30%;
    height: 17px;
    margin: 35px auto 0px auto;
  }

  .pay-monthly p {
    text-align: left;
    font-family: "Catamaran";
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    width: auto;
  }

  .subscription-plans-description {
    width: 85%;
    height: auto;
    /* border: 1px solid #000000; */
    margin: 26px auto 0px auto;
  }

  .subscription-plans-description p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px auto;
    width: auto;
  }

  .subscription-plans-footer {
    width: 90%;
    height: 27px;
    margin: 20px auto 0px auto;
  }

  .subscription-plans-footer p {
    width: 100%;
    text-align: center;
    font-family: "Catamaran";
    /* font-weight: 400; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #0916a7;
    opacity: 1;
    margin: 0px;
    font-weight: 500;
  }

  .pricing-section-container2 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    border-right: none;
    border-left: none;
    background: #ffffff;
  }

  .pricing-section-container2-title {
    width: 100%;
    height: auto;
    margin: 95px auto 0px auto;
  }

  .pricing-section-container2-title p {
    width: 75%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    font-size: 36px;
    letter-spacing: 0.36px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section2-content-div {
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px auto;
  }

  .pricing-section2-img-div {
    width: 190px;
    height: 152px;
    margin: 1px 0px 0px auto;
    z-index: -1;
    left: auto;
    right: 0;
  }

  .pricing-section2-content-box {
    position: relative;
    width: 80%;
    height: auto;
    background: #f4f4f4;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 16px;
    opacity: 1;
    margin: -10px auto 20px auto;
  }

  .shield-img-div {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
  }

  .shield-img-div img {
    width: 40px;
    height: 40px;
    margin: 20px 20px 0px 0px;
  }

  .pricing-section2-content-box-part1 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .key-div {
    width: 48%;
    height: auto;
    margin: 0px auto 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .key-div-img img {
    width: 33px;
    height: 33px;
    margin: auto auto;

  }

  .key-div-description {
    width: 80%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .key-div-description p {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16x;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #333333;
    opacity: 1;
  }

  .logo-div {
    width: 48%;
    height: auto;
    margin: 0px 0px 0px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .logo-div-img img {
    width: 45px;
    height: 45px;
    margin: 0px;
  }

  .logo-div-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 21px;
  }

  .logo-div-description p {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section2-content-box-part2 {
    width: 90%;
    height: auto;
    margin: 10px auto 20px auto;
    display: flex;
    flex-direction: row;
  }

  .no-ad-div {
    width: 48%;
    height: auto;
    margin: 0px auto 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .no-ad-div-img {
    width: 32px;
    height: 32px;
    margin: 0px;
  }

  .no-ad-div-img img {
    width: 32px;
    height: 32px;
  }

  .no-ad-div-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 21px;
  }

  .no-ad-div-description p {
    width: 100%;
    height: auro;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #333333;
    opacity: 1;
  }

  .laptop-code-div {
    width: 48%;
    height: auto;
    margin: 0px 0px 0px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .laptop-code-div-img img {
    width: 45px !important;
    height: 29px !important;
    margin: 0px;
  }

  .laptop-code-div-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 21px;
  }

  .laptop-code-div-description p {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section-container3 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    padding-bottom: 50px;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
  }

  .pricing-section-container3-title {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .pricing-section-container3-title p {
    margin: 0px auto 0px auto;
    width: auto;
    height: auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 36px;
    letter-spacing: 0.36px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section-container3-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }

  .pricing-section3-bottom-layer {
    width: 309px;
    height: 224px;
    margin: 20px auto 0px auto;
    position: relative;
    display: flex;
  }

  .pricing-section3-bottom-layer img {
    width: 100%;
    height: 100%;
  }

  .pricing-section3-top-layer {
    width: 720px;
    height: auto;
    background-color: transparent;
    margin: 20px auto 0px auto;
  }

  .faq-div {
    width: 720px;
    height: 45px;
    margin: 20px auto 10px auto;
    background: #eae9df;
    border: 0.30000001192092896px solid rgb(191, 191, 191, 0.5);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .active-faq-div {
    width: 720px;
    height: auto;
    margin: 0px auto 10px auto;
    background: #eae9df;
    border: 0.30000001192092896px solid rgba(191, 191, 191, 0.5);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }

  .active-faq-div>.question-div {
    margin: 0px auto 0px auto;
  }

  .question-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 0px auto 0px auto;
  }

  .question-div p {
    width: 685px;
    height: 24px;
    text-align: center;
    font-family: "Catamaran";
    line-height: 27px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    /* background-color: #0425af; */
  }

  .question-div img {
    width: 18px;
    height: 9px;
    cursor: pointer;
    margin-top: 5px;
  }

  .active-faq-div>.answer-div {
    width: 593px;
    height: auto;
    margin: 10px auto 0px auto;
    /* background-color: #0425af; */
  }

  .active-faq-div>.answer-div p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #042F69;
    opacity: 1;
    margin: 0px;
    padding-bottom: 10px;
  }

  .pricing-section-container4 {
    width: 100%;
    height: auto;
    background: transparent linear-gradient(0deg, #14213e 0%, #64627e 100%);
    display: flex;
    justify-content: flex-start;
    /* margin: 50px auto 0px auto; */
  }

  .pricing-section-container4-content {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    border-right: none;
    border-left: none;
  }

  .pricing-section-container4-content-title {
    width: 100%;
    height: auto;
    margin: 48px auto 0px auto;
  }

  .pricing-section-container4-content-title p {
    width: auto;
    height: 41px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 36px;
    font-stretch: condensed;
    letter-spacing: 0.36px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section-container4-content-description {
    width: 650px;
    height: 63px;
    margin: 12px auto 0px auto;
  }

  .pricing-section-container4-content-description p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #ffffff;
    opacity: 1;
    margin: 0px auto 0px auto;
  }

  .pricing-section-container4-card-tiles-div {
    width: 480px;
    height: auto;
    margin: 80px auto 0px auto;
    display: grid;
    row-gap: 30px;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 2fr 2fr;
  }

  .pricing-section-container4-carousal {
    display: none;
  }

  .card-tile-1 {
    width: 225px;
    height: 275px;
    background: #edebd9;
    border-radius: 12px;
    opacity: 1;
    margin: 0px auto auto 0px;
  }

  .card-tile-1-img {
    width: 175px;
    height: 127px;
    margin: 42px auto 0px auto;
  }

  .card-tile-1-title {
    width: 175px;
    height: 57px;
    margin: 35px auto 0px auto;
  }

  .card-tile-1-title p {
    width: 100%;
    height: 76px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-2 {
    width: 225px;
    height: 275px;
    background: #cbdef4;
    border-radius: 12px;
    opacity: 1;
    margin: 0px 0px auto auto;
  }

  .card-tile-2-img {
    width: 150px;
    height: 122px;
    margin: 42px auto 0px auto;
  }

  .card-tile-2-title {
    width: 175px;
    height: 30px;
    margin: 42px auto 0px auto;
  }

  .card-tile-2-title p {
    width: 100%;
    height: 30px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-3 {
    width: 225px;
    height: 275px;
    background: #e1f0df;
    border-radius: 12px;
    opacity: 1;
    margin: auto auto 0px 0px;
  }

  .card-tile-3-img {
    width: 212px;
    height: 96px;
    margin: 60px auto 0px auto;
  }

  .card-tile-3-title {
    width: 175px;
    height: 30px;
    margin: 49px auto 0px auto;
  }

  .card-tile-3-title p {
    width: 100%;
    height: 30px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-4 {
    width: 225px;
    height: 275px;
    background: #eddbe3;
    border-radius: 12px;
    opacity: 1;
    margin: auto 0px 0px auto;
  }

  .card-tile-4-img {
    width: 151px;
    height: 123px;
    margin: 41px auto 0px auto;
  }

  .card-tile-4-title {
    width: 175px;
    height: 30px;
    margin: 41px auto 0px auto;
  }

  .card-tile-4-title p {
    width: 100%;
    height: 30px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .pricing-section2-title {
    width: 100%;
    height: auto;
    margin: 40px 0px 0px 0px;
  }

  .pricing-section2-title p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 36px;
    letter-spacing: 0.36px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section2-description {
    width: 70%;
    height: auto;
    margin: 13px auto 0px auto;
  }

  .pricing-section2-description p {
    margin: 0px auto 10px auto;
    width: 100%;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section2-description p:last-child {
    margin: 0px;
  }

  .pricing-go-to-top {
    width: 90%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    margin: 25px 0px 0px auto;
  }

  .footer-image-div {
    width: 100%;
    height: auto;
    margin: 60px 0px 0px 0px;
  }

  .footer-image-div img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .pricing-main-container {
    width: 100%;
    height: auto;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .pricing-section-container1 {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0px 0px 0px 0px !important;
  }

  .pricing-section-container1-bottom-layer {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 706px;
    background: linear-gradient(180deg, #fbfbfc 0%, #edf2f5 100%);
  }

  .pricing-section-container1-upper-layer {
    position: relative;
    width: 100%;
    height: auto !important;
    z-index: 0;
    margin: 0px auto 0px auto !important;
    border-right: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pricing-section-container1-title {
    width: 100%;
    height: 51px;
    margin: 45px auto 0px auto;
  }

  .pricing-section-container1-title p {
    width: auto;
    height: auto;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    color: #333333;
    margin: 0px auto 0px auto;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .pricing-section-container1-content {
    width: 93%;
    height: auto;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .test-drive-first-div {
    width: 47.8%;
    height: auto;
    background: #efebd0;
    border-radius: 20px;
    margin: 0px auto 0px 0px;
  }

  .subscription-plans-div {
    width: 47.8%;
    height: auto;
    background: #efebd0;
    border-radius: 20px;
    background: transparent linear-gradient(180deg, #c2e3b6 0%, #efc7e5 100%);
    margin: 0px 0px 0px auto;
  }

  .test-drive-first-title {
    width: 50%;
    height: auto;
    margin: 30px 0px 0px 30px;
  }

  .test-drive-first-title p {
    font-family: "Catamaran";
    font-size: 22px;
    margin: 0px;
    letter-spacing: 0.22px;
    line-height: 33px;
    font-weight: bold;
    text-align: left;
    color: #000000;
  }

  .test-drive-first-description {
    width: 80%;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .test-drive-first-description p {
    text-align: left;
    width: auto;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    color: #000000;
    margin: 0px auto;
    height: auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .pricing-start-free-trial-div {
    width: 150px;
    height: 40px;
    margin: 23px auto 0px auto;
    background-color: #427bac;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pricing-start-free-trial-div:hover {
    color: #FFFFFF;
    background-color: #48588D;
  }

  .pricing-start-free-trial-div p {
    width: 100%;
    height: auto;
    margin: auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    opacity: 1;
  }

  .pricing-test-drive-first-note {
    width: 420px;
    height: 27px;
    margin: 24px auto 0px auto;
  }

  .pricing-test-drive-first-note p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #0425af;
    opacity: 1;
    width: auto;
    height: auto;
  }

  .pricing-test-drive-mobile-screen-div {
    width: 80%;
    height: 203px;
    margin: 56px auto 0px auto !important;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    scrollbar-color: transparent transparent;
  }

  .pricing-test-drive-dashboard-image {
    width: 100%;
    height: 677px;
    margin: 0px auto 0px auto;
    background-image: url("../Assets/Pricing/dashboardImage.webp");
    background-size: 100% 100%;
    object-fit: fill;

  }

  .subscription-plans-title {
    width: 60%;
    height: auto;
    margin: 30px 0px 0px 30px;
    /* border: 1px solid #000; */
  }

  .subscription-plans-title p {
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.22px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    line-height: 33px;
  }

  .saving-div {
    width: 200px;
    height: 40px;
    margin: 12px 20px 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .right-hand {
    width: 30px;
    height: 32px;
  }

  .saving {
    width: 100%;
    height: 32px;
    background: #ffffff;
    color: #51a215;
    border: 1px solid #3bb54e;
    border-radius: 12px 12px 0px 0px;
    text-align: center;
    margin: 7px 5px 0px 5px;
    font-family: "Catamaran";
    font-weight: bold;
    font-size: 16px;
    opacity: 1;
  }

  .left-hand {
    width: 30px;
    height: 32px;
  }

  .yearly-subscription-div {
    width: 92%;
    height: 90px;
    margin: 0px auto 0px auto;
    background: #b9f798;
    border: 1.2px solid rgb(51, 51, 51, 1);
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
  }

  .yearly-subscription-price {
    width: 70%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 0px 15px;
  }

  .yearly-subscription-price-div1 {
    width: 75px;
    height: 30px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
  }

  .yearly-subscription-price-div1 p {
    margin: 0px;
  }

  .yearly-subscription-price-div2 {
    width: 80px;
    height: 35px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 12px 0px 0px 6px;
  }

  .yearly-subscription-price-div2 p {
    line-height: 15px;
    margin: 0px auto;
    width: 100%;
  }

  .yearly-subscription-price-div2 p:last-child {
    margin: 0px;
  }

  .pay-yearly {
    width: 30%;
    height: auto;
    margin: 35px auto 0px auto;
  }

  .pay-yearly p {
    text-align: left;
    font-family: "Catamaran";
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    width: auto;
  }

  .monthly-subscription-div {
    width: 92%;
    height: 85px;
    margin: 32px auto 0px auto;
    background: #d3eda3;
    border: 1.2px solid rgb(51, 51, 51, 0.75);
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .monthly-subscription-price {
    width: 70%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 15px 0px 0px 15px;
  }

  .monthly-subscription-price-div1 {
    width: 65px;
    height: 27px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
  }

  .monthly-subscription-price-div1 p {
    margin: 0px;
  }

  .monthly-subscription-price-div2 {
    width: auto;
    height: 14px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 18px 0px 0px 15px;
  }

  .monthly-subscription-price-div2 p {
    margin: 0px 0px 2px 0px;
    width: auto;
  }

  .monthly-subscription-price-div2 p:last-child {
    margin: 0px;
  }

  .pay-monthly {
    width: 30%;
    height: 17px;
    margin: 35px auto 0px auto;
  }

  .pay-monthly p {
    text-align: left;
    font-family: "Catamaran";
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    width: auto;
  }

  .subscription-plans-description {
    width: 85%;
    height: auto;
    /* border: 1px solid #000000; */
    margin: 26px auto 0px auto;
  }

  .subscription-plans-description p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px auto;
    width: auto;
  }

  .subscription-plans-footer {
    width: 90%;
    height: 27px;
    margin: 20px auto 0px auto;
  }

  .subscription-plans-footer p {
    width: 100%;
    text-align: center;
    font-family: "Catamaran";
    /* font-weight: 400; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #0916a7;
    opacity: 1;
    margin: 0px;
    font-weight: 500;
  }

  .pricing-section-container2 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    border-right: none;
    border-left: none;
    background: #ffffff;
  }

  .pricing-section-container2-title {
    width: 100%;
    height: auto;
    margin: 95px auto 0px auto;
  }

  .pricing-section-container2-title p {
    width: 75%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    font-size: 36px;
    letter-spacing: 0.36px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section2-content-div {
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px auto;
  }

  .pricing-section2-img-div {
    width: 190px;
    height: 152px;
    margin: 1px 0px 0px auto;
    z-index: -1;
    left: auto;
    right: 0;
  }

  .pricing-section2-content-box {
    position: relative;
    width: 80%;
    height: auto;
    background: #f4f4f4;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 16px;
    opacity: 1;
    margin: -10px auto 20px auto;
  }

  .shield-img-div {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
  }

  .shield-img-div img {
    width: 40px;
    height: 40px;
    margin: 20px 20px 0px 0px;
  }

  .pricing-section2-content-box-part1 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: row;
  }

  .key-div {
    width: 48%;
    height: auto;
    margin: 0px auto 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .key-div-img img {
    width: 33px;
    height: 33px;
    margin: auto auto;

  }

  .key-div-description {
    width: 80%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .key-div-description p {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16x;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #333333;
    opacity: 1;
  }

  .logo-div {
    width: 48%;
    height: auto;
    margin: 0px 0px 0px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .logo-div-img img {
    width: 45px;
    height: 45px;
    margin: 0px;
  }

  .logo-div-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 21px;
  }

  .logo-div-description p {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section2-content-box-part2 {
    width: 90%;
    height: auto;
    margin: 10px auto 20px auto;
    display: flex;
    flex-direction: row;
  }

  .no-ad-div {
    width: 48%;
    height: auto;
    margin: 0px auto 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .no-ad-div-img {
    width: 32px;
    height: 32px;
    margin: 0px;
  }

  .no-ad-div-img img {
    width: 32px;
    height: 32px;
  }

  .no-ad-div-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 21px;
  }

  .no-ad-div-description p {
    width: 100%;
    height: auro;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #333333;
    opacity: 1;
  }

  .laptop-code-div {
    width: 48%;
    height: auto;
    margin: 0px 0px 0px auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .laptop-code-div-img img {
    width: 45px !important;
    height: 29px !important;
    margin: 0px;
  }

  .laptop-code-div-description {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 21px;
  }

  .laptop-code-div-description p {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section-container3 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    padding-bottom: 50px;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
  }

  .pricing-section-container3-title {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .pricing-section-container3-title p {
    margin: 0px auto 0px auto;
    width: auto;
    height: auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 36px;
    letter-spacing: 0.36px;
    color: #333333;
    opacity: 1;
  }

  .pricing-section-container3-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }

  .pricing-section3-bottom-layer {
    width: 309px;
    height: 224px;
    margin: 20px auto 0px auto;
    position: relative;
    display: flex;
  }

  .pricing-section3-bottom-layer img {
    width: 100%;
    height: 100%;
  }

  .pricing-section3-top-layer {
    width: 720px;
    height: auto;
    background-color: transparent;
    margin: 20px auto 0px auto;
  }

  .faq-div {
    width: 720px;
    height: 45px;
    margin: 20px auto 10px auto;
    background: #eae9df;
    border: 0.30000001192092896px solid rgb(191, 191, 191, 0.5);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .active-faq-div {
    width: 720px;
    height: auto;
    margin: 0px auto 10px auto;
    background: #eae9df;
    border: 0.30000001192092896px solid rgba(191, 191, 191, 0.5);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }

  .active-faq-div>.question-div {
    margin: 0px auto 0px auto;
  }

  .question-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 0px auto 0px auto;
  }

  .question-div p {
    width: 685px;
    height: 24px;
    text-align: center;
    font-family: "Catamaran";
    line-height: 27px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    /* background-color: #0425af; */
  }

  .question-div img {
    width: 18px;
    height: 9px;
    cursor: pointer;
    margin-top: 5px;
  }

  .active-faq-div>.answer-div {
    width: 593px;
    height: auto;
    margin: 10px auto 0px auto;
    /* background-color: #0425af; */
  }

  .active-faq-div>.answer-div p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #042F69;
    opacity: 1;
    margin: 0px;
    padding-bottom: 10px;
  }

  .pricing-section-container4 {
    width: 100%;
    height: auto;
    background: transparent linear-gradient(0deg, #14213e 0%, #64627e 100%);
    display: flex;
    justify-content: flex-start;
    /* margin: 50px auto 0px auto; */
  }

  .pricing-section-container4-content {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    border-right: none;
    border-left: none;
  }

  .pricing-section-container4-content-title {
    width: 100%;
    height: auto;
    margin: 48px auto 0px auto;
  }

  .pricing-section-container4-content-title p {
    width: auto;
    height: 41px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 36px;
    font-stretch: condensed;
    letter-spacing: 0.36px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section-container4-content-description {
    width: 650px;
    height: 63px;
    margin: 12px auto 0px auto;
  }

  .pricing-section-container4-content-description p {
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #ffffff;
    opacity: 1;
    margin: 0px auto 0px auto;
  }

  .pricing-section-container4-card-tiles-div {
    width: 480px;
    height: auto;
    margin: 80px auto 0px auto;
    display: grid;
    row-gap: 30px;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 2fr 2fr;
  }

  .pricing-section-container4-carousal {
    display: none;
  }

  .card-tile-1 {
    width: 225px;
    height: 275px;
    background: #edebd9;
    border-radius: 12px;
    opacity: 1;
    margin: 0px auto auto 0px;
  }

  .card-tile-1-img {
    width: 175px;
    height: 127px;
    margin: 42px auto 0px auto;
  }

  .card-tile-1-title {
    width: 175px;
    height: 57px;
    margin: 35px auto 0px auto;
  }

  .card-tile-1-title p {
    width: 100%;
    height: 76px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-2 {
    width: 225px;
    height: 275px;
    background: #cbdef4;
    border-radius: 12px;
    opacity: 1;
    margin: 0px 0px auto auto;
  }

  .card-tile-2-img {
    width: 150px;
    height: 122px;
    margin: 42px auto 0px auto;
  }

  .card-tile-2-title {
    width: 175px;
    height: 30px;
    margin: 42px auto 0px auto;
  }

  .card-tile-2-title p {
    width: 100%;
    height: 30px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-3 {
    width: 225px;
    height: 275px;
    background: #e1f0df;
    border-radius: 12px;
    opacity: 1;
    margin: auto auto 0px 0px;
  }

  .card-tile-3-img {
    width: 212px;
    height: 96px;
    margin: 60px auto 0px auto;
  }

  .card-tile-3-title {
    width: 175px;
    height: 30px;
    margin: 49px auto 0px auto;
  }

  .card-tile-3-title p {
    width: 100%;
    height: 30px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .card-tile-4 {
    width: 225px;
    height: 275px;
    background: #eddbe3;
    border-radius: 12px;
    opacity: 1;
    margin: auto 0px 0px auto;
  }

  .card-tile-4-img {
    width: 151px;
    height: 123px;
    margin: 41px auto 0px auto;
  }

  .card-tile-4-title {
    width: 175px;
    height: 30px;
    margin: 41px auto 0px auto;
  }

  .card-tile-4-title p {
    width: 100%;
    height: 30px;
    margin: 0px;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    color: #6c63ff;
    opacity: 1;
  }

  .pricing-section2-title {
    width: 100%;
    height: auto;
    margin: 40px 0px 0px 0px;
  }

  .pricing-section2-title p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-size: 36px;
    letter-spacing: 0.36px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section2-description {
    width: 70%;
    height: auto;
    margin: 13px auto 0px auto;
  }

  .pricing-section2-description p {
    margin: 0px auto 10px auto;
    width: 100%;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #ffffff;
    opacity: 1;
  }

  .pricing-section2-description p:last-child {
    margin: 0px;
  }

  .pricing-go-to-top {
    width: 90%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    margin: 25px 0px 0px auto;
  }

  .footer-image-div {
    width: 100%;
    height: auto;
    margin: 60px 0px 0px 0px;
  }

  .footer-image-div img {
    width: 100%;
    height: 100%;
  }
}