.privacy-policy-main-container {
    width: 100%;
    height: 3650px;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
}

.pp-actual-content-container {
    width: 1080px;
    height: 100%;
    border-left: 1px solid rgb(144, 144, 144, 0.5);
    border-right: 1px solid rgb(144, 144, 144, 0.5);
    margin: 0px auto 0px auto;
    background: #ffffff;
}

.pp-first-section {
    width: 1080px;
    height: 523px;
    margin: 50px auto 0px auto;
    /* background: #f3f303;  */
}

.pp-heading1 {
    width: 100%;
    height: 40px;
    margin: 0px auto 0px auto;
}

.pp-heading1 p {
    width: 250px;
    height: 40px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    color: #000000;
    letter-spacing: 0.32px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    /* font: normal condensed bold 32px/45px Helvetica Neue; */
    /* font-weight: bold; */
}

.pp-last-updated-div {
    width: 100%;
    height: 22px;
    margin: 21px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
}

.pp-last-updated-div p {
    width: 233px;
    height: 22px;
    text-align: right;
    /* font: normal normal medium 18px/32px Helvetica Neue; */
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 97px 0px auto;
}

.pp-description1 {
    width: 850px;
    height: 385px;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
    /* background-color: #f3f3f3; */
}

.pp-description1 p {
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    height: auto;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 0px 0px 10px 0px;
}

.pp-description1 p:last-child {
    margin: 0px;
    /* background-color: #f3f3f3; */
}

.pp-sub-section1 {
    width: 850px;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
    /* background-color: #9d5454; */
}

.pp-sub-section1-title {
    width: 433px;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px -8px;

}

.pp-sub-section1 p {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;

}

.pp-sub-section1 p:last-child {
    margin: 0px;
}

.pp-sub-section1 ol {
    margin: 0px;
}

.pp-sub-section1 li {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 10px 0px 12px 0px;
}

.pp-sub-section1 li:last-child {
    margin: 0px;
}

.pp-sub-section2 {
    width: 850px;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
    /* background-color: #f3f3f3; */
}

.pp-sub-section2-title {
    width: 433px;
    height: 20px;
    text-align: left;
    letter-spacing: 0.18px;
    font-size: 18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px -8px;
}

.pp-sub-section2 p {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section2 p:last-child {
    margin: 0px;
}

.pp-sub-section2 ol {
    margin: 10px 0px 0px 0px;
}

.pp-sub-section2 li {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section2 li:last-child {
    margin: 0px;
}

.pp-sub-section3 {
    width: 850px;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
    /* background-color: #d46e6e; */
}

.pp-sub-section3-title {
    width: 433px;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px -8px;
}

.pp-sub-section3 p {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section3 p:last-child {
    margin: 10px 0px 0px 0px;
}

.pp-sub-section3 ol {
    margin: 10px 0px 0px 0px;
}

.pp-sub-section3 li {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section3 li:last-child {
    margin: 0px;
}

.pp-sub-section4 {
    width: 850px;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
    /* background-color: #f3A3A3; */
}

.pp-sub-section4-title {
    width: 583px;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px -8px;
    /* background-color: #f3f3f3; */
}

.pp-sub-section4 p {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section4 p:last-child {
    margin: 0px;
}

.pp-sub-section4 ol {
    margin: 8px 0px 0px 0px;
}

.pp-sub-section4 li {
    text-align: left;
    /* font: normal normal medium 16px/28px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section4 li:last-child {
    margin: 0px;
}

.pp-sub-section5 {
    width: 850px;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
    /* background-color: #f3A3A3; */
}

.pp-sub-section5-title {
    width: 433px;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px -8px;
}

.pp-sub-section5 p {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section5 p:last-child {
    margin: 0px;
}

.pp-sub-section5 ol {
    margin: 12px 0px 0px 0px;
}

.pp-sub-section5 li {
    text-align: left;
    /* font: normal normal medium 16px/26px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section5 li:last-child {
    margin: 0px;
}

.pp-sub-section6 {
    width: 850px;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
    /* background-color: #c57575; */
}

.pp-sub-section6-title {
    width: 433px;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px -8px;
}

.pp-sub-section6 p {
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section6 p:last-child {
    margin: 0px;
}

.pp-sub-section6 ol {
    margin: 12px 0px 0px 0px;
}

.pp-sub-section6 li {
    text-align: left;
    /* font: normal normal medium 16px/26px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
}

.pp-sub-section6 li:last-child {
    margin: 0px;
}

.faq {
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
}

.pp-go-to-top {
    width: 100%;
    height: auto;
    margin: 20px 0px 0px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
}

@media only screen and (max-width: 440px) {
    .privacy-policy-main-container {
        max-width: 440px;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        margin: 0px 0px 0px 0px;
        display: flex;
        padding-bottom: 20px;
        align-items: flex-start;
    }

    .pp-actual-content-container {
        width: 100%;
        height: auto;
        border: none;
        margin: 0px auto 0px auto;
        background: #ffffff;
        font-weight: 500;
    }

    .pp-first-section {
        width: 100%;
        height: auto;
        margin: 27px auto 0px auto;
    }

    .pp-heading1 {
        width: 100%;
        height: 28px;
        margin: 0px auto 0px auto;
    }

    .pp-heading1 p {
        width: 146px;
        height: 28px;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 24px;
        color: #000000;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
    }

    .pp-last-updated-div {
        width: 95%;
        height: 15px;
        margin: 14px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        /* text-align: flex-end; */
    }

    .pp-last-updated-div p {
        width: 156px;
        height: 15px;
        text-align: right;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.12px;
        color: #000000;
        opacity: 1;
        margin: 0px;
    }

    .pp-description1 {
        width: 90%;
        height: auto;
        text-align: left;
        color: #000000;
        opacity: 1;
        margin: 10px auto 0px auto;
    }

    .pp-description1 p {
        margin: 0px 0px 6px 0px;
        height: auto;
        width: 100%;
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px !important;

    }

    .pp-description1 p:last-child {
        margin: 0px;
    }

    .pp-sub-section1 {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background: #f3f303; */
    }

    .pp-sub-section1-title {
        width: 100%;
        height: 17px;
        text-align: left;
        /* font: normal normal medium 14px/40px Helvetica Neue; */
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 10px 0px;
        ;
    }

    .pp-sub-section1 p {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section1 p:last-child {
        margin: 0px;
    }

    .pp-sub-section1 ol {
        margin: 0px 0px 0px -15px;
    }

    .pp-sub-section1 li {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        letter-spacing: 0.12px;
        line-height: 18px;
        font-size: 12px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 11px 0px;
    }

    .pp-sub-section1 li:last-child {
        margin: 0px;
    }

    .pp-sub-section2 {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f303; */
    }

    .pp-sub-section2-title {
        width: 100%;
        height: 17px;
        text-align: left;
        /* font: normal normal medium 14px/40px Helvetica Neue; */
        letter-spacing: 0.14px;
        font-size: 14px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 10px 0px;
    }

    .pp-sub-section2 p {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section2 p:last-child {
        margin: 0px;
    }

    .pp-sub-section2 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section2 li {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 11px 0px;
    }

    .pp-sub-section2 li:last-child {
        margin: 0px;
    }

    .pp-sub-section3 {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f303; */
    }

    .pp-sub-section3-title {
        width: 100%;
        height: 17px;
        text-align: left;
        /* font: normal normal medium 14px/40px Helvetica Neue; */
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 10px 0px;
    }

    .pp-sub-section3 p {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section3 p:last-child {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section3 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section3 li {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 11px 0px;
    }

    .pp-sub-section3 li:last-child {
        margin: 0px;
    }

    .pp-sub-section4 {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f303; */
    }

    .pp-sub-section4-title {
        width: 100%;
        height: 38px;
        text-align: left;
        /* font: normal normal medium 14px/40px Helvetica Neue; */
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 10px 0px;
        /* background-color: #f3f3f3; */
    }

    .pp-sub-section4 p {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section4 p:last-child {
        margin: 0px;
    }

    .pp-sub-section4 ol {
        margin: 8px 0px 0px 0px;
    }

    .pp-sub-section4 li {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 11px 0px;
    }

    .pp-sub-section4 li:last-child {
        margin: 0px;
    }

    .pp-sub-section5 {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f303; */
    }

    .pp-sub-section5-title {
        width: 100%;
        height: 18px;
        text-align: left;
        /* font: normal normal medium 14px/40px Helvetica Neue; */
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 10px 0px;
    }

    .pp-sub-section5 p {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section5 p:last-child {
        margin: 0px;
    }

    .pp-sub-section5 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section5 li {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 11px 0px;
    }

    .pp-sub-section5 li:last-child {
        margin: 0px;
    }

    .pp-sub-section6 {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f303; */
    }

    .pp-sub-section6-title {
        width: 100%;
        height: 18px;
        text-align: left;
        /* font: normal normal medium 14px/40px Helvetica Neue; */
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.14px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 10px 0px;
    }

    .pp-sub-section6 p {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section6 p:last-child {
        margin: 0px;
    }

    .pp-sub-section6 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section6 li {
        text-align: left;
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 11px 0px;
    }

    .pp-sub-section6 li:last-child {
        margin: 0px;
    }

    .faq {
        /* font: normal normal medium 12px/18px Helvetica Neue; */
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        font-weight: bold;
        margin: 0px 0px 10px 0px;
    }

    .pp-go-to-top {
        width: 100%;
        height: auto;
        margin: 20px 0px 0px -20px;
        display: flex;
        padding-right: 20px;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .privacy-policy-main-container {
        width: 100%;
        height: auto;
        background-color: #f8f8f8;
        margin: 0px 0px 0px 0px;
        display: flex;
        align-items: flex-start;
        font-family: "Catamaran";
    }

    .pp-actual-content-container {
        width: 90%;
        height: auto;
        border-left: none;
        border-right: none;
        margin: 0px auto 0px auto;
        background: none;
    }

    .pp-first-section {
        width: 100%;
        height: auto;
        margin: 50px auto 0px auto;
        /* background: #f3f303; */
    }

    .pp-heading1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .pp-heading1 p {
        width: auto;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 32px;
        color: #000000;
        letter-spacing: 0.32px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
    }

    .pp-last-updated-div {
        width: 100%;
        height: 22px;
        margin: 21px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        /* text-align: flex-end; */
    }

    .pp-last-updated-div p {
        width: 233px;
        height: 22px;
        text-align: right;
        /* font: normal normal medium 18px/32px Helvetica Neue; */
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 0px auto;
    }

    .pp-description1 {
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 54px auto 0px auto;
    }

    .pp-description1 p {
        height: auto;
        letter-spacing: 0.16px;
        line-height: 24px;
        margin: 0px 0px 10px 0px;
    }

    .pp-description1 p:last-child {
        margin: 0px;
    }

    .pp-sub-section1 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #9d5454; */
    }

    .pp-sub-section1-title {
        width: auto;
        height: 20px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section1 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;

    }

    .pp-sub-section1 p:last-child {
        margin: 0px;
    }

    .pp-sub-section1 ol {
        margin: 0px;
    }

    .pp-sub-section1 li {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 10px 0px 12px 0px;
    }

    .pp-sub-section1 li:last-child {
        margin: 0px;
    }

    .pp-sub-section2 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f3f3; */
    }

    .pp-sub-section2-title {
        width: auto;
        height: 20px;
        text-align: left;
        letter-spacing: 0.18px;
        font-size: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section2 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section2 p:last-child {
        margin: 0px;
    }

    .pp-sub-section2 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section2 li {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section2 li:last-child {
        margin: 0px;
    }

    .pp-sub-section3 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #d46e6e; */
    }

    .pp-sub-section3-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section3 p {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section3 p:last-child {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section3 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section3 li {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section3 li:last-child {
        margin: 0px;
    }

    .pp-sub-section4 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3A3A3; */
    }

    .pp-sub-section4-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
        /* background-color: #f3f3f3; */
    }

    .pp-sub-section4 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section4 p:last-child {
        margin: 0px;
    }

    .pp-sub-section4 ol {
        margin: 8px 0px 0px 0px;
    }

    .pp-sub-section4 li {
        text-align: left;
        /* font: normal normal medium 16px/28px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section4 li:last-child {
        margin: 0px;
    }

    .pp-sub-section5 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3A3A3; */
    }

    .pp-sub-section5-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section5 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section5 p:last-child {
        margin: 0px;
    }

    .pp-sub-section5 ol {
        margin: 12px 0px 0px 0px;
    }

    .pp-sub-section5 li {
        text-align: left;
        /* font: normal normal medium 16px/26px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section5 li:last-child {
        margin: 0px;
    }

    .pp-sub-section6 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #c57575; */
    }

    .pp-sub-section6-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section6 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section6 p:last-child {
        margin: 0px;
    }

    .pp-sub-section6 ol {
        margin: 12px 0px 0px 0px;
    }

    .pp-sub-section6 li {
        text-align: left;
        /* font: normal normal medium 16px/26px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section6 li:last-child {
        margin: 0px;
    }

    .faq {
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.16px;
        line-height: 24px;
        margin: 0px 0px 12px 0px;
    }

    .pp-go-to-top {
        width: 100%;
        height: auto;
        margin: 40px 0px 30px 0px;
        display: flex;
        padding-right: 20px;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .privacy-policy-main-container {
        width: 100%;
        height: auto;
        background-color: #f8f8f8;
        margin: 0px 0px 0px 0px;
        display: flex;
        align-items: flex-start;
        font-family: "Catamaran";
    }

    .pp-actual-content-container {
        width: 90%;
        height: auto;
        border-left: none;
        border-right: none;
        margin: 0px auto 0px auto;
        background: none;
    }

    .pp-first-section {
        width: 100%;
        height: auto;
        margin: 50px auto 0px auto;
        /* background: #f3f303; */
    }

    .pp-heading1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .pp-heading1 p {
        width: auto;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 32px;
        color: #000000;
        letter-spacing: 0.32px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
    }

    .pp-last-updated-div {
        width: 100%;
        height: 22px;
        margin: 21px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        /* text-align: flex-end; */
    }

    .pp-last-updated-div p {
        width: 233px;
        height: 22px;
        text-align: right;
        /* font: normal normal medium 18px/32px Helvetica Neue; */
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 0px auto;
    }

    .pp-description1 {
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 54px auto 0px auto;
    }

    .pp-description1 p {
        height: auto;
        letter-spacing: 0.16px;
        line-height: 24px;
        margin: 0px 0px 10px 0px;
    }

    .pp-description1 p:last-child {
        margin: 0px;
    }

    .pp-sub-section1 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #9d5454; */
    }

    .pp-sub-section1-title {
        width: auto;
        height: 20px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section1 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;

    }

    .pp-sub-section1 p:last-child {
        margin: 0px;
    }

    .pp-sub-section1 ol {
        margin: 0px;
    }

    .pp-sub-section1 li {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 10px 0px 12px 0px;
    }

    .pp-sub-section1 li:last-child {
        margin: 0px;
    }

    .pp-sub-section2 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3f3f3; */
    }

    .pp-sub-section2-title {
        width: auto;
        height: 20px;
        text-align: left;
        letter-spacing: 0.18px;
        font-size: 18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section2 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section2 p:last-child {
        margin: 0px;
    }

    .pp-sub-section2 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section2 li {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section2 li:last-child {
        margin: 0px;
    }

    .pp-sub-section3 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #d46e6e; */
    }

    .pp-sub-section3-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section3 p {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section3 p:last-child {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section3 ol {
        margin: 10px 0px 0px 0px;
    }

    .pp-sub-section3 li {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section3 li:last-child {
        margin: 0px;
    }

    .pp-sub-section4 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3A3A3; */
    }

    .pp-sub-section4-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
        /* background-color: #f3f3f3; */
    }

    .pp-sub-section4 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section4 p:last-child {
        margin: 0px;
    }

    .pp-sub-section4 ol {
        margin: 8px 0px 0px 0px;
    }

    .pp-sub-section4 li {
        text-align: left;
        /* font: normal normal medium 16px/28px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section4 li:last-child {
        margin: 0px;
    }

    .pp-sub-section5 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #f3A3A3; */
    }

    .pp-sub-section5-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section5 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section5 p:last-child {
        margin: 0px;
    }

    .pp-sub-section5 ol {
        margin: 12px 0px 0px 0px;
    }

    .pp-sub-section5 li {
        text-align: left;
        /* font: normal normal medium 16px/26px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section5 li:last-child {
        margin: 0px;
    }

    .pp-sub-section6 {
        width: 100%;
        height: auto;
        text-align: left;
        margin: 20px auto 0px auto;
        /* background-color: #c57575; */
    }

    .pp-sub-section6-title {
        width: auto;
        height: 20px;
        text-align: left;
        /* font: normal normal medium 18px/40px Helvetica Neue; */
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px -8px;
    }

    .pp-sub-section6 p {
        text-align: left;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section6 p:last-child {
        margin: 0px;
    }

    .pp-sub-section6 ol {
        margin: 12px 0px 0px 0px;
    }

    .pp-sub-section6 li {
        text-align: left;
        /* font: normal normal medium 16px/26px Helvetica Neue; */
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        opacity: 1;
        margin: 0px 0px 12px 0px;
    }

    .pp-sub-section6 li:last-child {
        margin: 0px;
    }

    .faq {
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.16px;
        line-height: 24px;
        margin: 0px 0px 12px 0px;
    }

    .pp-go-to-top {
        width: 100%;
        height: auto;
        margin: 40px 0px 30px 0px;
        display: flex;
        padding-right: 20px;
        justify-content: flex-end;
    }
}