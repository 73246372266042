.about-main-container {
    width: 100%;
    height: 2879px;
    /* padding: 0px 0px 0px 0px ; */
    margin: 0px;
    display: flex;
    flex-direction: column;
}

.about-section-background-container1 {
    width: 100%;
    height: 656px;
    margin: 0px auto 0px auto;
    background: transparent linear-gradient(180deg, #C1BAC6 0%, #FFFFFF 100%);
}

.about-section-content-div {
    width: 1080px;
    height: 656px;
    margin: 0px auto 0px auto;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    display: flex;
    flex-direction: column;
}

.about-section-container1-title-div {
    width: 100%;
    height: 40px;
    margin: 55px auto 0px auto;
}

.about-section-container1-title-div p {
    width: 574px;
    height: 40px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    background: transparent linear-gradient(93deg, #A81F06 0%, #487DD3 51%, #22AD3D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
    opacity: 1;
}

.about-section-container1-description {
    width: 100%;
    height: 76px;
    margin: 12px auto 0px auto;
}

.about-section-container1-description p {
    width: 700px;
    height: 76px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    font-family: "Catamaran";
    letter-spacing: 0.18px;
    color: #0A2648;
    opacity: 1;
}

.about-section-container1-image-div {
    width: 100%;
    height: 328px;
    display: flex;
    justify-content: center;
    margin: 85px auto 0px auto;
}

.about-section-container1-image {
    width: 351px;
    height: 328px;
    margin: 0px;
}

.about-section-container1-image img {
    width: 100%;
    height: 100%;
}

.about-section-container2 {
    width: 1080px;
    margin: 0px auto 0px auto;
    height: 606px;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
}

.about-section-container2-background-div {
    width: 793px;
    height: 412px;
    margin: 0px 0px 0px 287px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
    background: #F5F7FF;
    opacity: 1;
    z-index: -1;
    position: absolute;
}

.about-section-container2-content-div {
    width: 100%;
    height: auto;
    margin: 0px;
}

.about-section-cont2-chandler-image-div {
    width: 335px;
    height: 95px;
    margin: 0px 0px 0px 607px;
    /* background-color: blue; */
    display: inline-flex;

}

.chandler1,
.chandler2,
.chandler3 {
    width: 50px;
    height: 94px;
    background-image: url("../Assets/AboutUs/chandler.svg");
    background-size: 50px 94px;
    margin: 0px auto 0px auto;
}

.about-section-container2-part2 {
    width: 100%;
    height: 200px;
    /* background-color: rgb(15, 59, 98); */
    margin: 0px;
    display: flex;

}

.about-section-container2-actual-content {
    width: 500px;
    height: 148px;
    margin: 0px 0px 0px 26px;
}

.about-section-container2-ac-title-div {
    width: 230px;
    height: 36px;
    margin: 0px;
}

.about-section-container2-ac-title-div p {
    width: 202px;
    height: 36px;
    margin: 0px;
    text-align: left;
    font-size: 32px;
    /* font: normal normal medium 32px/24px Helvetica Neue;
     */
    font-family: "Catamaran";
    font-weight: 600;
    letter-spacing: 0.64px;
    color: #4378A8;
    opacity: 1;
}

.about-section-container2-ac-description {
    width: 464px;
    height: 98px;
    margin: 14px 0px 0px 15px;
}

.about-section-container2-ac-description p {
    width: 464px;
    height: 98px;
    margin: 0px;
    text-align: left;
    font-size: 18px;
    /* font: normal normal medium 18px/24px Helvetica Neue; */
    font-family: "Catamaran";
    letter-spacing: 0.18px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
}

.about-section-container2-second-image-tray {
    width: 500px;
    height: 180px;
    /* background-color: #FFFFFF; */
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-direction: row;
}

.about-section-container2-second-image-tray-img1 {
    width: 133px;
    height: 61px;
    background-image: url("../Assets/AboutUs/rack1.png");
    background-size: 133px 61px;
    margin: 45px 0px 0px 0px;
}

.about-section-container2-second-image-tray-img2 {
    width: 87px;
    height: 119px;
    background-image: url("../Assets/AboutUs/clockFrame.png");
    background-size: 87px 119px;
    margin: 45px 0px 0px 85px;
}

.about-section-container2-second-image-tray-img3 {
    width: 126px;
    height: 45px;
    background-image: url("../Assets/AboutUs/bookShelf.png  ");
    background-size: 126px 45px;
    margin: 40px 0px 0px 57px;
}

.about-section-container2-part3 {
    width: 100%;
    height: 274px;
    margin: 47px 0px 0px 0px;
    display: flex;
    /* background-color: #C1BAC6; */
}

.about-section-container2-part3-img1-div {
    width: 338px;
    height: 246px;
    margin: 15px 0px 0px 18px;
}

.about-section-container2-part3-img1 {
    width: 338px;
    height: 246px;
}

.about-section-container2-part3-img1 img {
    width: 100%;
    height: 100%;
}

.about-section-container2-part3-img2-div {
    width: 328px;
    height: 273px;
    margin: -10px 0px 0px 329px;
}

.about-section-container2-part3-img2 {
    width: 338px;
    height: 273px;
}

.about-section-container2-part3-img2 img {
    width: 100%;
    height: 100%;
}

.about-section-container3 {
    width: 100%;
    height: 663px;
    margin: 0px auto 0px auto;
    display: flex;
    position: relative;
    justify-content: center;
}

.about-section-container3-layer1-div {
    width: 970px;
    height: 663px;
    margin: 0px auto 0px auto;
    z-index: 1;
    position: absolute;
}

.about-section-container3-layer1-div img {
    width: 100%;
    height: 100%;
}

.about-section-container3-layer2-div {
    width: 100%;
    height: 663px;
    margin: 0px auto 0px auto;
    background: #000000;
    opacity: 0.85;
    z-index: 2;
    position: absolute;
}

.about-section-container3-layer3-div {
    width: 1080px;
    height: 663px;
    margin: 0px auto 0px auto;
    background: transparent;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    z-index: 3;
    position: absolute;
}

.about-section-container3-layer3-title {
    width: 100%;
    height: 36px;
    margin: 38px auto 0px auto;
}

.about-section-container3-layer3-title p {
    width: 100%;
    height: 36px;
    color: #FFFFFF;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 32px;
}

.about-section-container3-layer3-grid-div {
    width: 950px;
    height: 200px;
    margin: 45px auto 0px auto;
    /* background-color: #FFFFFF; */
    display: flex;
}

.about-section-container3-layer3-grid1 {
    width: 200px;
    height: 200px;
    border-radius: 12px 0px 12px 12px;
    background: #ECDFF8;
    opacity: 1;
    margin: 0px auto 0px 0px;
}

.about-section-container3-layer3-grid1-img-div {
    width: 42px;
    height: 50px;
    margin: 40px auto 0px auto;
}

.about-section-container3-layer3-grid1-img {
    width: 42px;
    height: 50px;
}

.about-section-container3-layer3-grid1-img img {
    width: 100%;
    height: 100%;
}

.about-section-container3-layer3-grid1-description,
.about-section-container3-layer3-grid2-description,
.about-section-container3-layer3-grid3-description,
.about-section-container3-layer3-grid4-description {
    width: 160px;
    height: auto;
    margin: 40px auto 0px auto;
}

.about-section-container3-layer3-grid1-description p,
.about-section-container3-layer3-grid2-description p,
.about-section-container3-layer3-grid3-description p,
.about-section-container3-layer3-grid4-description p {
    width: 160px;
    height: auto;
    text-align: center;
    font-size: 18px;
    font-family: "Catamaran";
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
}

.about-section-container3-layer3-grid2 {
    width: 200px;
    height: 200px;
    border-radius: 12px 0px 12px 12px;
    background: #F7F8DF;
    opacity: 1;
    margin: 0px auto 0px auto;
}

.about-section-container3-layer3-grid2-img-div {
    width: 42px;
    height: 50px;
    margin: 40px auto 0px auto;
}

.about-section-container3-layer3-grid2-img {
    width: 42px;
    height: 50px;
}

.about-section-container3-layer3-grid2-img img {
    width: 100%;
    height: 100%;
}

.about-section-container3-layer3-grid3 {
    width: 200px;
    height: 200px;
    border-radius: 12px 0px 12px 12px;
    background: #DFF8F1;
    opacity: 1;
    margin: 0px auto 0px auto;
}

.about-section-container3-layer3-grid3-img-div {
    width: 42px;
    height: 50px;
    margin: 40px auto 0px auto;
}

.about-section-container3-layer3-grid3-img {
    width: 42px;
    height: 50px;
}

.about-section-container3-layer3-grid3-img img {
    width: 100%;
    height: 100%;
}

.about-section-container3-layer3-grid4 {
    width: 200px;
    height: 200px;
    border-radius: 12px 0px 12px 12px;
    background: #F8DFDF;
    opacity: 1;
    margin: 0px 0px 0px auto;
}

.about-section-container3-layer3-grid4-img-div {
    width: 42px;
    height: 50px;
    margin: 40px auto 0px auto;
}

.about-section-container3-layer3-grid4-img {
    width: 42px;
    height: 50px;
}

.about-section-container3-layer3-grid4-img img {
    width: 100%;
    height: 100%;
}

.about-section-container3-layer3-title2 {
    width: 100%;
    height: 36px;
    margin: 45px auto 0px auto;
}

.about-section-container3-layer3-title2 p {
    width: 100%;
    height: 36px;
    color: #FFFFFF;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 32px;
    margin: 0px auto 0px auto;
}

.about-section-container3-layer3-description {
    width: 700px;
    height: auto;
    margin: 15px auto 0px auto;

}

.about-section-container3-layer3-description p {
    width: 700px;
    height: auto;
    text-align: center;
    font-size: 18px;
    margin: 0px auto 10px auto;
    font-family: "Catamaran";
    font-weight: 500;
    letter-spacing: 0.36px;
    color: #FFFFFF;
    opacity: 1;
}

.about-section-container3-layer3-description p:last-child {
    margin: 0px auto 0px auto;
}

.about-section-container4 {
    width: 1080px;
    height: 760px;
    margin: 0px auto 0px auto;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
}

.about-section-container4-title-div {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
}

.about-section-container4-title-div p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px 0px;
    text-align: center;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 32px;
}

.about-section-container4-content-div {
    width: 500px;
    height: 453px;
    background: #000000;
    box-shadow: 0px 0px 48px #8D8D8D29;
    border-radius: 16px;
    opacity: 1;
    margin: 28px auto 0px auto;
    position: relative;
}

.about-section-container4-image-tray {
    width: 100%;
    height: auto;
    margin: 0px;
    z-index: 1;
    position: absolute;
    display: flex;
}

.diabled-image-tray {
    display: none;
}

.about-section-container-image1 {
    width: 101px;
    height: 224px;
    margin: 96px 0px 0px 7px;
}

.about-section-container-image1 img {
    width: 100%;
    height: 100%;
}

.about-section-container-image2 {
    width: 136px;
    height: 179px;
    margin: 162px 0px 0px 250px;
}

.about-section-container-image2 img {
    width: 100%;
    height: 100%;
}

.about-section-container4-form-div {
    width: 100%;
    height: 350px;
    margin: 31px auto 0px auto;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.about-section-container4-form-div p {
    width: 100%;
    height: 20px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 18px;
    /* font: normal normal medium 18px/21px Helvetica Neue; */
    font-family: "Catamaran";
    letter-spacing: 0.18px;
    color: #FFFFFF;
    opacity: 1;
}

.form-div {
    width: 100%;
    height: 311px;
    margin: 23px auto 0px auto;
    /* background-color: #F5F7FF; */

}

.contact-us-form {
    width: 275px;
    height: 311px;
    /* border: 2px solid #FFF; */
    margin: 0px auto 0px auto;
}

.fullname-group {
    width: 275px;
    height: 60px;
    margin: 0px auto 0px auto;
}

.formdata-fullname {
    width: 274px;
    height: 45px;
    margin: 0px auto 0px auto;
    border-radius: 8px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    padding: 0px 0px 0px 10px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Catamaran";
    /* font: normal normal medium 14px/18px Helvetica Neue; */
}

.email-group {
    width: 275px;
    height: 60px;
    margin: 10px auto 0px auto;
}

.formdata-email {
    width: 274px;
    height: 45px;
    margin: 0px auto 0px auto;
    border-radius: 8px;
    background-color: #FFFFFF;
    /* font: normal normal medium 14px/18px Helvetica Neue; */
    font-weight: 500;
    font-family: "Catamaran";
    outline: none;
    border: none;
    padding: 0px 0px 0px 10px;
    font-size: 14px;
    letter-spacing: 0.14px;
}

.suggestions-form-textarea {
    width: 275px;
    height: 120px;
    margin: 10px auto 0px auto;
}

.formdata-textarea {
    width: 275px;
    height: 110px;
    resize: none;
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 15px 0px 0px 10px;
    outline: none;
    color: #2F077C;
    text-align: left;
    font-size: 14px;
    font-family: "Catamaran";
    margin: 0px;
    font-weight: 500;
    border: none;
}

.submit-button-div {
    width: 285px;
    height: 35px;
    margin: 30px auto 0px auto;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button {
    width: 275px;
    height: 35px;
    border: none;
    outline: none;
    padding: 0px 0px 0px 0px;
    color: #418300;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-family: "Catamaran";
    background: none;
    font-weight: 600;
    letter-spacing: 0.14px;
}

.submit-button-div:hover,
.submit-button:hover {
    background-color: #98BC00;
    color: #FFFFFF;
}

.footer-text {
    width: 392px;
    height: 15px;
    margin: 30px auto 0px auto;
    text-align: center;

}

.footer-text p {
    width: 100%;
    height: 15px;
    margin: 0px;
    font-size: 13px;
    font-family: "Catamaran";
    font-weight: 500;
    letter-spacing: 0.13px;
    color: #70E21D;
    opacity: 1;
}

.about-section-container4-footer {
    width: 600px;
    height: 89px;
    margin: 29px auto 0px auto;
    text-align: center;
}

.about-section-container4-footer p {
    width: 600px;
    height: 49px;
    font-size: 18px;
    font-weight: 500;
    /* font: normal normal medium 18px/28px Helvetica Neue; */
    font-family: "Catamaran";
    letter-spacing: 0px;
    color: #000000;
}

.about-section-container4-footer a {
    text-decoration: none;
    color: #0A0EC6;
}

.about-section-container4-footer a:hover {
    text-decoration: underline;
}

.thank-you-animation {
    width: 300px;
    height: 300px;
    margin: 20px auto 0px auto;
    /* border: 2px solid #FFFFFF;/ */
}

.close-button {
    width: 120px;
    height: 35px !important;
    border: none;
    margin: 0px auto 0px auto;
    background: #FFFFFF;
    border-radius: 8px;
    /* padding: 0px 0px 0px 10px; */
    color: #418300;
    cursor: pointer;
    font-size: 14px;
    /* font: normal normal medium 14px/18px Helvetica Neue; */
    font-family: "Catamaran";
    color: #418300;
    font-weight: 600;
    letter-spacing: 0.35px;
}

.close-button:hover {
    background-color: #98BC00;
    color: #FFFFFF;
}

.btn-div {
    width: 100%;
    height: 35px;
    margin: 10px auto 0px auto;
    display: flex;
    justify-content: center;
}

.valid-feedback {
    display: none;
}

.invalid-feedback {
    margin: 0px 0px 0px 10px;
    height: 13px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fullname-feedback,
.email-feedback {
    margin: 2px 0px 0px 10px;
    height: 13px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.error-icon {
    width: 10px;
    height: 10px;
    margin: 0px;
}

.invalid-feedback p {
    width: auto;
    margin: 6px 0px 0px 5px;
    font-size: 12px;
    color: #FD7F6F;
}

.aboutus--go-to-top {
    width: auto;
    height: 35px;
    margin: 0px 20px 20px 0px;
    display: flex;
    justify-content: flex-end;

}


@media only screen and (max-width: 440px) {
    .about-main-container {
        max-width: 440px;
        height: auto;
        width: 100%;
        /* padding: 0px 0px 0px 0px ; */
        margin: 0px;
        display: flex;
        flex-direction: column;
    }

    .about-section-background-container1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background: transparent linear-gradient(180deg, #C1BAC6 0%, #FFFFFF 100%);
    }

    .about-section-content-div {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        border: none;
        display: flex;
        flex-direction: column;
    }

    .about-section-container1-title-div {
        width: 100%;
        height: auto;
        margin: 52px auto 0px auto;
    }

    .about-section-container1-title-div p {
        width: 80%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        letter-spacing: 0.24px;
        font-size: 24px;
        background: transparent linear-gradient(93deg, #A81F06 0%, #487DD3 51%, #22AD3D 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        background-clip: text;
        opacity: 1;
    }

    .about-section-container1-description {
        width: 100%;
        height: auto;
        margin: 10px auto 0px auto;
    }

    .about-section-container1-description p {
        width: 87%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 16px;
        letter-spacing: 0.18px;
        line-height: 24px;
        font-family: "Catamaran";
        color: #0A2648;
        opacity: 1;
    }

    .about-section-container1-image-div {
        width: 100%;
        height: 249px;
        display: flex;
        justify-content: center;
        margin: 35px auto 0px auto;
    }

    .about-section-container1-image {
        width: 266px;
        height: 249px;
        margin: 0px;
    }

    .about-section-container1-image img {
        width: 100%;
        height: 100%;
    }

    .about-section-container2 {
        width: 100%;
        margin: 0px auto 0px auto;
        height: auto;
        border: none;
    }

    .about-section-container2-background-div {
        width: 95%;
        height: 213px;
        margin: 0px auto 0px 15px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
        background: #F5F7FF;
        opacity: 1;
        z-index: -1;
        position: absolute;
    }

    .about-section-container2-content-div {
        width: 100%;
        height: auto;
        margin: 0px;
    }

    .about-section-cont2-chandler-image-div {
        width: 335px;
        height: 66px;
        margin: 0px 0px 0px 107px;
        display: inline-flex;
    }

    .chandler1,
    .chandler2 {
        width: 35px;
        height: 75%;
        background-image: url("../Assets/AboutUs/chandler.svg");
        background-size: 35px 100%;
        margin: 0px 20px 0px 40px;
    }

    .chandler3 {
        width: 35px;
        height: 75%;
        background-image: url("../Assets/AboutUs/chandler.svg");
        background-size: 35px 100%;
        margin: 0px 20px 0px 20px;
    }

    .about-section-container2-part2 {
        width: 100%;
        height: 200px;
        margin: 0px;
        display: flex;
    }

    .about-section-container2-actual-content {
        width: 55%;
        height: 168px;
        margin: 0px 0px 0px 16px;
    }

    .about-section-container2-ac-title-div {
        width: 100%;
        height: 28px;
        margin: 0px;
    }

    .about-section-container2-ac-title-div p {
        width: 100%;
        height: 28px;
        margin: 0px;
        text-align: left;
        font-size: 24px;
        font-family: "Catamaran";
        font-weight: 600;
        letter-spacing: 0.24px;
        color: #4378A8;
        opacity: 1;
    }

    .about-section-container2-ac-description {
        width: 100%;
        height: 123px;
        margin: 15px 0px 0px 10px;
    }

    .about-section-container2-ac-description p {
        width: 100%;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 14px;
        font-family: "Catamaran";
        letter-spacing: 0.14px;
        color: #000000;
        opacity: 1;
    }

    .about-section-container2-second-image-tray {
        width: 100px;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column-reverse;
    }

    .about-section-container2-second-image-tray-img1 {
        width: 93px;
        height: 43px;
        background-image: url("../Assets/AboutUs/rack1.png");
        background-size: 93px 43px;
        margin: 10px 25px 0px 25px;
    }

    .about-section-container2-second-image-tray-img2 {
        width: 64px;
        height: 65px;
        background-image: url("../Assets/AboutUs/responsiveClockFrame.png");
        background-size: 64px 65px;
        margin: 45px 25px 0px 42px;
    }

    .about-section-container2-second-image-tray-img3 {
        width: 88px;
        height: 32px;
        background-image: url("../Assets/AboutUs/bookShelf.png  ");
        background-size: 88px 32px;
        margin: 0px 25px 0px 30px;
    }

    .about-section-container2-part3 {
        width: 100%;
        height: 274px;
        margin: 187px 0px 0px 0px;
        display: flex;
        flex-direction: column-reverse;
    }

    .about-section-container2-part3-img1-div {
        width: 116px;
        height: 173px;
        margin: 10px 0px 0px 18px;
    }

    .about-section-container2-part3-img1 {
        width: 116px;
        height: 173px;
    }

    .about-section-container2-part3-img1 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container2-part3-img2-div {
        width: 100%;
        height: 191px;
        margin: 0px;
        display: flex;
        justify-content: flex-end;
        ;
        align-items: flex-end;
        ;
    }

    .about-section-container2-part3-img2 {
        width: 229px;
        height: 191px;
    }

    .about-section-container2-part3-img2 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3 {
        width: 100%;
        height: 715px;
        margin: 0px auto 0px auto;
        display: flex;
        position: relative;
        justify-content: space-between;
    }

    .about-section-container3-layer1-div {
        width: 100%;
        height: 300px;
        margin: 100px 0px auto 0px;
        z-index: 1;
        position: absolute;
    }

    .about-section-container3-layer1-div img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer2-div {
        width: 100%;
        height: 715px;
        margin: 0px auto 0px auto;
        background: #000000;
        opacity: 0.85;
        z-index: 2;
        position: absolute;
    }

    .about-section-container3-layer3-div {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background: transparent;
        border: none;
        z-index: 3;
        position: absolute;
    }

    .about-section-container3-layer3-title {
        width: 100%;
        height: 28px;
        margin: 25px auto 0px auto;
    }

    .about-section-container3-layer3-title p {
        width: 100%;
        height: 28px;
        color: #FFFFFF;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 24px;
        letter-spacing: 0.24px;
    }

    .about-section-container3-layer3-grid-div {
        width: 100%;
        height: auto;
        margin: 25px auto 0px auto;
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
    }

    .about-section-container3-layer3-grid1 {
        width: 150px;
        height: 150px;
        border-radius: 0px 12px 12px 12px;
        background: #ECDFF8;
        opacity: 1;
        margin: 0px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-img-div {
        width: 30px;
        height: 36px;
        margin: 38px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-img {
        width: 30px;
        height: 36px;
    }

    .about-section-container3-layer3-grid1-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid1-description,
    .about-section-container3-layer3-grid2-description,
    .about-section-container3-layer3-grid3-description,
    .about-section-container3-layer3-grid4-description {
        width: 120px;
        height: auto;
        margin: 10px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-description p,
    .about-section-container3-layer3-grid2-description p,
    .about-section-container3-layer3-grid3-description p,
    .about-section-container3-layer3-grid4-description p {
        width: 120px;
        height: auto;
        text-align: center;
        font-size: 16px;
        /* font: normal normal medium 12px/20px Helvetica Neue; */
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.12px;
        color: #000000;
        opacity: 1;
    }

    .about-section-container3-layer3-grid2 {
        width: 150px;
        height: 150px;
        border-radius: 12px 0px 12px 12px;
        background: #F7F8DF;
        opacity: 1;
        margin: 0px auto 0px auto;
    }

    .about-section-container3-layer3-grid2-img-div {
        width: 30px;
        height: 36px;
        margin: 38px auto 0px auto;
    }

    .about-section-container3-layer3-grid2-img {
        width: 30px;
        height: 36px;
    }

    .about-section-container3-layer3-grid2-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid3 {
        width: 150px;
        height: 150px;
        border-radius: 12px 12px 12px 0px;
        background: #DFF8F1;
        opacity: 1;
        margin: 10px auto 0px auto;
    }

    .about-section-container3-layer3-grid3-img-div {
        width: 30px;
        height: 36px;
        margin: 38px auto 0px auto;
    }

    .about-section-container3-layer3-grid3-img {
        width: 30px;
        height: 36px;
    }

    .about-section-container3-layer3-grid3-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid4 {
        width: 150px;
        height: 150px;
        border-radius: 12px 12px 0px 12px;
        background: #F8DFDF;
        opacity: 1;
        margin: 10px auto 0px auto;
    }

    .about-section-container3-layer3-grid4-img-div {
        width: 30px;
        height: 36px;
        margin: 38px auto 0px auto;
    }

    .about-section-container3-layer3-grid4-img {
        width: 30px;
        height: 36px;
    }

    .about-section-container3-layer3-grid4-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-title2 {
        width: 100%;
        height: auto;
        margin: 20px auto 0px auto;
        padding-top: 0px;
    }

    .about-section-container3-layer3-title2 p {
        width: 100%;
        height: auto;
        color: #FFFFFF;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 24px;
        margin: 0px auto 0px auto;
        letter-spacing: 0.24px;
    }

    .about-section-container3-layer3-description {
        width: 94%;
        height: auto;
        /* padding-bottom: 10px; */
        margin: 8px auto 0px auto;
    }

    .about-section-container3-layer3-description p {
        width: 96%;
        height: auto;
        margin: 0px auto 10px auto;
        text-align: center;
        font-size: 14px;
        /* font: normal normal medium 18px/28px Helvetica Neue; */
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.14px;
        color: #FFFFFF;
        opacity: 1;
    }

    .about-section-container4 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        border: none;
    }

    .about-section-container4-title-div {
        width: 100%;
        height: 36px;
        margin: 29px auto 0px auto;
    }

    .about-section-container4-title-div p {
        width: 100%;
        height: 28px;
        margin: 0px auto 0px 0px;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 24px;
        letter-spacing: 0.24px;
    }

    .about-section-container4-content-div {
        width: 80%;
        height: 352px;
        background: #000000;
        box-shadow: 0px 0px 48px #8D8D8D29;
        border-radius: 10px;
        opacity: 1;
        margin: 16px auto 0px auto;
        position: relative;
    }

    .about-section-container4-image-tray {
        width: 100%;
        height: auto;
        margin: 0px;
        z-index: 1;
        position: absolute;
        display: none;
    }

    .about-section-container-image1 {
        width: 69px;
        height: 153px;
        margin: 62px 0px 0px 6px;
    }

    .about-section-container-image1 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container-image2 {
        width: 91px;
        height: 119px;
        margin: 117px 0px 0px 173px;
    }

    .about-section-container-image2 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container4-form-div {
        width: 100%;
        height: 350px;
        margin: 10px auto 0px auto;
        z-index: 2;
        position: absolute;
        display: flex;
        flex-direction: column;
    }

    .about-section-container4-form-div p {
        width: 100%;
        height: 20px;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 14px;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.14px;
        color: #FFFFFF;
        opacity: 1;
    }

    .form-div {
        width: 180px;
        height: 225px;
        margin: 20px auto 0px auto;
    }

    .contact-us-form {
        width: 180px;
        height: 210px;
        margin: 0px auto 0px auto;
    }

    .fullname-group {
        width: 180px;
        height: 40px;
        margin: 0px auto 0px auto;
    }

    .formdata-fullname {
        width: 180px;
        height: 30px;
        margin: 0px auto 0px auto;
        border-radius: 4px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 12px;
        font-weight: "Catamaran";
        /* font: normal normal medium 14px/18px Helvetica Neue; */
    }

    .email-group {
        width: 180px;
        height: 40px;
        margin: 7px auto 0px auto;
    }

    .formdata-email {
        width: 180px;
        height: 30px;
        margin: 0px auto 0px auto;
        border-radius: 4px;
        background-color: #FFFFFF;
        font-size: 12px;
        font-weight: "Catamaran";
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        /* font-size: 14px; */
        letter-spacing: 0.1px;
    }

    .suggestions-form-textarea {
        width: 180px;
        height: 95px;
        margin: 7px auto 0px auto;
    }

    .formdata-textarea {
        width: 180px;
        height: 84px;
        resize: none;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding: 15px 0px 0px 10px;
        outline: none;
        color: #2F077C;
        text-align: left;
        font-size: 12px;
        font-family: "Catamaran";
        margin: 0px;
        border: none;
    }

    .submit-button-div {
        width: 190px;
        height: 35px;
        margin: 25px auto 0px auto;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .submit-button {
        width: 190px;
        height: 35px;
        color: #418300;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 12px;
        font-family: "Catamaran";
        color: #418300;
        font-weight: 600;
        letter-spacing: 0.12px;
    }

    .submit-button:hover {
        background-color: #98BC00;
        color: #FFFFFF;
    }

    .footer-text {
        width: 95%;
        height: auto;
        margin: 40px auto 0px auto;
        text-align: center;

    }

    .footer-text p {
        width: 85%;
        height: auto;
        margin: auto;
        font-size: 12px;
        font-family: "Catamaran";
        letter-spacing: 0.12px;
        color: #70E21D;
        opacity: 1;
        line-height: 15px;
    }

    .about-section-container4-footer {
        width: 86%;
        height: auto;
        margin: 20px auto 0px auto;
        text-align: center;
        /* padding-bottom: 20px     */
    }

    .about-section-container4-footer p {
        width: 100%;
        height: 48px;
        font-size: 14px;
        font-weight: 500;
        font-family: "Catamaran";
        letter-spacing: 0.14px;
        color: #000000;

    }

    .about-section-container4-footer a {
        text-decoration: none;
        color: #0A0EC6;
    }

    .about-section-container4-footer a:hover {
        text-decoration: underline;
    }

    .diabled-image-tray {
        display: none;
    }

    .thank-you-animation {
        width: 150px;
        height: 150px;
        margin: 20px auto 0px auto;
        /* border: 2px solid #FFFFFF;/ */
    }

    .close-button {
        width: 120px;
        height: 35px !important;
        border: none;
        margin: 0px auto 0px auto;
        background: #FFFFFF;
        border-radius: 4px;
        /* padding: 0px 0px 0px 10px; */
        color: #418300;
        cursor: pointer;
        font-size: 12px;
        font-family: "Catamaran";
        /* font: normal normal medium 14px/18px Helvetica Neue; */
        color: #418300;
        font-weight: 500;
        letter-spacing: 0.1px;
    }

    .close-button:hover {
        background-color: #98BC00;
        color: #FFFFFF;
    }

    .btn-div {
        width: 100%;
        height: 35px;
        margin: 10px auto 0px auto;
        display: flex;
        justify-content: center;
    }

    .valid-feedback {
        display: none;
    }

    .invalid-feedback {
        margin: 0px 0px 0px 10px;
        height: 10px;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .fullname-feedback,
    .email-feedback {
        margin: 2px 0px 0px 10px;
        height: 13px;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .error-icon {
        width: 10px;
        height: 10px;
        margin: 0px;
    }

    .invalid-feedback p {
        width: auto;
        margin: 6px 0px 0px 5px;
        font-size: 12px;
        color: #FD7F6F;
    }

    .aboutus--go-to-top {
        width: auto;
        height: 25px;
        margin: 0px 20px 20px 0px;
        display: flex;
        justify-content: flex-end;
        ;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .about-main-container {
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column;
    }

    .about-section-background-container1 {
        width: 100%;
        height: 656px;
        margin: 0px auto 0px auto;
        background: transparent linear-gradient(180deg, #C1BAC6 0%, #FFFFFF 100%);
    }

    .about-section-content-div {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        border-right: none;
        border-left: none;
        display: flex;
        flex-direction: column;
    }

    .about-section-container1-title-div {
        width: 100%;
        height: auto;
        margin: 45px auto 0px auto;
    }

    .about-section-container1-title-div p {
        width: 574px;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 36px;
        letter-spacing: 0.36px;
        background: transparent linear-gradient(93deg, #A81F06 0%, #487DD3 51%, #22AD3D 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        background-clip: text;
        opacity: 1;
    }

    .about-section-container1-description {
        width: 100%;
        height: auto;
        margin: 12px auto 0px auto;
    }

    .about-section-container1-description p {
        width: 65%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        color: #0A2648;
        opacity: 1;
    }

    .about-section-container1-image-div {
        width: 100%;
        height: 328px;
        display: flex;
        justify-content: center;
        margin: 65px auto 0px auto;
    }

    .about-section-container1-image {
        width: 351px;
        height: 328px;
        margin: 0px;
    }

    .about-section-container1-image img {
        width: 100%;
        height: 100%;
    }

    .about-section-container2 {
        width: 100%;
        margin: 0px auto 0px auto;
        height: auto;
        border-right: none;
        border-left: none;
    }

    .about-section-container2-background-div {
        width: 73.4%;
        height: 302px;
        margin: 0px 0px 0px auto;
        left: auto;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
        background: #F5F7FF;
        opacity: 1;
        z-index: -1;
        position: absolute;
    }

    .about-section-container2-content-div {
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column;
    }

    .about-section-cont2-chandler-image-div {
        width: 100%;
        height: 95px;
        left: auto;
        right: 0;
        margin: 0;
        display: flex;
        justify-content: end;
    }

    .chandler1,
    .chandler2,
    .chandler3 {
        width: 50px;
        height: 94px;
        background-image: url("../Assets/AboutUs/chandler.svg");
        background-size: 50px 94px;
        margin: 0px 40px 0px 40px;
    }

    .about-section-container2-part2 {
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;

    }

    .about-section-container2-actual-content {
        width: 500px;
        height: 148px;
        margin: 20px 0px 0px 36px;
    }

    .about-section-container2-ac-title-div {
        width: 230px;
        height: 36px;
        margin: 0px;
    }

    .about-section-container2-ac-title-div p {
        width: 202px;
        height: 36px;
        margin: 0px;
        text-align: left;
        font-size: 32px;
        font-family: "Catamaran";
        font-weight: 600;
        letter-spacing: 0.32px;
        color: #4378A8;
        opacity: 1;
    }

    .about-section-container2-ac-description {
        width: 464px;
        height: 98px;
        margin: 14px 0px 0px 15px;
    }

    .about-section-container2-ac-description p {
        width: 464px;
        height: 98px;
        margin: 0px;
        text-align: left;
        font-size: 18px;
        font-family: "Catamaran";
        letter-spacing: 0.18px;
        font-weight: 500;
        color: #000000;
        opacity: 1;
    }

    .about-section-container2-second-image-tray {
        width: 250px;
        height: auto;
        margin: 30px auto 0px auto;
        display: flex;
        flex-direction: column;
    }

    .about-section-container2-second-image-tray-img1 {
        width: 133px;
        height: 61px;
        background-image: url("../Assets/AboutUs/rack1.png");
        background-size: 133px 61px;
        margin: 45px auto 0px auto;
    }

    .about-section-container2-second-image-tray-img2 {
        width: 87px;
        height: 119px;
        background-image: url("../Assets/AboutUs/clockFrame.png");
        background-size: 87px 119px;
        margin: 45px auto 0px auto;
    }

    .about-section-container2-second-image-tray-img3 {
        width: 126px;
        height: 45px;
        background-image: url("../Assets/AboutUs/bookShelf.png  ");
        background-size: 126px 45px;
        margin: 40px auto 0px auto;
    }

    .about-section-container2-part3 {
        width: 100%;
        height: 274px;
        margin: 47px auto 0px auto;
        display: flex;
        /* border: 1px solid #000; */
        /* background-color: #C1BAC6; */
    }

    .about-section-container2-part3-img1-div {
        width: 338px;
        height: 246px;
        margin: 15px 0px 0px 18px;
    }

    .about-section-container2-part3-img1 {
        width: 338px;
        height: 246px;
    }

    .about-section-container2-part3-img1 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container2-part3-img2-div {
        width: 328px;
        height: 273px;
        margin: 0px auto 0px auto;
    }

    .about-section-container2-part3-img2 {
        width: 338px;
        height: 273px;
    }

    .about-section-container2-part3-img2 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3 {
        width: 100%;
        height: auto !;
        margin: 0px auto 0px auto;
        display: flex;
        /* position: relative; */
        justify-content: center;
    }

    .about-section-container3-layer1-div {
        width: 100%;
        height: 863px;
        margin: 0px auto 0px auto;
        z-index: 1;
        position: absolute;
    }

    .about-section-container3-layer1-div img {
        width: 100%;
        height: 65%;
        margin: auto;
    }

    .about-section-container3-layer2-div {
        width: 100%;
        height: 863px;
        margin: 0px auto 0px auto;
        background: #000000;
        opacity: 0.85;
        z-index: 2;
        position: absolute;
    }

    .about-section-container3-layer3-div {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background: transparent;
        border-right: none;
        border-left: none;
        z-index: 3;
        position: absolute;
    }

    .about-section-container3-layer3-title {
        width: 100%;
        height: 36px;
        margin: 38px auto 0px auto;
    }

    .about-section-container3-layer3-title p {
        width: 100%;
        height: 36px;
        color: #FFFFFF;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 32px;
    }

    .about-section-container3-layer3-grid-div {
        width: 440px;
        height: auto;
        margin: 45px auto 0px auto;
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-template-rows: 2fr 2fr;
        row-gap: 40px;
    }

    .about-section-container3-layer3-grid1 {
        width: 200px;
        height: 200px;
        border-radius: 12px 12px 0px 12px;
        background: #ECDFF8;
        opacity: 1;
        margin: 0px auto 0px 0px;
    }

    .about-section-container3-layer3-grid1-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid1-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid1-description,
    .about-section-container3-layer3-grid2-description,
    .about-section-container3-layer3-grid3-description,
    .about-section-container3-layer3-grid4-description {
        width: 160px;
        height: auto;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-description p,
    .about-section-container3-layer3-grid2-description p,
    .about-section-container3-layer3-grid3-description p,
    .about-section-container3-layer3-grid4-description p {
        width: 160px;
        height: auto;
        text-align: center;
        font-size: 18px;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
    }

    .about-section-container3-layer3-grid2 {
        width: 200px;
        height: 200px;
        border-radius: 12px 12px 12px 0px;
        background: #F7F8DF;
        opacity: 1;
        margin: 0px 0px 0px auto;
    }

    .about-section-container3-layer3-grid2-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid2-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid2-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid3 {
        width: 200px;
        height: 200px;
        border-radius: 12px 0px 12px 12px;
        background: #DFF8F1;
        opacity: 1;
        margin: 0px auto 0px 0px;
    }

    .about-section-container3-layer3-grid3-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid3-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid3-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid4 {
        width: 200px;
        height: 200px;
        border-radius: 0px 12px 12px 12px;
        background: #F8DFDF;
        opacity: 1;
        margin: 0px 0px 0px auto;
    }

    .about-section-container3-layer3-grid4-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid4-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid4-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-title2 {
        width: 100%;
        height: 36px;
        margin: 45px auto 0px auto;
    }

    .about-section-container3-layer3-title2 p {
        width: 100%;
        height: auto;
        color: #FFFFFF;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 32px;
        margin: 0px auto 0px auto;
    }

    .about-section-container3-layer3-description {
        width: 80%;
        height: auto;
        margin: 15px auto 0px auto;

    }

    .about-section-container3-layer3-description p {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 16px;
        margin: 0px auto 10px auto;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.16px;
        color: #FFFFFF;
        opacity: 1;
    }

    .about-section-container3-layer3-description p:last-child {
        margin: 0px auto 0px auto;
    }

    .about-section-container4 {
        width: 100%;
        height: auto;
        margin: 190px auto 0px auto;
        border-right: none;
        border-left: none;
        position: relative;
    }

    .about-section-container4-title-div {
        width: 100%;
        height: auto;
        margin: 50px auto 0px auto;
    }

    .about-section-container4-title-div p {
        width: 100%;
        height: auto;
        margin: 0px auto 0px 0px;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 32px;
    }

    .about-section-container4-content-div {
        width: 500px;
        height: 453px;
        background: #000000;
        box-shadow: 0px 0px 48px #8D8D8D29;
        border-radius: 16px;
        opacity: 1;
        margin: 28px auto 0px auto;
        position: relative;
    }

    .about-section-container4-image-tray {
        width: 100%;
        height: auto;
        margin: 0px;
        z-index: 1;
        position: absolute;
        display: flex;
    }

    .diabled-image-tray {
        display: none;
    }

    .about-section-container-image1 {
        width: 101px;
        height: 224px;
        margin: 96px 0px 0px 7px;
    }

    .about-section-container-image1 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container-image2 {
        width: 136px;
        height: 179px;
        margin: 162px 0px 0px 250px;
    }

    .about-section-container-image2 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container4-form-div {
        width: 100%;
        height: 350px;
        margin: 31px auto 0px auto;
        z-index: 2;
        position: absolute;
        display: flex;
        flex-direction: column;
    }

    .about-section-container4-form-div p {
        width: 100%;
        height: 20px;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 18px;
        /* font: normal normal medium 18px/21px Helvetica Neue; */
        font-family: "Catamaran";
        letter-spacing: 0.18px;
        color: #FFFFFF;
        opacity: 1;
    }

    .form-div {
        width: 100%;
        height: 311px;
        margin: 23px auto 0px auto;
        /* background-color: #F5F7FF; */

    }

    .contact-us-form {
        width: 275px;
        height: 311px;
        /* border: 2px solid #FFF; */
        margin: 0px auto 0px auto;
    }

    .fullname-group {
        width: 275px;
        height: 60px;
        margin: 0px auto 0px auto;
    }

    .formdata-fullname {
        width: 274px;
        height: 45px;
        margin: 0px auto 0px auto;
        border-radius: 8px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        font-family: "Catamaran";
        /* font: normal normal medium 14px/18px Helvetica Neue; */
    }

    .email-group {
        width: 275px;
        height: 60px;
        margin: 10px auto 0px auto;
    }

    .formdata-email {
        width: 274px;
        height: 45px;
        margin: 0px auto 0px auto;
        border-radius: 8px;
        background-color: #FFFFFF;
        /* font: normal normal medium 14px/18px Helvetica Neue; */
        font-weight: 500;
        font-family: "Catamaran";
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 14px;
        letter-spacing: 0.14px;
    }

    .suggestions-form-textarea {
        width: 275px;
        height: 120px;
        margin: 10px auto 0px auto;
    }

    .formdata-textarea {
        width: 275px;
        height: 110px;
        resize: none;
        border-radius: 8px;
        background-color: #FFFFFF;
        padding: 15px 0px 0px 10px;
        outline: none;
        color: #2F077C;
        text-align: left;
        font-size: 14px;
        font-family: "Catamaran";
        margin: 0px;
        font-weight: 500;
        border: none;
    }

    .submit-button-div {
        width: 285px;
        height: 35px;
        margin: 30px auto 0px auto;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .submit-button {
        width: 275px;
        height: 35px;
        border: none;
        outline: none;
        padding: 0px 0px 0px 0px;
        color: #418300;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: "Catamaran";
        background: none;
        font-weight: 600;
        letter-spacing: 0.14px;
    }

    .submit-button-div:hover,
    .submit-button:hover {
        background-color: #98BC00;
        color: #FFFFFF;
    }

    .footer-text {
        width: 392px;
        height: 15px;
        margin: 30px auto 0px auto;
        text-align: center;

    }

    .footer-text p {
        width: 100%;
        height: 15px;
        margin: 0px;
        font-size: 13px;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.13px;
        color: #70E21D;
        opacity: 1;
    }

    .about-section-container4-footer {
        width: 600px;
        height: 89px;
        margin: 29px auto 0px auto;
        text-align: center;
    }

    .about-section-container4-footer p {
        width: 600px;
        height: 49px;
        font-size: 18px;
        font-weight: 500;
        /* font: normal normal medium 18px/28px Helvetica Neue; */
        font-family: "Catamaran";
        letter-spacing: 0px;
        color: #000000;
    }

    .about-section-container4-footer a {
        text-decoration: none;
        color: #0A0EC6;
    }

    .about-section-container4-footer a:hover {
        text-decoration: underline;
    }

    .thank-you-animation {
        width: 300px;
        height: 300px;
        margin: 20px auto 0px auto;
        /* border: 2px solid #FFFFFF;/ */
    }

    .close-button {
        width: 120px;
        height: 35px !important;
        border: none;
        margin: 0px auto 0px auto;
        background: #FFFFFF;
        border-radius: 8px;
        /* padding: 0px 0px 0px 10px; */
        color: #418300;
        cursor: pointer;
        font-size: 14px;
        /* font: normal normal medium 14px/18px Helvetica Neue; */
        font-family: "Catamaran";
        color: #418300;
        font-weight: 600;
        letter-spacing: 0.35px;
    }

    .close-button:hover {
        background-color: #98BC00;
        color: #FFFFFF;
    }

    .btn-div {
        width: 100%;
        height: 35px;
        margin: 10px auto 0px auto;
        display: flex;
        justify-content: center;
    }

    .valid-feedback {
        display: none;
    }

    .invalid-feedback {
        margin: 0px 0px 0px 10px;
        height: 13px;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .fullname-feedback,
    .email-feedback {
        margin: 2px 0px 0px 10px;
        height: 13px;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .error-icon {
        width: 10px;
        height: 10px;
        margin: 0px;
    }

    .invalid-feedback p {
        width: auto;
        margin: 6px 0px 0px 5px;
        font-size: 12px;
        color: #FD7F6F;
    }

    .aboutus--go-to-top {
        width: auto;
        height: 35px;
        margin: 0px 20px 20px 0px;
        display: flex;
        justify-content: flex-end;

    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .about-main-container {
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column;
    }

    .about-section-background-container1 {
        width: 100%;
        height: 656px;
        margin: 0px auto 0px auto;
        background: transparent linear-gradient(180deg, #C1BAC6 0%, #FFFFFF 100%);
    }

    .about-section-content-div {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        border-right: none;
        border-left: none;
        display: flex;
        flex-direction: column;
    }

    .about-section-container1-title-div {
        width: 100%;
        height: auto;
        margin: 45px auto 0px auto;
    }

    .about-section-container1-title-div p {
        width: 574px;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 36px;
        letter-spacing: 0.36px;
        background: transparent linear-gradient(93deg, #A81F06 0%, #487DD3 51%, #22AD3D 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        background-clip: text;
        opacity: 1;
    }

    .about-section-container1-description {
        width: 100%;
        height: auto;
        margin: 12px auto 0px auto;
    }

    .about-section-container1-description p {
        width: 65%;
        height: auto;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        color: #0A2648;
        opacity: 1;
    }

    .about-section-container1-image-div {
        width: 100%;
        height: 328px;
        display: flex;
        justify-content: center;
        margin: 65px auto 0px auto;
    }

    .about-section-container1-image {
        width: 351px;
        height: 328px;
        margin: 0px;
    }

    .about-section-container1-image img {
        width: 100%;
        height: 100%;
    }

    .about-section-container2 {
        width: 100%;
        margin: 0px auto 0px auto;
        height: auto;
        border-right: none;
        border-left: none;
    }

    .about-section-container2-background-div {
        width: 73.4%;
        height: 302px;
        margin: 0px 0px 0px auto;
        left: auto;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
        background: #F5F7FF;
        opacity: 1;
        z-index: -1;
        position: absolute;
    }

    .about-section-container2-content-div {
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column;
    }

    .about-section-cont2-chandler-image-div {
        width: 100%;
        height: 95px;
        left: auto;
        right: 0;
        margin: 0;
        display: flex;
        justify-content: end;
    }

    .chandler1,
    .chandler2,
    .chandler3 {
        width: 50px;
        height: 94px;
        background-image: url("../Assets/AboutUs/chandler.svg");
        background-size: 50px 94px;
        margin: 0px 40px 0px 40px;
    }

    .about-section-container2-part2 {
        width: 100%;
        height: auto;
        margin: 0px;
        display: flex;

    }

    .about-section-container2-actual-content {
        width: 500px;
        height: 148px;
        margin: 20px 0px 0px 36px;
    }

    .about-section-container2-ac-title-div {
        width: 230px;
        height: 36px;
        margin: 0px;
    }

    .about-section-container2-ac-title-div p {
        width: 202px;
        height: 36px;
        margin: 0px;
        text-align: left;
        font-size: 32px;
        font-family: "Catamaran";
        font-weight: 600;
        letter-spacing: 0.32px;
        color: #4378A8;
        opacity: 1;
    }

    .about-section-container2-ac-description {
        width: 464px;
        height: 98px;
        margin: 14px 0px 0px 15px;
    }

    .about-section-container2-ac-description p {
        width: 464px;
        height: 98px;
        margin: 0px;
        text-align: left;
        font-size: 18px;
        font-family: "Catamaran";
        letter-spacing: 0.18px;
        font-weight: 500;
        color: #000000;
        opacity: 1;
    }

    .about-section-container2-second-image-tray {
        width: 250px;
        height: auto;
        margin: 30px auto 0px auto;
        display: flex;
        flex-direction: column;
    }

    .about-section-container2-second-image-tray-img1 {
        width: 133px;
        height: 61px;
        background-image: url("../Assets/AboutUs/rack1.png");
        background-size: 133px 61px;
        margin: 45px auto 0px auto;
    }

    .about-section-container2-second-image-tray-img2 {
        width: 87px;
        height: 119px;
        background-image: url("../Assets/AboutUs/clockFrame.png");
        background-size: 87px 119px;
        margin: 45px auto 0px auto;
    }

    .about-section-container2-second-image-tray-img3 {
        width: 126px;
        height: 45px;
        background-image: url("../Assets/AboutUs/bookShelf.png  ");
        background-size: 126px 45px;
        margin: 40px auto 0px auto;
    }

    .about-section-container2-part3 {
        width: 100%;
        height: 274px;
        margin: 47px auto 0px auto;
        display: flex;
        /* border: 1px solid #000; */
        /* background-color: #C1BAC6; */
    }

    .about-section-container2-part3-img1-div {
        width: 338px;
        height: 246px;
        margin: 15px 0px 0px 18px;
    }

    .about-section-container2-part3-img1 {
        width: 338px;
        height: 246px;
    }

    .about-section-container2-part3-img1 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container2-part3-img2-div {
        width: 328px;
        height: 273px;
        margin: 0px auto 0px auto;
    }

    .about-section-container2-part3-img2 {
        width: 338px;
        height: 273px;
    }

    .about-section-container2-part3-img2 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3 {
        width: 100%;
        height: auto !;
        margin: 0px auto 0px auto;
        display: flex;
        /* position: relative; */
        justify-content: center;
    }

    .about-section-container3-layer1-div {
        width: 100%;
        height: 863px;
        margin: 0px auto 0px auto;
        z-index: 1;
        position: absolute;
    }

    .about-section-container3-layer1-div img {
        width: 100%;
        height: 65%;
        margin: auto;
    }

    .about-section-container3-layer2-div {
        width: 100%;
        height: 863px;
        margin: 0px auto 0px auto;
        background: #000000;
        opacity: 0.85;
        z-index: 2;
        position: absolute;
    }

    .about-section-container3-layer3-div {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background: transparent;
        border-right: none;
        border-left: none;
        z-index: 3;
        position: absolute;
    }

    .about-section-container3-layer3-title {
        width: 100%;
        height: 36px;
        margin: 38px auto 0px auto;
    }

    .about-section-container3-layer3-title p {
        width: 100%;
        height: 36px;
        color: #FFFFFF;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 32px;
    }

    .about-section-container3-layer3-grid-div {
        width: 440px;
        height: auto;
        margin: 45px auto 0px auto;
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-template-rows: 2fr 2fr;
        row-gap: 40px;
    }

    .about-section-container3-layer3-grid1 {
        width: 200px;
        height: 200px;
        border-radius: 12px 12px 0px 12px;
        background: #ECDFF8;
        opacity: 1;
        margin: 0px auto 0px 0px;
    }

    .about-section-container3-layer3-grid1-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid1-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid1-description,
    .about-section-container3-layer3-grid2-description,
    .about-section-container3-layer3-grid3-description,
    .about-section-container3-layer3-grid4-description {
        width: 160px;
        height: auto;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid1-description p,
    .about-section-container3-layer3-grid2-description p,
    .about-section-container3-layer3-grid3-description p,
    .about-section-container3-layer3-grid4-description p {
        width: 160px;
        height: auto;
        text-align: center;
        font-size: 18px;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.18px;
        color: #000000;
        opacity: 1;
    }

    .about-section-container3-layer3-grid2 {
        width: 200px;
        height: 200px;
        border-radius: 12px 12px 12px 0px;
        background: #F7F8DF;
        opacity: 1;
        margin: 0px 0px 0px auto;
    }

    .about-section-container3-layer3-grid2-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid2-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid2-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid3 {
        width: 200px;
        height: 200px;
        border-radius: 12px 0px 12px 12px;
        background: #DFF8F1;
        opacity: 1;
        margin: 0px auto 0px 0px;
    }

    .about-section-container3-layer3-grid3-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid3-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid3-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-grid4 {
        width: 200px;
        height: 200px;
        border-radius: 0px 12px 12px 12px;
        background: #F8DFDF;
        opacity: 1;
        margin: 0px 0px 0px auto;
    }

    .about-section-container3-layer3-grid4-img-div {
        width: 42px;
        height: 50px;
        margin: 40px auto 0px auto;
    }

    .about-section-container3-layer3-grid4-img {
        width: 42px;
        height: 50px;
    }

    .about-section-container3-layer3-grid4-img img {
        width: 100%;
        height: 100%;
    }

    .about-section-container3-layer3-title2 {
        width: 100%;
        height: 36px;
        margin: 45px auto 0px auto;
    }

    .about-section-container3-layer3-title2 p {
        width: 100%;
        height: auto;
        color: #FFFFFF;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 32px;
        margin: 0px auto 0px auto;
    }

    .about-section-container3-layer3-description {
        width: 80%;
        height: auto;
        margin: 15px auto 0px auto;

    }

    .about-section-container3-layer3-description p {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 16px;
        margin: 0px auto 10px auto;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.16px;
        color: #FFFFFF;
        opacity: 1;
    }

    .about-section-container3-layer3-description p:last-child {
        margin: 0px auto 0px auto;
    }

    .about-section-container4 {
        width: 100%;
        height: auto;
        margin: 190px auto 0px auto;
        border-right: none;
        border-left: none;
        position: relative;
    }

    .about-section-container4-title-div {
        width: 100%;
        height: auto;
        margin: 50px auto 0px auto;
    }

    .about-section-container4-title-div p {
        width: 100%;
        height: auto;
        margin: 0px auto 0px 0px;
        text-align: center;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-weight: bold;
        font-stretch: condensed;
        font-size: 32px;
    }

    .about-section-container4-content-div {
        width: 500px;
        height: 453px;
        background: #000000;
        box-shadow: 0px 0px 48px #8D8D8D29;
        border-radius: 16px;
        opacity: 1;
        margin: 28px auto 0px auto;
        position: relative;
    }

    .about-section-container4-image-tray {
        width: 100%;
        height: auto;
        margin: 0px;
        z-index: 1;
        position: absolute;
        display: flex;
    }

    .diabled-image-tray {
        display: none;
    }

    .about-section-container-image1 {
        width: 101px;
        height: 224px;
        margin: 96px 0px 0px 7px;
    }

    .about-section-container-image1 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container-image2 {
        width: 136px;
        height: 179px;
        margin: 162px 0px 0px 250px;
    }

    .about-section-container-image2 img {
        width: 100%;
        height: 100%;
    }

    .about-section-container4-form-div {
        width: 100%;
        height: 350px;
        margin: 31px auto 0px auto;
        z-index: 2;
        position: absolute;
        display: flex;
        flex-direction: column;
    }

    .about-section-container4-form-div p {
        width: 100%;
        height: 20px;
        margin: 0px auto 0px auto;
        text-align: center;
        font-size: 18px;
        /* font: normal normal medium 18px/21px Helvetica Neue; */
        font-family: "Catamaran";
        letter-spacing: 0.18px;
        color: #FFFFFF;
        opacity: 1;
    }

    .form-div {
        width: 100%;
        height: 311px;
        margin: 23px auto 0px auto;
        /* background-color: #F5F7FF; */

    }

    .contact-us-form {
        width: 275px;
        height: 311px;
        /* border: 2px solid #FFF; */
        margin: 0px auto 0px auto;
    }

    .fullname-group {
        width: 275px;
        height: 60px;
        margin: 0px auto 0px auto;
    }

    .formdata-fullname {
        width: 274px;
        height: 45px;
        margin: 0px auto 0px auto;
        border-radius: 8px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        font-family: "Catamaran";
        /* font: normal normal medium 14px/18px Helvetica Neue; */
    }

    .email-group {
        width: 275px;
        height: 60px;
        margin: 10px auto 0px auto;
    }

    .formdata-email {
        width: 274px;
        height: 45px;
        margin: 0px auto 0px auto;
        border-radius: 8px;
        background-color: #FFFFFF;
        /* font: normal normal medium 14px/18px Helvetica Neue; */
        font-weight: 500;
        font-family: "Catamaran";
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 14px;
        letter-spacing: 0.14px;
    }

    .suggestions-form-textarea {
        width: 275px;
        height: 120px;
        margin: 10px auto 0px auto;
    }

    .formdata-textarea {
        width: 275px;
        height: 110px;
        resize: none;
        border-radius: 8px;
        background-color: #FFFFFF;
        padding: 15px 0px 0px 10px;
        outline: none;
        color: #2F077C;
        text-align: left;
        font-size: 14px;
        font-family: "Catamaran";
        margin: 0px;
        font-weight: 500;
        border: none;
    }

    .submit-button-div {
        width: 285px;
        height: 35px;
        margin: 30px auto 0px auto;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .submit-button {
        width: 275px;
        height: 35px;
        border: none;
        outline: none;
        padding: 0px 0px 0px 0px;
        color: #418300;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: "Catamaran";
        background: none;
        font-weight: 600;
        letter-spacing: 0.14px;
    }

    .submit-button-div:hover,
    .submit-button:hover {
        background-color: #98BC00;
        color: #FFFFFF;
    }

    .footer-text {
        width: 392px;
        height: 15px;
        margin: 30px auto 0px auto;
        text-align: center;

    }

    .footer-text p {
        width: 100%;
        height: 15px;
        margin: 0px;
        font-size: 13px;
        font-family: "Catamaran";
        font-weight: 500;
        letter-spacing: 0.13px;
        color: #70E21D;
        opacity: 1;
    }

    .about-section-container4-footer {
        width: 600px;
        height: 89px;
        margin: 29px auto 0px auto;
        text-align: center;
    }

    .about-section-container4-footer p {
        width: 600px;
        height: 49px;
        font-size: 18px;
        font-weight: 500;
        /* font: normal normal medium 18px/28px Helvetica Neue; */
        font-family: "Catamaran";
        letter-spacing: 0px;
        color: #000000;
    }

    .about-section-container4-footer a {
        text-decoration: none;
        color: #0A0EC6;
    }

    .about-section-container4-footer a:hover {
        text-decoration: underline;
    }

    .thank-you-animation {
        width: 300px;
        height: 300px;
        margin: 20px auto 0px auto;
        /* border: 2px solid #FFFFFF;/ */
    }

    .close-button {
        width: 120px;
        height: 35px !important;
        border: none;
        margin: 0px auto 0px auto;
        background: #FFFFFF;
        border-radius: 8px;
        /* padding: 0px 0px 0px 10px; */
        color: #418300;
        cursor: pointer;
        font-size: 14px;
        /* font: normal normal medium 14px/18px Helvetica Neue; */
        font-family: "Catamaran";
        color: #418300;
        font-weight: 600;
        letter-spacing: 0.35px;
    }

    .close-button:hover {
        background-color: #98BC00;
        color: #FFFFFF;
    }

    .btn-div {
        width: 100%;
        height: 35px;
        margin: 10px auto 0px auto;
        display: flex;
        justify-content: center;
    }

    .valid-feedback {
        display: none;
    }

    .invalid-feedback {
        margin: 0px 0px 0px 10px;
        height: 13px;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .fullname-feedback,
    .email-feedback {
        margin: 2px 0px 0px 10px;
        height: 13px;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .error-icon {
        width: 10px;
        height: 10px;
        margin: 0px;
    }

    .invalid-feedback p {
        width: auto;
        margin: 6px 0px 0px 5px;
        font-size: 12px;
        color: #FD7F6F;
    }

    .aboutus--go-to-top {
        width: auto;
        height: 35px;
        margin: 0px 20px 20px 0px;
        display: flex;
        justify-content: flex-end;

    }

}