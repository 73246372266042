.security-main-container {
  width: 100%;
  height: 1881px;
  background-color: #f8f8f8;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  font-family: "Catamaran";
}

.security-actual-content-container {
  width: 1080px;
  height: 1881px;
  border-left: 1px solid rgb(144, 144, 144, 0.5);
  border-right: 1px solid rgb(144, 144, 144, 0.5);
  margin: 0px auto 0px auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* align-items: ; */
  justify-content: flex-start;
}

.security-first-section {
  width: 1080px;
  height: auto;
  margin: 50px auto 0px auto;
  /* background-color: beige; */
}

.security-heading1 {
  width: 100%;
  height: 40px;
  margin: 0px auto 0px auto;
}

.security-heading1 p {
  width: 250px;
  height: 40px;
  margin: 0px auto 0px auto;
  text-align: center;
  font-size: 32px;
  letter-spacing: 0.32px;
  color: #000000;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
}

.security-last-updated-div {
  width: 100%;
  height: 29px;
  margin: 23px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
  /* text-align: flex-end; */
}

.security-last-updated-div p {
  width: 233px;
  height: 20px;
  text-align: right;
  /* font: normal normal medium 18px/32px Helvetica Neue; */
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 107px 0px auto;
}

.security-description1 {
  width: 850px;
  height: auto;
  text-align: left;
  /* font: normal normal medium 16px/24px Helvetica Neue; */
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  color: #000000;
  opacity: 1;
  margin: 54px auto 0px auto;
  /* background-color: #f3f3f3;    */
}

.security-description1 p {
  margin: 0px;
}

.security-sub-section1 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section1-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;

  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section1-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section1-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section1-description p:last-child {
  margin: 0px;
}

.security-sub-section2 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section2-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section2-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section2-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section2-description p:last-child {
  margin: 0px;
}

.security-sub-section3 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section3-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section3-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section3-description p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section4 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section4-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section4-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section4-description p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section5 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section5-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section5-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section5-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section5-description p:last-child {
  margin: 0px;
}

.security-sub-section6 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section6-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section6-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section6-description p {
  text-align: left;
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section7 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: #b32c2c; */
}

.security-sub-section7-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */
}

.security-sub-section7-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.security-sub-section7-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.security-sub-section7-description p:last-child {
  margin: 0px;
}

.security-go-to-top {
  width: 100%;
  height: auto;
  margin: 20px 0px 0px 0px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
}

@media only screen and (max-width: 440px) {
  .security-main-container {
    max-width: 440px;
    width: 100%;
    height: auto;
    /* background-color: #f8f8f8; */
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
  }

  .security-actual-content-container {
    width: 100%;
    height: auto;
    border: none;
    margin: 0px auto 0px auto;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    /* align-items: ; */
    justify-content: flex-start;
  }

  .security-first-section {
    width: 100%;
    height: auto;
    margin: 29px auto 0px auto;
    /* background-color: beige; */
    /* background-color: #9d2323; */
  }

  .security-heading1 {
    width: 100%;
    height: 28px;
    margin: 0px auto 0px auto;
  }

  .security-heading1 p {
    width: 82px;
    height: 28px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .security-last-updated-div {
    width: 95%;
    height: 29px;
    margin: 14px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .security-last-updated-div p {
    width: 156px;
    height: 15px;
    text-align: right;
    /* font: normal normal medium 12px/18px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 0px auto;
  }

  .security-description1 {
    width: 90%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 12px/18px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #000000;
    opacity: 1;
    margin: 15px auto 0px auto;
    /* background-color: #9d2323;    */
  }

  .security-description1 p {
    margin: 0px;
  }

  .security-sub-section1 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section1-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section1-description p:last-child {
    margin: 0px;
  }

  .security-sub-section2 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section2-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/40px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section2-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section2-description p:last-child {
    margin: 0px;
  }

  .security-sub-section3 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section3-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section3-description p {
    text-align: left;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section4 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section4-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section4-description p {
    text-align: left;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section5 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section5-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section5-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section5-description p:last-child {
    margin: 0px;
  }


  .security-sub-section6 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section6-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section6-description p {
    text-align: left;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section7 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section7-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section7-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .security-sub-section7-description p:last-child {
    margin: 0px;
  }

  .security-go-to-top {
    width: 100%;
    height: auto;
    margin: 20px 0px 20px -20px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .security-main-container {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
  }

  .security-actual-content-container {
    width: 90%;
    height: auto;
    border-left: none;
    border-right: none;
    margin: 0px auto 0px auto;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .security-first-section {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
    /* background-color: beige; */
  }

  .security-heading1 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .security-heading1 p {
    width: auto;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.32px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .security-last-updated-div {
    width: 100%;
    height: 29px;
    margin: 23px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .security-last-updated-div p {
    width: 233px;
    height: 20px;
    text-align: right;
    /* font: normal normal medium 18px/32px Helvetica Neue; */
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 0px auto;
  }

  .security-description1 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
    /* background-color: #f3f3f3;    */
  }

  .security-description1 p {
    margin: 0px;
  }

  .security-sub-section1 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section1-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;

    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .security-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section1-description p:last-child {
    margin: 0px;
  }

  .security-sub-section2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section2-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section2-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section2-description p:last-child {
    margin: 0px;
  }

  .security-sub-section3 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section3-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section3-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section4 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section4-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section4-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section5-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section5-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section5-description p:last-child {
    margin: 0px;
  }

  .security-sub-section6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section6-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section7 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section7-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section7-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section7-description p:last-child {
    margin: 0px;
  }

  .security-go-to-top {
    width: 100%;
    height: auto;
    margin: 40px 0px 20px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .security-main-container {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
  }

  .security-actual-content-container {
    width: 90%;
    height: auto;
    border-left: none;
    border-right: none;
    margin: 0px auto 0px auto;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .security-first-section {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
    /* background-color: beige; */
  }

  .security-heading1 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .security-heading1 p {
    width: auto;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.32px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .security-last-updated-div {
    width: 100%;
    height: 29px;
    margin: 23px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .security-last-updated-div p {
    width: 233px;
    height: 20px;
    text-align: right;
    /* font: normal normal medium 18px/32px Helvetica Neue; */
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 0px auto;
  }

  .security-description1 {
    width: 100%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
    /* background-color: #f3f3f3;    */
  }

  .security-description1 p {
    margin: 0px;
  }

  .security-sub-section1 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section1-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;

    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .security-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section1-description p:last-child {
    margin: 0px;
  }

  .security-sub-section2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section2-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section2-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section2-description p:last-child {
    margin: 0px;
  }

  .security-sub-section3 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section3-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section3-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section4 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section4-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section4-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section5-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section5-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section5-description p:last-child {
    margin: 0px;
  }

  .security-sub-section6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section6-description p {
    text-align: left;
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section7 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .security-sub-section7-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */
  }

  .security-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .security-sub-section7-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .security-sub-section7-description p:last-child {
    margin: 0px;
  }

  .security-go-to-top {
    width: 100%;
    height: auto;
    margin: 40px 0px 20px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}