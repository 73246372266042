.sidebar-container {
    width: 232px;
    height: auto;
    margin: 0px;
    /* display: flex; */
    background-color: #f8f8f8;
}

.sidebar-title-div {
    width: 100%;
    height: 27px;
}

.sidebar-title-div p {
    width: auto;
    height: 27px;
    text-align: center;
    margin: 0px 0px 0px 0px;
    font-family: "Catamaran";
    font-size: 24px;
    letter-spacing: 0.24px;
    font-weight: bold;
}

.sidebar-menu-list {
    width: 232px;
    height: auto;
    margin: 33px 0px 0px 0px;
}

.navbar-list-group {
    width: auto;
    height: auto;
    margin: 0px 0px 12px 37px;
}

.navbar-list-group-title {
    width: auto;
    height: 20px;
    margin: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navbar-list-group-title .expand-collapse {
    margin: 0px 15px 0px 0px;
}

.navbar-list-group-title p {
    width: auto;
    text-align: left;
    margin: 0px;
    font-size: 18px;
    font-family: "Catamaran";
    letter-spacing: 0.18px;
    color: #333333;
    opacity: 1;
    font-weight: 500;
}

.navbar-list-group-title p:hover {
    text-decoration: underline #0083BC;
}

.arrow-right-display {
    width: 10px;
    height: 15px;
    margin: 1.5px 0px 0px 11px;
    display: block;
}

.arrow-right-not-display {
    display: none;
}

.sub-list-display {
    width: 207px;
    height: auto;
}

.sub-list-not-display {
    display: none;
    margin-bottom: 20px;
}

.navbar-list-item {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 25px;
    cursor: pointer;
}

.inactive-sub-nav-item {
    width: 100%;
    text-align: left;
    height: auto;
    margin: 7px 0px 5px 1px;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    color: #333333;
    opacity: 1;
}

.active-sub-nav-item {
    width: 100%;
    text-align: left;
    height: auto;
    margin: 7px 0px 5px 1px;
    font-size: 16px;
    letter-spacing: 0.16px;
    font-family: "Catamaran";
    color: #0083BC;
    font-weight: 600;
    opacity: 1;
}


@media only screen and (max-width: 440px) {
    .sidebar-container {
        width: 265px;
        height: auto;
        margin: 0px;
        background: #ffffff;
        border-bottom: 3px solid #00000029;
        border-top: 3px solid #00000029;
        border-right: 3px solid #00000029;
        border-radius: 0px 6px 6px 0px !important;
    }

    .sidebar-title-div {
        width: 100%;
        height: 20px;
        text-align: center;
        margin: 20px 0px 0px 0px;
    }

    .sidebar-title-div p {
        height: 20px;
        margin: 0px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.18px;
    }

    .sidebar-menu-list {
        width: 242px;
        height: auto;
        margin: 20px 0px 0px 0px;
    }

    .navbar-list-group {
        width: auto;
        height: auto;
        /* display: flex; */
        margin: 0px 0px 12px 22px;
    }

    .navbar-list-group-title {
        width: auto;
        height: 17px;
        margin: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .navbar-list-group-title .expand-collapse {
        margin: 0px 15px 0px 0px;
    }

    .navbar-list-group-title p {
        width: auto !important;
        text-align: left;
        margin: 0px;
        font-size: 14px;
        letter-spacing: 0.14px;
        color: #333333;
        opacity: 1;
        font-weight: 550;
    }

    .navbar-list-group-title p:hover {
        text-decoration: none;
    }

    .arrow-right-display {
        width: 7px;
        height: auto;
        margin: 0px auto 0px 15px;
        display: block;
    }

    .arrow-right-not-display {
        display: none;
    }

    .sub-list-display {
        /* display: flex; */
        width: 207px;
        height: auto;
    }

    .sub-list-not-display {
        display: none;
        margin-bottom: 20px;
    }

    .navbar-list-item {
        width: 100%;
        height: auto;
        margin: 0px 0px 0px 25px;
        cursor: pointer;
    }

    .inactive-sub-nav-item {
        width: 100%;
        text-align: left;
        height: auto;
        margin: 3px 0px 2px 1px;
        font-size: 12px;
        letter-spacing: 0.12px;
        color: #333333;
        opacity: 1;
    }

    .active-sub-nav-item {
        width: 100%;
        text-align: left;
        height: auto;
        margin: 3px 0px 3px 1px;
        font-size: 12px;
        letter-spacing: 0.12px;
        color: #0083BC;
        font-weight: 500;
        opacity: 1;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .sidebar-container {
        width: 300px;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-bottom: 3px solid #00000029;
        border-top: 3px solid #00000029;
        border-right: 3px solid #00000029;
        border-radius: 0px 6px 6px 0px !important;
    }

    .sidebar-title-div {
        width: 100%;
        height: auto;
        text-align: center;
        margin: 20px 0px 0px 0px;
    }

    .sidebar-title-div p {
        height: auto;
        margin: 0px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 30px;
    }

    .sidebar-menu-list {
        width: 300px;
        height: auto;
        margin: 20px 0px 0px 0px;

    }

    .navbar-list-group {
        width: auto;
        height: auto;
        /* display: flex; */
        margin: 0px 0px 12px 30px;
        /* border: 1px solid #000; */
    }

    .navbar-list-group-title {
        width: auto;
        height: auto;
        margin: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .navbar-list-group-title .expand-collapse {
        margin: 0px 15px 0px 0px;
    }

    .navbar-list-group-title p {
        width: auto !important;
        text-align: left;
        margin: 0px;
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        font-weight: 550;
    }

    .navbar-list-group-title p:hover {
        text-decoration: none;
    }

    .arrow-right-display {
        width: 9px;
        height: auto;
        margin: 0px auto 0px 10px;
        display: block;
    }

    .arrow-right-not-display {
        display: none;
    }

    .sub-list-display {
        /* display: flex; */
        width: 250px;
        height: auto;
    }

    .sub-list-not-display {
        display: none;
        margin-bottom: 20px;
    }

    .navbar-list-item {
        width: 100%;
        height: auto;
        margin: 0px 0px 0px 25px;
        cursor: pointer;
    }

    .inactive-sub-nav-item {
        width: 100%;
        text-align: left;
        height: auto;
        margin: 3px 0px 2px 1px;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #333333;
        font-weight: 500;
        opacity: 1;
    }

    .active-sub-nav-item {
        width: 100%;
        text-align: left;
        height: auto;
        margin: 3px 0px 3px 1px;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #0083BC;
        font-weight: 500;
        opacity: 1;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .sidebar-container {
        width: 300px;
        height: auto;
        margin: 0px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-bottom: 3px solid #00000029;
        border-top: 3px solid #00000029;
        border-right: 3px solid #00000029;
        border-radius: 0px 6px 6px 0px !important;
    }

    .sidebar-title-div {
        width: 100%;
        height: auto;
        text-align: center;
        margin: 20px 0px 0px 0px;
    }

    .sidebar-title-div p {
        height: auto;
        margin: 0px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 30px;
    }

    .sidebar-menu-list {
        width: 300px;
        height: auto;
        margin: 20px 0px 0px 0px;

    }

    .navbar-list-group {
        width: auto;
        height: auto;
        /* display: flex; */
        margin: 0px 0px 12px 30px;
        /* border: 1px solid #000; */
    }

    .navbar-list-group-title {
        width: auto;
        height: auto;
        margin: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .navbar-list-group-title .expand-collapse {
        margin: 0px 15px 0px 0px;
    }

    .navbar-list-group-title p {
        width: auto !important;
        text-align: left;
        margin: 0px;
        font-size: 18px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        font-weight: 550;
    }

    .navbar-list-group-title p:hover {
        text-decoration: none;
    }

    .arrow-right-display {
        width: 9px;
        height: auto;
        margin: 0px auto 0px 10px;
        display: block;
    }

    .arrow-right-not-display {
        display: none;
    }

    .sub-list-display {
        /* display: flex; */
        width: 250px;
        height: auto;
    }

    .sub-list-not-display {
        display: none;
        margin-bottom: 20px;
    }

    .navbar-list-item {
        width: 100%;
        height: auto;
        margin: 0px 0px 0px 25px;
        cursor: pointer;
    }

    .inactive-sub-nav-item {
        width: 100%;
        text-align: left;
        height: auto;
        margin: 3px 0px 2px 1px;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #333333;
        font-weight: 500;
        opacity: 1;
    }

    .active-sub-nav-item {
        width: 100%;
        text-align: left;
        height: auto;
        margin: 3px 0px 3px 1px;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #0083BC;
        font-weight: 500;
        opacity: 1;
    }
}