.add-category-main-container {
    width: 768px;
    height: auto;
    margin: 0px auto 0px auto;
    font-family: "Catamaran";
}

span {
    font-weight: 500 !important;
}

.add-category-breadcrumb {
    width: 80%;
    height: auto;
    margin: 46px 0px 0px 68px;
    display: inline-flex;
}

.add-category-breadcrumb p {
    margin: 0px 10px 0px 0px;
    height: auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #000000;
    width: auto !important;
}

.add-category-breadcrumb p:last-child {
    font-weight: bold;
}

.add-category-title-div {
    width: 100%;
    height: auto;
    margin: 30px 0px 0px 72px;
}

.add-category-title-div p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 24px;
    letter-spacing: 0.24px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
}

.add-category-section-container1 {
    width: 600px;
    height: auto;
    margin: 14px 0px 0px 72px;
}

.add-category-section-container1-description {
    width: 600px;
    height: auto;
    margin: 0px;
}

.add-category-section-container1-description ol>li {
    width: 580px;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    font-family: "Catamaran";
    line-height: 27px;
    color: #333333;
    opacity: 1;
}

.add-category-section-container1-img1-div {
    width: 378px;
    height: 400px;
    margin: 20px auto 20px auto;
}

.add-category-section-container1-img1-div img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


@media only screen and (max-width: 440px) {
    .add-category-main-container {
        max-width: 440px;
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .add-category-breadcrumb {
        width: 90%;
        height: 16px;
        margin: 23px auto 0px auto;
        display: flex;
    }

    .add-category-breadcrumb p {
        margin: 0px 5px 0px 0px;
        height: 16px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.10px;
        color: #000000;
        opacity: 1;
    }

    .add-category-breadcrumb p:last-child {
        font-weight: bold;
    }

    .add-category-title-div {
        width: 90%;
        height: auto;
        margin: 15px auto 0px auto;
    }

    .add-category-title-div p {
        width: 90%;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .add-category-section-container1 {
        width: 100%;
        height: auto;
        margin: 7px auto 0px auto;
    }

    .add-category-section-container1-description {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .add-category-section-container1-description ol {
        width: 95%;
        padding-left: 0px;
        margin: 0px auto 0px auto !important;
    }

    .add-category-section-container1-description ol>li {
        width: 90%;
        height: auto;
        margin: 0px 0px 5px 20px;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 18px;
        color: #333333;
        opacity: 1;
    }

    .add-category-section-container1-img1-div {
        width: 88%;
        height: 88%;
        margin: 20px auto 20px auto;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .add-category-main-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    span {
        font-weight: 500 !important;
    }

    .add-category-breadcrumb {
        width: 80% !important;
        height: auto;
        margin: 46px 0px 0px 40px;
        display: flex;
    }

    .add-category-breadcrumb p {
        margin: 0px 10px 0px 0px;
        width: auto !important;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
    }

    .add-category-breadcrumb p:last-child {
        font-weight: bold;
    }

    .add-category-title-div {
        width: 100%;
        height: auto;
        margin: 20px 0px 0px 40px;
    }

    .add-category-title-div p {
        width: auto;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 24px;
        font-family: "Catamaran";
        letter-spacing: 0.24px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .add-category-section-container1 {
        width: 90%;
        height: auto;
        margin: 14px auto 0px auto;
        /* border: 1px solid #000000; */
    }

    .add-category-section-container1-description {
        width: 100%;
        height: auto;
        margin: 0px;
    }

    .add-category-section-container1-description p {
        width: 90%;
        height: auto;
        margin: 0px 0px 0px 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-family: "Catamaran";
        line-height: 24px;
        color: #333333;
        opacity: 1;
    }

    .add-category-section-container1-description ol {
        padding-left: 20px;
    }

    .add-category-section-container1-description ol>li {
        width: 100%;
        height: auto;
        margin: 0px 0px 0px 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-family: "Catamaran";
        line-height: 24px;
        color: #333333;
        opacity: 1;
    }

    .add-category-section-container1-img1-div,
    .add-category-section-container1-img2-div {
        width: 378px;
        height: 400px;
        margin: 20px auto 20px auto;
    }

    .add-category-section-container1-img1-div img,
    .add-category-section-container1-img2-div img,
    .add-category-section-container1-img3-div img,
    .add-category-section-container1-img4-div img,
    .add-category-section-container1-img5-div img,
    .add-category-section-container1-img6-div img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .add-category-section-container1-img3-div {
        width: 378px;
        height: 600px;
        margin: 20px auto 20px auto;
    }

    .add-category-section-container1-img4-div,
    .add-category-section-container1-img5-div {
        width: 378px;
        height: 250px;
        margin: 20px auto 20px auto;
    }

    .add-category-section-container1-img6-div {
        width: 378px;
        height: 794px;
        margin: 20px auto 20px auto;
    }

    .learn-add-category-go-to-top {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        margin: 35px 45px 35px 0px !important;
    }


}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .add-category-main-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    span {
        font-weight: 500 !important;
    }

    .add-category-breadcrumb {
        width: 80% !important;
        height: auto;
        margin: 46px 0px 0px 40px;
        display: flex;
    }

    .add-category-breadcrumb p {
        margin: 0px 10px 0px 0px;
        width: auto !important;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-family: "Catamaran";
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
    }

    .add-category-breadcrumb p:last-child {
        font-weight: bold;
    }

    .add-category-title-div {
        width: 100%;
        height: auto;
        margin: 20px 0px 0px 40px;
    }

    .add-category-title-div p {
        width: auto;
        height: auto;
        margin: 0px;
        text-align: left;
        font-size: 24px;
        font-family: "Catamaran";
        letter-spacing: 0.24px;
        font-weight: bold;
        color: #000000;
        opacity: 1;
    }

    .add-category-section-container1 {
        width: 90%;
        height: auto;
        margin: 14px auto 0px auto;
        /* border: 1px solid #000000; */
    }

    .add-category-section-container1-description {
        width: 100%;
        height: auto;
        margin: 0px;
    }

    .add-category-section-container1-description p {
        width: 90%;
        height: auto;
        margin: 0px 0px 0px 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-family: "Catamaran";
        line-height: 24px;
        color: #333333;
        opacity: 1;
    }

    .add-category-section-container1-description ol {
        padding-left: 20px;
    }

    .add-category-section-container1-description ol>li {
        width: 100%;
        height: auto;
        margin: 0px 0px 0px 0px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-family: "Catamaran";
        line-height: 24px;
        color: #333333;
        opacity: 1;
    }

    .add-category-section-container1-img1-div,
    .add-category-section-container1-img2-div {
        width: 378px;
        height: 400px;
        margin: 20px auto 20px auto;
    }

    .add-category-section-container1-img1-div img,
    .add-category-section-container1-img2-div img,
    .add-category-section-container1-img3-div img,
    .add-category-section-container1-img4-div img,
    .add-category-section-container1-img5-div img,
    .add-category-section-container1-img6-div img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .add-category-section-container1-img3-div {
        width: 378px;
        height: 600px;
        margin: 20px auto 20px auto;
    }

    .add-category-section-container1-img4-div,
    .add-category-section-container1-img5-div {
        width: 378px;
        height: 250px;
        margin: 20px auto 20px auto;
    }

    .add-category-section-container1-img6-div {
        width: 378px;
        height: 794px;
        margin: 20px auto 20px auto;
    }

    .learn-add-category-go-to-top {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        margin: 35px 45px 35px 0px !important;
    }


}