.learning-center-main-container {
    width: 100%;
    height: 100%;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    font-family: "Catamaran";
}

.learning-center-actual-section-container {
    width: 1080px;
    height: auto;
    margin: 0px auto 0px auto !important;
    border-right: 1px solid rgb(144, 144, 144, 0.5);
    border-left: 1px solid rgb(144, 144, 144, 0.5);
    background-color: #F8F8F8;
}

.lc-section-container1 {
    width: 1080px;
    height: auto;
    margin: 0px auto 0px auto;
}

.lc-section-container1-title {
    width: 100%;
    height: 36px;
    margin: 33px auto 0px auto;
}

.lc-section-container1-title p {
    width: auto;
    height: 36px;
    text-align: center;
    font-size: 36px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-stretch: condensed;
    margin: 0px auto 0px auto;
    color: #333333;
    cursor: pointer;
}

.lc-section-container1-content {
    width: 100%;
    height: 60px;
    margin: 51px auto 0px auto;
    display: flex;
}

.search-container {
    width: 625px;
    height: 52px;
    background: #FFFFFF;
    /* border: 0.6px solid rgb(0, 131, 188,0.5); */
    border-radius: 8px;
    opacity: 1;
    margin: auto 0px auto 35px;
    display: flex;
}

.active-search-container {
    width: 625px;
    height: 52px;
    background: #FFFFFF;
    border: 0.6px solid rgb(0, 131, 188, 0.5);
    border-radius: 8px;
    opacity: 1;
    margin: auto 0px auto 35px;
    display: flex;
}

.inactive-search-container {
    border: 0.6px solid rgb(191, 191, 191, 0.5);
    width: 625px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    margin: auto 0px auto 35px;
    display: flex;
}

.search-container img:first-child {
    width: 27px;
    height: 27px;
    margin: 13px 0px 0px 16px;
    cursor: pointer;
}

.active-search-container .search-close-button {
    width: 21px;
    height: 21px;
    margin: auto 10px auto 15px;
    cursor: pointer;
}

.active-search-container input {
    width: 520px;
    height: 25px;
    margin: 12px 0px 0px 10px;
    font-size: 18px;
    padding: 0px 0px 0px 10px;
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(0, 131, 188, 0.25);

}

.inactive-search-container input {
    width: 520px;
    height: 25px;
    margin: 12px 0px 0px 10px;
    font-size: 18px;
    padding: 0px 0px 0px 10px;
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(191, 191, 191, 0.5);

}

.last-updated-div {
    width: 100%;
    height: 50px;
    margin: auto 35px auto 137px;
    /* border: 1px solid #BFBFBF; */
}

.last-updated-div p {
    width: 100%;
    height: 17px;
    text-align: right;
    /* font: normal normal medium 16px/24px Helvetica Neue; */
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin: 0px 0px 16px 0px;
}

.last-updated-div a {
    color: #0083BC;
    cursor: pointer;
    text-decoration: none;
}

.last-updated-div a:hover {

    text-decoration: underline;
}

.last-updated-div p:last-child {
    margin: 0px;
}

.lc-section-container1-search-content-div {
    width: 625px;
    height: auto;
    /* margin: 0px auto 0px auto; */
}

.lc-section-container1-search-results {
    width: 625px;
    height: 440px;
    background-color: #ffffff;
    border: 0.6px solid rgb(0, 131, 188, 0.5);
    margin: -3px 0px 0px 35px;
    z-index: 1;
    position: absolute;
    border-radius: 0px 0px 8px 8px;
    overflow-y: scroll;
    overflow-x: hidden;

}

.search-results-items-container {
    width: 100%;
    height: auto;
    /* border: 2px solid rgb(182, 104, 2); */
    background: #ffffff;
    color: #000000;
    display: flex;
    flex-direction: column;
}

.search-results-item {
    width: 100%;
    height: 20px;
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    /* background-color: blue */
}

.search-results-item-title {
    width: 100%;
    height: 20px;
    text-align: left;
    margin: 0px 0px 0px 32px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

.search-results-item-title p:hover {
    color: #0578FF;
    text-decoration: underline;
}

.search-results-item-title img {
    width: 15px;
    height: 15px;
    margin: 0px;
}

.search-results-item-title p {
    width: 90%;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 11px 0px 15px 10px;
    font-size: 16px;
    font-weight: 600;
    font: normal normal medium 16px/20px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.lc-section-container2 {
    width: 1080px;
    height: auto;
    /* background-color: burlywood; */
    margin: 86px 0px 0px 0px;
    display: flex;
}

.lc-section-container2-sidebar {
    width: 232px;
    height: auto;
    margin: 0px;
    /* background-color: aquamarine; */
}

.sidebar-menu-list {
    width: 100%;
    height: auto;
    margin: 0px;
}

.lc-section-container2-content-box {
    width: 768px;
    height: auto;
    margin: 0px auto 0px auto;
    background-color: #FFFFFF;
}

@media only screen and (max-width: 440px) {
    .learning-center-main-container {
        max-width: 440px;
        width: 100%;
        height: 100%;
        padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        background-color: #F8F8F8;
    }

    .learning-center-actual-section-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border: none;
        background-color: #F8F8F8;
    }

    .lc-section-container1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .lc-section-container1-title {
        width: 100%;
        height: 27px;
        margin: 20px auto 0px auto;
    }

    .lc-section-container1-title p {
        width: 172px;
        height: 27px;
        text-align: center;
        font-size: 24px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-stretch: condensed;
        margin: 0px auto 0px auto;
        color: #333333;
    }

    .lc-section-container1-content {
        width: 90%;
        height: 90px;
        margin: 25px auto 0px auto;
        /* background-color: blanchedalmond; */
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: center;
    }


    .active-search-container {
        width: 95%;
        height: 40px;
        background: #FFFFFF;
        border: 0.6px solid rgb(0, 131, 188, 0.5);
        border-radius: 6px;
        opacity: 1;
        margin: 20px auto 0px auto;
        display: flex;
        position: relative;
    }

    .inactive-search-container {
        border: 0.6px solid rgb(191, 191, 191, 0.5);
        width: 95%;
        height: 40px;
        background: #FFFFFF;
        border-radius: 6px;
        opacity: 1;
        margin: 20px auto 0px auto;
        display: flex;
        position: relative;
    }

    .search-container img:first-child {
        width: 18px;
        height: 18px;
        margin: auto 0px auto 16px;
    }

    .search-container .search-close-button {
        width: 12px;
        height: 12px;
        margin: auto 10px auto 5px;
    }

    .active-search-container input {
        width: 80%;
        height: 18px;
        margin: auto 0px auto 10px;
        font-size: 14px;
        padding: 0px 0px 0px 5px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgb(0, 131, 188, 0.25);

    }

    .inactive-search-container input {
        width: 80%;
        height: 18px;
        margin: auto 0px auto 10px;
        font-size: 14px;
        padding: 0px 0px 0px 5px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgb(191, 191, 191, 0.5);

    }


    .lc-section-container1-search-content-div {
        width: 90% !important;
        height: auto;
        margin: 0px auto 0px auto;
        background-color: #333333;
        display: flex;
        justify-content: center;
    }

    .lc-section-container1-search-results {
        width: 84.5% !important;
        height: 395px;
        background-color: #ffffff;
        border: 0.6px solid rgb(0, 131, 188, 0.5);
        margin: 0px auto 0px auto;
        z-index: 1;
        position: absolute;
        border-radius: 0px 0px 8px 8px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0px 0px 0px 0px;

    }

    .search-results-items-container {
        width: 100%;
        height: auto;
        /* border: 2px solid rgb(182, 104, 2); */
        background: #ffffff;
        color: #000000;
        display: flex;
        flex-direction: column;
    }

    .search-results-item {
        width: 99% !important;
        height: auto !important;
        margin: 5px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        /* background-color: blue */
    }

    .search-results-item-title {
        width: 90%;
        height: auto;
        text-align: left;
        margin: 0px 0px 0px 21px !important;
        display: inline-flex;
        cursor: pointer;
    }

    .search-results-item-title p:hover {
        color: #0578FF;
        text-decoration: underline;
    }

    .search-results-item-title img {
        width: 12px !important;
        height: 12px !important;
        margin: 0px !important;
    }

    .search-results-item-title p {
        width: 85% !important;
        height: auto !important;
        text-overflow: clip;
        overflow: hidden;
        white-space: nowrap;
        margin: 0px 0px 0px 10px !important;
        font-size: 13px !important;
        font-weight: 600;
        letter-spacing: 0.13px;
        line-height: 19px;
        color: #000000;
        opacity: 1;
    }

    .last-updated-div {
        /* display: none; */
        width: 200px;
        height: 38px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        /* border: 1px solid #BFBFBF; */
    }

    .last-updated-div p {
        width: auto;
        height: 14px;
        text-align: right;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.11px;
        line-height: 16.5px;
        color: #333333;
        opacity: 1;
        margin: 0px;
    }

    .last-updated-div a {
        color: #0083BC;
        text-decoration: none;
    }

    .last-updated-div a:hover {
        text-decoration: underline;
    }

    .last-updated-div p:last-child {
        margin: 0px 0px 0px -2px;
    }

    .lc-section-container2 {
        width: 100%;
        height: auto;
        /* background-color: burlywood; */
        margin: 15px 0px 0px 0px;
        display: flex;
    }

    .responsive-sidebar-div {
        position: fixed;
        margin: 0px;
        z-index: 1;
        height: auto;
        opacity: 1;
        display: flex;
        /* overflow-y: scroll; */
        /* height: 100%; */
    }

    .opened-sidebar {
        margin: -125px 0px 0px 0px !important;
        width: 290px !important;

    }

    .closed-sidebar {
        height: 420px;
        width: 45px;
    }

    .toggle-sidebar-responsive {
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        margin: 0px;
    }

    .plus {
        width: 40px !important;
        height: 40px;
        margin: -95px 0px 0px -2px;
        padding: 0px 0px 0px 0px;
    }

    .plus img {
        width: 30px;
        height: 30px;
    }

    .minus {
        width: 40px !important;
        height: 40px;
        margin: auto 0px auto -7.5px;
        padding: 0px 0px 0px 0px;
    }

    .minus img {
        width: 40px;
        height: 40px;
    }


    .lc-section-container2-sidebar {
        /* display: none; */
        width: 245px;
        height: auto;
        margin: 0px;


        /* background-color: aquamarine; */
    }

    .opened-sidebar-container {
        display: flex;
        width: 255px;
        margin: 0px;

    }

    .closed-sidebar-container {
        display: none;
    }

    .sidebar-menu-list {
        width: 100%;
        height: auto;
        margin: 0px;

    }

    .lc-section-container2-content-box {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .learning-center-main-container {
        width: 100%;
        height: auto;
        padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        background-color: #F8F8F8;
        font-family: "Catamaran";
    }

    .learning-center-actual-section-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #F8F8F8;
    }

    .lc-section-container1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        /* border: 1px solid #000; */
    }

    .lc-section-container1-title {
        width: 100%;
        height: auto;
        margin: 33px auto 0px auto;
    }

    .lc-section-container1-title p {
        width: auto;
        height: auto;
        text-align: center;
        font-size: 36px;
        letter-spacing: 0.36px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-stretch: condensed;
        margin: 0px auto 0px auto;
        color: #333333;
        cursor: pointer;
    }

    .lc-section-container1-content {
        width: 100%;
        height: auto;
        margin: 51px auto 0px auto;
        display: flex;
        justify-content: space-evenly;
        /* border: 1px solid #000; */
    }

    .search-container {
        width: 58%;
        height: 52px;
        background: #FFFFFF;
        /* border: 0.6px solid rgb(0, 131, 188,0.5); */
        border-radius: 8px;
        opacity: 1;
        margin: 0px auto 0px 0px;
        display: flex;
        /* border: 1px solid #000; */
    }

    .active-search-container {
        width: 58%;
        height: 52px;
        background: #FFFFFF;
        border: 0.6px solid rgb(0, 131, 188, 0.5);
        border-radius: 8px;
        opacity: 1;
        margin: 0px auto;
        display: flex;
    }

    .inactive-search-container {
        border: 0.6px solid rgb(191, 191, 191, 0.5);
        width: 58%;
        height: 52px;
        background: #FFFFFF;
        border-radius: 8px;
        opacity: 1;
        margin: auto 0px auto 35px;
        display: flex;
    }

    .search-container img:first-child {
        width: 27px;
        height: 27px;
        margin: 13px 0px 0px 16px;
        cursor: pointer;
    }

    .active-search-container .search-close-button {
        width: 21px;
        height: 21px;
        margin: auto 10px auto 15px;
        cursor: pointer;
    }

    .active-search-container input {
        width: 83%;
        height: 25px;
        margin: 12px 0px 0px 10px;
        font-size: 18px;
        padding: 0px 0px 0px 10px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgb(0, 131, 188, 0.25);

    }

    .inactive-search-container input {
        width: 83%;
        height: 25px;
        margin: 12px 0px 0px 10px;
        font-size: 18px;
        padding: 0px 0px 0px 10px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgb(191, 191, 191, 0.5);

    }

    .last-updated-div {
        width: 40%;
        height: auto;
        margin: 0px 35px 0px auto;
        /* border: 1px solid #BFBFBF; */
    }

    .last-updated-div p {
        width: 100%;
        height: auto;
        text-align: right;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #333333;
        opacity: 1;
        margin: 0px 0px 0px 0px;
    }

    .last-updated-div a {
        color: #0083BC;
        cursor: pointer;
        text-decoration: none;
    }

    .last-updated-div a:hover {
        text-decoration: underline;
    }

    .last-updated-div p:last-child {
        margin: 0px;
    }

    .lc-section-container1-search-content-div {
        width: 59%;
        height: auto;
        /* margin: 0px auto 0px auto; */
    }

    .lc-section-container1-search-results {
        width: 58%;
        height: 440px;
        background-color: #ffffff;
        border: 0.6px solid rgb(0, 131, 188, 0.5);
        margin: -3px 0px 0px 35px;
        z-index: 1;
        position: absolute;
        border-radius: 0px 0px 8px 8px;
        overflow-y: scroll;
        overflow-x: hidden;

    }

    .search-results-items-container {
        width: 100%;
        height: auto;
        /* border: 2px solid rgb(182, 104, 2); */
        background: #ffffff;
        color: #000000;
        display: flex;
        flex-direction: column;
    }

    .search-results-item {
        width: 100%;
        height: 20px;
        margin: 10px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        /* background-color: blue */
    }

    .search-results-item-title {
        width: 100%;
        height: 20px;
        text-align: left;
        margin: 0px 0px 0px 32px;
        display: inline-flex;
        align-items: center;
        align-content: center;
        cursor: pointer;
    }

    .search-results-item-title p:hover {
        color: #0578FF;
        text-decoration: underline;
    }

    .search-results-item-title img {
        width: 15px;
        height: 15px;
        margin: 0px;
    }

    .search-results-item-title p {
        width: 90%;
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 11px 0px 15px 10px;
        font-size: 16px;
        font-weight: 600;
        font: normal normal medium 16px/20px Helvetica Neue;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .lc-section-container2 {
        width: 100%;
        height: auto;
        /* background-color: burlywood; */
        margin: 15px auto;
        display: flex;
        /* border: 1px solid #000000; */
    }

    .responsive-sidebar-div {
        position: fixed;
        margin: 0px 0px 0px 0px;
        z-index: 1;
        height: auto;
        opacity: 1;
        display: flex;
    }

    .opened-sidebar {
        margin: -150px 0px 0px 0px !important;
        width: 300px !important;
        height: auto;
    }

    .closed-sidebar {
        height: 420px;
        width: 50px;
    }

    .toggle-sidebar-responsive {
        height: 100%;
        width: 60px;
        display: flex;
        align-items: center;
        margin: 0px;
    }

    .plus {
        width: 60px !important;
        height: 60px;
        margin: 0px 0px 0px -2px;
        padding: 0px 0px 0px 0px;
    }

    .plus img {
        width: 50px;
        height: 50px;
    }

    .minus {
        width: 50px !important;
        height: 50px;
        margin: auto 0px auto -7.5px;
        padding: 0px 0px 0px 0px;
    }

    .minus img {
        width: 50px;
        height: 50px;
    }


    /* .lc-section-container2-sidebar {
        width: 232px;
        height: auto;
        margin: 0px;
        display: flex;
    } */

    .opened-sidebar-container {
        display: flex;
        width: 400px;
        margin: 0px;

    }

    .closed-sidebar-container {
        display: none;
    }

    .sidebar-menu-list {
        width: 100%;
        height: auto;
        margin: 0px;

    }

    .lc-section-container2-content-box {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
    }

    .lc-section-container2-content-box {
        width: 80%;
        height: auto;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .learning-center-main-container {
        width: 100%;
        height: auto;
        padding: 0px 0px 0px 0px !important;
        margin: 0px 0px 0px 0px !important;
        display: flex;
        flex-direction: column;
        background-color: #F8F8F8;
        font-family: "Catamaran";
    }

    .learning-center-actual-section-container {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto !important;
        border-right: none;
        border-left: none;
        background-color: #F8F8F8;
    }

    .lc-section-container1 {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        /* border: 1px solid #000; */
    }

    .lc-section-container1-title {
        width: 100%;
        height: auto;
        margin: 33px auto 0px auto;
    }

    .lc-section-container1-title p {
        width: auto;
        height: auto;
        text-align: center;
        font-size: 36px;
        letter-spacing: 0.36px;
        font-family: "Helvetica Neue Condensed Bold", sans-serif;
        font-stretch: condensed;
        margin: 0px auto 0px auto;
        color: #333333;
        cursor: pointer;
    }

    .lc-section-container1-content {
        width: 100%;
        height: auto;
        margin: 51px auto 0px auto;
        display: flex;
        justify-content: space-evenly;
        /* border: 1px solid #000; */
    }

    .search-container {
        width: 58%;
        height: 52px;
        background: #FFFFFF;
        /* border: 0.6px solid rgb(0, 131, 188,0.5); */
        border-radius: 8px;
        opacity: 1;
        margin: 0px auto 0px 0px;
        display: flex;
        /* border: 1px solid #000; */
    }

    .active-search-container {
        width: 58%;
        height: 52px;
        background: #FFFFFF;
        border: 0.6px solid rgb(0, 131, 188, 0.5);
        border-radius: 8px;
        opacity: 1;
        margin: 0px auto;
        display: flex;
    }

    .inactive-search-container {
        border: 0.6px solid rgb(191, 191, 191, 0.5);
        width: 58%;
        height: 52px;
        background: #FFFFFF;
        border-radius: 8px;
        opacity: 1;
        margin: auto 0px auto 35px;
        display: flex;
    }

    .search-container img:first-child {
        width: 27px;
        height: 27px;
        margin: 13px 0px 0px 16px;
        cursor: pointer;
    }

    .active-search-container .search-close-button {
        width: 21px;
        height: 21px;
        margin: auto 10px auto 15px;
        cursor: pointer;
    }

    .active-search-container input {
        width: 83%;
        height: 25px;
        margin: 12px 0px 0px 10px;
        font-size: 18px;
        padding: 0px 0px 0px 10px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgb(0, 131, 188, 0.25);

    }

    .inactive-search-container input {
        width: 83%;
        height: 25px;
        margin: 12px 0px 0px 10px;
        font-size: 18px;
        padding: 0px 0px 0px 10px;
        outline: none;
        border: none;
        border-bottom: 2px solid rgb(191, 191, 191, 0.5);

    }

    .last-updated-div {
        width: 40%;
        height: auto;
        margin: 0px 35px 0px auto;
        /* border: 1px solid #BFBFBF; */
    }

    .last-updated-div p {
        width: 100%;
        height: auto;
        text-align: right;
        /* font: normal normal medium 16px/24px Helvetica Neue; */
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #333333;
        opacity: 1;
        margin: 0px 0px 0px 0px;
    }

    .last-updated-div a {
        color: #0083BC;
        cursor: pointer;
        text-decoration: none;
    }

    .last-updated-div a:hover {
        text-decoration: underline;
    }

    .last-updated-div p:last-child {
        margin: 0px;
    }

    .lc-section-container1-search-content-div {
        width: 59%;
        height: auto;
        /* margin: 0px auto 0px auto; */
    }

    .lc-section-container1-search-results {
        width: 58%;
        height: 440px;
        background-color: #ffffff;
        border: 0.6px solid rgb(0, 131, 188, 0.5);
        margin: -3px 0px 0px 35px;
        z-index: 1;
        position: absolute;
        border-radius: 0px 0px 8px 8px;
        overflow-y: scroll;
        overflow-x: hidden;

    }

    .search-results-items-container {
        width: 100%;
        height: auto;
        /* border: 2px solid rgb(182, 104, 2); */
        background: #ffffff;
        color: #000000;
        display: flex;
        flex-direction: column;
    }

    .search-results-item {
        width: 100%;
        height: 20px;
        margin: 10px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        /* background-color: blue */
    }

    .search-results-item-title {
        width: 100%;
        height: 20px;
        text-align: left;
        margin: 0px 0px 0px 32px;
        display: inline-flex;
        align-items: center;
        align-content: center;
        cursor: pointer;
    }

    .search-results-item-title p:hover {
        color: #0578FF;
        text-decoration: underline;
    }

    .search-results-item-title img {
        width: 15px;
        height: 15px;
        margin: 0px;
    }

    .search-results-item-title p {
        width: 90%;
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 11px 0px 15px 10px;
        font-size: 16px;
        font-weight: 600;
        font: normal normal medium 16px/20px Helvetica Neue;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .lc-section-container2 {
        width: 100%;
        height: auto;
        /* background-color: burlywood; */
        margin: 15px auto;
        display: flex;
        /* border: 1px solid #000000; */
    }

    .responsive-sidebar-div {
        position: fixed;
        margin: 0px 0px 0px 0px;
        z-index: 1;
        height: auto;
        opacity: 1;
        display: flex;
    }

    .opened-sidebar {
        margin: -150px 0px 0px 0px !important;
        width: 300px !important;
        height: auto;
    }

    .closed-sidebar {
        height: 420px;
        width: 50px;
    }

    .toggle-sidebar-responsive {
        height: 100%;
        width: 60px;
        display: flex;
        align-items: center;
        margin: 0px;
    }

    .plus {
        width: 60px !important;
        height: 60px;
        margin: 0px 0px 0px -2px;
        padding: 0px 0px 0px 0px;
    }

    .plus img {
        width: 50px;
        height: 50px;
    }

    .minus {
        width: 50px !important;
        height: 50px;
        margin: auto 0px auto -7.5px;
        padding: 0px 0px 0px 0px;
    }

    .minus img {
        width: 50px;
        height: 50px;
    }


    /* .lc-section-container2-sidebar {
        width: 232px;
        height: auto;
        margin: 0px;
        display: flex;
    } */

    .opened-sidebar-container {
        display: flex;
        width: 400px;
        margin: 0px;

    }

    .closed-sidebar-container {
        display: none;
    }

    .sidebar-menu-list {
        width: 100%;
        height: auto;
        margin: 0px;

    }

    .lc-section-container2-content-box {
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
    }

    .lc-section-container2-content-box {
        width: 80%;
        height: auto;
        margin: 0px auto 0px auto;
        background-color: #FFFFFF;
    }
}