::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
}



.App {
  height: 100% !important;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  position: relative;
}

/* .empty-head {
  margin: 0px auto 0px auto !important;
  height: 64px !important;
  width: 100% !important;
  background-color: #2c4e6c !important;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: -1;
} */

.browser-router-container {
  margin: 0px auto 0px auto;
  height: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.head {
  margin: 0px auto 0px auto !important;
  height: 64px !important;
  width: 100% !important;
  background: #2c4e6c !important;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.header-container {
  height: 64px;
  width: 100% !important;
  background: #2c4e6c;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.navbar {
  height: 64px;
  width: 1080px !important;
  padding: 0px;
  margin: 0px auto 0px auto !important;
}

.borders {
  border-right: 1px solid rgb(144, 144, 144, 0.5);
  border-left: 1px solid rgb(144, 144, 144, 0.5);
}

.no-borders {
  border: none;
}

.navbar-container {
  width: 100%;
  height: 64px;
  margin: 0px auto 0px auto;
  padding: 0px 0px 0px 0px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.title-logo {
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: auto 0px auto 20px;
}

.title-logo img {
  width: 50px;
  height: 51px;
}

.navbar-brand {
  width: auto;
  height: auto;
  font-family: Impact, Regular !important;
  font-size: 32px;
  color: #9ec7d8;
  padding: 0px 0px 0px 0px;
  margin: auto 0px;
  text-align: center;
  cursor: pointer;
}

.page {
  width: auto;
  height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.page1 {
  margin: 0px 0px 0px 124px;
}

.page2,
.page3,
.page4 {
  margin: 0px 0px 0px 71px;
}

.page3 {
  margin: 0px 0px 0px 73px;
}

.page4 {
  margin: 0px 0px 0px 72px;
}

.page5 {
  margin: 0px 0px 0px 72px;
}

.page p {
  width: auto;
  font-family: "Catamaran";
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #e7ecf0;
  text-align: center !important;
  margin: 19px auto 0px auto !important;
  cursor: pointer;
}

.active-page p {
  width: 100%;
  /* font-weight: 600 !important; */
  font-size: 18px !important;
  color: #ffffff;
  text-align: center !important;
  cursor: pointer;
  margin: 19px auto 0px auto !important;
}

.img {
  display: none;
}

.active-page-indicator {
  width: 25px;
  height: 10px;
  display: flex;
  align-items: flex-end;
  margin: 0px auto 0px auto;
}

.navbar-mobile-container {
  display: none;
}

@media only screen and (max-width: 442px) {
  .App {
    max-width: 442px !important;
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    position: relative;
    /* background-color: #2c4e6c !important; */
  }

  /* .empty-head {
    max-width: 432px !important;
    margin: 0px auto 0px auto !important;
    height: 64px !important;
    width: 100% !important;
    background-color: #2c4e6c !important;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: -1;
  } */

  .browser-router-container {
    margin: 0px auto 0px auto;
    height: auto !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .head {
    margin: 0px auto 0px auto !important;
    height: 64px !important;
    width: 100% !important;
    background: #2c4e6c !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }

  .header-container {
    height: 64px;
    width: 100% !important;
    background: #2c4e6c;
    display: flex;
    margin: 0px auto;
  }

  .navbar {
    height: 64px;
    width: 100% !important;
    padding: 0px;
    margin: 0px auto 0px auto !important;
    border: none;
    display: flex !important;
    justify-content: center;
  }

  .navbar-container {
    display: none;
  }

  .navbar-mobile-container {
    width: 100% !important;
    height: auto;
    margin: 0 auto !important;
    padding: 0;
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between !important;
    /* border: 1px solid #FFFFFF; */
  }

  .best-budget-div {
    width: 85% !important;
    /* border: 1px solid #FFFFFF; */
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px auto 0px auto !important;
  }

  .navbar-brand {
    width: 150px;
    height: 35px;
    background-image: url("./Assets/bestBudgetBrandIcon.png");
    background-size: 100% 100%;
    margin: 0px auto 0px auto !important;
    cursor: pointer;
  }

  .toggle-button-div {
    /* border: 1px solid #FFFFFF; */
    width: 15% !important;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px auto;
  }

  .toggle-button-div img {
    margin: 0px 20px 0px 0px !important;
    width: 25px;
    height: 25px;
  }

  .mobile-nav {
    width: 99%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: auto;
  }

  .mobile-nav-menu-div {
    margin: 0px 20px 0px 0px !important;
    position: absolute;
    z-index: 2 !important;
    width: 164px;
    height: auto;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 4px #d0d0d0;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
  }

  .nav-item {
    width: 144px;
    height: 23px;
    text-align: left;
    color: #333333;
    margin: 10px 0px 0px 7px;
    padding: 0px 0px 0px 10px;
    font-size: 12px;
    letter-spacing: 0.12px;
    font-family: Catamaran;
    font-weight: bold !important;
  }

  .nav-item:last-child {
    padding-bottom: 10px;
  }

  .nav-item:hover {
    background-color: #edf3f5;
    border-radius: 4px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

  .App {
    max-width: 1024px;
    min-width: 768px;
    height: 100% !important;
    width: auto;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    position: relative;
  }

  /* .empty-head {
    margin: 0px auto 0px auto !important;
    height: 64px !important;
    width: 100% !important;
    background-color: #2c4e6c !important;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: -1;
  } */

  .browser-router-container {
    margin: 0px auto 0px auto;
    height: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .head {
    margin: 0px auto 0px auto !important;
    height: 64px !important;
    width: 100% !important;
    background: #2c4e6c !important;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .header-container {
    height: 64px;
    width: 100% !important;
    background: #2c4e6c;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .navbar {
    height: 64px;
    width: 100% !important;
    padding: 0px;
    margin: 0px auto 0px auto !important;
  }

  .borders {
    border-right: none;
    border-left: none;
  }

  .no-borders {
    border: none;
  }

  .navbar-container {
    width: 100%;
    height: 64px;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .title-logo {
    width: auto !important;
    height: 100%;
    /* border: 1px solid #ffffff; */
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin: auto 0px auto 20px;
  }

  .title-logo img {
    width: 50px;
    height: 51px;
  }

  .navbar-brand {
    width: auto !important;
    height: auto;
    font-family: Impact, Regular !important;
    font-size: 24px;
    color: #9ec7d8;
    padding: 0px 0px 0px 0px;
    margin: auto 0px;
    text-align: center;
    cursor: pointer;
  }

  .page {
    width: auto;
    height: 100%;
    margin: 0px auto;
    /* border: 1px solid #ffffff; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .page1 {
    margin: 0px auto 0px auto;
  }

  .page2,
  .page3,
  .page4,
  .page5 {
    margin: 0px auto 0px auto;
  }


  .page p {
    width: auto;
    font-family: "Catamaran";
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #e7ecf0;
    text-align: center !important;
    margin: 19px auto 0px auto !important;
    cursor: pointer;
  }

  .active-page p {
    width: 100%;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #ffffff;
    text-align: center !important;
    cursor: pointer;
    margin: 19px auto 0px auto !important;
  }

  .img {
    display: none;
  }

  .active-page-indicator {
    width: 20px;
    height: 10px;
    display: flex;
    align-items: flex-end;
    margin: 0px auto 0px auto;
  }

  .navbar-mobile-container {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  .App {
    max-width: 1024px;
    min-width: 768px;
    height: 100% !important;
    width: auto;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    position: relative;
  }

  /* .empty-head {
    margin: 0px auto 0px auto !important;
    height: 64px !important;
    width: 100% !important;
    background-color: #2c4e6c !important;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: -1;
  } */

  .browser-router-container {
    margin: 0px auto 0px auto;
    height: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .head {
    margin: 0px auto 0px auto !important;
    height: 64px !important;
    width: 100% !important;
    background: #2c4e6c !important;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  .header-container {
    height: 64px;
    width: 100% !important;
    background: #2c4e6c;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .navbar {
    height: 64px;
    width: 100% !important;
    padding: 0px;
    margin: 0px auto 0px auto !important;
  }

  .borders {
    border-right: none;
    border-left: none;
  }

  .no-borders {
    border: none;
  }

  .navbar-container {
    width: 100%;
    height: 64px;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .title-logo {
    width: auto !important;
    height: 100%;
    /* border: 1px solid #ffffff; */
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin: auto 0px auto 20px;
  }

  .title-logo img {
    width: 50px;
    height: 51px;
  }

  .navbar-brand {
    width: auto !important;
    height: auto;
    font-family: Impact, Regular !important;
    font-size: 24px;
    color: #9ec7d8;
    padding: 0px 0px 0px 0px;
    margin: auto 0px;
    text-align: center;
    cursor: pointer;
  }

  .page {
    width: auto;
    height: 100%;
    margin: 0px auto;
    /* border: 1px solid #ffffff; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .page1 {
    margin: 0px auto 0px auto;
  }

  .page2,
  .page3,
  .page4,
  .page5 {
    margin: 0px auto 0px auto;
  }


  .page p {
    width: auto;
    font-family: "Catamaran";
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #e7ecf0;
    text-align: center !important;
    margin: 19px auto 0px auto !important;
    cursor: pointer;
  }

  .active-page p {
    width: 100%;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #ffffff;
    text-align: center !important;
    cursor: pointer;
    margin: 19px auto 0px auto !important;
  }

  .img {
    display: none;
  }

  .active-page-indicator {
    width: 20px;
    height: 10px;
    display: flex;
    align-items: flex-end;
    margin: 0px auto 0px auto;
  }

  .navbar-mobile-container {
    display: none;
  }
}