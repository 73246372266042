.blog-main-container {
    width: 100%;
    height: auto;
    background: #ffffff;
    /* background: linear-gradient(#ffffff, #7eb8cb, #2c4e6c); */
    margin: 0px 0px 0px 0px;
}

.blog-section1-container {
    width: 1000px;
    height: auto;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #000000; */

}

.blog-section1-content {
    width: 1000px;
    height: 400px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    background: rgb(214, 235, 235, 0.5);
}

.blog-section1-content-description {
    width: 848px;
    height: 220px;
    margin: 30px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid #000000; */
}

.blog-h2 {
    width: auto;
    height: auto;
    margin: 15px auto 0px auto;
}

.blog-title {
    width: auto;
    height: auto;
    font-family: Catamaran;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.32px;
    color: #000000;
    margin: 40px auto 0px auto;
    cursor: pointer;
}

.blog-title:hover {
    text-decoration: underline;
}

.blog-section1-content-desc-text {
    width: 430px;
    height: 220px;
    margin: 0px;
}

.blog-section1-content-desc-text p {
    width: 100%;
    height: auto;
    font-size: 20px;
    font-family: Catamaran;
    color: #000000;
    text-align: left;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0.2px;
}

.read-more-text {
    cursor: pointer;
    font-size: 20px;
    font-family: Catamaran;
    color: #6C71FA !important;
    text-align: left;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0.2px;
}

.read-more-text:hover {
    text-decoration: underline;
    color: #2c4e6c;
}


.blog-section1-content-image {
    width: 373px;
    height: 220px;
    margin: 0px;
}

.blog-section1-content-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.blog-section1-grid-container {
    width: 1000px;
    height: auto;
    /* border: 1px solid #000000; */
    margin: 80px auto 0px auto;
    display: grid;
    grid-template-rows: 2fr;
    grid-template-columns: 2fr 2fr 2fr;
    column-gap: 40px;
}

.grid {
    width: 300px;
    height: 380px;
    background-color: rgb(214, 235, 235, 0.5);
    margin: 0px auto 10px auto;
    cursor: pointer;
}

.related-topics-heading {
    width: 100%;
    height: auto;
    font-family: Catamaran;
    font-size: 36px;
    text-align: center;
    margin: 50px auto;
    text-decoration: underline;
}

.blog-grid1 img {
    width: 100%;
    height: 180px;
    border-radius: 0px !important;
    margin: 0px;
}

.blog-grid2 img {
    width: 100%;
    height: 180px;
    border-radius: 0px;
    margin: 0px;
}

.blog-grid3 img {
    width: 100%;
    height: 180px;
    border-radius: 0px;
    margin: 0px;
}

.blog-grid1 h5,
.blog-grid2 h5,
.blog-grid3 h5 {
    width: 90%;
    height: auto;
    font-family: Catamaran;
    margin: 10px auto;
    font-size: 20px;
    line-height: 25px;

}

.blog-grid1 p,
.blog-grid2 p,
.blog-grid3 p {
    width: 90%;
    height: auto;
    font-size: 16px;
    color: #1c1313;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 400;
    margin: 5px auto;
}

@media only screen and (max-width: 440px) {
    .blog-main-container {
        max-width: 440px;
        width: 100%;
        height: auto;
        /* background: linear-gradient(#ffffff, #7eb8cb, #2c4e6c); */
        margin: 0px 0px 0px 0px;
    }

    .blog-section1-container {
        width: 100%;
        height: auto;
        margin: 25px auto;
        display: flex;
        flex-direction: column;
    }

    .blog-section1-content {
        width: 94%;
        height: auto;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        /* border: 1px solid #000000; */
    }

    .blog-section1-content-description {
        width: 91%;
        height: auto;
        margin: 20px auto 0px auto;
        display: flex;
        flex-direction: column-reverse;
    }



    .blog-title {
        width: 90%;
        height: auto;
        font-family: Catamaran;
        font-weight: semibold;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.2px;
        color: #000000;
        margin: 20px auto 0px auto;
        cursor: pointer;
    }

    .blog-section1-content-desc-text {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 20px auto 0px auto;
        /* border: 2px solid #fdfdfd; */
    }

    .blog-section1-content-desc-text p {
        width: 100%;
        height: auto;
        font-size: 12px;
        font-family: Catamaran;
        color: #000000;
        text-align: left;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.12px;
        margin: 0px auto 5px auto;
    }

    .read-more-text {
        cursor: pointer;
        font-size: 12px;
        font-family: Catamaran;
        color: #6C71FA;
        text-align: left;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.12px;
        text-decoration: underline;
    }

    /* .read-more-text:hover {
        text-decoration: underline;
        color: #2c4e6c;
    } */


    .blog-section1-content-image {
        width: 90%;
        height: auto;
        display: flex;
        margin: 0px auto 0px auto;
    }

    .blog-section1-content-image img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .blog-section1-grid-container {
        width: 300px;
        height: auto;
        margin: 25px auto 0px auto;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr;
    }

    .grid {
        width: 300px;
        height: 350px;
        background-color: rgb(214, 235, 235, 0.5);
        margin: 0px auto 30px auto;
        cursor: pointer;
    }



    .blog-grid1 img {
        width: 100%;
        height: 165px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid2 img {
        width: 100%;
        height: 165px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid3 img {
        width: 100%;
        height: 165px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid1 h5,
    .blog-grid2 h5,
    .blog-grid3 h5 {
        width: 90%;
        height: auto;
        font-family: Catamaran;
        margin: 40px auto 0px auto;
        font-size: 16px;
    }

    .blog-grid1 p,
    .blog-grid2 p,
    .blog-grid3 p {
        width: 90%;
        height: auto;
        font-size: 12px;
        color: #1c1313;
        font-family: Catamaran;
        line-height: 18px;
        letter-spacing: 0.12px;
        font-weight: 500;
        margin: 5px auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .blog-main-container {
        width: 100%;
        height: auto;
        background: #ffffff;
        margin: 0px 0px 0px 0px;
    }

    .blog-section1-container {
        width: 92.5%;
        height: auto;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        /* border: 1px solid #000000; */

    }

    .blog-section1-content {
        width: 100%;
        height: auto;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        background: rgb(214, 235, 235, 0.5);
    }

    .blog-section1-content-description {
        width: 100%;
        height: auto;
        margin: 20px auto 0px auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        /* border: 1px solid #000000; */
    }

    .blog-h2 {
        width: auto;
        height: auto;
        margin: 15px auto 0px auto;
    }

    .blog-title {
        width: 80%;
        height: auto;
        text-align: center;
        font-family: Catamaran;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.32px;
        color: #000000;
        margin: 20px auto 0px auto;
        cursor: pointer;
    }

    .blog-title:hover {
        text-decoration: underline;
    }

    .blog-section1-content-desc-text {
        width: 80%;
        height: auto;
        margin: 0px auto;
    }

    .blog-section1-content-desc-text p {
        width: 100%;
        height: auto;
        font-size: 18px;
        font-family: Catamaran;
        color: #000000;
        text-align: left;
        font-weight: normal;
        line-height: 27px;
        letter-spacing: 0.18px;
    }

    .read-more-text {
        cursor: pointer;
        font-size: 18px;
        font-family: Catamaran;
        color: #6C71FA !important;
        text-align: left;
        font-weight: normal;
        line-height: 27px;
        letter-spacing: 0.18px;
    }

    .read-more-text:hover {
        text-decoration: underline;
        color: #2c4e6c;
    }


    .blog-section1-content-image {
        width: 80%;
        height: 326px;
        margin: 0px auto;
    }

    .blog-section1-content-image img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .blog-section1-grid-container {
        width: 650px;
        height: auto;
        /* border: 1px solid #000000; */
        margin: 40px auto 0px auto;
        display: grid;
        grid-template-rows: 2fr 2fr;
        grid-template-columns: 2fr 2fr;
        column-gap: 40px;
        row-gap: 40px;
    }

    .grid {
        width: 300px;
        height: 380px;
        background-color: rgb(214, 235, 235, 0.5);
        margin: 0px auto 10px auto;
        cursor: pointer;
    }

    .related-topics-heading {
        width: 100%;
        height: auto;
        font-family: Catamaran;
        font-size: 36px;
        text-align: center;
        margin: 50px auto;
        text-decoration: underline;
    }

    .blog-grid1 img {
        width: 100%;
        height: 180px;
        border-radius: 0px !important;
        margin: 0px;
    }

    .blog-grid2 img {
        width: 100%;
        height: 180px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid3 img {
        width: 100%;
        height: 180px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid1 h5,
    .blog-grid2 h5,
    .blog-grid3 h5 {
        width: 90%;
        height: auto;
        font-family: Catamaran;
        margin: 10px auto;
        font-size: 20px;
        line-height: 25px;

    }

    .blog-grid1 p,
    .blog-grid2 p,
    .blog-grid3 p {
        width: 90%;
        height: auto;
        font-size: 16px;
        color: #1c1313;
        font-family: Catamaran;
        line-height: 24px;
        letter-spacing: 0.16px;
        font-weight: 400;
        margin: 5px auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .blog-main-container {
        width: 100%;
        height: auto;
        background: #ffffff;
        margin: 0px 0px 0px 0px;
    }

    .blog-section1-container {
        width: 92.5%;
        height: auto;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        /* border: 1px solid #000000; */

    }

    .blog-section1-content {
        width: 100%;
        height: auto;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        background: rgb(214, 235, 235, 0.5);
    }

    .blog-section1-content-description {
        width: 100%;
        height: auto;
        margin: 20px auto 0px auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        /* border: 1px solid #000000; */
    }

    .blog-h2 {
        width: auto;
        height: auto;
        margin: 15px auto 0px auto;
    }

    .blog-title {
        width: 80%;
        height: auto;
        text-align: center;
        font-family: Catamaran;
        font-weight: bold;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.32px;
        color: #000000;
        margin: 20px auto 0px auto;
        cursor: pointer;
    }

    .blog-title:hover {
        text-decoration: underline;
    }

    .blog-section1-content-desc-text {
        width: 80%;
        height: auto;
        margin: 0px auto;
    }

    .blog-section1-content-desc-text p {
        width: 100%;
        height: auto;
        font-size: 18px;
        font-family: Catamaran;
        color: #000000;
        text-align: left;
        font-weight: normal;
        line-height: 27px;
        letter-spacing: 0.18px;
    }

    .read-more-text {
        cursor: pointer;
        font-size: 18px;
        font-family: Catamaran;
        color: #6C71FA !important;
        text-align: left;
        font-weight: normal;
        line-height: 27px;
        letter-spacing: 0.18px;
    }

    .read-more-text:hover {
        text-decoration: underline;
        color: #2c4e6c;
    }


    .blog-section1-content-image {
        width: 80%;
        height: 326px;
        margin: 0px auto;
    }

    .blog-section1-content-image img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .blog-section1-grid-container {
        width: 650px;
        height: auto;
        /* border: 1px solid #000000; */
        margin: 40px auto 0px auto;
        display: grid;
        grid-template-rows: 2fr 2fr;
        grid-template-columns: 2fr 2fr;
        column-gap: 40px;
        row-gap: 40px;
    }

    .grid {
        width: 300px;
        height: 380px;
        background-color: rgb(214, 235, 235, 0.5);
        margin: 0px auto 10px auto;
        cursor: pointer;
    }

    .related-topics-heading {
        width: 100%;
        height: auto;
        font-family: Catamaran;
        font-size: 36px;
        text-align: center;
        margin: 50px auto;
        text-decoration: underline;
    }

    .blog-grid1 img {
        width: 100%;
        height: 180px;
        border-radius: 0px !important;
        margin: 0px;
    }

    .blog-grid2 img {
        width: 100%;
        height: 180px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid3 img {
        width: 100%;
        height: 180px;
        border-radius: 0px;
        margin: 0px;
    }

    .blog-grid1 h5,
    .blog-grid2 h5,
    .blog-grid3 h5 {
        width: 90%;
        height: auto;
        font-family: Catamaran;
        margin: 10px auto;
        font-size: 20px;
        line-height: 25px;

    }

    .blog-grid1 p,
    .blog-grid2 p,
    .blog-grid3 p {
        width: 90%;
        height: auto;
        font-size: 16px;
        color: #1c1313;
        font-family: Catamaran;
        line-height: 24px;
        letter-spacing: 0.16px;
        font-weight: 400;
        margin: 5px auto;
    }
}