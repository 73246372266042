.main-container {
  width: 100%;
  height: auto !important;
  padding: 0px 0px 0px 0px !important;
  margin: 0px auto 0px auto !important;
}

.section {
  max-width: 1080px !important;
}

.first-page {
  width: 100% !important;
  height: auto;
  margin: 0px auto 0px auto !important;
  background: transparent linear-gradient(257deg, #000000 0%, #0d2738 100%);
}

.fixed-banner {
  background-color: #eacf21;
  /* Background color */
  color: rgb(0, 0, 0);
  /* Text color */
  padding: 10px 0px;
  text-align: center;
  margin: 0px auto 0px auto;
  position: relative;
  z-index: 2 !important;
  /* Fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  /* Space between content and close button */
  align-items: center;
  /* Ensures the banner is on top */
}

.fixed-banner span {
  margin: 0px 100px;
}

.fixed-banner a {
  color: #ef0909;
  /* Link color */
  text-decoration: none;
  font-size: 16px;
}

.fixed-banner a:hover {
  color: #ffffff;
  /* text-shadow: 0 0 5px #ff0, 0 0 10px #ff0, 0 0 20px #ff0; */
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: 0px;
}


.section-container1 {
  padding: 0;
  width: 1080px;
  height: 658px;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  margin: 0px auto 0px auto !important;
  display: flex;
  flex-direction: row;
  padding: 0px 0px 0px 0px;
  position: relative;
}

.section1-part1 {
  width: 677px;
  height: 653px;
  display: flex;
  flex-direction: column;
  margin: 60px 0px 0px 0px;
}

.section1-title {
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  font-size: 46px;
  margin: 0px 0px 0px 47px;
  text-align: left;
  width: 519px;
  height: auto;
  color: #D6D6D6;
}

.section1-description {
  width: 550px;
  height: auto;
  margin: 13px 0px 0px 49px;
  display: flex;
  flex-direction: column;
}

.section1-description p {
  margin: 0px 0px 20px 8px;
  width: 550px;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  color: #D0D0D0;
  padding: 0px 0px 0px 0px;
  opacity: 1;
}

.section1-description p:last-child {
  margin: 0px 0px 0px 8px;
}

.section1-start-div {
  width: 550px;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 35px 0px 0px 50px;
}

.section1-start-div button {
  width: 200px;
  height: 55px;
  background-color: #427bac;
  color: #ffffff;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  letter-spacing: 0.18px;
  cursor: pointer;
  margin: 0px auto 0px auto;
}

.section1-start-div button:hover {
  color: #FFFFFF;
  background-color: #48588D;
  border: none;
}

.section1-footer-animation {
  width: 494px;
  height: 182px;
  margin: 76px 0px 0px 132px;
  text-align: center;
  color: #000;
}

.section1-footer-animation img {
  width: 100%;
  height: 182px;
}

.section1-part1-border {
  width: 650px;
  height: 0px;
  background-color: transparent;
  border-bottom: 0.12px solid rgb(255, 255, 255, 0.15);
  margin: 0px 0px 0px 50px;
}

.section1-part2 {
  width: 420px;
  height: 583px;
  display: flex;
  flex-direction: column;
  margin: 60px 0px 0px 0px;
}

.section1-mobile-animation {
  width: 287px;
  height: 542px;
  overflow: hidden;
  margin: 0px 0px 0px 13px;
  text-align: center;
  opacity: 1;
}

.section1-bottom-tray-shape {
  width: 250px;
  height: 33px;
  background-color: #100C0C;
  border-radius: 60%;
  margin: 10px 0px 0px 33px;
}

.section-container2 {
  width: 1080px !important;
  overflow-x: hidden;
  height: auto;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  margin: 0px auto 0px auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px 0px;
}

.section2-title {
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  font-size: 36px;
  color: #ffffff;
  margin: 120px auto 0px auto;
  width: 460px;
  height: 42px;
  letter-spacing: 0.36px;
  word-spacing: 2px;
  text-align: center;
}

.section2-grid-container {
  display: grid;
  grid-template-rows: 2fr 2fr 2fr;
  grid-template-columns: 2fr 2fr 2fr;
  width: 982px;
  height: 860px;
  margin: 100px auto 0px auto;
}

.section2-grid-element1,
.section2-grid-element2,
.section2-grid-element3,
.section2-grid-element4,
.section2-grid-element5,
.section2-grid-element6,
.section2-grid-element7,
.section2-grid-element8,
.section2-grid-element9 {
  width: 300px;
  height: 268px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
}

.grid-element1-img-container,
.grid-element2-img-container,
.grid-element3-img-container,
.grid-element4-img-container,
.grid-element5-img-container,
.grid-element6-img-container,
.grid-element7-img-container,
.grid-element8-img-container,
.grid-element9-img-container {
  width: 300px !important;
  height: 75px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start;
  border: none !important;
  border-radius: none !important;
  margin: 0px !important;
}

.dashboard-icon {
  width: 75px !important;
  height: 75px;
  margin: 0px;
}

.grid-element1-title,
.grid-element2-title,
.grid-element3-title,
.grid-element4-title,
.grid-element5-title,
.grid-element6-title,
.grid-element7-title,
.grid-element8-title,
.grid-element9-title {
  width: 300px;
  height: 27px !important;
  margin: 15px 0px 0px 0px !important;
  border-radius: none !important;
  font-family: "Catamaran";
  font-weight: bold;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 0.24px;
  padding: 0px 0px 0px 0px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.grid-element1-title-text,
.grid-element2-title-text,
.grid-element3-title-text,
.grid-element4-title-text,
.grid-element5-title-text,
.grid-element6-title-text,
.grid-element7-title-text,
.grid-element8-title-text,
.grid-element9-title-text {
  width: 100%;
  margin: 0px;
  color: #ffffff !important;
  padding: 0px 0px 0px 0px !important;
}

.grid-element1-description,
.grid-element2-description,
.grid-element3-description,
.grid-element4-description,
.grid-element5-description,
.grid-element6-description,
.grid-element7-description,
.grid-element8-description,
.grid-element9-description {
  width: 300px;
  height: 122px;
  font-family: "Catamaran";
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #ffffff;
  margin: 8px 0px 0px 0px;
  text-align: left;
  line-height: 27px;
}

.section-container3 {
  background-color: #ffffff;
  padding: 0;
  width: 1080px;
  height: 1216px;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  margin: 57px auto 0px auto !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.section3-title {
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  font-size: 36px;
  color: #333333;
  margin: 0px auto 0px auto;
  width: 582px;
  height: 42px;
  text-align: center;
  letter-spacing: 0.36px;
}

.section3-part1 {
  width: 1000px;
  height: 350px;
  margin: 40px auto 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #9a9a88;
  box-shadow: inset 0px 3px 6px #00000029;
}

.section3-part1-img-container {
  width: 428px;
  height: 279px;
  margin: 35px 0px 0px 43px;
}

.section3-part1-img-container img {
  width: 100%;
  height: 100%;
}

.section3-part1-text-div {
  width: 457px;
  height: 170px;
  margin: 37px 0px 0px 35px;

}

.section3-part1-text-div-title {
  width: 457px;
  height: auto;
  margin: 0px 0px 16px 0px;

}

.section3-part1-text-div-title p {
  width: 457px;
  height: auto;
  margin: 0px;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.32px;
  line-height: 48px;
  color: #000000;
  opacity: 1;
}

.section3-part1-text-div-description {
  width: 448px;
  height: 132px;
  margin: 10px 0px 0px 6px;
}

.section3-part1-text-div-description p {
  margin: 0px 0px 0px 5px;
  width: auto;
  height: 132px;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #000000;
  opacity: 1;
  line-height: 30px;
}

.section3-part2 {
  width: 1000px;
  height: 350px;
  margin: 28px auto 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #aab9c4;
  box-shadow: inset 0px 3px 6px #00000029;
}

.section3-part2-img-container {
  width: 482px;
  height: 279px;
  margin: 71px 10px 0px 0px;
}

.section3-part2-img-container img {
  width: 100%;
  height: 100%;
}

.section3-part2-text-div {
  width: 500px;
  height: 140px;
  margin: 27px 0px 0px 30px;

}

.section3-part2-text-div-title {
  width: 476px;
  height: 40px;
  margin: 0px 0px 16px 0px;
}

.section3-part2-text-div-title p {
  margin: 0px;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.32px;
  color: #000000;
  opacity: 1;
}

.section3-part2-text-div-description {
  width: 448px;
  height: 82px;
  margin: 10px 0px 0px 6px;
}

.section3-part2-text-div-description p {
  margin: 0px 0px 0px 5px;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #000000;
  opacity: 1;
  line-height: 30px;
}

.section3-part3 {
  width: 1000px;
  height: 350px;
  margin: 28px auto 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #a68bb2;
  box-shadow: inset 0px 3px 6px #00000029;
}

.section3-part3-img-container {
  width: 497px;
  height: 326px;
  margin: 24px 0px 0px 0px;
  /* background-image: url("../Assets/LandingWebsite/nuclearFamily.webp");
  background-size: 497px 326px; */
}

.section3-part3-img-container img {
  width: 100%;
  height: 100%;
}

.section3-part3-text-div {
  width: 500px;
  height: 140px;
  margin: 24px 0px 0px 25px;
}

.section3-part3-text-div-title {
  width: 488px;
  height: 40px;
  margin: 0px 0px 16px 0px;
}

.section3-part3-text-div-title p {
  margin: 0px;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0.32px;
}

.section3-part3-text-div-description {
  width: 458px;
  height: 97px;
  margin: 10px 0px 0px 6px;
}

.section3-part3-text-div-description p {
  margin: 0px 0px 0px 5px;
  width: 458px;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #000000;
  opacity: 1;
  line-height: 30px;
}

.section-container4 {
  padding: 0;
  width: 1080px;
  height: 700px;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.section4-title {
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  font-size: 36px;
  color: #000000;
  margin: 60px auto 0px auto;
  width: 240px;
  height: 41px;
  text-align: center;
  letter-spacing: 0.36px;
  word-spacing: 2px;
}

.content-background-container {
  margin: 0px;
  width: 100%;
  height: 700px;
  position: absolute;
  z-index: -1;
  padding: 0px 0px 0px 0px;
  background-image: url("../Assets/LandingWebsite/backgroundMeshImage.png");
  background-size: 100% 700px;
  opacity: 0.5;
  display: none;
}

.actual-content {
  height: 440px;
  width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0px 0px 0px;
}

.actual-content-box-container {
  width: 1025px;
  height: 230px;
  margin: 0px;
  display: flex;
  flex-direction: row;
}

.actual-content-box1 {
  width: 245px;
  height: 245px;
  margin: 0px;
  background: #671E88;
  border-radius: 12px;
  opacity: 1;
}

.actual-content-box1-id {
  width: 36px;
  height: 36px;
  margin: 22px 25px 0px auto;
  display: flex;
  justify-content: end;
}

.actual-content-box1-id p {
  margin: 0px;
  height: 36px;
  text-align: center;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #FFFFFF;
  opacity: 1;
}

.actual-content-box1-title {
  width: auto;
  height: 27px;
  margin: 25px 0px 0px 25px;
}

.actual-content-box1-title p {
  text-align: left;
  margin: 0px auto 0px auto;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  color: #FFFFFF;
  letter-spacing: 0.24px;
}

.actual-content-box1-description {
  width: 200px !important;
  height: 37px;
  margin: 9px 0px 0px 25px;
  display: flex;
  justify-content: center;
}

.actual-content-box1-description p {
  text-align: left;
  width: 200px;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: 0.16px;
  font-size: 16px;
  color: #FFFFFF;
  opacity: 1;
  margin: 0px auto 0px auto;
  line-height: 24px;
}

.actual-content-box2 {
  width: 245px;
  height: 245px;
  margin: 0px 0px 0px 15px;
  background: #1E2288;
  border-radius: 12px;
  opacity: 1;
}

.actual-content-box2-id {
  width: 36px;
  height: 36px;
  margin: 22px 25px 0px auto;
  display: flex;
  justify-content: end;
}

.actual-content-box2-id p {
  margin: 0px;
  height: 36px;
  text-align: center;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #FFFFFF;
  opacity: 1;
}

.actual-content-box2-title {
  width: auto;
  height: 27px;
  margin: 25px 0px 0px 25px;
}

.actual-content-box2-title p {
  text-align: left;
  margin: 0px;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  font-weight: 600;
  color: #FFFFFF;
  letter-spacing: 0.24px;
  width: auto;
}

.actual-content-box2-description {
  width: 200px !important;
  height: 57px;
  margin: 9px 0px 0px 25px;
}

.actual-content-box2-description p {
  text-align: left;
  width: 200px !important;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #FFFFFF;
  opacity: 1;
  margin: 0px auto 0px auto;
  line-height: 24px;
}

.actual-content-box3 {
  width: 245px;
  height: 245px;
  margin: 0px 0px 0px 15px;
  background: #1E5B87;
  border-radius: 12px;
  opacity: 1;
}

.actual-content-box3-id {
  width: 36px;
  height: 36px;
  margin: 22px 25px 0px auto;
  display: flex;
  justify-content: end;
}

.actual-content-box3-id p {
  margin: 0px;
  height: 36px;
  text-align: center;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #FFFFFF;
  opacity: 1;
}

.actual-content-box3-title {
  width: auto;
  height: 27px;
  margin: 25px 0px 0px 25px;
}

.actual-content-box3-title p {
  text-align: left;
  margin: 0px;
  font-family: "Catamaran";
  font-weight: 600;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 0.24px;
}

.actual-content-box3-description {
  width: 200px !important;
  height: 57px;
  margin: 9px 0px 0px 25px;
}

.actual-content-box3-description p {
  text-align: left;
  font-family: "Catamaran";
  width: 200px !important;
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: 0.16px;
  font-size: 16px;
  color: #FFFFFF;
  opacity: 1;
  margin: 0px auto 0px auto;
  line-height: 24px;
}

.actual-content-box4 {
  width: 245px;
  height: 245px;
  margin: 0px 0px 0px 15px;
  background: #73730d;
  border-radius: 12px;
  opacity: 1;
}

.actual-content-box4-id {
  width: 36px;
  height: 36px;
  margin: 22px 25px 0px auto;
  display: flex;
  justify-content: end;
}

.actual-content-box4-id p {
  margin: 0px;
  height: 36px;
  text-align: center;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #FFFFFF;
  opacity: 1;
}

.actual-content-box4-title {
  width: 155px;
  height: 27px;
  margin: 25px 0px 0px 25px;
}

.actual-content-box4-title p {
  text-align: left;
  margin: 0px;
  font-family: "Catamaran";
  font-weight: bold;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0.24px;
}

.actual-content-box4-description {
  width: 200px !important;
  height: 77px;
  margin: 9px 0px 0px 25px;
}

.actual-content-box4-description p {
  margin: 0px;
  width: 200px !important;
  text-align: left;
  font-family: "Catamaran";
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: 0.16px;
  font-size: 16px;
  color: #FFFFFF;
  opacity: 1;
  margin: 0px auto 0px auto;
  line-height: 24px;
}

.section4-part2-container {
  width: 782px;
  height: 176px;
  margin: 60px auto 0px auto !important;
}

.section4-part2-title {
  width: 783px !important;
  height: 31px;
  margin: 0px auto 0px auto;
  display: flex;
  justify-content: center;
  padding: 0px 0px 0px 0px;
}

.section4-part2-title p {
  margin: 0px auto 0px auto;
  padding: 0px 0px 0px 0px;
  height: 31px;
  text-align: center !important;
  text-transform: uppercase;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  font-size: 32px;
  letter-spacing: 0.32px;
  background: transparent linear-gradient(268deg, #671E88 0%, #1E2287 29%, #1F5B87 64%, #87871F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-clip: text;
  word-spacing: 2px;
  letter-spacing: 2px;
}

.section4-part2-description {
  width: 700px;
  height: 82px;
  margin: 21px auto 0px auto;
}

.section4-part2-description p {
  margin: 0px auto 0px auto;
  text-align: center;
  font-family: "Catamaran";
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  opacity: 1;
  line-height: 30px;
}

.content-background-container2 {
  display: flex;
  margin: 0px;
  width: 100%;
  height: 633px;
  position: absolute;
  z-index: -1;
  padding: 0px 0px 0px 0px;
  background: transparent linear-gradient(180deg, #49434B 0%, #351D62 100%);
}

.section-container5 {
  padding: 0;
  width: 1080px;
  height: 633px;
  border-right: 1px solid rgb(191, 191, 191, 0.5);
  border-left: 1px solid rgb(191, 191, 191, 0.5);
  margin: 0px auto 0px auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section5-title {
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
  font-weight: bold;
  font-stretch: condensed;
  font-size: 36px;
  color: #FFFFFF;
  margin: 60px 0px 0px 0px;
  width: 706px;
  height: 41px;
  text-align: center;
  letter-spacing: 0.36px;
}

.section5-content-box {
  width: 99.8%;
  height: 360px;
  display: flex;
  flex-direction: row;
  margin: 40px auto 0px auto;
  border: 0.6px solid rgb(191, 191, 191, 0.5);
}

.section5-content-box1,
.section5-content-box2,
.section5-content-box3 {
  width: 33%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  opacity: 1;
}

.section5-content-box1,
.section5-content-box2 {
  border-right: 0.6px solid rgb(191, 191, 191, 0.5);
}

.section5-content-box1 img {
  width: 100px;
  height: 100px;
  margin: 30px auto 0px auto;
}

.section5-content-box2 img {
  width: 100px;
  height: 100px;
  margin: 30px auto 0px auto;
}


.section5-content-box3 img {
  width: 100px;
  height: 100px;
  margin: 30px auto 0px auto;
}


.section5-content-box1-title {
  font-family: "Catamaran";
  font-weight: 600;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px;
  color: #FFFFFF;
  margin: 25px 0px 0px 0px;
  width: 360px;
  height: 31px;
  text-align: center;
  letter-spacing: 0.24px;
}

.section5-content-box1-description {
  width: 300px;
  height: auto;
  font-size: 18px;
  font-family: "Catamaran";
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 12px 0px 0px 0px;
  text-align: center;
  letter-spacing: 0.18px;
  line-height: 27px;
  color: #FFFFFF;
}

.page-top-container {
  width: 100%;
  height: 28px;
  margin: 45px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.go-to-page-top {
  width: 45px;
  height: 32px;
  cursor: pointer;
  margin: 0px 16px 0px 0px;
  background-image: url("./../Assets/LandingWebsite/goToPageTopIcon.svg");
  background-size: 45px 32px;
}

.go-to-page-top:hover {
  background-image: url("./../Assets/LandingWebsite/goToPageTopDarkIcon.svg");
}

@media only screen and (max-width: 442px) {
  .main-container {
    max-width: 440px !important;
    height: auto !important;
    width: 100%;
  }

  .section {
    max-width: 100% !important;
  }

  .first-page {
    width: 100% !important;
    height: auto;
    background: transparent linear-gradient(180deg, #000000 0%, #000000 25%, #0D2738 100%);
  }

  .fixed-banner {
    background-color: #eacf21;
    /* Background color */
    color: rgb(0, 0, 0);
    /* Text color */
    padding: 10px 0px;
    text-align: center;
    margin: 0px auto 0px auto;
    position: relative;
    z-index: 10;
    /* Fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    /* Space between content and close button */
    align-items: center;
    /* Ensures the banner is on top */
  }

  .fixed-banner span {
    width: 100%;
    margin: 0px 10px;
    font-size: 12px;
  }

  .fixed-banner a {
    color: #ef0909;
    /* Link color */
    text-decoration: none;
    font-size: 12px;
  }

  .fixed-banner a:hover {
    color: #ffffff;
    /* text-shadow: 0 0 5px #ff0, 0 0 10px #ff0, 0 0 20px #ff0; */
  }

  .section-container1 {
    width: 100% !important;
    height: auto !important;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    /* border: 1px solid pink; */
  }

  .section1-part1 {
    width: 83%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 40px auto 0px auto;
    /* border: 1px solid pink; */
  }

  .section1-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 24px;
    color: #D6D6D6;
    margin: 0px auto 0px auto !important;
    text-align: center;
    width: auto;
    height: auto;
    letter-spacing: 0.24px;
  }

  .section1-description {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto !important;
    /* border: 1px solid #FFF; */

  }

  .section1-description p {
    width: 100%;
    height: auto;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    text-align: justify;
    font-size: 12px;
    color: #D0D0D0;
    line-height: 18px;
    letter-spacing: 0.12px;
    margin: 0px 0px 10px 0px;
  }

  .section1-description p:last-child {
    margin: 0px;
  }

  .section1-start-div {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    margin: 25px 0px 10px 0px;
  }

  .section1-start-div button {
    border: 1.2px solid #ffffff;
    width: 125px;
    height: 35px;
    background-color: #427bac;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section1-start-div button:hover {
    color: #FFFFFF;
    background-color: #48588D;
  }

  .section1-footer-animation {
    display: none;
  }

  .section1-part1-border {
    display: none;
  }

  .section1-part2 {
    margin: 40px auto 10px auto;
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .section1-mobile-animation {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    background-color: rgb(0, 0, 0.0.25);
    text-align: center;
  }

  .section1-mobile-animation video {
    width: 100% !important;
    height: auto !important;
    margin: 0px 0px 10px 0px;
  }

  .section1-bottom-tray-shape {
    display: none;
  }

  .bottom-layer {
    display: none;
  }

  .page-border {
    display: none;
  }

  .section-container2 {
    padding: 0;
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
  }

  .section2-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 24px;
    color: #FFFFFF;
    margin: 40px auto 0px auto;
    width: 100%;
    height: auto;
    letter-spacing: 0.24px;
    text-align: center;
  }

  .section2-grid-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    width: 85%;
    height: auto;
    justify-content: center;
    margin: 33px auto 0px auto;
    /* border: 1px solid pink; */
  }

  .section2-grid-element1,
  .section2-grid-element2,
  .section2-grid-element3,
  .section2-grid-element4,
  .section2-grid-element5,
  .section2-grid-element6,
  .section2-grid-element7,
  .section2-grid-element8,
  .section2-grid-element9 {
    width: 100%;
    height: auto;
    margin: 10px auto 0px auto;
    display: flex;
    padding: 0px;

    /* border: 1px solid #fff; */
  }

  .grid-element1-img-container,
  .grid-element2-img-container,
  .grid-element3-img-container,
  .grid-element4-img-container,
  .grid-element5-img-container,
  .grid-element6-img-container,
  .grid-element7-img-container,
  .grid-element8-img-container,
  .grid-element9-img-container {
    width: 100% !important;
    height: 31px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    border: none !important;
    border-radius: none !important;
    margin: 0px !important;
  }

  .dashboard-icon {
    width: 31px !important;
    height: 31px;
    margin: 0px;
  }

  .grid-element1-title,
  .grid-element2-title,
  .grid-element3-title,
  .grid-element4-title,
  .grid-element5-title,
  .grid-element6-title,
  .grid-element7-title,
  .grid-element8-title,
  .grid-element9-title {
    width: 100%;
    height: auto !important;
    margin: 5px 0px 0px 0px !important;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .grid-element1-title-text,
  .grid-element2-title-text,
  .grid-element3-title-text,
  .grid-element4-title-text,
  .grid-element5-title-text,
  .grid-element6-title-text,
  .grid-element7-title-text,
  .grid-element8-title-text,
  .grid-element9-title-text {
    color: #FFFFFF !important;
    width: 100%;
    margin: 0px;
    padding: 0px !important;
  }

  .grid-element1-description,
  .grid-element2-description,
  .grid-element3-description,
  .grid-element4-description,
  .grid-element5-description,
  .grid-element6-description,
  .grid-element7-description,
  .grid-element8-description,
  .grid-element9-description {
    width: 95%;
    height: auto;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0px 0px 0px 0px !important;
    text-align: left;
    line-height: 15px;
  }


  .section-container3 {
    background-color: #ffffff;
    padding: 0;
    width: 100%;
    height: auto;
    border: none;
    margin: 0px auto 0px auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid Red; */
  }

  .section3-title {
    font-family: "Helvetica Neue Condensed Bold";
    font-weight: bold;
    font-stretch: condensed;
    font-size: 24px;
    color: #000000;
    margin: 40px auto 0px auto;
    width: 100%;
    height: auto;
    text-align: center;
    letter-spacing: 0.24px;
  }

  .section3-part1 {
    width: 92.6%;
    height: auto;
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #9a9a88;
    box-shadow: inset 0px 3px 6px #00000029;

  }

  .section3-part1-img-container {
    width: 80%;
    height: auto;
    margin: 25px auto 0px auto;
  }

  .section3-part1-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part1-text-div {
    width: 95%;
    height: auto;
    margin: 15px auto 20px auto;

  }

  .section3-part1-text-div-title {
    width: 90%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .section3-part1-text-div-title p {
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 25px;
    color: #000000;
    opacity: 1;
    width: auto;
    height: auto;
  }

  .section3-part1-text-div-description {
    width: 90%;
    height: auto;
    margin: 8px auto 0px auto;
  }

  .section3-part1-text-div-description p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 12px;
    font-family: "Catamaran";
    font-weight: 500;
    font-optical-sizing: auto;
    font-style: normal;
    color: #000000;
    line-height: 18px;
    opacity: 1;
  }



  .section3-part2 {
    width: 92.6%;
    height: auto;
    margin: 25px auto 0px auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end !important;
    align-items: flex-start;
    background-color: #AAB9C4;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part2-img-container {
    width: 80%;
    height: auto;
    margin: 25px auto 0px auto;
  }

  .section3-part2-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part2-text-div {
    width: 95%;
    height: auto;
    margin: 15px auto 20px auto;
  }

  .section3-part2-text-div-title {
    width: 90%;
    height: auto;
    margin: 0px auto 16px auto;
  }

  .section3-part2-text-div-title p {
    margin: 0px auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 1;
  }

  .section3-part2-text-div-description {
    width: 90%;
    height: auto;
    margin: 12px auto 0px auto;
  }

  .section3-part2-text-div-description p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 12px;
    font-family: "Catamaran";
    font-weight: 500;
    font-optical-sizing: auto;
    font-style: normal;
    color: #000000;
    line-height: 18px;
    opacity: 1;
  }

  .section3-part3 {
    width: 92.6%;
    height: 365px;
    margin: 25px auto 0px auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: #A68BB2;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part3-img-container {
    width: 100%;
    height: auto;
    margin: 11px auto 0px auto;
  }

  .section3-part3-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part3-text-div {
    width: 95%;
    height: auto;
    margin: 15px auto 20px auto;
    /* border: 1px solid #000000; */
  }

  .section3-part3-text-div-title {
    width: 90%;
    height: auto;
    margin: 0px auto 0px auto;

  }

  .section3-part3-text-div-title p {
    margin: 0px auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 1;
  }

  .section3-part3-text-div-description {
    width: 90%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .section3-part3-text-div-description p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 12px;
    font-family: "Catamaran";
    font-weight: 500;
    font-optical-sizing: auto;
    font-style: normal;
    color: #000000;
    line-height: 18px;
    opacity: 1;
  }

  .content-background-container {
    display: none;
  }

  .section-container4 {
    padding: 0;
    max-width: 440px;
    width: 100% !important;
    height: auto;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
  }

  .section4-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 24px;
    color: #000000;
    margin: 40px auto 0px auto;
    width: 100%;
    height: 27px;
    text-align: center;
    letter-spacing: 1.13px;
  }

  .actual-content {
    height: auto;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0px auto;
  }

  .actual-content-box-container {
    width: 98% !important;
    height: auto;
    margin: 0px auto 0px auto;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: 45% 45%;
    grid-template-rows: auto auto;
    row-gap: 10px;
    column-gap: 10px;

  }

  .actual-content-box1 {
    width: 100% !important;
    height: 180px;
    margin: 0px;
    background: #671E88;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box1-id {
    width: 23px;
    height: auto;
    margin: 15px 15px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box1-id p {
    margin: 0px;
    height: auto;
    text-align: left;
    font-size: 20px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box1-title {
    width: 90%;
    height: auto;
    margin: 15px auto 0px auto;
  }

  .actual-content-box1-title p {
    text-align: left;
    width: auto;
    height: auto;
    margin: 0px auto;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
  }

  .actual-content-box1-description {
    width: 80% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .actual-content-box1-description p {
    text-align: left;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto;
    line-height: 15px;
    width: auto;
    height: auto;
  }

  .actual-content-box2 {
    width: 100% !important;
    height: 180px;
    margin: 0px;
    background: #1E2288;
    border-radius: 12px;
    opacity: 1;

  }

  .actual-content-box2-id {
    width: 23px;
    height: auto;
    margin: 15px 15px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box2-id p {
    margin: 0px;
    height: auto;
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box2-title {
    width: 90%;
    height: auto;
    margin: 15px auto 0px auto;
  }

  .actual-content-box2-title p {
    text-align: left;
    margin: 0px auto;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
  }

  .actual-content-box2-description {
    width: 80% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .actual-content-box2-description p {
    text-align: left;
    width: auto !important;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto;
    line-height: 15px;
  }

  .actual-content-box3 {
    width: 100% !important;
    height: 180px;
    margin: 0px;
    background: #1E5B87;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box3-id {
    width: 23px;
    height: auto;
    margin: 15px 15px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box3-id p {
    margin: 0px;
    height: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box3-title {
    width: 90%;
    height: auto;
    margin: 15px auto 0px auto;
  }

  .actual-content-box3-title p {
    text-align: left;
    margin: 0px auto;
    width: auto;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    height: auto;
  }

  .actual-content-box3-description {
    width: 80% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .actual-content-box3-description p {
    width: auto !important;
    text-align: left;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto;
    line-height: 15px;
    height: auto;
  }

  .actual-content-box4 {
    width: 100% !important;
    height: 180px;
    margin: 0px;
    background: #73730d;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box4-id {
    width: 23px;
    height: auto;
    margin: 15px 15px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box4-id p {
    margin: 0px;
    height: auto;
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box4-title {
    width: 90%;
    height: auto;
    margin: 15px auto 0px auto;
  }

  .actual-content-box4-title p {
    text-align: left;
    margin: 0px auto;
    width: auto;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    height: auto;
  }

  .actual-content-box4-description {
    width: 80% !important;
    height: auto;
    margin: 10px auto 0px auto;
  }

  .actual-content-box4-description p {
    text-align: left;
    width: auto !important;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px;
    line-height: 15px;
    height: auto;
  }

  .section4-part2-container {
    width: 99%;
    height: auto;
    margin: 30px auto 20px auto !important;
    /* border: 2px solid #000; */
  }

  .section4-part2-title {
    width: 85% !important;
    height: auto;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
  }

  .section4-part2-title p {
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    font-family: "Helvetica Neue Condensed Bold";
    font-weight: bold;
    font-stretch: condensed;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    background: transparent linear-gradient(268deg, #671E88 0%, #1E2287 29%, #1F5B87 64%, #87871F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
  }

  .section4-part2-description {
    width: 76%;
    height: auto;
    margin: 10px auto 0px auto !important;
  }

  .section4-part2-description p {
    margin: 0px auto;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    line-height: 20px;
  }

  .content-background-container2 {
    display: none;
  }

  .section-container5 {
    background: transparent linear-gradient(180deg, #49434B 0%, #351D62 100%);
    padding: 0;
    max-width: 430px;
    width: 100%;
    height: auto;
    border: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section5-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 24px;
    color: #FFFFFF;
    margin: 40px 0px 0px 0px;
    width: 264px;
    height: 68px;
    text-align: center;
    letter-spacing: 0.24px;
    line-height: 36px;
  }

  .section5-content-box {
    width: 280px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0px 0px 0px;
    border: none;
  }

  .section5-content-box1,
  .section5-content-box2,
  .section5-content-box3 {
    width: 252px;
    height: 202px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    margin: 0px;
  }

  .section5-content-box1 img {
    width: 70px;
    height: 70px;
    margin-top: 0px;
  }

  .section5-content-box2 img {
    width: 70px;
    height: 70px;
    margin-top: 0px;
  }

  .section5-content-box3 img {
    width: 70px;
    height: 70px;
    margin-top: 0px;
  }

  .section5-content-box1-title {
    font-size: 14px;
    color: #FAFAFA;
    margin: 10px 0px 0px 0px;
    width: auto;
    height: 16px;
    text-align: center;
  }

  .section5-content-box1-description {
    width: 200px;
    height: auto;
    font-size: 12px;
    margin: 10px 0px 0px 0px;
    text-align: center;
    line-height: 15px;
    color: #FAFAFA;
  }

  .page-top-container {
    width: 100%;
    height: 25px;
    margin: 0px 40px 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .go-to-page-top {
    width: 35px !important;
    height: 25px !important;
    cursor: pointer;
    margin: 0px 0px 0px -10px;
    background-image: url("./../Assets/LandingWebsite/goToPageTopIcon.svg");
    background-size: 35px 25px;
  }

  .go-to-page-top:hover {
    background-image: url("./../Assets/LandingWebsite/goToPageTopDarkIcon.svg");
  }


}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .main-container {
    width: 100%;
    height: auto !important;
    padding: 0px 0px 0px 0px !important;
    margin: 0px auto 0px auto !important;
  }

  .section {
    max-width: 100% !important;
  }

  .first-page {
    width: 100% !important;
    height: auto;
    margin: 0px auto 0px auto !important;
    background: transparent linear-gradient(257deg, #000000 0%, #0d2738 100%);
  }

  .fixed-banner {
    background-color: #eacf21;
    /* Background color */
    color: rgb(0, 0, 0);
    /* Text color */
    padding: 10px 0px;
    text-align: center;
    margin: 0px auto 0px auto;
    position: relative;
    z-index: 2 !important;
    /* Fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    /* Space between content and close button */
    align-items: center;
    /* Ensures the banner is on top */
  }

  .fixed-banner span {
    margin: 0px 100px;
  }

  .fixed-banner a {
    color: #ef0909;
    /* Link color */
    text-decoration: none;
    font-size: 16px;
  }

  .fixed-banner a:hover {
    color: #ffffff;
    /* text-shadow: 0 0 5px #ff0, 0 0 10px #ff0, 0 0 20px #ff0; */
  }

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 0px;
  }


  .section-container1 {
    padding: 0;
    width: 100%;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 0px;
    position: relative;
  }

  .section1-part1 {
    width: 60%;
    height: auto;
    /* border: 1px solid #fff; */
    display: flex;
    flex-direction: column;
    margin: 60px auto 0px auto;
  }

  .section1-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    margin: 0px auto 0px auto;
    text-align: center;
    width: 85%;
    height: auto;
    color: #D6D6D6;

    /* border: 1px solid #fff; */
  }

  .section1-description {
    width: 80%;
    height: auto;
    margin: 13px auto 0px auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #fff; */
  }

  .section1-description p {
    margin: 0px 0px 20px 0px;
    width: 100%;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    color: #D0D0D0;
    padding: 0px 0px 0px 0px;
    opacity: 1;
  }

  .section1-description p:last-child {
    margin: 0px 0px 0px 0px;
  }

  .section1-start-div {
    width: 80%;
    height: 60px;
    display: flex;
    justify-content: center;
    margin: 35px auto 0px auto;
  }

  .section1-start-div button {
    width: 200px;
    height: 55px;
    background-color: #427bac;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    letter-spacing: 0.16px;
    cursor: pointer;
    margin: 0px auto 0px auto;
  }

  .section1-start-div button:hover {
    color: #FFFFFF;
    background-color: #48588D;
    border: none;
  }

  .section1-footer-animation {
    width: 494px;
    height: 182px;
    margin: 76px auto 0px 49px;
    /* border: 1px solid #fff; */
    color: #000;
  }

  .section1-footer-animation img {
    width: 100%;
    height: 182px;
  }

  .section1-part1-border {
    width: 494px;
    height: 0px;
    background-color: transparent;
    border-bottom: 0.12px solid rgb(255, 255, 255, 0.15);
    margin: 0px auto 0px auto;
  }

  .section1-part2 {
    width: 39%;
    height: auto;
    display: flex;
    /* border: 1px solid #fff; */
    flex-direction: column;
    margin: 60px 0px 0px 0px;
  }

  .section1-mobile-animation {
    width: 68%;
    height: auto;
    overflow: hidden;
    margin: 40px auto 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .section1-bottom-tray-shape {
    width: 68%;
    height: 33px;
    background-color: #100C0C;
    border-radius: 60%;
    margin: 10px 0px 0px 33px;
  }

  .section-container2 {
    width: 100% !important;
    overflow-x: hidden;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 0px 0px;
  }

  .section2-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #ffffff;
    margin: 60px auto 0px auto;
    width: 50%;
    height: auto;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .section2-grid-container {
    display: grid;
    grid-template-rows: 2fr 2fr 2fr;
    grid-template-columns: 2fr 2fr 2fr;
    /* border: 1px solid #fff; */
    width: 91%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .section2-grid-element1,
  .section2-grid-element2,
  .section2-grid-element3,
  .section2-grid-element4,
  .section2-grid-element5,
  .section2-grid-element6,
  .section2-grid-element7,
  .section2-grid-element8,
  .section2-grid-element9 {
    width: 90%;
    height: auto;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    /* border: 1px solid #fff; */
  }

  .grid-element1-img-container,
  .grid-element2-img-container,
  .grid-element3-img-container,
  .grid-element4-img-container,
  .grid-element5-img-container,
  .grid-element6-img-container,
  .grid-element7-img-container,
  .grid-element8-img-container,
  .grid-element9-img-container {
    width: 100% !important;
    height: 55px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    border: none !important;
    border-radius: none !important;
    margin: 0px !important;
  }

  .dashboard-icon {
    width: 55px !important;
    height: 55px;
    margin: 0px;
  }

  .grid-element1-title,
  .grid-element2-title,
  .grid-element3-title,
  .grid-element4-title,
  .grid-element5-title,
  .grid-element6-title,
  .grid-element7-title,
  .grid-element8-title,
  .grid-element9-title {
    width: 100%;
    height: auto !important;
    margin: 15px 0px 0px 0px !important;
    border-radius: none !important;
    font-family: "Catamaran";
    font-weight: bold;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 22px;
    letter-spacing: 0.24px;
    padding: 0px 0px 0px 0px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .grid-element1-title-text,
  .grid-element2-title-text,
  .grid-element3-title-text,
  .grid-element4-title-text,
  .grid-element5-title-text,
  .grid-element6-title-text,
  .grid-element7-title-text,
  .grid-element8-title-text,
  .grid-element9-title-text {
    width: 100%;
    margin: 0px;
    color: #ffffff !important;
    padding: 0px 0px 0px 0px !important;
  }

  .grid-element1-description,
  .grid-element2-description,
  .grid-element3-description,
  .grid-element4-description,
  .grid-element5-description,
  .grid-element6-description,
  .grid-element7-description,
  .grid-element8-description,
  .grid-element9-description {
    width: 100%;
    height: 122px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #ffffff;
    margin: 8px 0px 0px 0px;
    text-align: left;
    line-height: 24px;
  }

  .section-container3 {
    background-color: #ffffff;
    padding: 0;
    width: 100%;
    height: auto;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    margin: 57px auto 0px auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .section3-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #333333;
    margin: 0px auto 0px auto;
    width: 60%;
    /* border: 1px solid #000; */
    height: 42px;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .section3-part1 {
    width: 93%;
    height: 300px;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #9a9a88;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part1-img-container {
    width: 42.8%;
    height: auto;
    /* border: 1px solid #fff; */
    margin: auto 0px auto 30px;
  }

  .section3-part1-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part1-text-div {
    width: 45.7%;
    height: auto;
    margin: 37px auto 0px auto;
    /* border: 1px solid #fff; */

  }

  .section3-part1-text-div-title {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .section3-part1-text-div-title p {
    width: 100%;
    height: auto;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0.26px;
    line-height: 35px;
    color: #000000;
    opacity: 1;
  }

  .section3-part1-text-div-description {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
  }

  .section3-part1-text-div-description p {
    margin: 0px 0px 0px 0px;
    width: auto;
    height: auto;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .section3-part2 {
    width: 93%;
    height: 300px;
    margin: 28px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #aab9c4;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part2-img-container {
    width: 48.2%;
    height: auto;
    margin: auto;
    /* border: 1px solid #fff; */
  }

  .section3-part2-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part2-text-div {
    width: 50%;
    height: auto;
    margin: 37px 0px 0px 25px;

    /* border: 1px solid #fff; */

  }

  .section3-part2-text-div-title {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
  }

  .section3-part2-text-div-title p {
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0.26px;
    line-height: 39px;
    color: #000000;
    opacity: 1;
  }

  .section3-part2-text-div-description {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
  }

  .section3-part2-text-div-description p {
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .section3-part3 {
    width: 93%;
    height: 300px;
    margin: 28px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #a68bb2;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part3-img-container {
    width: 49.7%;
    height: auto;
    margin: auto;
  }

  .section3-part3-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part3-text-div {
    width: 50%;
    height: auto;
    margin: 37px 0px 0px 25px;
    /* border: 1px solid #fff; */
  }

  .section3-part3-text-div-title {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
  }

  .section3-part3-text-div-title p {
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0.26px;
    line-height: 39px;
  }

  .section3-part3-text-div-description {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
  }

  .section3-part3-text-div-description p {
    margin: 0px 0px 0px 0px;
    width: 100%;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .section-container4 {
    padding: 0;
    width: 100%;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .section4-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #000000;
    margin: 60px auto 0px auto;
    width: 30%;
    height: auto;
    text-align: center;
    letter-spacing: 0.36px;
    /* border: 1px solid #000000; */
  }

  .content-background-container {
    margin: 0px;
    width: 100%;
    height: 700px;
    position: absolute;
    z-index: -1;
    padding: 0px 0px 0px 0px;
    background-image: url("../Assets/LandingWebsite/backgroundMeshImage.png");
    background-size: 100% 700px;
    opacity: 0.5;
    display: none;
  }

  .actual-content {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto 0px auto;
    /* border: 1px solid #000000; */
  }

  .actual-content-box-container {
    width: 576px;
    height: auto;
    margin: 20px auto;
    display: grid;
    grid-template-rows: 2fr 2fr;
    grid-template-columns: 2fr 2fr;
  }


  .actual-content-box1 {
    width: 245px;
    height: 245px;
    margin: 20px auto;
    background: #671E88;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box1-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box1-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box1-title {
    width: auto;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box1-title p {
    text-align: left;
    margin: 0px auto 0px auto;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 0.24px;
  }

  .actual-content-box1-description {
    width: 200px !important;
    height: 37px;
    margin: 9px 0px 0px 25px;
    display: flex;
    justify-content: center;
  }

  .actual-content-box1-description p {
    text-align: left;
    width: 200px;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }

  .actual-content-box2 {
    width: 245px;
    height: 245px;
    margin: 20px auto 0px auto;
    background: #1E2288;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box2-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box2-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box2-title {
    width: auto;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box2-title p {
    text-align: left;
    margin: 0px;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 0.24px;
    width: auto;
  }

  .actual-content-box2-description {
    width: 200px !important;
    height: 57px;
    margin: 9px 0px 0px 25px;
  }

  .actual-content-box2-description p {
    text-align: left;
    width: 200px !important;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }

  .actual-content-box3 {
    width: 245px;
    height: 245px;
    margin: 20px auto 0px auto;
    background: #1E5B87;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box3-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box3-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box3-title {
    width: auto;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box3-title p {
    text-align: left;
    margin: 0px;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0.24px;
  }

  .actual-content-box3-description {
    width: 200px !important;
    height: 57px;
    margin: 9px 0px 0px 25px;
  }

  .actual-content-box3-description p {
    text-align: left;
    font-family: "Catamaran";
    width: 200px !important;
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }

  .actual-content-box4 {
    width: 245px;
    height: 245px;
    margin: 20px auto 0px auto;
    background: #73730d;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box4-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box4-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box4-title {
    width: 155px;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box4-title p {
    text-align: left;
    margin: 0px;
    font-family: "Catamaran";
    font-weight: bold;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 0.24px;
  }

  .actual-content-box4-description {
    width: 200px !important;
    height: 77px;
    margin: 9px 0px 0px 25px;
  }

  .actual-content-box4-description p {
    margin: 0px;
    width: 200px !important;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }


  .section4-part2-container {
    width: 72%;
    height: auto;
    margin: 10px auto 20px auto !important;
  }

  .section4-part2-title {
    width: 100% !important;
    height: auto;
    margin: 0px auto 0px auto;
    display: flex;
    justify-content: center;
    padding: 0px 0px 0px 0px;
  }

  .section4-part2-title p {
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    height: auto;
    text-align: center !important;
    text-transform: uppercase;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 32px;
    letter-spacing: 0.32px;
    background: transparent linear-gradient(268deg, #671E88 0%, #1E2287 29%, #1F5B87 64%, #87871F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
    letter-spacing: 2px;
  }

  .section4-part2-description {
    width: 100%;
    height: auto;
    margin: 21px auto 0px auto;
  }

  .section4-part2-description p {
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .content-background-container2 {
    display: flex;
    margin: 0px;
    width: 100%;
    height: 633px;
    position: absolute;
    z-index: -1;
    padding: 0px 0px 0px 0px;
    background: transparent linear-gradient(180deg, #49434B 0%, #351D62 100%);
  }

  .section-container5 {
    padding: 0;
    width: 100%;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section5-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #FFFFFF;
    margin: 30px auto 0px auto;
    width: 65%;
    height: auto;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .section5-content-box {
    width: 99.8%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 40px auto 0px auto;
    border: 0.6px solid rgb(191, 191, 191, 0.5);
  }

  .section5-content-box1,
  .section5-content-box2,
  .section5-content-box3 {
    width: 33%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    opacity: 1;
  }

  .section5-content-box1,
  .section5-content-box2 {
    border-right: 0.6px solid rgb(191, 191, 191, 0.5);
  }

  .section5-content-box1 img {
    width: 80px;
    height: 80px;
    margin: 20px auto 0px auto;
  }

  .section5-content-box2 img {
    width: 80px;
    height: 80px;
    margin: 20px auto 0px auto;
  }


  .section5-content-box3 img {
    width: 80px;
    height: 80px;
    margin: 20px auto 0px auto;
  }


  .section5-content-box1-title {
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 22px;
    color: #FFFFFF;
    margin: 25px 0px 0px 0px;
    width: 100%;
    height: 31px;
    text-align: center;
    letter-spacing: 0.22px;
  }

  .section5-content-box1-description {
    width: 73%;
    height: auto;
    font-size: 16px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    margin: 12px 0px 30px 0px;
    text-align: center;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .page-top-container {
    width: 100%;
    height: 28px;
    margin: 45px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .go-to-page-top {
    width: 45px;
    height: 32px;
    cursor: pointer;
    margin: 0px 16px 0px 0px;
    background-image: url("./../Assets/LandingWebsite/goToPageTopIcon.svg");
    background-size: 45px 32px;
  }

  .go-to-page-top:hover {
    background-image: url("./../Assets/LandingWebsite/goToPageTopDarkIcon.svg");
  }


}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-container {
    width: 100%;
    height: auto !important;
    padding: 0px 0px 0px 0px !important;
    margin: 0px auto 0px auto !important;
  }

  .section {
    max-width: 100% !important;
  }

  .first-page {
    width: 100% !important;
    height: auto;
    margin: 0px auto 0px auto !important;
    background: transparent linear-gradient(257deg, #000000 0%, #0d2738 100%);
  }

  .fixed-banner {
    background-color: #eacf21;
    /* Background color */
    color: rgb(0, 0, 0);
    /* Text color */
    padding: 10px 0px;
    text-align: center;
    margin: 0px auto 0px auto;
    position: relative;
    z-index: 2 !important;
    /* Fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    /* Space between content and close button */
    align-items: center;
    /* Ensures the banner is on top */
  }

  .fixed-banner span {
    margin: 0px 100px;
  }

  .fixed-banner a {
    color: #ef0909;
    /* Link color */
    text-decoration: none;
    font-size: 16px;
  }

  .fixed-banner a:hover {
    color: #ffffff;
    /* text-shadow: 0 0 5px #ff0, 0 0 10px #ff0, 0 0 20px #ff0; */
  }

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 0px;
  }


  .section-container1 {
    padding: 0;
    width: 100%;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 0px;
    position: relative;
  }

  .section1-part1 {
    width: 60%;
    height: auto;
    /* border: 1px solid #fff; */
    display: flex;
    flex-direction: column;
    margin: 60px auto 0px auto;
  }

  .section1-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    margin: 0px auto 0px auto;
    text-align: center;
    width: 85%;
    height: auto;
    color: #D6D6D6;

    /* border: 1px solid #fff; */
  }

  .section1-description {
    width: 80%;
    height: auto;
    margin: 13px auto 0px auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #fff; */
  }

  .section1-description p {
    margin: 0px 0px 20px 0px;
    width: 100%;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.18px;
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    color: #D0D0D0;
    padding: 0px 0px 0px 0px;
    opacity: 1;
  }

  .section1-description p:last-child {
    margin: 0px 0px 0px 0px;
  }

  .section1-start-div {
    width: 80%;
    height: 60px;
    display: flex;
    justify-content: center;
    margin: 35px auto 0px auto;
  }

  .section1-start-div button {
    width: 200px;
    height: 55px;
    background-color: #427bac;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 16px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    letter-spacing: 0.16px;
    cursor: pointer;
    margin: 0px auto 0px auto;
  }

  .section1-start-div button:hover {
    color: #FFFFFF;
    background-color: #48588D;
    border: none;
  }

  .section1-footer-animation {
    width: 494px;
    height: 182px;
    margin: 76px auto 0px 49px;
    /* border: 1px solid #fff; */
    color: #000;
  }

  .section1-footer-animation img {
    width: 100%;
    height: 182px;
  }

  .section1-part1-border {
    width: 494px;
    height: 0px;
    background-color: transparent;
    border-bottom: 0.12px solid rgb(255, 255, 255, 0.15);
    margin: 0px auto 0px auto;
  }

  .section1-part2 {
    width: 39%;
    height: auto;
    display: flex;
    /* border: 1px solid #fff; */
    flex-direction: column;
    margin: 60px 0px 0px 0px;
  }

  .section1-mobile-animation {
    width: 68%;
    height: auto;
    overflow: hidden;
    margin: 40px auto 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  .section1-bottom-tray-shape {
    width: 68%;
    height: 33px;
    background-color: #100C0C;
    border-radius: 60%;
    margin: 10px 0px 0px 33px;
  }

  .section-container2 {
    width: 100% !important;
    overflow-x: hidden;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 0px 0px;
  }

  .section2-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #ffffff;
    margin: 60px auto 0px auto;
    width: 50%;
    height: auto;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .section2-grid-container {
    display: grid;
    grid-template-rows: 2fr 2fr 2fr;
    grid-template-columns: 2fr 2fr 2fr;
    /* border: 1px solid #fff; */
    width: 91%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .section2-grid-element1,
  .section2-grid-element2,
  .section2-grid-element3,
  .section2-grid-element4,
  .section2-grid-element5,
  .section2-grid-element6,
  .section2-grid-element7,
  .section2-grid-element8,
  .section2-grid-element9 {
    width: 90%;
    height: auto;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    /* border: 1px solid #fff; */
  }

  .grid-element1-img-container,
  .grid-element2-img-container,
  .grid-element3-img-container,
  .grid-element4-img-container,
  .grid-element5-img-container,
  .grid-element6-img-container,
  .grid-element7-img-container,
  .grid-element8-img-container,
  .grid-element9-img-container {
    width: 100% !important;
    height: 55px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    border: none !important;
    border-radius: none !important;
    margin: 0px !important;
  }

  .dashboard-icon {
    width: 55px !important;
    height: 55px;
    margin: 0px;
  }

  .grid-element1-title,
  .grid-element2-title,
  .grid-element3-title,
  .grid-element4-title,
  .grid-element5-title,
  .grid-element6-title,
  .grid-element7-title,
  .grid-element8-title,
  .grid-element9-title {
    width: 100%;
    height: auto !important;
    margin: 15px 0px 0px 0px !important;
    border-radius: none !important;
    font-family: "Catamaran";
    font-weight: bold;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 22px;
    letter-spacing: 0.24px;
    padding: 0px 0px 0px 0px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .grid-element1-title-text,
  .grid-element2-title-text,
  .grid-element3-title-text,
  .grid-element4-title-text,
  .grid-element5-title-text,
  .grid-element6-title-text,
  .grid-element7-title-text,
  .grid-element8-title-text,
  .grid-element9-title-text {
    width: 100%;
    margin: 0px;
    color: #ffffff !important;
    padding: 0px 0px 0px 0px !important;
  }

  .grid-element1-description,
  .grid-element2-description,
  .grid-element3-description,
  .grid-element4-description,
  .grid-element5-description,
  .grid-element6-description,
  .grid-element7-description,
  .grid-element8-description,
  .grid-element9-description {
    width: 100%;
    height: 122px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #ffffff;
    margin: 8px 0px 0px 0px;
    text-align: left;
    line-height: 24px;
  }

  .section-container3 {
    background-color: #ffffff;
    padding: 0;
    width: 100%;
    height: auto;
    border-right: 1px solid rgb(191, 191, 191, 0.5);
    border-left: 1px solid rgb(191, 191, 191, 0.5);
    margin: 57px auto 0px auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .section3-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #333333;
    margin: 0px auto 0px auto;
    width: 60%;
    /* border: 1px solid #000; */
    height: 42px;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .section3-part1 {
    width: 93%;
    height: 300px;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #9a9a88;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part1-img-container {
    width: 42.8%;
    height: auto;
    /* border: 1px solid #fff; */
    margin: auto 0px auto 30px;
  }

  .section3-part1-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part1-text-div {
    width: 45.7%;
    height: auto;
    margin: 37px auto 0px auto;
    /* border: 1px solid #fff; */

  }

  .section3-part1-text-div-title {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .section3-part1-text-div-title p {
    width: 100%;
    height: auto;
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0.26px;
    line-height: 35px;
    color: #000000;
    opacity: 1;
  }

  .section3-part1-text-div-description {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
  }

  .section3-part1-text-div-description p {
    margin: 0px 0px 0px 0px;
    width: auto;
    height: auto;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .section3-part2 {
    width: 93%;
    height: 300px;
    margin: 28px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #aab9c4;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part2-img-container {
    width: 48.2%;
    height: auto;
    margin: auto;
    /* border: 1px solid #fff; */
  }

  .section3-part2-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part2-text-div {
    width: 50%;
    height: auto;
    margin: 37px 0px 0px 25px;

    /* border: 1px solid #fff; */

  }

  .section3-part2-text-div-title {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
  }

  .section3-part2-text-div-title p {
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0.26px;
    line-height: 39px;
    color: #000000;
    opacity: 1;
  }

  .section3-part2-text-div-description {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
  }

  .section3-part2-text-div-description p {
    margin: 0px 0px 0px 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .section3-part3 {
    width: 93%;
    height: 300px;
    margin: 28px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #a68bb2;
    box-shadow: inset 0px 3px 6px #00000029;
  }

  .section3-part3-img-container {
    width: 49.7%;
    height: auto;
    margin: auto;
  }

  .section3-part3-img-container img {
    width: 100%;
    height: 100%;
  }

  .section3-part3-text-div {
    width: 50%;
    height: auto;
    margin: 37px 0px 0px 25px;
    /* border: 1px solid #fff; */
  }

  .section3-part3-text-div-title {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
  }

  .section3-part3-text-div-title p {
    margin: 0px;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0.26px;
    line-height: 39px;
  }

  .section3-part3-text-div-description {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
  }

  .section3-part3-text-div-description p {
    margin: 0px 0px 0px 0px;
    width: 100%;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .section-container4 {
    padding: 0;
    width: 100%;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .section4-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #000000;
    margin: 60px auto 0px auto;
    width: 30%;
    height: auto;
    text-align: center;
    letter-spacing: 0.36px;
    /* border: 1px solid #000000; */
  }

  .content-background-container {
    margin: 0px;
    width: 100%;
    height: 700px;
    position: absolute;
    z-index: -1;
    padding: 0px 0px 0px 0px;
    background-image: url("../Assets/LandingWebsite/backgroundMeshImage.png");
    background-size: 100% 700px;
    opacity: 0.5;
    display: none;
  }

  .actual-content {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto 0px auto;
    /* border: 1px solid #000000; */
  }

  .actual-content-box-container {
    width: 576px;
    height: auto;
    margin: 20px auto;
    display: grid;
    grid-template-rows: 2fr 2fr;
    grid-template-columns: 2fr 2fr;
  }


  .actual-content-box1 {
    width: 245px;
    height: 245px;
    margin: 20px auto;
    background: #671E88;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box1-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box1-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box1-title {
    width: auto;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box1-title p {
    text-align: left;
    margin: 0px auto 0px auto;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 0.24px;
  }

  .actual-content-box1-description {
    width: 200px !important;
    height: 37px;
    margin: 9px 0px 0px 25px;
    display: flex;
    justify-content: center;
  }

  .actual-content-box1-description p {
    text-align: left;
    width: 200px;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }

  .actual-content-box2 {
    width: 245px;
    height: 245px;
    margin: 20px auto 0px auto;
    background: #1E2288;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box2-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box2-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box2-title {
    width: auto;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box2-title p {
    text-align: left;
    margin: 0px;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    letter-spacing: 0.24px;
    width: auto;
  }

  .actual-content-box2-description {
    width: 200px !important;
    height: 57px;
    margin: 9px 0px 0px 25px;
  }

  .actual-content-box2-description p {
    text-align: left;
    width: 200px !important;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }

  .actual-content-box3 {
    width: 245px;
    height: 245px;
    margin: 20px auto 0px auto;
    background: #1E5B87;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box3-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box3-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box3-title {
    width: auto;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box3-title p {
    text-align: left;
    margin: 0px;
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0.24px;
  }

  .actual-content-box3-description {
    width: 200px !important;
    height: 57px;
    margin: 9px 0px 0px 25px;
  }

  .actual-content-box3-description p {
    text-align: left;
    font-family: "Catamaran";
    width: 200px !important;
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }

  .actual-content-box4 {
    width: 245px;
    height: 245px;
    margin: 20px auto 0px auto;
    background: #73730d;
    border-radius: 12px;
    opacity: 1;
  }

  .actual-content-box4-id {
    width: 36px;
    height: 36px;
    margin: 22px 25px 0px auto;
    display: flex;
    justify-content: end;
  }

  .actual-content-box4-id p {
    margin: 0px;
    height: 36px;
    text-align: center;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;
  }

  .actual-content-box4-title {
    width: 155px;
    height: 27px;
    margin: 25px 0px 0px 25px;
  }

  .actual-content-box4-title p {
    text-align: left;
    margin: 0px;
    font-family: "Catamaran";
    font-weight: bold;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 0.24px;
  }

  .actual-content-box4-description {
    width: 200px !important;
    height: 77px;
    margin: 9px 0px 0px 25px;
  }

  .actual-content-box4-description p {
    margin: 0px;
    width: 200px !important;
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.16px;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 1;
    margin: 0px auto 0px auto;
    line-height: 24px;
  }


  .section4-part2-container {
    width: 72%;
    height: auto;
    margin: 10px auto 20px auto !important;
  }

  .section4-part2-title {
    width: 100% !important;
    height: auto;
    margin: 0px auto 0px auto;
    display: flex;
    justify-content: center;
    padding: 0px 0px 0px 0px;
  }

  .section4-part2-title p {
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    height: auto;
    text-align: center !important;
    text-transform: uppercase;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 32px;
    letter-spacing: 0.32px;
    background: transparent linear-gradient(268deg, #671E88 0%, #1E2287 29%, #1F5B87 64%, #87871F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    background-clip: text;
    letter-spacing: 2px;
  }

  .section4-part2-description {
    width: 100%;
    height: auto;
    margin: 21px auto 0px auto;
  }

  .section4-part2-description p {
    margin: 0px auto 0px auto;
    text-align: center;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    line-height: 27px;
  }

  .content-background-container2 {
    display: flex;
    margin: 0px;
    width: 100%;
    height: 633px;
    position: absolute;
    z-index: -1;
    padding: 0px 0px 0px 0px;
    background: transparent linear-gradient(180deg, #49434B 0%, #351D62 100%);
  }

  .section-container5 {
    padding: 0;
    width: 100%;
    height: auto;
    border-right: none;
    border-left: none;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section5-title {
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
    font-weight: bold;
    font-stretch: condensed;
    font-size: 36px;
    color: #FFFFFF;
    margin: 30px auto 0px auto;
    width: 65%;
    height: auto;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .section5-content-box {
    width: 99.8%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 40px auto 0px auto;
    border: 0.6px solid rgb(191, 191, 191, 0.5);
  }

  .section5-content-box1,
  .section5-content-box2,
  .section5-content-box3 {
    width: 33%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    opacity: 1;
  }

  .section5-content-box1,
  .section5-content-box2 {
    border-right: 0.6px solid rgb(191, 191, 191, 0.5);
  }

  .section5-content-box1 img {
    width: 80px;
    height: 80px;
    margin: 20px auto 0px auto;
  }

  .section5-content-box2 img {
    width: 80px;
    height: 80px;
    margin: 20px auto 0px auto;
  }


  .section5-content-box3 img {
    width: 80px;
    height: 80px;
    margin: 20px auto 0px auto;
  }


  .section5-content-box1-title {
    font-family: "Catamaran";
    font-weight: 600;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 22px;
    color: #FFFFFF;
    margin: 25px 0px 0px 0px;
    width: 100%;
    height: 31px;
    text-align: center;
    letter-spacing: 0.22px;
  }

  .section5-content-box1-description {
    width: 73%;
    height: auto;
    font-size: 16px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    margin: 12px 0px 30px 0px;
    text-align: center;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .page-top-container {
    width: 100%;
    height: 28px;
    margin: 45px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .go-to-page-top {
    width: 45px;
    height: 32px;
    cursor: pointer;
    margin: 0px 16px 0px 0px;
    background-image: url("./../Assets/LandingWebsite/goToPageTopIcon.svg");
    background-size: 45px 32px;
  }

  .go-to-page-top:hover {
    background-image: url("./../Assets/LandingWebsite/goToPageTopDarkIcon.svg");
  }


}