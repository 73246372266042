.website-footer-section {
    width: 100%;
    height: 254px !important;
    background-color: #2C4E6C;
    display: flex;
    margin: 0px auto 0px auto;
}

.section-container6 {
    height: 254px;
    width: 1080px !important;
    margin: 0px auto 0px auto !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.footer-title-box {
    height: 128px;
    width: 241px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 34px 0px 0px 180px;
}

.company-logo {
    width: 220px;
    height: 51px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.footer-icon {
    width: 50px;
    height: 50px;
}

.footer-navbar-brand {
    width: auto;
    height: auto;
    font-family: Impact, Regular !important;
    font-size: 32px;
    color: #9EC7D8;
    text-align: left;
    margin: auto 0px auto 0px;
}

.follow-us-on {
    width: 100%;
    height: 12px;
    margin: 5px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
}

.follow-us-on p {
    width: 100%;
    height: 12px;
    margin: 0px 20px 0px 0px;
    text-align: right;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 0.25px;
    font-size: 10px;
}

.social-media-icon-tray {
    width: 190px;
    height: 25px;
    margin: 9px 0px 0px 30px;
}

.twitter-icon {
    margin: 0px;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.instagram-icon {
    margin: 0px 0px 0px 15px;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.facebook-icon {
    margin: 0px 0px 0px 15px;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.youtube-icon {
    margin: 0px 0px 0px 15px;
    width: 30px !important;
    height: 25px !important;
    cursor: pointer;
    aspect-ratio: 1.4;
}

.linkedin-icon {
    margin: 0px 0px 0px 15px;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.copy-right-panel {
    width: auto;
    height: 13px;
    margin: 15px 0px 0px -49px;
    display: inline-flex;
    align-items: center;
}

.copy-right-panel p {
    width: 100%;
    height: 24px;
    margin: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    color: #ffffff;
    font-size: 10px;
    letter-spacing: 0.25px;
    text-align: right;
}

.copy-right-panel img {
    width: 12px;
    height: 12px;
    margin: 2px 5px 0px 5px;
}

.footer-routes-box {
    width: 581px;
    height: 123px;
    margin: 43px 0px 0px 61px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.application-div {
    width: 96px;
    height: 123px;
}

.application-title {
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #E7ECF0;
    letter-spacing: 0.24px;
}

.application-div p {
    width: 126px !important;
    height: auto;
    margin: 0px 0px 5px 0px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    color: #E7ECF0;
    font-size: 16px;
    letter-spacing: 0.16px;
    text-align: left;
    cursor: pointer;
}

.application-div p:first-child {
    margin: 6px 0px 0px 0px;
}

.legal-div {
    width: 120px;
    height: 123px;
    margin: 0px 0px 0px 150px;
}

.legal-title {
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #E7ECF0;
    letter-spacing: 0.24px;
}

.legal-div p {
    width: 101px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    color: #E7ECF0;
    font-size: 16px;
    letter-spacing: 0.16px;
    text-align: left;
    cursor: pointer;
    margin: 0px 0px 5px 0px;
}

.legal-div p:first-child {
    margin: 6px 0px 0px 0px;
}

.about-div {
    width: 96px;
    height: 123px;
    margin: 0px 0px 0px 150px;
}

.about-title {
    text-align: left;
    font-family: "Catamaran";
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #E7ECF0;
    letter-spacing: 0.24px;
}

.about-div p {
    width: 100px;
    font-family: "Catamaran";
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    color: #E7ECF0;
    font-size: 16px;
    letter-spacing: 0.16px;
    text-align: left;
    cursor: pointer;
    margin: 0px 0px 5px 0px;
}

.about-div p:first-child {
    margin: 6px 0px 0px 0px;
}

@media only screen and (max-width: 442px) {
    .website-footer-section {
        max-width: 440px;
        width: 100%;
        height: 308px !important;
        background-color: #2C4E6C;
        display: flex;
        justify-content: flex-start;
        margin: 0px;
        padding: 0px;
    }

    .section-container6 {
        height: 72% !important;
        width: 100% !important;
        margin: 0px auto 0px auto !important;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
    }

    .footer-title-box {
        height: auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 5px 0px 0px 0px;
    }

    .company-logo {
        width: 60% !important;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .compnay-logo-title {
        width: 100px;
        height: auto;
        margin: 0px 0px 0px 5px;
    }

    .footer-icon {
        width: 38px !important;
        height: 38px !important;
    }

    .footer-navbar-brand {
        width: 119px;
        height: auto;
        font-family: Impact, Regular !important;
        font-size: 23px;
        color: #9EC7D8;
        text-align: left;
    }

    .navbar-brand-icon {
        width: 150px;
        height: 35px !important;
        background-image: url("../Assets/bestBudgetBrandIcon.png");
        background-size: 100% 100%;
        margin: 10px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .follow-us-on {
        width: 90%;
        height: auto;
        margin: 10px 5px 0px 0px;
    }

    .follow-us-on p {
        margin: 0px;
        text-align: right;
    }

    .social-media-icon-tray {
        width: 200px;
        height: 19;
        margin: 5px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
    }

    .social-media-icon-tray img {
        margin: 0px 5px 0px 5px;
        width: 18.75px;
        height: 18.75px;
    }

    .youtube-icon {
        aspect-ratio: 1.4;
    }

    .copy-right-panel {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 12px 5px 0px 0px;
    }

    .copy-right-panel p {
        width: 100%;
        height: auto;
        margin: 0px 0px 0px 8px;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #ffffff;
        font-size: 10px;
    }

    .copy-right-panel img {
        width: 12px;
        height: 12px;
        margin: 1.5px 2.5px 0px 2.5px;
    }

    .footer-routes-box {
        width: 95% !important;
        height: auto !important;
        margin: 20px auto 0px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .application-div {
        width: 101px;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .application-title {
        text-align: left;
        font-family: "Catamaran";
        font-weight: 600;
        font-optical-sizing: auto;
        font-style: normal;
        font-size: 16px;
        color: #FAFAFA;
        letter-spacing: 0.16px;
        margin: 0px 0px 5px 0px;
    }

    .application-div p {
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #FAFAFA;
        font-size: 12px;
        letter-spacing: 0.12px;
        text-align: left;
        cursor: pointer;
        margin: 0px 0px 5px 0px;
    }

    .legal-div {
        width: 84px;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .legal-title {
        text-align: left;
        font-family: "Catamaran";
        font-weight: 600;
        font-optical-sizing: auto;
        font-style: normal;
        font-size: 16px;
        color: #FAFAFA;
        margin: 0px 0px 5px 0px;
        letter-spacing: 0.16px;
    }

    .legal-div p {
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #FAFAFA;
        font-size: 12px;
        letter-spacing: 0.12px;
        text-align: left;
        cursor: pointer;
        margin: 0px 0px 5px 0px;
    }

    .about-div {
        width: 55px;
        height: auto;
        margin: 0px auto 0px auto;
    }

    .about-title {
        text-align: left;
        font-family: "Catamaran";
        font-weight: 600;
        font-optical-sizing: auto;
        font-style: normal;
        font-size: 16px;
        color: #FAFAFA;
        letter-spacing: 0.16px;
        margin: 0px 0px 5px 0px;
    }

    .about-div p {
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        width: auto !important;
        margin: 0px 0px 5px 0px;
        color: #FAFAFA;
        font-size: 12px;
        letter-spacing: 0.12px;
        text-align: left;
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .website-footer-section {
        max-width: 1024px;
        min-width: 768px;
        width: 100%;
        height: 254px !important;
        background-color: #2C4E6C;
        display: flex;
        margin: 0px auto 0px auto;
    }

    .section-container6 {
        height: 254px;
        width: 100% !important;
        margin: 0px auto 0px auto !important;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .footer-title-box {
        height: auto !important;
        width: 36%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        /* border: 1px solid #fff; */
        margin: 34px auto 0px auto;
    }

    .company-logo {
        width: 69%;
        height: 51px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        /* border: 1px solid #fff; */
        margin: 0px 0px 0px auto;
    }

    .footer-icon {
        width: 50px;
        height: 50px;
    }

    .footer-navbar-brand {
        width: auto;
        height: auto;
        font-family: Impact, Regular !important;
        font-size: 24px;
        color: #9EC7D8;
        text-align: left;
        margin: auto 0px auto 0px;
    }

    .follow-us-on {
        width: 69%;
        height: 12px;
        margin: 5px 0px 0px auto;
        display: flex;
        justify-content: center;
        /* border: 1px solid #fff; */
    }

    .follow-us-on p {
        width: 100%;
        height: 12px;
        margin: 0px 0px 0px 0px;
        text-align: right;
        color: #ffffff;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        letter-spacing: 0.25px;
        font-size: 10px;
    }

    .social-media-icon-tray {
        width: 100%;
        height: 25px;
        margin: 9px 0px 0px auto;
        display: flex;
        justify-content: flex-end;
        /* border: 1px solid #fff; */
    }

    .twitter-icon {
        margin: 0px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .instagram-icon {
        margin: 0px 0px 0px 15px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .facebook-icon {
        margin: 0px 0px 0px 15px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .youtube-icon {
        margin: 0px 0px 0px 15px;
        width: 30px !important;
        height: 25px !important;
        cursor: pointer;
        aspect-ratio: 1.4;
    }

    .linkedin-icon {
        margin: 0px 0px 0px 15px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .copy-right-panel {
        width: auto;
        height: 13px;
        margin: 15px 0px 0px auto;
        display: inline-flex;
        align-items: center;
    }

    .copy-right-panel p {
        width: 100%;
        height: 24px;
        margin: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #ffffff;
        font-size: 10px;
        letter-spacing: 0.25px;
        text-align: right;
    }

    .copy-right-panel img {
        width: 12px;
        height: 12px;
        margin: 2px 5px 0px 5px;
    }

    .footer-routes-box {
        width: 60%;
        height: auto;
        margin: 43px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px solid #fff; */
    }

    .application-div {
        width: auto;
        height: auto;
        margin: 0px auto;
        /* border: 1px solid #fff; */
    }

    .application-title {
        text-align: left;
        font-family: "Catamaran";
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E7ECF0;
        letter-spacing: 0.22px;
    }

    .application-div p {
        width: auto !important;
        height: auto;
        margin: 0px 0px 5px 0px;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #E7ECF0;
        font-size: 16px;
        letter-spacing: 0.16px;
        text-align: left;
        cursor: pointer;
    }

    .application-div p:first-child {
        margin: 6px 0px 0px 0px;
    }

    .legal-div {
        width: auto;
        height: auto;
        /* border: 1px solid #fff; */
        margin: 0px auto;
    }

    .legal-title {
        text-align: left;
        font-family: "Catamaran";
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E7ECF0;
        letter-spacing: 0.22px;
    }

    .legal-div p {
        width: auto;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #E7ECF0;
        font-size: 16px;
        letter-spacing: 0.16px;
        text-align: left;
        cursor: pointer;
        margin: 0px 0px 5px 0px;
    }

    .legal-div p:first-child {
        margin: 6px 0px 0px 0px;
    }

    .about-div {
        width: auto;
        height: auto;
        /* border: 1px solid #fff; */
        margin: 0px auto 0px auto;
    }

    .about-title {
        text-align: left;
        font-family: "Catamaran";
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E7ECF0;
        letter-spacing: 0.22px;
    }

    .about-div p {
        width: auto;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #E7ECF0;
        font-size: 16px;
        letter-spacing: 0.16px;
        text-align: left;
        cursor: pointer;
        margin: 0px 0px 5px 0px;
    }

    .about-div p:first-child {
        margin: 6px 0px 0px 0px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .website-footer-section {
        max-width: 1024px;
        min-width: 768px;
        width: 100%;
        height: 254px !important;
        background-color: #2C4E6C;
        display: flex;
        margin: 0px auto 0px auto;
    }

    .section-container6 {
        height: 254px;
        width: 100% !important;
        margin: 0px auto 0px auto !important;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .footer-title-box {
        height: auto !important;
        width: 36%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        /* border: 1px solid #fff; */
        margin: 34px auto 0px auto;
    }

    .company-logo {
        width: 69%;
        height: 51px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        /* border: 1px solid #fff; */
        margin: 0px 0px 0px auto;
    }

    .footer-icon {
        width: 50px;
        height: 50px;
    }

    .footer-navbar-brand {
        width: auto;
        height: auto;
        font-family: Impact, Regular !important;
        font-size: 24px;
        color: #9EC7D8;
        text-align: left;
        margin: auto 0px auto 0px;
    }

    .follow-us-on {
        width: 69%;
        height: 12px;
        margin: 5px 0px 0px auto;
        display: flex;
        justify-content: center;
        /* border: 1px solid #fff; */
    }

    .follow-us-on p {
        width: 100%;
        height: 12px;
        margin: 0px 0px 0px 0px;
        text-align: right;
        color: #ffffff;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        letter-spacing: 0.25px;
        font-size: 10px;
    }

    .social-media-icon-tray {
        width: 100%;
        height: 25px;
        margin: 9px 0px 0px auto;
        display: flex;
        justify-content: flex-end;
        /* border: 1px solid #fff; */
    }

    .twitter-icon {
        margin: 0px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .instagram-icon {
        margin: 0px 0px 0px 15px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .facebook-icon {
        margin: 0px 0px 0px 15px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .youtube-icon {
        margin: 0px 0px 0px 15px;
        width: 30px !important;
        height: 25px !important;
        cursor: pointer;
        aspect-ratio: 1.4;
    }

    .linkedin-icon {
        margin: 0px 0px 0px 15px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
    }

    .copy-right-panel {
        width: auto;
        height: 13px;
        margin: 15px 0px 0px auto;
        display: inline-flex;
        align-items: center;
    }

    .copy-right-panel p {
        width: 100%;
        height: 24px;
        margin: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #ffffff;
        font-size: 10px;
        letter-spacing: 0.25px;
        text-align: right;
    }

    .copy-right-panel img {
        width: 12px;
        height: 12px;
        margin: 2px 5px 0px 5px;
    }

    .footer-routes-box {
        width: 60%;
        height: auto;
        margin: 43px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px solid #fff; */
    }

    .application-div {
        width: auto;
        height: auto;
        margin: 0px auto;
        /* border: 1px solid #fff; */
    }

    .application-title {
        text-align: left;
        font-family: "Catamaran";
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E7ECF0;
        letter-spacing: 0.22px;
    }

    .application-div p {
        width: auto !important;
        height: auto;
        margin: 0px 0px 5px 0px;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #E7ECF0;
        font-size: 16px;
        letter-spacing: 0.16px;
        text-align: left;
        cursor: pointer;
    }

    .application-div p:first-child {
        margin: 6px 0px 0px 0px;
    }

    .legal-div {
        width: auto;
        height: auto;
        /* border: 1px solid #fff; */
        margin: 0px auto;
    }

    .legal-title {
        text-align: left;
        font-family: "Catamaran";
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E7ECF0;
        letter-spacing: 0.22px;
    }

    .legal-div p {
        width: auto;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #E7ECF0;
        font-size: 16px;
        letter-spacing: 0.16px;
        text-align: left;
        cursor: pointer;
        margin: 0px 0px 5px 0px;
    }

    .legal-div p:first-child {
        margin: 6px 0px 0px 0px;
    }

    .about-div {
        width: auto;
        height: auto;
        /* border: 1px solid #fff; */
        margin: 0px auto 0px auto;
    }

    .about-title {
        text-align: left;
        font-family: "Catamaran";
        font-optical-sizing: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E7ECF0;
        letter-spacing: 0.22px;
    }

    .about-div p {
        width: auto;
        font-family: "Catamaran";
        font-weight: 400;
        font-optical-sizing: auto;
        font-style: normal;
        color: #E7ECF0;
        font-size: 16px;
        letter-spacing: 0.16px;
        text-align: left;
        cursor: pointer;
        margin: 0px 0px 5px 0px;
    }

    .about-div p:first-child {
        margin: 6px 0px 0px 0px;
    }

}