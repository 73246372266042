body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Helvetica Neue Condensed Bold';
  src: url('../public/fonts/Helvetica\ Neue\ Condensed\ Bold.ttf') format('truetype');
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap');