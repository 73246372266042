.blog3-main-container {
  width: 100%;
  height: auto;
  /* background: linear-gradient(#ffffff,  #7eb8cb,#2c4e6c ); */
  background: #ffffff;
  margin: 0px 0px 0px 0px;
}

.blog3-section1-container {
  width: 1352px;
  height: auto;
  margin: 70px auto;
  display: flex;
  flex-direction: column;
}

.blog3-main-image-container {
  width: 1080px;
  height: 480px;
  display: flex;
  margin: 0px auto 0px auto;
}

.blog3-main-image-container img {
  width: 1080px;
  height: 480px;
  margin: 0px auto;
}

.blog3-main-content {
  width: 100%;
  height: auto;
  margin: 20px auto 0px auto;
}

.blog3-main-title {
  width: 876px;
  height: auto;
  color: #000000;
  text-align: center;
  font-size: 32px;
  font-family: Catamaran;
  line-height: 48px;
  letter-spacing: 0.32px;
  margin: 0px auto 0px auto;
}

.blog3-main-desc-text {
  width: 800px;
  height: auto;
  margin: 20px auto 0px auto;
  text-align: left;
}

.blog3-main-desc-text p {
  width: 100%;
  height: auto;
  margin: 0px;
  color: #000000;
  text-align: center;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-description-container {
  width: 1352px;
  height: auto;
  margin: 20px auto;
  display: flex;
  position: relative;
  justify-content: space-between;
}



.blog3-main-sidebar {
  width: 250px;
  height: 100%;
  margin: 40px auto;
  /* border: 2px solid #1b1c1c; */
  position: sticky;
  top: 20px;
}

.blog3-main-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.blog3-main-sidebar li {
  margin-bottom: 10px;
  text-align: left;
  height: 55px;
  display: flex;
}

.blog3-main-sidebar li>a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: Catamaran;
  width: 100%;
  margin: auto 0px;
}

.blog3-main-sidebar li>a:hover {
  color: #000000;
  font-weight: 600;
  text-decoration: underline;
}

.blog3-main-sidebar li.active {
  border-left: 8px solid #0e618e;
  background-color: #ffffff;
  padding-left: 5px;
  border-radius: 0px 5px 5px 0px;
  margin-left: -15px;
}

.blog3-main-desc-content {
  width: 754px;
  height: auto;
  margin: 20px auto 0px auto;
  /* border: 2px solid #000000; */
}

.blog3-main-desc-text2 {
  width: 680px;
  height: auto;
  margin: 20px auto 0px auto;
  text-align: left;

}

.blog3-main-desc-text2 p {
  width: 680px;
  height: auto;
  margin: 10px 0px 0px 20px;
  color: #000000;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text2 li {
  width: 660px;
  height: auto;
  margin: 10px 0px 0px 0px;
  color: #000000;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text3,
.blog3-main-desc-text4,
.blog3-main-desc-text5,
.blog3-main-desc-text6 {
  width: 680px;
  height: auto;
  margin: 20px auto 0px auto;
}

.blog3-main-desc-text2 h3,
.blog3-main-desc-text3 h3,
.blog3-main-desc-text4 h3,
.blog3-main-desc-text5 h3 {
  margin: 20px 0px 0px 10px;
}

.blog3-main-desc-text3 li {
  margin: 10px 0px 0px 0px;
  width: 660px;
  height: auto;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text3 p {
  margin: 10px 0px 0px 20px;
  width: 660px;
  height: auto;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text4 p {
  width: 660px;
  margin: 10px 0px 0px 20px;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text4 li {
  margin: 10px 0px 0px 0px;
  width: 660px;
  height: auto;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text5 p {
  width: 660px;
  margin: 10px 0px 0px 20px;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-desc-text5 li {
  margin: 10px 0px 0px 0px;
  width: 660px;
  height: auto;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
  text-align: left;
}

.blog3-main-desc-text6 p {
  margin: 10px 0px 0px 20px;
  width: 660px;
  height: auto;
  font-size: 18px;
  font-family: Catamaran;
  line-height: 27px;
  letter-spacing: 0.18px;
}

.blog3-main-right-sidebar {
  width: 320px;
  height: 620px;
  margin: 40px auto;
  background-color: #F1EEF9;
}

.blog3-main-right-sidebar-section1,
.blog3-main-right-sidebar-section2 {
  width: 280px;
  height: auto;
  margin: 25px auto 0px auto;
  display: flex;
  flex-direction: column;
}

.blog3-main-right-sidebar-section1 h4 {
  width: auto;
  height: auto;
  font-size: 28px;
  font-weight: 550;
  text-align: center;
  margin: 0px;
}

.blog3-main-right-sidebar-section1 p {
  width: auto;
  height: auto;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin: 15px auto;
  line-height: 30px;
}

.blog3-main-right-sidebar-button {
  width: 180px;
  height: 50px;
  background-color: #427bac;
  color: #ffffff;
  font-family: "Catamaran";
  font-weight: 500;
  font-size: 18px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  letter-spacing: 0.18px;
  cursor: pointer;
  margin: 15px auto 0px auto;
}

.blog3-main-right-sidebar-button:hover {
  color: #FFFFFF;
  background-color: #48588D;
  border: none;
}

.blog3-main-right-sidebar-section2 p {
  width: auto;
  height: auto;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  margin: 10px auto 0px auto;
  line-height: 30px;
  font-style: italic;
}

.blog3-right-sidebar-related-topics-routes {
  width: 280px;
  height: auto;
}

.blog3-right-sidebar-related-topics-routes p {
  width: auto;
  height: auto;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  margin: 10px auto;
  line-height: 30px;
  font-style: normal;
  cursor: pointer;
}

.blog3-right-sidebar-related-topics-routes p:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 440px) {
  .blog3-main-container {
    max-width: 440px;
    width: 100%;
    height: auto;
    /* background: linear-gradient(#ffffff, #7eb8cb, #2c4e6c); */
    margin: 0px 0px 0px 0px;
  }

  .blog3-section1-container {
    width: 100%;
    height: auto;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-image-container {
    width: 94%;
    height: 180px;
    display: flex;
    margin: 0px auto 0px auto;
  }

  .blog3-main-image-container img {
    width: 100%;
    height: 180px;
    margin: 0px auto;
  }

  .blog3-main-content {
    width: 83%;
    height: auto;
    margin: 0px auto 0px auto !important;
  }

  .blog3-main-desc-content {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto !important;
    /* border: 1px solid #000; */
  }

  .blog3-main-title {
    width: 100%;
    height: auto;
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin: 20px auto 0px auto;
  }

  .blog3-main-desc-text {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .blog3-main-desc-text p {
    width: 97%;
    text-align: center;
    height: auto;
    margin: 0px auto;
    color: #000000;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-description-container {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    display: flex;
    position: relative;
    flex-direction: column;
    /* border: 2px solid #7eb8cb; */
  }

  .blog3-main-sidebar {
    width: 250px;
    height: 100%;
    margin: 40px auto;
    display: none;
    /* border: 2px solid #1b1c1c; */
    position: sticky;
    top: 20px;
  }

  .blog3-main-desc-text2 {
    width: 100%;
    height: auto;
    margin: 10px auto 0px auto;
    text-align: left;
    /* border: 1px solid #000000; */
  }

  .blog3-main-desc-text2 h2 {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #000000;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text2 p {
    width: 93%;
    height: auto;
    margin: 5px auto 0px auto;
    color: #000000;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-desc-text2 li,
  .blog3-main-desc-text3 li,
  .blog3-main-desc-text4 li,
  .blog3-main-desc-text5 li {
    width: 98%;
    height: auto;
    margin: 5px 0px 0px -15px;
    color: #000000;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-desc-text3,
  .blog3-main-desc-text4,
  .blog3-main-desc-text5,
  .blog3-main-desc-text6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* border: 2px solid #7eb8cb; */
  }

  .blog3-main-desc-text2 h2,
  .blog3-main-desc-text3 h2,
  .blog3-main-desc-text4 h2,
  .blog3-main-desc-text5 h2,
  .blog3-main-desc-text6 h2 {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #000000;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text2 h3,
  .blog3-main-desc-text3 h3,
  .blog3-main-desc-text4 h3,
  .blog3-main-desc-text5 h3 {
    margin: 10px auto 0px auto;
    width: 100%;
    height: auto;
    color: #000000;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-desc-text3 p {
    margin: 5px auto 0px auto;
    width: 93%;
    height: auto;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-desc-text4 p {
    width: 93%;
    margin: 5px auto 0px auto;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }


  .blog3-main-desc-text5 p {
    width: 93%;
    margin: 5px auto 0px auto;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-desc-text6 p {
    margin: 5px auto 0px auto;
    width: 93%;
    height: auto;
    font-size: 12px;
    font-family: Catamaran;
    line-height: 18px;
    letter-spacing: 0.12px;
  }

  .blog3-main-right-sidebar {
    width: 280px;
    height: 390px;
    margin: 40px auto;
    background-color: #F1EEF9;
  }

  .blog3-main-right-sidebar-section1 {
    width: 173px;
    height: auto;
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-right-sidebar-section1 h4 {
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: 550;
    text-align: center;
    margin: 0px;
  }

  .blog3-main-right-sidebar-section1 p {
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin: 15px auto 0px auto;
    line-height: 15px;
  }

  .blog3-main-right-sidebar-button {
    width: 130px;
    height: 40px;
    background-color: #427bac;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 12px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    letter-spacing: 0.12px;
    cursor: pointer;
    margin: 10px auto 0px auto;
  }

  .blog3-main-right-sidebar-button:hover {
    color: #FFFFFF;
    background-color: #48588D;
    border: none;
  }

  .blog3-main-right-sidebar-section2 {
    width: 240px;
    height: auto;
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-right-sidebar-section2 p {
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    margin: 10px auto 0px auto;
    line-height: 30px;
    font-style: italic;
  }

  .blog3-right-sidebar-related-topics-routes {
    width: 240px;
    height: auto;
  }

  .blog3-right-sidebar-related-topics-routes p {
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    margin: 10px auto;
    line-height: 15px;
    font-style: normal;
    cursor: pointer;
    text-decoration: underline;
  }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .blog3-main-container {
    width: 100%;
    height: auto;
    /* background: linear-gradient(#ffffff,  #7eb8cb,#2c4e6c ); */
    background: #ffffff;
    margin: 0px 0px 0px 0px;
  }

  .blog3-section1-container {
    width: 100%;
    height: auto;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-image-container {
    width: 90%;
    height: 380px;
    display: flex;
    margin: 0px auto 0px auto;
  }

  .blog3-main-image-container img {
    width: 100%;
    height: 100%;
    margin: 0px auto;
  }

  .blog3-main-content {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .blog3-main-title {
    width: 81%;
    height: auto;
    color: #000000;
    text-align: center;
    font-size: 30px;
    font-family: Catamaran;
    line-height: 45px;
    letter-spacing: 0.30px;
    margin: 0px auto 0px auto;
  }

  .blog3-main-desc-text {
    width: 80%;
    height: auto;
    margin: 12px auto 0px auto;
    text-align: left;
  }

  .blog3-main-desc-text p {
    width: 100%;
    height: auto;
    margin: 0px;
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-description-container {
    width: 90%;
    height: auto;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
  }



  .blog3-main-sidebar {
    width: 250px;
    height: 100%;
    margin: 40px auto;
    /* border: 2px solid #1b1c1c; */
    position: sticky;
    top: 20px;
    display: none;
  }

  .blog3-main-sidebar ul {
    list-style-type: none;
    padding: 0;
  }

  .blog3-main-sidebar li {
    margin-bottom: 10px;
    text-align: left;
    height: 55px;
    display: flex;
  }

  .blog3-main-sidebar li>a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    font-family: Catamaran;
    width: 100%;
    margin: auto 0px;
  }

  .blog3-main-sidebar li>a:hover {
    color: #000000;
    font-weight: 600;
    text-decoration: underline;
  }

  .blog3-main-sidebar li.active {
    border-left: 8px solid #0e618e;
    background-color: #ffffff;
    padding-left: 5px;
    border-radius: 0px 5px 5px 0px;
    margin-left: -15px;
  }

  .blog3-main-desc-content {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* border: 2px solid #000000; */
  }

  .blog3-main-desc-text2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    text-align: left;

  }

  .blog3-main-desc-text2 p {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #000000;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text2 li {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #000000;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text3,
  .blog3-main-desc-text4,
  .blog3-main-desc-text5,
  .blog3-main-desc-text6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .blog3-main-desc-text2 h3,
  .blog3-main-desc-text3 h3,
  .blog3-main-desc-text4 h3,
  .blog3-main-desc-text5 h3 {
    margin: 20px 0px 0px 10px;
  }

  .blog3-main-desc-text3 li {
    margin: 10px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text3 p {
    margin: 10px 0px 0px 20px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text4 p {
    width: 100%;
    margin: 10px 0px 0px 20px;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text4 li {
    margin: 10px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text5 p {
    width: 100%;
    margin: 10px 0px 0px 20px;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text5 li {
    margin: 10px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-align: left;
  }

  .blog3-main-desc-text6 p {
    margin: 10px 0px 0px 20px;
    width: 97%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-right-sidebar {
    width: 70%;
    height: auto;
    margin: 40px auto;
    background-color: #F1EEF9;
  }

  .blog3-main-right-sidebar-section1,
  .blog3-main-right-sidebar-section2 {
    width: 80%;
    height: auto;
    margin: 25px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-right-sidebar-section1 h4 {
    width: auto;
    height: auto;
    font-size: 28px;
    font-weight: 550;
    text-align: center;
    margin: 0px;
  }

  .blog3-main-right-sidebar-section1 p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin: 15px auto;
    line-height: 30px;
  }

  .blog3-main-right-sidebar-button {
    width: 180px;
    height: 50px;
    background-color: #427bac;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    border: 2px solid #ffffff;
    border-radius: 8px;
    letter-spacing: 0.18px;
    cursor: pointer;
    margin: 15px auto 0px auto;
  }

  .blog3-main-right-sidebar-button:hover {
    color: #FFFFFF;
    background-color: #48588D;
    border: none;
  }

  .blog3-main-right-sidebar-section2 p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin: 10px auto 0px auto;
    line-height: 30px;
    font-style: italic;
  }

  .blog3-right-sidebar-related-topics-routes {
    width: 100%;
    height: auto;
  }

  .blog3-right-sidebar-related-topics-routes p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: 10px auto;
    line-height: 30px;
    font-style: normal;
    cursor: pointer;
  }

  .blog3-right-sidebar-related-topics-routes p:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .blog3-main-container {
    width: 100%;
    height: auto;
    /* background: linear-gradient(#ffffff,  #7eb8cb,#2c4e6c ); */
    background: #ffffff;
    margin: 0px 0px 0px 0px;
  }

  .blog3-section1-container {
    width: 100%;
    height: auto;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-image-container {
    width: 90%;
    height: 380px;
    display: flex;
    margin: 0px auto 0px auto;
  }

  .blog3-main-image-container img {
    width: 100%;
    height: 100%;
    margin: 0px auto;
  }

  .blog3-main-content {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .blog3-main-title {
    width: 81%;
    height: auto;
    color: #000000;
    text-align: center;
    font-size: 30px;
    font-family: Catamaran;
    line-height: 45px;
    letter-spacing: 0.30px;
    margin: 0px auto 0px auto;
  }

  .blog3-main-desc-text {
    width: 80%;
    height: auto;
    margin: 12px auto 0px auto;
    text-align: left;
  }

  .blog3-main-desc-text p {
    width: 100%;
    height: auto;
    margin: 0px;
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-description-container {
    width: 90%;
    height: auto;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
  }



  .blog3-main-sidebar {
    width: 250px;
    height: 100%;
    margin: 40px auto;
    /* border: 2px solid #1b1c1c; */
    position: sticky;
    top: 20px;
    display: none;
  }

  .blog3-main-sidebar ul {
    list-style-type: none;
    padding: 0;
  }

  .blog3-main-sidebar li {
    margin-bottom: 10px;
    text-align: left;
    height: 55px;
    display: flex;
  }

  .blog3-main-sidebar li>a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    font-family: Catamaran;
    width: 100%;
    margin: auto 0px;
  }

  .blog3-main-sidebar li>a:hover {
    color: #000000;
    font-weight: 600;
    text-decoration: underline;
  }

  .blog3-main-sidebar li.active {
    border-left: 8px solid #0e618e;
    background-color: #ffffff;
    padding-left: 5px;
    border-radius: 0px 5px 5px 0px;
    margin-left: -15px;
  }

  .blog3-main-desc-content {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* border: 2px solid #000000; */
  }

  .blog3-main-desc-text2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    text-align: left;

  }

  .blog3-main-desc-text2 p {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #000000;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text2 li {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #000000;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text3,
  .blog3-main-desc-text4,
  .blog3-main-desc-text5,
  .blog3-main-desc-text6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .blog3-main-desc-text2 h3,
  .blog3-main-desc-text3 h3,
  .blog3-main-desc-text4 h3,
  .blog3-main-desc-text5 h3 {
    margin: 20px 0px 0px 10px;
  }

  .blog3-main-desc-text3 li {
    margin: 10px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text3 p {
    margin: 10px 0px 0px 20px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text4 p {
    width: 100%;
    margin: 10px 0px 0px 20px;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text4 li {
    margin: 10px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text5 p {
    width: 100%;
    margin: 10px 0px 0px 20px;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-desc-text5 li {
    margin: 10px 0px 0px 0px;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-align: left;
  }

  .blog3-main-desc-text6 p {
    margin: 10px 0px 0px 20px;
    width: 97%;
    height: auto;
    font-size: 16px;
    font-family: Catamaran;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .blog3-main-right-sidebar {
    width: 70%;
    height: auto;
    margin: 40px auto;
    background-color: #F1EEF9;
  }

  .blog3-main-right-sidebar-section1,
  .blog3-main-right-sidebar-section2 {
    width: 80%;
    height: auto;
    margin: 25px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .blog3-main-right-sidebar-section1 h4 {
    width: auto;
    height: auto;
    font-size: 28px;
    font-weight: 550;
    text-align: center;
    margin: 0px;
  }

  .blog3-main-right-sidebar-section1 p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin: 15px auto;
    line-height: 30px;
  }

  .blog3-main-right-sidebar-button {
    width: 180px;
    height: 50px;
    background-color: #427bac;
    color: #ffffff;
    font-family: "Catamaran";
    font-weight: 500;
    font-size: 18px;
    border: 2px solid #ffffff;
    border-radius: 8px;
    letter-spacing: 0.18px;
    cursor: pointer;
    margin: 15px auto 0px auto;
  }

  .blog3-main-right-sidebar-button:hover {
    color: #FFFFFF;
    background-color: #48588D;
    border: none;
  }

  .blog3-main-right-sidebar-section2 p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin: 10px auto 0px auto;
    line-height: 30px;
    font-style: italic;
  }

  .blog3-right-sidebar-related-topics-routes {
    width: 100%;
    height: auto;
  }

  .blog3-right-sidebar-related-topics-routes p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: 10px auto;
    line-height: 30px;
    font-style: normal;
    cursor: pointer;
  }

  .blog3-right-sidebar-related-topics-routes p:hover {
    text-decoration: underline;
  }
}