.start-budgeting-main-container {
  width: 768px;
  height: auto;
  margin: 0px auto 0px auto;
}

.start-budgeting-breadcrumb {
  width: 80%;
  height: auto;
  margin: 46px 0px 0px 68px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.start-budgeting-breadcrumb p {
  margin: 0px 10px 0px 0px;
  height: auto;
  text-align: left;
  font-size: 16px;
  font-family: "Catamaran";
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 1;
  width: auto;
}

.start-budgeting-breadcrumb p:last-child {
  font-weight: bold;
}

.start-budgeting-title-div {
  width: auto;
  height: auto;
  margin: 30px 0px 0px 72px;
}

.start-budgeting-title-div p {
  width: auto;
  height: auto;
  margin: 0px;
  text-align: left;
  font-size: 24px;
  font-family: "Catamaran";
  letter-spacing: 0.24px;
  font-weight: bold;
  color: #000000;
  opacity: 1;
}

.start-budgeting-description {
  width: 600px;
  height: auto;
  margin: 14px 0px 0px 72px;
}

.start-budgeting-description p {
  margin: 0px 0px 0px 10px;
  width: 97%;
  height: auto;
  text-align: left;
  font-size: 18px;
  font-family: "Catamaran";
  line-height: 27px;
  letter-spacing: 0.18px;
  color: #333333;
  opacity: 1;
}

.start-budgeting-description ol {
  width: 491px;
  height: auto;
  margin: 6px 0px 0px 0px;
  column-count: 2;
  column-gap: 85px;
}

.start-budgeting-description li {
  width: auto;
  height: auto;
  line-height: 27px;
  letter-spacing: 0.18px;
  margin: 0px 0px 2px 0px;
}



.start-budgeting-description li:last-child {
  margin: 0px;
}

.start-budgeting-section-comtainer1 {
  width: 600px;
  height: auto;
  margin: 9px 0px 0px 72px;
}

.start-budgeting-section-container1-title {
  width: 100%;
  height: auto;
  margin: 0px;
}

.start-budgeting-section-container1-title p {
  width: auto;
  height: auto;
  margin: 0px;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  font-family: "Catamaran";
  letter-spacing: 0.24px;
  color: #333333;
  line-height: 36px;
  opacity: 1;
}

.start-budgeting-section-container1-description {
  width: 600px;
  height: auto;
  margin: 7px auto 0px auto;
}

.start-budgeting-section-container1-description p,
ol>li {
  width: 580px;
  height: auto;
  margin: 0px auto 0px auto;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  font-family: "Catamaran";
  line-height: 27px;
  color: #333333;
  opacity: 1;
}

span {
  font-weight: 500 !important;
}

.start-budgeting-section-container1-img1-div {
  width: 378px;
  height: 400px;
  margin: 20px auto 20px auto;
}

.start-budgeting-section-container1-img2-div {
  width: 378px;
  height: 600px;
  margin: 20px auto 20px auto;
}

.start-budgeting-section-container1-img3-div {
  width: 378px;
  height: 794px;
  margin: 20px auto 20px auto;
}

.start-budgeting-section-container1-img4-div {
  width: 378px;
  height: 250px;
  margin: 20px auto 20px auto;
}

.start-budgeting-section-container1-img5-div {
  width: 628px;
  height: 400px;
  margin: 20px auto 20px auto;
  /* border: 1px solid #000; */
}

.start-budgeting-section-container1-img1-div img,
.start-budgeting-section-container1-img2-div img,
.start-budgeting-section-container1-img3-div img,
.start-budgeting-section-container1-img4-div img,
.start-budgeting-section-container1-img5-div img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}


@media only screen and (max-width: 440px) {
  .start-budgeting-main-container {
    max-width: 440px;
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .start-budgeting-breadcrumb {
    width: 90%;
    height: 16px;
    margin: 23px auto 0px auto;
    display: flex;
    justify-content: start;
    align-items: start;

  }

  .start-budgeting-breadcrumb p {
    width: auto !important;
    margin: 0px 5px 0px 0px;
    height: 16px;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #000000;
    opacity: 1;

    /* line-height: 15px; */
  }

  .start-budgeting-breadcrumb p:last-child {
    font-weight: bold;
  }

  .start-budgeting-title-div {
    width: 90%;
    height: auto;
    margin: 15px auto 0px auto;
  }

  .start-budgeting-title-div p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
  }

  .start-budgeting-description {
    width: 90%;
    height: auto;
    margin: 7px auto 0px auto;
  }

  .start-budgeting-description p {
    margin: 0px 0px 0px 10px;
    width: 90%;
    height: auto;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-description ol {
    width: 90%;
    height: auto;
    /* border: 1px solid #000; */
    margin: 10px auto 0px auto;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #333333;
    column-count: 2;
    column-gap: 60px;
    padding-left: 20px;
  }

  .start-budgeting-description li {
    width: auto;
    height: auto;
    margin: 0px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-description li:last-child {
    margin: 0px;
  }

  .start-budgeting-section-comtainer1 {
    width: 100%;
    height: auto;
    margin: 18px auto 0px auto;
    /* background-color: antiquewhite; */
  }

  .start-budgeting-section-container1-title {
    width: 92%;
    height: 27px;
    margin: 0px auto 0px auto;
  }

  .start-budgeting-section-container1-title p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.16px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-section-container1-description {
    width: 94%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .start-budgeting-section-container1-description p {
    width: 90%;
    height: auto;
    margin: 5px 0px 0px 10px;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-section-container1-description ol {
    width: 100%;
    padding-left: 0px;
    margin: 5px auto 0px auto;
  }

  .start-budgeting-section-container1-description ol>li {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px 20px;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-section-container1-img1-div,
  .start-budgeting-section-container1-img2-div,
  .start-budgeting-section-container1-img3-div,
  .start-budgeting-section-container1-img4-div,
  .start-budgeting-section-container1-img5-div {
    width: 88%;
    height: 88%;
    margin: 20px auto 20px auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .start-budgeting-main-container {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .start-budgeting-breadcrumb {
    width: 80%;
    height: auto;
    margin: 46px 0px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .start-budgeting-breadcrumb p {
    margin: 0px 10px 0px 0px;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    color: #000000;
    opacity: 1;
    width: auto;
  }

  .start-budgeting-breadcrumb p:last-child {
    font-weight: bold;
  }

  .start-budgeting-title-div {
    width: auto;
    height: auto;
    margin: 20px 0px 0px 40px;
  }

  .start-budgeting-title-div p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 24px;
    font-family: "Catamaran";
    letter-spacing: 0.24px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
  }

  .start-budgeting-description {
    width: 90%;
    height: auto;
    margin: 14px 0px 0px 40px;
    /* border: 1px solid #000; */
  }

  .start-budgeting-description p {
    margin: 0px 0px 0px 10px;
    width: 90%;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-description ol {
    width: 491px;
    height: auto;
    margin: 6px 0px 0px 0px;
    column-count: 2;
    column-gap: 85px;
  }

  .start-budgeting-description li {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin: 0px 0px 2px 0px;
  }

  .start-budgeting-description li:last-child {
    margin: 0px;
  }

  .start-budgeting-section-comtainer1 {
    width: 600px;
    height: auto;
    margin: 9px auto 0px auto;
  }

  .start-budgeting-section-container1-title {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 40px;
  }

  .start-budgeting-section-container1-title p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    font-family: "Catamaran";
    letter-spacing: 0.24px;
    color: #333333;
    line-height: 36px;
    opacity: 1;
  }

  .start-budgeting-section-container1-description {
    width: 80%;
    height: auto;
    margin: 7px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .start-budgeting-section-container1-description p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    font-family: "Catamaran";
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-section-container1-description ol {
    padding-left: 20px;
  }

  .start-budgeting-section-container1-description ol>li {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    font-family: "Catamaran";
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  span {
    font-weight: 500 !important;
  }

  .start-budgeting-section-container1-img1-div {
    width: 378px;
    height: 400px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img2-div {
    width: 378px;
    height: 600px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img3-div {
    width: 378px;
    height: 794px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img4-div {
    width: 378px;
    height: 250px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img5-div {
    width: 471px;
    height: 300px;
    margin: 20px 0px 20px -30px;
    /* border: 1px solid #000; */
  }

  .start-budgeting-section-container1-img1-div img,
  .start-budgeting-section-container1-img2-div img,
  .start-budgeting-section-container1-img3-div img,
  .start-budgeting-section-container1-img4-div img,
  .start-budgeting-section-container1-img5-div img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .start-budgeting-main-container {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .start-budgeting-breadcrumb {
    width: 80%;
    height: auto;
    margin: 46px 0px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .start-budgeting-breadcrumb p {
    margin: 0px 10px 0px 0px;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    letter-spacing: 0.16px;
    color: #000000;
    opacity: 1;
    width: auto;
  }

  .start-budgeting-breadcrumb p:last-child {
    font-weight: bold;
  }

  .start-budgeting-title-div {
    width: auto;
    height: auto;
    margin: 20px 0px 0px 40px;
  }

  .start-budgeting-title-div p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 24px;
    font-family: "Catamaran";
    letter-spacing: 0.24px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
  }

  .start-budgeting-description {
    width: 90%;
    height: auto;
    margin: 14px 0px 0px 40px;
    /* border: 1px solid #000; */
  }

  .start-budgeting-description p {
    margin: 0px 0px 0px 10px;
    width: 90%;
    height: auto;
    text-align: left;
    font-size: 16px;
    font-family: "Catamaran";
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-description ol {
    width: 491px;
    height: auto;
    margin: 6px 0px 0px 0px;
    column-count: 2;
    column-gap: 85px;
  }

  .start-budgeting-description li {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin: 0px 0px 2px 0px;
  }

  .start-budgeting-description li:last-child {
    margin: 0px;
  }

  .start-budgeting-section-comtainer1 {
    width: 600px;
    height: auto;
    margin: 9px auto 0px auto;
  }

  .start-budgeting-section-container1-title {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 40px;
  }

  .start-budgeting-section-container1-title p {
    width: auto;
    height: auto;
    margin: 0px;
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    font-family: "Catamaran";
    letter-spacing: 0.24px;
    color: #333333;
    line-height: 36px;
    opacity: 1;
  }

  .start-budgeting-section-container1-description {
    width: 80%;
    height: auto;
    margin: 7px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .start-budgeting-section-container1-description p {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    font-family: "Catamaran";
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  .start-budgeting-section-container1-description ol {
    padding-left: 20px;
  }

  .start-budgeting-section-container1-description ol>li {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    font-family: "Catamaran";
    line-height: 24px;
    color: #333333;
    opacity: 1;
  }

  span {
    font-weight: 500 !important;
  }

  .start-budgeting-section-container1-img1-div {
    width: 378px;
    height: 400px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img2-div {
    width: 378px;
    height: 600px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img3-div {
    width: 378px;
    height: 794px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img4-div {
    width: 378px;
    height: 250px;
    margin: 20px auto 20px auto;
  }

  .start-budgeting-section-container1-img5-div {
    width: 471px;
    height: 300px;
    margin: 20px 0px 20px -30px;
    /* border: 1px solid #000; */
  }

  .start-budgeting-section-container1-img1-div img,
  .start-budgeting-section-container1-img2-div img,
  .start-budgeting-section-container1-img3-div img,
  .start-budgeting-section-container1-img4-div img,
  .start-budgeting-section-container1-img5-div img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}