.service-terms-main-container {
  width: 100%;
  height: 4322px;
  background-color: #f8f8f8;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  font-family: "Catamaran";
}

.st-actual-content-container {
  width: 1080px;
  height: 4322px;
  border-left: 1px solid rgb(144, 144, 144, 0.5);
  border-right: 1px solid rgb(144, 144, 144, 0.5);
  margin: 0px auto 0px auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-flex-start;
}

.st-first-section {
  width: 1080px;
  height: auto;
  margin: 50px auto 0px auto;
}

.st-heading1 {
  width: 100%;
  height: 40px;
  margin: 0px auto 0px auto;
}

.st-heading1 p {
  width: 250px;
  height: 40px;
  margin: 0px auto 0px auto;
  text-align: center;
  font-size: 32px;
  color: #000000;
  font-family: "Helvetica Neue Condensed Bold", sans-serif;
}

.st-last-updated-div {
  width: 100%;
  height: 29px;
  margin: 23px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
}

.st-last-updated-div p {
  width: 233px;
  height: 20px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
  margin: 0px 107px 0px auto;
}

.st-description1 {
  width: 850px;
  height: auto;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  color: #000000;
  opacity: 1;
  margin: 54px auto 0px auto;
}

.st-description1 p {
  margin: 0px;
}

.st-sub-section1 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section1-title {
  width: 253px;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;

}

.st-sub-section1-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section1-description p {
  text-align: left;
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.st-sub-section1-description p:last-child {
  margin: 0px;
}

.st-sub-section2 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section2-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;

}

.st-sub-section2-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section2-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section2-description p:last-child {
  margin: 0px;
}

.st-sub-section3 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section3-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;

}

.st-sub-section3-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section3-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section3-description p:last-child {
  margin: 0px;
}

.st-sub-section4 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section4-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section4-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section4-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section4-description p:last-child {
  margin: 0px;
}

.st-sub-section5 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section5-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section5-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section5-description p {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section6 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section6-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section6-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section6-description p {
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin: 0px 0px 12px 0px;
}

.st-sub-section6-description p:last-child {
  margin: 0px;
}

.st-sub-section7 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section7-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section7-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section7-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section7-description p:last-child {
  margin: 0px;
}

.st-sub-section8 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section8-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section8-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section8-description p {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section9 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section9-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section9-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section9-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section9-description p:last-child {
  margin: 0px;
}

.st-sub-section9-description ol {
  margin: 0px;
  color: #333333;
}

.st-sub-section9-description ol>li {

  letter-spacing: 0.16px;
  line-height: 24px;
  margin: 0px 0px 12px 0px;
}

.st-sub-section9-description ol>li:last-child {
  margin: 0px;

}

.st-sub-section10 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section10-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section10-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section10-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section10-description p:last-child {
  margin: 0px;
}

.st-sub-section11 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section11-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section11-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section11-description p {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section12 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
}

.st-sub-section12-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
}

.st-sub-section12-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
}

.st-sub-section12-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;

  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */

}

.st-sub-section12-description p:last-child {
  margin: 0px;
}

.st-sub-section13 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: rgba(7, 52, 92, 0.718); */
}

.st-sub-section13-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */

}

.st-sub-section13-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.st-sub-section13-description p {
  margin: 0px;
  font-size: 16px;

  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.st-sub-section14 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: rgba(7, 52, 92, 0.718); */
}

.st-sub-section14-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */

}

.st-sub-section14-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.st-sub-section14-description p {
  margin: 0px;
  font-size: 16px;

  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.st-sub-section14-description ol {
  margin: 12px 0px 0px 0px;
  color: #333333;
}

.st-sub-section14-description ol>li {
  margin: 0px 0px 12px 0px;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.st-sub-section14-description ol>li:last-child {
  margin: 0px;
}


.st-sub-section15 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: rgba(7, 52, 92, 0.718); */
}

.st-sub-section15-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */

}

.st-sub-section15-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.st-sub-section15-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;

  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */

}

.st-sub-section15-description p:last-child {
  margin: 0px;
}

.st-sub-section16 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: rgba(7, 52, 92, 0.718); */
}

.st-sub-section16-title {
  width: 100%;
  height: 20px;
  text-align: left;
  font: normal normal medium 18px/40px Helvetica Neue;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */

}

.st-sub-section16-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.st-sub-section16-description p {
  margin: 0px;
  font-size: 16px;

  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */
}

.st-sub-section17 {
  width: 850px;
  height: auto;
  margin: 20px auto 0px auto;
  /* background-color: rgba(7, 52, 92, 0.718); */
}

.st-sub-section17-title {
  width: 100%;
  height: 20px;
  text-align: left;
  /* font: normal normal medium 18px/40px Helvetica Neue; */
  font-size: 18px;
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 1;
  margin: 0px 0px 14px 0px;
  /* text-transform: uppercase; */

}

.st-sub-section17-description {
  width: 850px;
  height: auto;
  margin: 0px auto 0px auto;
  /* background: #7d3434; */
}

.st-sub-section17-description p {
  margin: 0px 0px 12px 0px;
  font-size: 16px;

  letter-spacing: 0.16px;
  line-height: 24px;
  /* font: normal normal medium 16px Helvetica; */

}

.st-sub-section17-description p:last-child {
  margin: 0px;
}

.st-go-to-top {
  width: 100%;
  height: auto;
  margin: 20px 0px 0px 0px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
}

@media only screen and (max-width: 440px) {
  .service-terms-main-container {
    width: 100%;
    height: auto;
    /* background-color: #f8f8f8; */
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
  }

  .st-actual-content-container {
    width: 100%;
    height: auto;
    border: none;
    margin: 0px auto 0px auto;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    /* align-items: ; */
    justify-content: flex-flex-start;
    font-weight: 500 !important;
  }

  .st-first-section {
    width: 100%;
    height: auto;
    margin: 29px auto 0px auto;
    /* background-color: beige; */
  }

  .st-heading1 {
    width: 100%;
    height: 28px;
    margin: 0px auto 0px auto;
  }

  .st-heading1 p {
    width: 168px;
    height: 28px;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 24px;
    letter-spacing: 0.24px;
    color: #000000;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .st-last-updated-div {
    width: 95%;
    height: 15px;
    margin: 14px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    /* text-align: flex-end; */
  }

  .st-last-updated-div p {
    width: 233px;
    height: 15px;
    text-align: right;
    /* font: normal normal medium 12px/32px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    /* letter-spacing: 0px */
    margin: 0px 0px 0px auto;
  }

  .st-description1 {
    width: 90%;
    height: auto;
    text-align: left;
    /* font: normal normal medium 12px/18px Helvetica Neue; */
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #000000;
    opacity: 1;
    margin: 15px auto 0px auto;
    /* background-color: #f3f3f3;    */
  }

  .st-description1 p {
    margin: 0px;
  }

  .st-sub-section1 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: #b32c2c; */
  }

  .st-sub-section1-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 12px/18px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
  }

  .st-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px/18px Helvetica; */
  }

  .st-sub-section1-description p:last-child {
    margin: 0px;
  }

  .st-sub-section2 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section2-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/24px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #9a2323; */
  }

  .st-sub-section2-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section2-description p:last-child {
    margin: 0px;
  }

  .st-sub-section3 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section3-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 12px/18px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section3-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section3-description p:last-child {
    margin: 0px;
  }

  .st-sub-section4 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section4-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/40px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section4-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */

  }

  .st-sub-section4-description p:last-child {
    margin: 0px;
  }

  .st-sub-section5 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section5-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section5-description p {
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section6 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .st-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section6-description p {
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
    margin: 0px 0px 10px 0px;
  }

  .st-sub-section6-description p:last-child {
    margin: 0px;
  }

  .st-sub-section7 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section7-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section7-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px/18px Helvetica; */
  }

  .st-sub-section7-description p:last-child {
    margin: 0px;
  }

  .st-sub-section8 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section8-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */
  }

  .st-sub-section8-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section8-description p {
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section9 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section9-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section9-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section9-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section9-description p:last-child {
    margin: 0px;
  }

  .st-sub-section9-description ol {
    margin: 0px 0px 0px -15px;
    color: #333333;
    letter-spacing: 0.12px;
    line-height: 18px;
  }

  .st-sub-section9-description ol>li {
    margin: 0px 0px 10px 0px;
  }

  .st-sub-section9-description ol>li:last-child {
    margin: 0px;
  }

  .st-sub-section10 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section10-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section10-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section10-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section10-description p:last-child {
    margin: 0px;
  }

  .st-sub-section11 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section11-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section11-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section11-description p {
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section12 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section12-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section12-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section12-description p {
    margin: 0px 0px 12px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */

  }

  .st-sub-section12-description p:last-child {
    margin: 0px;
  }

  .st-sub-section13 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0s.718); */
  }

  .st-sub-section13-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section13-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section13-description p {
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section14 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section14-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section14-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section14-description p {
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section14-description ol {
    margin: 10px 0px 0px 0px;
    color: #333333;
    letter-spacing: 0.12px;
    line-height: 18px;
  }

  .st-sub-section14-description ol>li {
    margin: 0px 0px 10px 0px;
  }

  .st-sub-section14-description ol>li:last-child {
    margin: 0px;
  }

  .st-sub-section15 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section15-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section15-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section15-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */

  }

  .st-sub-section15-description p:last-child {
    margin: 0px;
  }

  .st-sub-section16 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section16-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section16-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section16-description p {
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
    /* font: normal normal medium 12px Helvetica; */
  }

  .st-sub-section17 {
    width: 90%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section17-title {
    width: 100%;
    height: 17px;
    text-align: left;
    /* font: normal normal medium 14px/20px Helvetica Neue; */
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 12px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section17-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section17-description p {
    margin: 0px 0px 10px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 18px;
  }

  .st-sub-section17-description p:last-child {
    margin: 0px;
  }

  .st-go-to-top {
    width: 100%;
    height: auto;
    margin: 20px 0px 20px -20px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .service-terms-main-container {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
  }

  .st-actual-content-container {
    width: 90%;
    height: auto;
    border-left: none;
    border-right: none;
    margin: 0px auto 0px auto;
    background: none;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .st-first-section {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .st-heading1 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-heading1 p {
    width: auto;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    color: #000000;
    letter-spacing: 0.32px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .st-last-updated-div {
    width: 100%;
    height: 29px;
    margin: 23px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
  }

  .st-last-updated-div p {
    width: 233px;
    height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0px;
    margin: 0px 0px 0px auto;
  }

  .st-description1 {
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
  }

  .st-description1 p {
    margin: 0px;
  }

  .st-sub-section1 {
    width: 100%;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
  }

  .st-sub-section1-title {
    width: auto;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .st-sub-section1-description p:last-child {
    margin: 0px;
  }

  .st-sub-section2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section2-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;

  }

  .st-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section2-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section2-description p:last-child {
    margin: 0px;
  }

  .st-sub-section3 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section3-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;

  }

  .st-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section3-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section3-description p:last-child {
    margin: 0px;
  }

  .st-sub-section4 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section4-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section4-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section4-description p:last-child {
    margin: 0px;
  }

  .st-sub-section5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section5-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section5-description p {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section6-description p {
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
  }

  .st-sub-section6-description p:last-child {
    margin: 0px;
  }

  .st-sub-section7 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section7-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section7-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section7-description p:last-child {
    margin: 0px;
  }

  .st-sub-section8 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section8-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section8-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section8-description p {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section9 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section9-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section9-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section9-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section9-description p:last-child {
    margin: 0px;
  }

  .st-sub-section9-description ol {
    margin: 0px;
    color: #333333;
  }

  .st-sub-section9-description p>ol>li {
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
  }

  .st-sub-section9-description ol>li:last-child {
    margin: 0px;

  }

  .st-sub-section10 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section10-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section10-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section10-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section10-description p:last-child {
    margin: 0px;
  }

  .st-sub-section11 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section11-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section11-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section11-description p {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section12 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section12-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section12-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section12-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */

  }

  .st-sub-section12-description p:last-child {
    margin: 0px;
  }

  .st-sub-section13 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section13-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section13-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section13-description p {
    margin: 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .st-sub-section14 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section14-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section14-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section14-description p {
    margin: 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .st-sub-section14-description ol {
    margin: 12px 0px 0px 0px;
    color: #333333;
  }

  .st-sub-section14-description ol>li {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section14-description ol>li:last-child {
    margin: 0px;
  }


  .st-sub-section15 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section15-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section15-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section15-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */

  }

  .st-sub-section15-description p:last-child {
    margin: 0px;
  }

  .st-sub-section16 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section16-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font: normal normal medium 18px/40px Helvetica Neue;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section16-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section16-description p {
    margin: 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .st-sub-section17 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section17-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section17-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section17-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */

  }

  .st-sub-section17-description p:last-child {
    margin: 0px;
  }

  .st-go-to-top {
    width: 100%;
    height: auto;
    margin: 40px 0px 20px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .service-terms-main-container {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    font-family: "Catamaran";
  }

  .st-actual-content-container {
    width: 90%;
    height: auto;
    border-left: none;
    border-right: none;
    margin: 0px auto 0px auto;
    background: none;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .st-first-section {
    width: 100%;
    height: auto;
    margin: 50px auto 0px auto;
  }

  .st-heading1 {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-heading1 p {
    width: auto;
    height: auto;
    margin: 0px auto 0px auto;
    text-align: center;
    font-size: 32px;
    color: #000000;
    letter-spacing: 0.32px;
    font-family: "Helvetica Neue Condensed Bold", sans-serif;
  }

  .st-last-updated-div {
    width: 100%;
    height: 29px;
    margin: 23px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
  }

  .st-last-updated-div p {
    width: 233px;
    height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0px;
    margin: 0px 0px 0px auto;
  }

  .st-description1 {
    width: 100%;
    height: auto;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    color: #000000;
    opacity: 1;
    margin: 54px auto 0px auto;
  }

  .st-description1 p {
    margin: 0px;
  }

  .st-sub-section1 {
    width: 100%;
    height: auto;
    text-align: left;
    margin: 20px auto 0px auto;
  }

  .st-sub-section1-title {
    width: auto;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section1-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section1-description p {
    text-align: left;
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .st-sub-section1-description p:last-child {
    margin: 0px;
  }

  .st-sub-section2 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section2-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;

  }

  .st-sub-section2-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section2-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section2-description p:last-child {
    margin: 0px;
  }

  .st-sub-section3 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section3-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;

  }

  .st-sub-section3-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section3-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section3-description p:last-child {
    margin: 0px;
  }

  .st-sub-section4 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section4-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section4-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section4-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section4-description p:last-child {
    margin: 0px;
  }

  .st-sub-section5 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section5-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section5-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section5-description p {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section6 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section6-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section6-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section6-description p {
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
  }

  .st-sub-section6-description p:last-child {
    margin: 0px;
  }

  .st-sub-section7 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section7-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section7-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section7-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section7-description p:last-child {
    margin: 0px;
  }

  .st-sub-section8 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section8-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section8-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section8-description p {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section9 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section9-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section9-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section9-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section9-description p:last-child {
    margin: 0px;
  }

  .st-sub-section9-description ol {
    margin: 0px;
    color: #333333;
  }

  .st-sub-section9-description p>ol>li {
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 0px 0px 12px 0px;
  }

  .st-sub-section9-description ol>li:last-child {
    margin: 0px;

  }

  .st-sub-section10 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section10-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section10-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section10-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section10-description p:last-child {
    margin: 0px;
  }

  .st-sub-section11 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section11-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section11-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section11-description p {
    margin: 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section12 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
  }

  .st-sub-section12-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
  }

  .st-sub-section12-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
  }

  .st-sub-section12-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */

  }

  .st-sub-section12-description p:last-child {
    margin: 0px;
  }

  .st-sub-section13 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section13-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section13-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section13-description p {
    margin: 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .st-sub-section14 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section14-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section14-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section14-description p {
    margin: 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .st-sub-section14-description ol {
    margin: 12px 0px 0px 0px;
    color: #333333;
  }

  .st-sub-section14-description ol>li {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
  }

  .st-sub-section14-description ol>li:last-child {
    margin: 0px;
  }


  .st-sub-section15 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section15-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section15-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section15-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */

  }

  .st-sub-section15-description p:last-child {
    margin: 0px;
  }

  .st-sub-section16 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section16-title {
    width: 100%;
    height: 20px;
    text-align: left;
    font: normal normal medium 18px/40px Helvetica Neue;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section16-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section16-description p {
    margin: 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */
  }

  .st-sub-section17 {
    width: 100%;
    height: auto;
    margin: 20px auto 0px auto;
    /* background-color: rgba(7, 52, 92, 0.718); */
  }

  .st-sub-section17-title {
    width: 100%;
    height: 20px;
    text-align: left;
    /* font: normal normal medium 18px/40px Helvetica Neue; */
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #000000;
    opacity: 1;
    margin: 0px 0px 14px 0px;
    /* text-transform: uppercase; */

  }

  .st-sub-section17-description {
    width: 100%;
    height: auto;
    margin: 0px auto 0px auto;
    /* background: #7d3434; */
  }

  .st-sub-section17-description p {
    margin: 0px 0px 12px 0px;
    font-size: 16px;

    letter-spacing: 0.16px;
    line-height: 24px;
    /* font: normal normal medium 16px Helvetica; */

  }

  .st-sub-section17-description p:last-child {
    margin: 0px;
  }

  .st-go-to-top {
    width: 100%;
    height: auto;
    margin: 40px 0px 20px 0px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
  }
}